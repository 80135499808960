import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  GetAllProjectlibraryDetails,
  GetBusinessCaseSummaryData,
  GetFinancialsAbatementData,
  GetGMPTableData,
  GetProjectBusinessRulesData,
} from "../services/decarbLibrary.service";

const initialState = {
  projectLibrary: [],
  projectLibraryLoading: false,
  summary: {},
  summaryLoading: false,
  businessRules: [],
  businessRulesLoading: false,
  abatementData: [],
  financialData: [],
  abatementFinancialDataLoading: false,
  gmpData: [],
  gmpDataLoading: false,
  error: null,
};

export const getProjectLibrary = createAsyncThunk(
  "DecarbLibrary/GetAllProjectlibraryDetails",
  async () => {
    const apiResponse = await GetAllProjectlibraryDetails();

    return apiResponse;
  }
);

export const getBusinessCases = createAsyncThunk(
  "DecarbLibrary/GetBusinessCaseSummaryData",
  async (request) => {
    const apiResponse = await GetBusinessCaseSummaryData({ request });

    return apiResponse;
  }
);

export const getBusinessRules = createAsyncThunk(
  "DecarbLibrary/GetProjectBusinessRulesData",
  async (request) => {
    const apiResponse = await GetProjectBusinessRulesData({ request });

    return apiResponse;
  }
);

export const getAbatementData = createAsyncThunk(
  "DecarbLibrary/GetFinancialsAbatementData",
  async (request) => {
    const apiResponse = await GetFinancialsAbatementData({ request });

    return apiResponse;
  }
);

export const getGmpTable = createAsyncThunk(
  "DecarbLibrary/GetGMPTableData",
  async (request) => {
    const apiResponse = await GetGMPTableData({ request });

    return apiResponse;
  }
);

const decarbLibrarySlice = createSlice({
  name: "decarbLibrary",
  initialState,
  reducers: {
    dropDecarbLibraryState(state) {
      state.projectLibrary = initialState.projectLibrary;
      state.projectLibraryLoading = initialState.projectLibraryLoading;
      state.businessRules = initialState.businessRules;
      state.businessRulesLoading = initialState.businessRulesLoading;
      state.summary = initialState.summary;
      state.summaryLoading = initialState.summaryLoading;
      state.abatementData = initialState.abatementData;
      state.financialData = initialState.financialData;
      state.abatementFinancialDataLoading =
        initialState.abatementFinancialDataLoading;
      state.error = initialState.error;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getProjectLibrary.pending, (state) => {
        state.projectLibraryLoading = true;
      })
      .addCase(getProjectLibrary.fulfilled, (state, action) => {
        state.projectLibraryLoading = false;
        state.projectLibrary = action.payload;
      })
      .addCase(getProjectLibrary.rejected, (state, action) => {
        state.projectLibraryLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getBusinessCases.pending, (state) => {
        state.summaryLoading = true;
      })
      .addCase(getBusinessCases.fulfilled, (state, action) => {
        state.summaryLoading = false;
        state.summary = action.payload;
      })
      .addCase(getBusinessCases.rejected, (state, action) => {
        state.summaryLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getBusinessRules.pending, (state) => {
        state.businessRulesLoading = true;
      })
      .addCase(getBusinessRules.fulfilled, (state, action) => {
        state.businessRulesLoading = false;
        state.businessRules = action.payload;
      })
      .addCase(getBusinessRules.rejected, (state, action) => {
        state.businessRulesLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getAbatementData.pending, (state) => {
        state.abatementDataLoading = true;
      })
      .addCase(getAbatementData.fulfilled, (state, action) => {
        state.abatementDataLoading = false;
        state.abatementData = action.payload.abatement;
        state.financialData = action.payload.financials;
      })
      .addCase(getAbatementData.rejected, (state, action) => {
        state.abatementDataLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getGmpTable.pending, (state) => {
        state.gmpDataLoading = true;
      })
      .addCase(getGmpTable.fulfilled, (state, action) => {
        state.gmpDataLoading = false;
        state.gmpData = action.payload;
      })
      .addCase(getGmpTable.rejected, (state, action) => {
        state.gmpDataLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { dropDecarbLibraryState } = decarbLibrarySlice.actions;

export default decarbLibrarySlice.reducer;
