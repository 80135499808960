import React from "react";

export function EmissionIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9178_22563)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.9987 7.16732C8.27484 7.16732 8.4987 7.39118 8.4987 7.66732V11.0007C8.4987 11.2768 8.27484 11.5007 7.9987 11.5007C7.72256 11.5007 7.4987 11.2768 7.4987 11.0007V7.66732C7.4987 7.39118 7.72256 7.16732 7.9987 7.16732Z"
          fill="#A4A3B1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.33985 4.62826C8.5451 4.81299 8.56174 5.12914 8.37701 5.33439L8.37035 5.3418C8.18562 5.54706 7.86947 5.56369 7.66422 5.37896C7.45896 5.19423 7.44232 4.87809 7.62705 4.67284L7.63372 4.66543C7.81845 4.46017 8.13459 4.44353 8.33985 4.62826Z"
          fill="#A4A3B1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.9987 1.83398C4.59294 1.83398 1.83203 4.5949 1.83203 8.00065C1.83203 11.4064 4.59294 14.1673 7.9987 14.1673C11.4045 14.1673 14.1654 11.4064 14.1654 8.00065C14.1654 4.5949 11.4045 1.83398 7.9987 1.83398ZM0.832031 8.00065C0.832031 4.04261 4.04066 0.833984 7.9987 0.833984C11.9567 0.833984 15.1654 4.04261 15.1654 8.00065C15.1654 11.9587 11.9567 15.1673 7.9987 15.1673C4.04066 15.1673 0.832031 11.9587 0.832031 8.00065Z"
          fill="#A4A3B1"
        />
      </g>
      <defs>
        <clipPath id="clip0_9178_22563">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
