import React from "react";

export function AddForecastIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 3.6C14.25 2.85442 14.8544 2.25 15.6 2.25H20.4C21.1456 2.25 21.75 2.85442 21.75 3.6V20.4C21.75 21.1456 21.1456 21.75 20.4 21.75H3.6C2.85442 21.75 2.25 21.1456 2.25 20.4V16.6C2.25 15.8544 2.85441 15.25 3.6 15.25H8.25V9.6C8.25 8.85442 8.85441 8.25 9.6 8.25H14.25V3.6ZM14.25 9.75H9.75V20.25H14.25V9.75ZM15.75 20.25H20.25V3.75H15.75V20.25ZM8.25 20.25V16.75H3.75V20.25H8.25Z"
        fill="#A4A3B1"
      />
    </svg>
  );
}
