import React, { useState, useEffect } from "react";
import { Table } from "../../../Table/Table";
import "./IntensityTargetTable.scss";
import InputCustomRenderer from "./InputCustomRenderer";
import InputNumberCustomRenderer from "./InputNumberCustomRenderer";
import { suppressEnter, suppressNavigation } from "../../../../util/tabUtil";
export function IntensityTargetTable({
  startYear,
  endYear,
  onUpdate,
  targetOutput,
  targetDataType,
  targetyearData,
}) {
  const [columnDefs, setColumnDefs] = useState();
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    if (startYear && endYear && startYear < endYear) {
      let colDefs = [];
      colDefs.push(
        {
          field: "dataType",
          headerName: "Data Type",
          headerTooltip: "Data Type",
          pinned: "left",
          suppressMovable: true,
          suppressNavigable: true,
          cellRenderer: InputCustomRenderer,
          suppressCellFocus: true,
          suppressKeyboardEvent: (params) => {
            return suppressEnter(params) || suppressNavigation(params);
          },
          cellRendererParams: () => {
            return {
              onUpdate: onUpdate,
            };
          },
        },
        {
          field: "outputUnit",
          headerName: "Output unit",
          headerTooltip: "Output unit",
          minWidth: 200,
          suppressMovable: true,
          suppressNavigable: true,
          suppressCellFocus: true,
          suppressKeyboardEvent: (params) => {
            return suppressEnter(params) || suppressNavigation(params);
          },
          cellRenderer: InputCustomRenderer,
          cellRendererParams: () => {
            return {
              onUpdate: onUpdate,
            };
          },
        }
      );
      for (let i = 0; i <= +endYear - +startYear; i++) {
        if (i === 0) {
          colDefs.push({
            field: `${Number(startYear) + i}`,
            headerName: `${Number(startYear) + i}` + " (base year)",
            headerTooltip: `${Number(startYear) + i}` + " (base year)",
            suppressMovable: true,
            suppressNavigable: true,
            suppressCellFocus: true,
            suppressKeyboardEvent: (params) => {
              return suppressEnter(params) || suppressNavigation(params);
            },
            cellRenderer: InputNumberCustomRenderer,
            cellRendererParams: () => {
              return {
                onUpdate: onUpdate,
              };
            },
          });
        } else {
          colDefs.push({
            field: `${Number(startYear) + i}`,
            headerName: `${Number(startYear) + i}`,
            headerTooltip: `${Number(startYear) + i}`,
            suppressMovable: true,
            suppressNavigable: true,
            suppressCellFocus: true,
            suppressKeyboardEvent: (params) => {
              return suppressEnter(params) || suppressNavigation(params);
            },
            cellRenderer: InputNumberCustomRenderer,
            cellRendererParams: () => {
              return {
                onUpdate: onUpdate,
              };
            },
          });
        }
      }

      setColumnDefs(colDefs);
      checkForIntensityTableData();
    }
  }, [endYear, startYear]);

  const checkForIntensityTableData = () => {
    let sampleObj = {};
    if (Object.keys(targetyearData).length > 0) {
      const targetyearDataSet = targetyearData;
      const x = targetyearDataSet.map((item) => {
        sampleObj[item.activityYear] = item.manualIntensity_Value;
      });
    }
    const dataType = "dataType";
    const outputUnit = "outputUnit";
    sampleObj[dataType] = targetDataType ? targetDataType : "";
    sampleObj[outputUnit] = targetOutput ? targetOutput : "";
    onUpdate(sampleObj);
    setRowData([sampleObj]);
  };
  return (
    <div className="emissions-table">
      <Table
        rowData={rowData}
        colDefs={columnDefs}
        pagination={false}
        draggable={false}
      />
    </div>
  );
}
