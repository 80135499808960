import React, { useState } from "react";
import { Table } from "../../../../../Table/Table";
import { decamelize } from "../../../../../../util/dateUtil";
import { useSelector } from "react-redux";

export function SimulateAbatementTable({ data, loader }) {
  const project = useSelector((state) => state.project);

  //Generate Dynamic table columns for abatement data
  function getAbatementColDefs() {
    let defaultCols = [
      {
        field: "Category",
        minWidth: 300,
        headerName: "",
        suppressMovable: true,
        pinned: "left",
        valueFormatter: (p) => decamelize(p.value, " "),
      },
      {
        field: "Unit",
        width: 100,
        headerName: "Unit",
        suppressMovable: true,
      },
    ];

    if (data?.length) {
      const firstRow = data?.[0];
      Object.keys(firstRow).forEach((key) => {
        if (key !== "Category" && key !== "Unit") {
          defaultCols.push({
            field: key,
            minWidth: 200,
            headerName: key,
            suppressMovable: true,
          });
        }
      });
    }

    return defaultCols;
  }

  return (
    <div className="project-results-table">
      <Table
        colDefs={data?.length > 0 ? getAbatementColDefs() : []}
        rowData={data ? data : []}
        draggable={false}
        loading={loader}
      />
    </div>
  );
}
