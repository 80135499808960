import ExcelJS from "exceljs";

/**
 * @method validateExcel
 * This function will validate the excel file based on the headers provided.
 */
export function validateExcel(file, excelHeaders, calllback) {
  var reader = new FileReader();

  reader.onloadend = function (event) {
    var arrayBuffer = reader.result;

    var workbook = new ExcelJS.Workbook();
    workbook.xlsx.load(arrayBuffer).then(async function (workbook) {
      const headerRow = workbook.worksheets[0].findRow(1);
      const templateHeaders = headerRow.values;
      let response = {};

      if (headerRow.cellCount !== excelHeaders.length) {
        response = {
          status: "Error",
          message:
            "The number of columns in the excel file is not equal to the number of columns in the template.",
        };

        return calllback(response);
      }

      const arraysDiff = excelHeaders.filter(
        (x) => !templateHeaders.includes(x)
      );

      if (arraysDiff.length > 0) {
        response = {
          status: "Error",
          message:
            "The column headers in the excel file do not match the column headers in the template.",
        };
        return calllback(response);
      }

      if (
        arraysDiff.length === 0 &&
        headerRow.cellCount === excelHeaders.length
      ) {
        response = {
          status: "Success",
          message: "",
        };
      }

      calllback(response);
    });
  };
  reader.readAsArrayBuffer(file);
}

/**
 * @method createExcelFile
 * This function will create an excel file from the data provided.
 */
export async function createExcelFile(sheets) {
  if (sheets && sheets.length > 0) {
    const workbook = new ExcelJS.Workbook();

    for (let i = 0; i < sheets.length; i++) {
      if (!sheets[i].data || sheets[i].data.length === 0) {
        continue;
      }

      const worksheet = workbook.addWorksheet(sheets[i].name);

      worksheet.columns = Object.keys(sheets[i].data[0]).map((header) => {
        return {
          header: header.replace("zvalueToReplace", ""),
          key: header,
          width: 20,
        };
      });

      worksheet.getRow(1).font = {
        size: 12,
        bold: true,
      };

      sheets[i].data.forEach((d) => {
        worksheet.addRow(d);
      });
    }

    const buffer = await workbook.xlsx.writeBuffer();

    return new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
    });
  }
}
