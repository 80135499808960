import { useDispatch, useSelector } from "react-redux";
import {
  CancelBtn,
  forecastSkipModalBody,
  forecastSkipModalEmptyFEY,
  forecastSkipModalHeader,
  YesSkipBtn,
} from "../../../../util/constants";
import { Button } from "../../../Button/Button";
import { Modal } from "../../../Modal/Modal";
import "./SkipForecastModal.scss";
import { useState } from "react";
import { updateSkipForecast } from "../../../../store/services/engagement.service";
import {
  setShowForecastFlag,
  setShowTargetFlag,
  setSkipForecast,
} from "../../../../store/slices/engagementSlice";
import {
  setShowForecast,
  setShowTarget,
} from "../../../../store/services/forecast.service";
import { LoadingIndicatorEmbed } from "../../../LoadingIndicator/LoadingIndicatorEmbed";

function ModalBody() {
  const engagement = useSelector((state) => state.engagement.engagement);

  return (
    <div>
      <span className="forecast-modal-body">
        {engagement.foreCastEndYear
          ? forecastSkipModalBody
          : forecastSkipModalEmptyFEY}
      </span>
    </div>
  );
}

function ModalFooter({ handleCancelClick, onConfirm, isDataSaveInProgress }) {
  const engagement = useSelector((state) => state.engagement.engagement);

  return (
    <div className="forecast-modal-footer">
      <Button
        variant="secondary"
        onClick={handleCancelClick}
        disabled={isDataSaveInProgress}
      >
        {CancelBtn}
      </Button>
      <LoadingIndicatorEmbed show={isDataSaveInProgress}>
        <Button
          onClick={onConfirm}
          variant="primary"
          disabled={isDataSaveInProgress || !engagement.foreCastEndYear}
        >
          {YesSkipBtn}
        </Button>
      </LoadingIndicatorEmbed>
    </div>
  );
}

export function SkipForecastModal({ isModalVisible, onClose, onConfirm }) {
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const [isDataSaveInProgress, setIsDataSaveInProgress] = useState(false);

  async function onConfirmClick() {
    setIsDataSaveInProgress(true);

    try {
      await updateSkipForecast({
        request: {
          engagementId: engagement.id,
          skipvalue: 1,
        },
      });
      dispatch(setSkipForecast({ skipForecast: 1 }));

      await setShowForecast({
        request: { engagementId: engagement.id, status: 1 },
      });
      dispatch(setShowForecastFlag({ show: 1 }));
      await setShowTarget({
        request: { engagementId: engagement.id, status: 0 },
      });
      dispatch(setShowTargetFlag({ show: 0 }));
    } catch (e) {
      console.error(e);
    }

    setIsDataSaveInProgress(false);
    onConfirm();
  }

  function onCloseClick() {
    setIsDataSaveInProgress(false);
    onClose();
  }

  return (
    <Modal
      show={isModalVisible}
      onClose={onCloseClick}
      header={forecastSkipModalHeader}
      body={<ModalBody />}
      footer={
        <ModalFooter
          handleCancelClick={onCloseClick}
          onConfirm={onConfirmClick}
          isDataSaveInProgress={isDataSaveInProgress}
        />
      }
    />
  );
}
