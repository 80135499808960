import React, { useState, useEffect } from "react";
import "./AddOrganizationSpecModal.scss";
import { Select } from "../../../Select/Select";
import { Button } from "../../../Button/Button";
import {
  CancelBtn,
  SaveBtn,
  entLevel1,
  entLevel2,
  entLevel3,
  entLevel4,
  selectOrg,
} from "../../../../util/constants";
import { Modal } from "../../../Modal/Modal";

export function OrganizationModal({
  isModalVisible,
  onClose,
  scopeArray,
  hierarchyData,
  forecastOrganizationalData,
  selectedEmissionModalData,
  selectedGeoData,
  selectedOrgModalData,
  onConfirm,
  onEdit,
}) {
  // const { state } = useLocation();
  const [orgLevel1, setorgLevel1] = useState([]);
  const [orgLevel2, setorgLevel2] = useState([]);
  const [orgLevel3, setorgLevel3] = useState([]);
  const [orgLevel4, setorgLevel4] = useState([]);
  const [orgLevel1Array, setorgLevel1Array] = useState([]);
  const [orgLevel2Array, setorgLevel2Array] = useState([]);
  const [orgLevel3Array, setorgLevel3Array] = useState([]);
  const [orgLevel4Array, setorgLevel4Array] = useState([]);
  const [filteredByScopeArray, setFilteredByScope] = useState([]);
  const [disableLevel2, setDisableLevel2] = useState(true);
  const [disableLevel3, setDisableLevel3] = useState(true);
  const [disableLevel4, setDisableLevel4] = useState(true);
  const [disableSave, setDisableSave] = useState(true);
  const [level1SelectAll, setlevel1SelectAll] = useState(false);
  const [level2SelectAll, setlevel2SelectAll] = useState(false);
  const [selectAllLevel, setSelectAllLevel] = useState("");
  async function GetAllHierarchyData() {
    if (hierarchyData) {
      let filteredorgArray = [];
      if (scopeArray) {
        filteredorgArray = hierarchyData.filter((element) =>
          scopeArray.includes(element.scope)
        );
        await setFilteredByScope(filteredorgArray);
      }

      if (selectedOrgModalData) {
        if (selectedOrgModalData.length == 0) {
          setorgLevel1([]);
          setorgLevel2([]);
          setorgLevel3([]);
          setorgLevel4([]);
          setorgLevel1Array([]);
          setorgLevel2Array([]);
          setorgLevel3Array([]);
          setorgLevel4Array([]);
          setDisableLevel2(true);
          setDisableLevel3(true);
          setDisableLevel4(true);
          setDisableSave(true);
        }
      }

      if (selectedEmissionModalData) {
        if (selectedEmissionModalData?.category_level3?.includes("All")) {
          if (selectedEmissionModalData?.category_level2?.includes("All")) {
            if (selectedEmissionModalData?.category_level1?.includes("All")) {
            } else {
              filteredorgArray = filteredorgArray.filter((element) =>
                selectedEmissionModalData?.category_level1?.includes(
                  element.category_level1
                )
              );
            }
          } else {
            filteredorgArray = filteredorgArray.filter(
              (element) =>
                selectedEmissionModalData?.category_level1?.includes(
                  element.category_level1
                ) &&
                selectedEmissionModalData?.category_level2?.includes(
                  element.category_level2
                )
            );
          }
        } else {
          filteredorgArray = filteredorgArray.filter((element) =>
            selectedEmissionModalData?.category_level3?.includes(
              element.category_level3
            )
          );
        }
      }
      if (selectedGeoData) {
        if (selectedGeoData?.region_level3?.includes("All")) {
          if (selectedGeoData?.region_level2?.includes("All")) {
            if (selectedGeoData?.region_level1?.includes("All")) {
            } else {
              filteredorgArray = filteredorgArray.filter((element) =>
                selectedGeoData?.region_level1?.includes(element.region_level1)
              );
            }
          } else {
            filteredorgArray = filteredorgArray.filter(
              (element) =>
                selectedGeoData?.region_level1?.includes(
                  element.region_level1
                ) &&
                selectedGeoData?.region_level2?.includes(element.region_level2)
            );
          }
        } else {
          if (selectedGeoData?.region_level3 !== undefined) {
            filteredorgArray = filteredorgArray.filter((element) =>
              selectedGeoData?.region_level3?.includes(element.region_level3)
            );
          }
        }
      }
      let orglevel1 = [
        ...new Set(filteredorgArray.map((element) => element.entity_level1)),
      ];
      orglevel1 = orglevel1.filter((element) => element !== null);
      if (orglevel1.length === 0) {
        orglevel1.push("N/A");
      }
      await setFilteredByScope(filteredorgArray);
      await setorgLevel1Array(orglevel1);
      if (
        forecastOrganizationalData?.entity_level4 &&
        forecastOrganizationalData?.functionality !== "Save"
      ) {
        await handleEditModal(forecastOrganizationalData, filteredorgArray);
      }
    }
  }
  async function handleEditModal(forecastOrganizationalData, filteredorgArray) {
    await setorgLevel1(forecastOrganizationalData.entity_level1);
    await setorgLevel2Array(forecastOrganizationalData.entity_level2);
    setorgLevel2(forecastOrganizationalData.entity_level2);
    setorgLevel3Array(forecastOrganizationalData.entity_level3);
    setorgLevel3(forecastOrganizationalData.entity_level3);
    setorgLevel4Array(forecastOrganizationalData.entity_level4);
    setorgLevel4(forecastOrganizationalData.entity_level4);
    setDisableSave(true);
    let selectAll = "";
    let filteredByScopeArray = filteredorgArray.filter((element) =>
      scopeArray.includes(element.scope)
    );
    let emissionLevel1 = [
      ...new Set(filteredByScopeArray.map((element) => element.entity_level1)),
    ];
    if (
      emissionLevel1.length === forecastOrganizationalData.entity_level1.length
    ) {
      selectAll = "orgLevel1";
    } else {
      let filteredByScopeArray = hierarchyData.filter(
        (element) =>
          forecastOrganizationalData.entity_level1.includes(
            element.entity_level1
          ) && scopeArray.includes(element.scope)
      );
      let emissionLevel2 = [
        ...new Set(
          filteredByScopeArray.map((element) => element.entity_level2)
        ),
      ];
      if (
        emissionLevel2.length ===
        forecastOrganizationalData.entity_level2.length
      ) {
        selectAll = "orgLevel2";
      } else {
        let filteredByScopeArray = hierarchyData.filter(
          (element) =>
            forecastOrganizationalData.entity_level1.includes(
              element.entity_level1
            ) &&
            scopeArray.includes(element.scope) &&
            forecastOrganizationalData.entity_level2.includes(
              element.entity_level2
            )
        );
        let emissionLevel3 = [
          ...new Set(
            filteredByScopeArray.map((element) => element.entity_level3)
          ),
        ];
        if (
          emissionLevel3.length ===
          forecastOrganizationalData.entity_level3.length
        ) {
          selectAll = "orgLevel3";
        } else {
          let filteredByScopeArray = hierarchyData.filter(
            (element) =>
              forecastOrganizationalData.entity_level1.includes(
                element.entity_level1
              ) &&
              scopeArray.includes(element.scope) &&
              forecastOrganizationalData.entity_level2.includes(
                element.entity_level2
              ) &&
              forecastOrganizationalData.entity_level3.includes(
                element.entity_level3
              )
          );
          let emissionLevel4 = [
            ...new Set(
              filteredByScopeArray.map((element) => element.entity_level4)
            ),
          ];
          if (
            emissionLevel4.length ===
            forecastOrganizationalData.entity_level4.length
          ) {
            selectAll = "orgLevel3";
          }
        }
      }
    }
    await handleEdit(selectAll, filteredorgArray);
  }
  async function handleLevel1Change(event) {
    await setorgLevel2Array([]);
    await setorgLevel3Array([]);
    await setorgLevel4Array([]);
    await setorgLevel1(event);
    await setorgLevel2([]);
    await setorgLevel3([]);
    await setorgLevel4([]);
    setDisableSave(true);
    setDisableLevel3(true);
    setDisableLevel4(true);
    setDisableLevel2(false);
    let filteredorgArray = filteredByScopeArray.filter(
      (element) =>
        event.includes(element.entity_level1) &&
        scopeArray.includes(element.scope)
    );
    let orglevel2 = [
      ...new Set(filteredorgArray.map((element) => element.entity_level2)),
    ];
    orglevel2 = orglevel2.filter((element) => element !== null);
    if (orglevel2.length === 0) {
      orglevel2.push("N/A");
    }
    await setorgLevel2Array(orglevel2);
    if (orglevel2.length === 1 && orglevel2[0] !== "N/A") {
      handleLevel2Change(orglevel2, event);
    }
    if (event.length === orgLevel1Array.length && orgLevel1Array.length !== 1) {
      setlevel1SelectAll(true);
      setSelectAllLevel("orgLevel1");
    } else {
      setSelectAllLevel("");
      event.length > 0 ? setDisableLevel2(false) : setDisableLevel2(true);
    }
  }
  async function handleLevel2Change(event, level1) {
    setorgLevel2(event);
    setorgLevel3([]);
    setorgLevel4([]);
    await setorgLevel3Array([]);
    await setorgLevel4Array([]);
    setDisableSave(true);
    setlevel2SelectAll(false);
    let level1selected = [];
    if (level1?.length > 0) {
      level1selected = level1;
    } else {
      level1selected = orgLevel1;
    }
    let array = filteredByScopeArray.filter(
      (element) =>
        level1selected.includes(element.entity_level1) &&
        scopeArray.includes(element.scope)
    );
    let filteredorgArray = array.filter(
      (element) =>
        event.includes(element.entity_level2) &&
        scopeArray.includes(element.scope)
    );
    let orglevel3 = [
      ...new Set(filteredorgArray.map((element) => element.entity_level3)),
    ];
    orglevel3 = orglevel3.filter((element) => element !== null);
    if (orglevel3.length === 0) {
      orglevel3.push("N/A");
    }
    await setorgLevel3Array(orglevel3);
    if (orglevel3.length === 1 && orglevel3[0] !== "N/A") {
      handleLevel3Change(orglevel3, event);
    }
    if (orgLevel2Array.length === event.length && orgLevel2Array.length !== 1) {
      setlevel2SelectAll(true);
      if (selectAllLevel === "") {
        setSelectAllLevel("orgLevel2");
      }
    } else {
      setSelectAllLevel("");
      event.length > 0 ? setDisableLevel3(false) : setDisableLevel3(true);
    }
  }
  async function handleLevel3Change(event, level2) {
    setorgLevel3(event);
    setorgLevel4([]);
    await setorgLevel4Array([]);
    setDisableSave(true);
    let level2selected = [];
    if (level2?.length > 0) {
      level2selected = level2;
    } else {
      level2selected = orgLevel2;
    }
    let filteredorgArray = filteredByScopeArray.filter(
      (element) =>
        event.includes(element.entity_level3) &&
        level2selected.includes(element.entity_level2) &&
        scopeArray.includes(element.scope)
    );

    let orglevel3 = [
      ...new Set(filteredorgArray.map((element) => element.entity_level4)),
    ];
    orglevel3 = orglevel3.filter((element) => element !== null);
    if (orglevel3.length === 0) {
      orglevel3.push("N/A");
    }
    await setorgLevel4Array(orglevel3);
    if (orglevel3.length === 1 && orglevel3[0] !== "N/A") {
      handleLevel4Change(orglevel3);
    }
    if (orgLevel3Array.length === event.length && orgLevel3Array.length !== 1) {
      handleLevel4Change(orglevel3);
      if (selectAllLevel === "") {
        setSelectAllLevel("orgLevel3");
      }
    } else {
      setSelectAllLevel("");
      event.length > 0 ? setDisableLevel4(false) : setDisableLevel4(true);
    }
  }
  function handleLevel4Change(event) {
    setorgLevel4(event);
    if (event.length === orgLevel4Array.length && orgLevel4Array.length !== 1) {
      if (selectAllLevel === "") {
        setSelectAllLevel("orgLevel4");
      }
    }
    event.length > 0 ? setDisableSave(false) : setDisableSave(true);
  }
  async function processLevel2forLevel1SelectAll() {
    handleLevel2Change(orgLevel2Array);
    orgLevel2Array.length > 1
      ? setDisableLevel2(true)
      : setDisableLevel2(false);
  }
  async function processLevel3forLevel2SelectAll() {
    handleLevel3Change(orgLevel3Array);
    orgLevel3Array.length > 1
      ? setDisableLevel3(true)
      : setDisableLevel3(false);
    handleLevel4Change(orgLevel4Array);
    orgLevel4Array.length > 1 || disableLevel3
      ? setDisableLevel4(true)
      : setDisableLevel4(false);
  }
  async function handleAllpayload(selectAll, filteredorgArray) {
    let array = [];
    let filteredArray = [];
    if (filteredorgArray.length > 0) {
      filteredArray = filteredorgArray.filter((element) =>
        scopeArray.includes(element.scope)
      );
    } else {
      filteredArray = filteredByScopeArray.filter((element) =>
        scopeArray.includes(element.scope)
      );
    }

    if (selectAllLevel === "orgLevel1" || selectAll === "orgLevel1") {
      scopeArray.forEach((scope) => {
        for (let i = 0; i < filteredArray.length; i++) {
          if (scope === filteredArray[i].scope) {
            let record = {
              scope: scope,
              category_level1: filteredArray[i].category_level1,
              category_level2: filteredArray[i].category_level2,
              category_level3: filteredArray[i].category_level3,
              region_level1: filteredArray[i].region_level1,
              region_level2: filteredArray[i].region_level2,
              region_level3: filteredArray[i].region_level3,
              entity_level1: "All",
              entity_level2: "All",
              entity_level3: "All",
              entity_level4: "All",
              custom_specification1: filteredArray[i]["custom_specification1"],
              custom_specification2: filteredArray[i]["custom_specification2"],
              custom_specification3: filteredArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAllLevel === "orgLevel2") {
      orgLevel1.forEach((record) => {
        for (let i = 0; i < filteredArray.length; i++) {
          if (
            record === filteredArray[i].entity_level1 &&
            scopeArray.includes(filteredArray[i].scope)
          ) {
            let record = {
              scope: filteredArray[i].scope,
              category_level1: filteredArray[i].category_level1,
              category_level2: filteredArray[i].category_level2,
              category_level3: filteredArray[i].category_level3,
              region_level1: filteredArray[i].region_level1,
              region_level2: filteredArray[i].region_level2,
              region_level3: filteredArray[i].region_level3,
              entity_level1: filteredArray[i].entity_level1,
              entity_level2: "All",
              entity_level3: "All",
              entity_level4: "All",
              custom_specification1: filteredArray[i]["custom_specification1"],
              custom_specification2: filteredArray[i]["custom_specification2"],
              custom_specification3: filteredArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (
      forecastOrganizationalData?.entity_level1 &&
      selectAll === "orgLevel2"
    ) {
      forecastOrganizationalData.entity_level1.forEach((record) => {
        for (let i = 0; i < filteredArray.length; i++) {
          if (
            record === filteredArray[i].entity_level1 &&
            scopeArray.includes(filteredArray[i].scope)
          ) {
            let record = {
              scope: filteredArray[i].scope,
              category_level1: filteredArray[i].category_level1,
              category_level2: filteredArray[i].category_level2,
              category_level3: filteredArray[i].category_level3,
              region_level1: filteredArray[i].region_level1,
              region_level2: filteredArray[i].region_level2,
              region_level3: filteredArray[i].region_level3,
              entity_level1: filteredArray[i].entity_level1,
              entity_level2: "All",
              entity_level3: "All",
              entity_level4: "All",
              custom_specification1: filteredArray[i]["custom_specification1"],
              custom_specification2: filteredArray[i]["custom_specification2"],
              custom_specification3: filteredArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAllLevel === "orgLevel3") {
      orgLevel2.forEach((record) => {
        for (let i = 0; i < filteredArray.length; i++) {
          if (
            record === filteredArray[i].entity_level2 &&
            scopeArray.includes(filteredArray[i].scope) &&
            orgLevel1.includes(filteredArray[i].entity_level1)
          ) {
            let record = {
              scope: filteredArray[i].scope,
              category_level1: filteredArray[i].category_level1,
              category_level2: filteredArray[i].category_level2,
              category_level3: filteredArray[i].category_level3,
              region_level1: filteredArray[i].region_level1,
              region_level2: filteredArray[i].region_level2,
              region_level3: filteredArray[i].region_level3,
              entity_level1: filteredArray[i].entity_level1,
              entity_level2: filteredArray[i].entity_level2,
              entity_level3: "All",
              entity_level4: "All",
              custom_specification1: filteredArray[i]["custom_specification1"],
              custom_specification2: filteredArray[i]["custom_specification2"],
              custom_specification3: filteredArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (
      forecastOrganizationalData?.entity_level2 &&
      selectAll === "orgLevel3"
    ) {
      forecastOrganizationalData.entity_level2.forEach((record) => {
        for (let i = 0; i < filteredArray.length; i++) {
          if (
            record === filteredArray[i].entity_level2 &&
            scopeArray.includes(filteredArray[i].scope) &&
            forecastOrganizationalData.entity_level1.includes(
              filteredArray[i].entity_level1
            )
          ) {
            let record = {
              scope: filteredArray[i].scope,
              category_level1: filteredArray[i].category_level1,
              category_level2: filteredArray[i].category_level2,
              category_level3: filteredArray[i].category_level3,
              region_level1: filteredArray[i].region_level1,
              region_level2: filteredArray[i].region_level2,
              region_level3: filteredArray[i].region_level3,
              entity_level1: filteredArray[i].entity_level1,
              entity_level2: filteredArray[i].entity_level2,
              entity_level3: "All",
              entity_level4: "All",
              custom_specification1: filteredArray[i]["custom_specification1"],
              custom_specification2: filteredArray[i]["custom_specification2"],
              custom_specification3: filteredArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAllLevel === "orgLevel4") {
      orgLevel3.forEach((record) => {
        for (let i = 0; i < filteredArray.length; i++) {
          if (
            record === filteredArray[i].entity_level3 &&
            scopeArray.includes(filteredArray[i].scope) &&
            orgLevel1.includes(filteredArray[i].entity_level1) &&
            orgLevel2.includes(filteredArray[i].entity_level2)
          ) {
            let record = {
              scope: filteredArray[i].scope,
              category_level1: filteredArray[i].category_level1,
              category_level2: filteredArray[i].category_level2,
              category_level3: filteredArray[i].category_level3,
              region_level1: filteredArray[i].region_level1,
              region_level2: filteredArray[i].region_level2,
              region_level3: filteredArray[i].region_level3,
              entity_level1: filteredArray[i].entity_level1,
              entity_level2: filteredArray[i].entity_level2,
              entity_level3: filteredArray[i].entity_level3,
              entity_level4: "All",
              custom_specification1: filteredArray[i]["custom_specification1"],
              custom_specification2: filteredArray[i]["custom_specification2"],
              custom_specification3: filteredArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (
      forecastOrganizationalData?.entity_level3 &&
      selectAll === "orgLevel4"
    ) {
      forecastOrganizationalData.entity_level3.forEach((record) => {
        for (let i = 0; i < filteredArray.length; i++) {
          if (
            record === filteredArray[i].entity_level3 &&
            scopeArray.includes(filteredArray[i].scope) &&
            forecastOrganizationalData.entity_level1.includes(
              filteredArray[i].entity_level1
            ) &&
            forecastOrganizationalData.entity_level2.includes(
              filteredArray[i].entity_level2
            )
          ) {
            let record = {
              scope: filteredArray[i].scope,
              category_level1: filteredArray[i].category_level1,
              category_level2: filteredArray[i].category_level2,
              category_level3: filteredArray[i].category_level3,
              region_level1: filteredArray[i].region_level1,
              region_level2: filteredArray[i].region_level2,
              region_level3: filteredArray[i].region_level3,
              entity_level1: filteredArray[i].entity_level1,
              entity_level2: filteredArray[i].entity_level2,
              entity_level3: filteredArray[i].entity_level3,
              entity_level4: "All",
              custom_specification1: filteredArray[i]["custom_specification1"],
              custom_specification2: filteredArray[i]["custom_specification2"],
              custom_specification3: filteredArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    return array;
  }
  async function processData(functionality, array) {
    let uniqueRecords = [];
    let coveredReg2 = [];
    let filteredEmissionArray = filteredByScopeArray.filter((element) =>
      scopeArray.includes(element.scope)
    );
    if (functionality === "Save") {
      if (orgLevel3.length == 1 && orgLevel3[0] == "N/A") {
        if (orgLevel2.length == 1 && orgLevel2[0] == "N/A") {
          if (orgLevel1.length == 1 && orgLevel1[0] == "N/A") {
            let record = {
              scope: scopeArray[0],
              entity_level1: "",
              entity_level2: "",
              entity_level3: "",
              entity_level4: "",
            };
            uniqueRecords.push(record);
          } else {
            orgLevel1.forEach((value) => {
              let index = filteredEmissionArray.findIndex(
                (element) => element.entity_level1 === value
              );
              if (index !== -1) {
                let record = {
                  scope: scopeArray[0],
                  entity_level1: filteredEmissionArray[index]["entity_level1"],
                  entity_level2: filteredEmissionArray[index]["entity_level2"],
                  entity_level3: filteredEmissionArray[index]["entity_level3"],
                  entity_level4: filteredEmissionArray[index]["entity_level4"],
                  region_level1: filteredEmissionArray[index]["region_level1"],
                  region_level2: filteredEmissionArray[index]["region_level2"],
                  region_level3: filteredEmissionArray[index]["region_level3"],
                  category_level1:
                    filteredEmissionArray[index]["category_level1"],
                  category_level2:
                    filteredEmissionArray[index]["category_level2"],
                  category_level3:
                    filteredEmissionArray[index]["category_level3"],
                };
                uniqueRecords.push(record);
              }
            });
          }
        } else {
          orgLevel2.forEach((value) => {
            if (value !== "N/A") {
              let index = filteredEmissionArray.findIndex(
                (element) => element.entity_level2 === value
              );
              if (index !== -1) {
                let record = {
                  scope: filteredEmissionArray[index]["scope"],
                  entity_level1: filteredEmissionArray[index]["entity_level1"],
                  entity_level2: filteredEmissionArray[index]["entity_level2"],
                  entity_level3: filteredEmissionArray[index]["entity_level3"],
                  entity_level4: filteredEmissionArray[index]["entity_level4"],
                  region_level1: filteredEmissionArray[index]["region_level1"],
                  region_level2: filteredEmissionArray[index]["region_level2"],
                  region_level3: filteredEmissionArray[index]["region_level3"],
                  category_level1:
                    filteredEmissionArray[index]["category_level1"],
                  category_level2:
                    filteredEmissionArray[index]["category_level2"],
                  category_level3:
                    filteredEmissionArray[index]["category_level3"],
                };
                uniqueRecords.push(record);
              }
            }
          });
        }
      } else {
        orgLevel3.forEach((value) => {
          for (let i = 0; i < filteredEmissionArray.length; i++) {
            if (
              value === filteredEmissionArray[i].entity_level3 &&
              orgLevel4.includes(filteredEmissionArray[i].entity_level4)
            ) {
              let record = {
                scope: filteredEmissionArray[i]["scope"],
                entity_level1: filteredEmissionArray[i]["entity_level1"],
                entity_level2: filteredEmissionArray[i]["entity_level2"],
                entity_level3: filteredEmissionArray[i]["entity_level3"],
                entity_level4: filteredEmissionArray[i]["entity_level4"],
                region_level1: filteredEmissionArray[i]["region_level1"],
                region_level2: filteredEmissionArray[i]["region_level2"],
                region_level3: filteredEmissionArray[i]["region_level3"],
                category_level1: filteredEmissionArray[i]["category_level1"],
                category_level2: filteredEmissionArray[i]["category_level2"],
                category_level3: filteredEmissionArray[i]["category_level3"],
                custom_specification1:
                  filteredEmissionArray[i]["custom_specification1"],
                custom_specification2:
                  filteredEmissionArray[i]["custom_specification2"],
                custom_specification3:
                  filteredEmissionArray[i]["custom_specification3"],
              };
              uniqueRecords.push(record);
            }
          }
        });
      }
    }
    if (functionality === "Edit") {
      filteredEmissionArray = array.filter((element) =>
        scopeArray.includes(element.scope)
      );
      forecastOrganizationalData.entity_level3.forEach((value) => {
        for (let i = 0; i < filteredEmissionArray.length; i++) {
          if (
            value === filteredEmissionArray[i].entity_level3 &&
            forecastOrganizationalData.entity_level4.includes(
              filteredEmissionArray[i].entity_level4
            )
          ) {
            let record = {
              scope: filteredEmissionArray[i]["scope"],
              entity_level1: filteredEmissionArray[i]["entity_level1"],
              entity_level2: filteredEmissionArray[i]["entity_level2"],
              entity_level3: filteredEmissionArray[i]["entity_level3"],
              entity_level4: filteredEmissionArray[i]["entity_level4"],
              region_level1: filteredEmissionArray[i]["region_level1"],
              region_level2: filteredEmissionArray[i]["region_level2"],
              region_level3: filteredEmissionArray[i]["region_level3"],
              category_level1: filteredEmissionArray[i]["category_level1"],
              category_level2: filteredEmissionArray[i]["category_level2"],
              category_level3: filteredEmissionArray[i]["category_level3"],
              custom_specification1:
                filteredEmissionArray[i]["custom_specification1"],
              custom_specification2:
                filteredEmissionArray[i]["custom_specification2"],
              custom_specification3:
                filteredEmissionArray[i]["custom_specification3"],
            };
            uniqueRecords.push(record);
          }
        }
      });
    }
    return uniqueRecords;
  }
  async function handleSave() {
    setlevel2SelectAll(false);
    setlevel1SelectAll(false);
    const uniqueRecords = await processData("Save", []);
    const allRecords = await handleAllpayload(selectAllLevel, []);
    onConfirm({
      records: uniqueRecords,
      entity_level1: orgLevel1,
      entity_level2: orgLevel2,
      entity_level3: orgLevel3,
      entity_level4: orgLevel4,
      allPayload: allRecords.length > 0 ? allRecords : uniqueRecords,
      selectAll: selectAllLevel,
      functionality: "Save",
    });
  }
  async function handleEdit(selectAll, filteredorgArray) {
    const uniqueRecords = await processData("Edit", filteredorgArray);
    const allRecords = await handleAllpayload(selectAll, filteredorgArray);
    onEdit({
      records: uniqueRecords,
      entity_level1: forecastOrganizationalData.entity_level1,
      entity_level2: forecastOrganizationalData.entity_level2,
      entity_level3: forecastOrganizationalData.entity_level3,
      entity_level4: forecastOrganizationalData.entity_level4,
      allPayload: allRecords.length > 0 ? allRecords : uniqueRecords,
      selectAll: selectAll,
    });
  }

  useEffect(() => {
    GetAllHierarchyData();
  }, [
    scopeArray,
    hierarchyData,
    selectedEmissionModalData,
    selectedGeoData,
    selectedOrgModalData,
  ]);
  useEffect(() => {
    if (level1SelectAll) {
      processLevel2forLevel1SelectAll();
    }
  }, [level1SelectAll]);
  useEffect(() => {
    if (level2SelectAll) {
      processLevel3forLevel2SelectAll();
    }
  }, [level2SelectAll]);

  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={selectOrg}
      body={
        <div className="add-custom-modal-body">
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {entLevel1}
              <span className="required-star">*</span>
            </span>
            <Select
              value={orgLevel1}
              options={orgLevel1Array}
              onChange={handleLevel1Change}
              multiple={true}
              showSelectAllButton={true}
            ></Select>
          </div>
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {entLevel2}
              <span className="required-star">*</span>
            </span>
            <Select
              value={orgLevel2}
              options={orgLevel2Array}
              onChange={handleLevel2Change}
              multiple={true}
              showSelectAllButton={true}
              disabled={disableLevel2}
            ></Select>
          </div>
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {entLevel3}
              <span className="required-star">*</span>
            </span>
            <Select
              value={orgLevel3}
              options={orgLevel3Array}
              onChange={handleLevel3Change}
              multiple={true}
              showSelectAllButton={true}
              disabled={disableLevel3}
            ></Select>
          </div>
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {entLevel4}
              <span className="required-star">*</span>
            </span>
            <Select
              value={orgLevel4}
              options={orgLevel4Array}
              multiple={true}
              onChange={handleLevel4Change}
              showSelectAllButton={true}
              disabled={disableLevel4}
            ></Select>
          </div>
        </div>
      }
      footer={
        <div className="forecast-modal-footer">
          <Button variant="secondary" onClick={() => onClose([])}>
            {CancelBtn}
          </Button>
          <Button
            variant="primary"
            className="save"
            onClick={handleSave}
            disabled={disableSave}
          >
            {SaveBtn}
          </Button>
        </div>
      }
    />
  );
}
