import React, { useState } from "react";
import { DeleteLogo, EditIcon, SaveIcon } from "../../../icons";
import { MotifIconButton } from "@ey-xd/motif-react";
import { memo } from "react";
import { useSelector } from "react-redux";

// Render Delete button in table
export default memo(({ node, onDeleteClick, onEditClick, onSaveClick }) => {
  const gmp = useSelector((state) => state.gmp.gmp);
  const [saveIcon, showSaveIcon] = useState(false);
  const onEditIconClick = (data) => {
    // showSaveIcon(true);
    onEditClick(data);
  };
  const onSaveIconClick = (data) => {
    showSaveIcon(false);
    onSaveClick(data);
  };
  return (
    <div>
      {saveIcon && (
        <MotifIconButton
          aria-label="save"
          onClick={() => {
            onSaveIconClick(node.data);
          }}
        >
          <SaveIcon></SaveIcon>
        </MotifIconButton>
      )}
      {!saveIcon && (
        <div>
          <MotifIconButton
            aria-label="edit"
            disabled={node.data.IsValidated === "N"}
            className={
              gmp?.rowIndex === node.rowIndex
                ? "editClass"
                : "nonEditClass" +
                  " " +
                  (node.data.IsValidated === "N" ? "disableEditGMPIcon" : "")
            }
            onClick={() => {
              onEditIconClick(node);
            }}
          >
            <EditIcon></EditIcon>
          </MotifIconButton>
          <MotifIconButton
            aria-label="delete"
            className={
              gmp?.rowIndex === node.rowIndex ? "editClass" : "nonEditClass"
            }
            onClick={() => {
              onDeleteClick(node.data);
            }}
          >
            <DeleteLogo></DeleteLogo>
          </MotifIconButton>
        </div>
      )}
    </div>
  );
});
