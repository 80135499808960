import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Table } from "../Table/Table";
import "./FinancialsTable.scss";
import CustomActionsRenderer from "./customActionRenderer";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../Button/Button";
import { ConfirmationModal } from "../CommonUIComponents/ConfirmationModal/ConfimationModal";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import {
  deleteRuleModalHeader,
  deleteRuleModalBody,
  deleteRuleModalBodyDesc,
  calculate,
} from "../../util/constants";
import { getColumnValueFlags } from "../../store/services/project.service";
import {
  getGMPRulesMapData,
  deleteGmpRule,
  applyGMPRules,
} from "../../store/services/gmp.service";
import {
  setrulesMapData,
  setIsVariableApply,
} from "../../store/slices/gmpSlice";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";

export function FinancialsTable() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const steps = useSelector((state) => state.stepper.steps);
  const gmp = useSelector((state) => state.gmp.gmp);
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const [tableData, setTableData] = useState([]);
  const [selectedRowData, setRuleData] = useState([]);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [resetCompleted, setresetCompleted] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [filterValidateRows, setFilterValidateRows] = useState([]);
  const lockingData = useSelector((state) => state.project?.lockingData);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const user = useSelector((state) => state.user.user);
  let refreshIntervalId = undefined;
  let refreshIntervalCount = 0;

  // screen refresh once financial falg is completed
  useEffect(() => {
    if (resetCompleted) {
      screenRefreshafterApply();
    }
  }, [resetCompleted]);

  useEffect(() => {
    if (gmp?.rulesMapData) {
      setTableData(gmp.rulesMapData);
      checkIsValidateFlag(gmp.rulesMapData);
    }
  }, [gmp?.rulesMapData]);

  const checkIsValidateFlag = (data) => {
    let filterArray = data.filter(
      (element) => element?.isValidated?.toUpperCase() === "N"
    );
    setFilterValidateRows(filterArray);
  };
  // Refreshingthe screen after reset flag check is commplete
  const screenRefreshafterApply = async () => {
    getRulesMapData();
    setShowLoader(false);
    refreshIntervalCount = 0;
  };

  // Onclick of delete icon
  async function onDeleteClick(data) {
    setRuleData(data);
    setDeleteModalVisible(true);
  }

  // On confirmation of deleteion
  async function onDeleteProjectModalConfirm() {
    setDeleteLoader(true);
    setDeleteModalVisible(false);
    const request = {
      ruleId: selectedRowData?.gmpRuleID,
    };
    const response = await deleteGmpRule({ request });
    dispatch(setIsVariableApply({ isVariableApply: false }));
    getRulesMapData();
  }

  // Get GMP rules data to refresh table
  const getRulesMapData = async () => {
    const request = {
      projectId: project.projectId,
    };
    const data = await getGMPRulesMapData({ request });
    dispatch(setrulesMapData({ rulesMapData: data }));
    setDeleteLoader(false);
  };

  const onDeleteProjectModalClose = () => {
    setDeleteModalVisible(false);
  };

  const applyVariables = async () => {
    // setVariableApply(true);
    dispatch(setIsVariableApply({ isVariableApply: true }));
    setShowLoader(true);
    const request = {
      engCode: engagement.engagementCode,
      projectId: project.projectId,
    };
    const data = await applyGMPRules({ request });
    console.error(data);
    try {
      refreshIntervalId = window.setInterval(checkFlags, 2000);
    } catch (error) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setShowLoader(false);
    }
  };

  // Check the  Financial calulation flag
  const checkFlags = async () => {
    refreshIntervalCount++;
    if (refreshIntervalCount > 30) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setShowLoader(false);
      refreshIntervalCount = 0;
    }
    const flagsPayload = {
      columnName: "ApplyGMP",
      tableName: "projectrule",
      id: project.projectId,
    };
    const response = await getColumnValueFlags({ flagsPayload });
    if (response.columnValue === "3") {
      if (refreshIntervalId) {
        window.clearInterval(refreshIntervalId);
        refreshIntervalId = undefined;
      }
      setresetCompleted(true);
      setShowLoader(false);
    }
  };

  return (
    <div className="financials-table">
       <LoadingIndicatorEmbed
          show={deleteLoader}
          className={"decarb-loading-indicator-transparent"}
        >
      <Table
        rowData={tableData}
        colDefs={[
          {
            headerName: "Parameter",
            field: "parameter_name",
            headerTooltip: "Parameter",
          },
          {
            headerName: "Rule name",
            field: "projectRuleName",
            headerTooltip: "Rule name",
          },
          {
            headerName: "Variable type",
            field: "variable_type",
            headerTooltip: "Variable type",
          },
          {
            headerName: "Data type",
            field: "gmP_data_type",
            headerTooltip: "Data type",
          },
          {
            cellRenderer: CustomActionsRenderer,
            suppressMovable: true,
            cellRendererParams: () => {
              return {
                onDeleteClick: onDeleteClick,
              };
            },
            field: "actions",
            headerName: "",
            flex: 1,
            minWidth: 150,
            suppressMovable: true,
          },
        ]}
        draggable={false}
        rowClassRules={{
          "gmp-link-table-pending-validation": (params) => {
            return params?.data?.isValidated === "N";
          },
        }}
      />
       </LoadingIndicatorEmbed>
      <ConfirmationModal
        isModalVisible={isDeleteModalVisible}
        onClose={onDeleteProjectModalClose}
        onConfirm={onDeleteProjectModalConfirm}
        modalHeaderText={deleteRuleModalHeader}
        modalBodyText={deleteRuleModalBody}
        modalDescriptionText={deleteRuleModalBodyDesc}
      />
      <div className="secondary-btn-apply">
        <LoadingIndicatorEmbed show={showLoader}>
          <Button
            variant="primary-alt"
            disabled={
              gmp?.rulesMapData?.length === 0 ||
              filterValidateRows.length > 0 ||
              gmp?.isVariableApply === true ||
              (lockingData?.data?.lockStatus === 1 &&
                lockingData?.data?.lockedBy !== user.username)
            }
            onClick={applyVariables}
          >
            {calculate}
          </Button>
        </LoadingIndicatorEmbed>
        <span className="new-label">
            {"For any Financial Rule Mapping additions or deletions, please click 'Calculate' to update results"}
          </span>
      </div>
    </div>
  );
}
