import React from "react";

export function Log() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0013 3.3763C9.34648 3.3763 9.6263 3.65612 9.6263 4.0013L9.6263 8.3763H14.0013C14.3465 8.3763 14.6263 8.65612 14.6263 9.0013C14.6263 9.34648 14.3465 9.6263 14.0013 9.6263H9.0013C8.65613 9.6263 8.3763 9.34648 8.3763 9.0013L8.3763 4.0013C8.3763 3.65612 8.65612 3.3763 9.0013 3.3763Z"
        fill="#85B9FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.0013 1.29297C4.74411 1.29297 1.29297 4.74411 1.29297 9.0013C1.29297 13.2585 4.74411 16.7096 9.0013 16.7096C13.2585 16.7096 16.7096 13.2585 16.7096 9.0013C16.7096 4.74411 13.2585 1.29297 9.0013 1.29297ZM0.0429688 9.0013C0.0429688 4.05375 4.05375 0.0429688 9.0013 0.0429688C13.9489 0.0429688 17.9596 4.05375 17.9596 9.0013C17.9596 13.9489 13.9489 17.9596 9.0013 17.9596C4.05375 17.9596 0.0429688 13.9489 0.0429688 9.0013Z"
        fill="#85B9FD"
      />
    </svg>
  );
}
