import { Modal } from "../../Modal/Modal";
import "./SessionTimeoutModal.scss";
import {
  sessionTimeoutLogOut,
  sessionTimeoutMessage,
  sessionTimeoutStayLoggedIn,
  sessionTimeoutTitle,
} from "../../../util/constants";
import { Button } from "../../Button/Button";
import { useEffect, useState } from "react";

function ModalHeader() {
  return (
    <div className="session-timeout-modal-header">
      <span>{sessionTimeoutTitle}</span>
    </div>
  );
}

function ModalFooter({ handleCancelClick, onConfirm }) {
  return (
    <div className="view-endagement-delete-modal-footer">
      <Button variant="secondary" onClick={handleCancelClick}>
        {sessionTimeoutLogOut}
      </Button>
      <Button onClick={onConfirm} variant="primary">
        {sessionTimeoutStayLoggedIn}
      </Button>
    </div>
  );
}

export function SessionTimeoutModal({ isModalVisible, onCancel, onConfirm }) {
  const padTime = (time) => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };

  const format = (time) => {
    // Convert seconds into minutes and take the whole part
    const minutes = Math.floor(time / 60);

    // Get the seconds left after converting minutes
    const seconds = time % 60;

    //Return combined values as string in format mm:ss
    return `${minutes}:${padTime(seconds)}`;
  };

  const [counter, setCounter] = useState(300);

  useEffect(() => {
    if (isModalVisible) {
      let timer;
      if (counter > 0) {
        timer = setTimeout(() => setCounter((c) => c - 1), 1000);
      }

      if (counter === 0) {
        onCancel();
      }

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }
  }, [counter, isModalVisible]);

  return (
    <Modal
      show={isModalVisible}
      onClose={onConfirm}
      header={<ModalHeader />}
      body={<span>{sessionTimeoutMessage.replace("$1", format(counter))}</span>}
      footer={
        <ModalFooter handleCancelClick={onCancel} onConfirm={onConfirm} />
      }
      className={"session-timeout-modal"}
    />
  );
}
