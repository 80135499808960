import { format } from "date-fns";

export function formarDateTime(date, formatStr = "yyyy-MM-dd hh:mm:ss") {
  return date ? format(date, formatStr) : "";
}

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function decamelize(str, separator) {
  separator = typeof separator === "undefined" ? "_" : separator;
  let asd = str
    .replace(/([a-z\d])([A-Z])/g, "$1" + separator + "$2")
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + separator + "$2")
    .toLowerCase();

  return asd.charAt(0).toUpperCase() + asd.substr(1, asd.length - 1);
}
