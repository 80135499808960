import { MotifCheckbox } from "@ey-xd/motif-react";
import React, { memo, useState } from "react";
import { useSelector } from "react-redux";

export default memo(({ value, node, onValueChange }) => {
  const selectedRowData = useSelector((state) => state.project.selectedRowData);
  const selectedRow = useSelector((state) => state.project.isAnyRowSelected);
  const [check, setCheck] = useState(false);

  const handleChange = (event) => {
    if (event.target.checked) {
      setCheck(true);
    } else {
      setCheck(false);
    }
    onValueChange(event.target.checked, node);
  };
  return (
    <MotifCheckbox
      id={node.data.id}
      name="checkbox-example"
      onChange={(event) => handleChange(event)}
      value={node.data}
      checked={
        check ||
        (selectedRowData.rowIndex === node.rowIndex &&
          selectedRowData.isAnyRowSelected)
      }
      disabled={
        !check &&
        selectedRowData.rowIndex !== node.rowIndex &&
        selectedRowData.isAnyRowSelected
      }
    ></MotifCheckbox>
  );
});
