import { memo } from "react";
import { Input } from "../../../Input/Input";

export default memo(({ value, setValue, node, onUpdate }) => {
  function updateValue(event) {
    setValue(event.target.value);
    onUpdate(node.data)
  }

  return node.data.data !== "BAU data (fixed)" ? (
    <Input
      type="text"
      onChange={updateValue}
      id={crypto.randomUUID()}
      value={value}
      name="motif-checkbox"
    />
  ) : (
    <span>{value}</span>
  );
});
