import { useSelector } from "react-redux";
import "./ForecastHeading.scss";

export function ForecastHeading() {
  const engagement = useSelector((state) => state.engagement.engagement);

  return (
    <div className="forecast-heading">
      <span className="forecast-heading-main">{engagement.engagementName}</span>
      <span className="forecast-heading-desc">{engagement.clientName}</span>
    </div>
  );
}
