import { SearchIcon } from "../../icons";
import "./SearchInput.scss";
import { MotifErrorMessage, MotifSearch } from "@ey-xd/motif-react";

export function SearchInput({
  label,
  onChange,
  value,
  id = crypto.randomUUID(),
  className,
  disabled = false,
  maxLength = 100,
  isError,
  errorMessage,
  disableErrorPlaceholder = true,
  hideClearButton = false,
  placeholder,
  onEnter,
  onSearchButtonClick,
  ...other
}) {
  return (
    <>
      <MotifSearch
        {...other}
        aria-label="Search"
        id={id}
        label={label}
        onChange={onChange}
        value={value}
        className={`decarb-search-input  ${className}`}
        disabled={disabled}
        maxLength={maxLength}
        hideClearButton={hideClearButton || disabled}
        placeholder={placeholder}
        onEnter={onEnter}
        onSearchButtonClick={onSearchButtonClick}
        icons={{ search: <SearchIcon /> }}
      />
      {isError ? (
        <MotifErrorMessage className="decarb-input-error-message">
          {errorMessage}
        </MotifErrorMessage>
      ) : !disableErrorPlaceholder ? (
        <div className="decarb-input-hide-error">No Error</div>
      ) : (
        <></>
      )}
    </>
  );
}
