import { useSelector } from "react-redux";
import { Modal } from "../../Modal/Modal";
import { RepeatIcon } from "../../../icons/repeat";
import {
  fileVersionHistoryModalHeaderDate,
  fileVersionHistoryModalHeaderFileName,
  fileVersionHistoryModalHeaderUploadedBy,
  manageEmissionBodyUploadFileHistory,
} from "../../../util/constants";
import { Table } from "../../Table/Table";
import { customCellRenderer } from "./cutomCellRenderer";
import { formarDateTime } from "../../../util/dateUtil";

function ModalHeader() {
  return (
    <div className="file-version-history-modal-header">
      <div className="motif-fab">
        <RepeatIcon />
      </div>
      <span>{manageEmissionBodyUploadFileHistory}</span>
    </div>
  );
}

function ModalBody() {
  const fileHistory = useSelector((state) => state.forecast.fileUploadHistory);
  const fileHistoryLoading = useSelector(
    (state) => state.forecast.fileUploadHistoryLoading
  );

  return (
    <div>
      <Table
        rowData={fileHistory}
        colDefs={[
          {
            field: "uploadDate",
            filter: true,
            headerName: fileVersionHistoryModalHeaderDate,
            headerTooltip: fileVersionHistoryModalHeaderDate,
            maxWidth: 110,
            suppressMovable: true,
            valueFormatter: (params) => {
              return formarDateTime(params.value, "dd MMMM yyyy");
            },
          },
          {
            field: "fileName",
            filter: true,
            headerName: fileVersionHistoryModalHeaderFileName,
            headerTooltip: fileVersionHistoryModalHeaderFileName,
            flex: 1,
            minWidth: 200,
            suppressMovable: true,
          },
          {
            field: "email",
            filter: true,
            headerName: fileVersionHistoryModalHeaderUploadedBy,
            headerTooltip: fileVersionHistoryModalHeaderUploadedBy,
            minWidth: 400,
            flex: 1,
            suppressMovable: true,
          },
          {
            field: "liveVersion",
            headerName: "",
            filter: true,
            cellRenderer: customCellRenderer,
            maxWidth: 150,
            suppressMovable: true,
          },
        ]}
        draggable={false}
        loading={fileHistoryLoading}
      />
    </div>
  );
}

export function FileVersionHistoryModal({ isModalVisible, onClose }) {
  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={<ModalHeader />}
      body={<ModalBody />}
      size={"xl"}
      className={"file-version-history-modal"}
    />
  );
}
