import { configureStore } from "@reduxjs/toolkit";
import stepperSlice from "./slices/stepperSlice";
import engagementSlice from "./slices/engagementSlice";
import projectSlice from "./slices/projectSlice";
import setupSlice from "./slices/setupSlice";
import forecastSlice from "./slices/forecastSlice";
import userSlice from "./slices/userSlice";
import simulateSlice from "./slices/simulateSlice";
import decarbLibrarySlice from "./slices/decarbLibrarySlice";
import gmpSlice from "./slices/gmpSlice";
import { thunk } from "redux-thunk";

export default configureStore({
  reducer: {
    stepper: stepperSlice,
    engagement: engagementSlice,
    project: projectSlice,
    setup: setupSlice,
    forecast: forecastSlice,
    user: userSlice,
    simulate: simulateSlice,
    decarbLibrary: decarbLibrarySlice,
    gmp: gmpSlice,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    thunk,
  ],
});
