import React from "react";

export function ControlSlider() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.08965 4.79102C5.45575 4.79102 4.96881 5.35246 5.05845 5.98L6.24893 14.3133C6.32224 14.8265 6.76174 15.2077 7.28013 15.2077H7.72248C8.24086 15.2077 8.68036 14.8265 8.75367 14.3133L9.94415 5.97999C10.0338 5.35246 9.54686 4.79102 8.91295 4.79102H6.08965ZM3.82102 6.15677C3.62379 4.7762 4.69506 3.54102 6.08965 3.54102H8.91295C10.3075 3.54102 11.3788 4.77619 11.1816 6.15677L9.99111 14.4901C9.82983 15.6191 8.86293 16.4577 7.72248 16.4577H7.28013C6.13968 16.4577 5.17278 15.6191 5.01149 14.4901L3.82102 6.15677Z"
        fill="#85B9FD"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.04297 9.99935C1.04297 9.65417 1.32279 9.37435 1.66797 9.37435H5.0013C5.34648 9.37435 5.6263 9.65417 5.6263 9.99935C5.6263 10.3445 5.34648 10.6243 5.0013 10.6243H1.66797C1.32279 10.6243 1.04297 10.3445 1.04297 9.99935ZM9.3763 9.99935C9.3763 9.65417 9.65613 9.37435 10.0013 9.37435H18.3346C18.6798 9.37435 18.9596 9.65417 18.9596 9.99935C18.9596 10.3445 18.6798 10.6243 18.3346 10.6243H10.0013C9.65613 10.6243 9.3763 10.3445 9.3763 9.99935Z"
        fill="#85B9FD"
      />
    </svg>
  );
}
