import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteLogo, EditIcon } from "../../../icons";
import { MotifIconButton } from "@ey-xd/motif-react";
import { routes } from "../../../routes/routes.constatns";
import { useSelector } from "react-redux";
import { AddForecastCustomDelete } from "./CustomDeleteModal";
import "./CustomEditComponent.scss";
import { deleteForecast } from "../../../store/services/forecast.service";
export default memo(({ node, onDelete }) => {
  const navigate = useNavigate();
  const engagement = useSelector((state) => state.engagement.engagement);
  const [isModalVisible, setModal] = useState(false);
  const user = useSelector((state) => state.user.user);
  const lockingData = useSelector((state) => state.project.lockingData);

  const onClose = () => {
    setModal(false);
  };

  async function deleteRecord() {
    const request = {
      engagementId: engagement.id,
      batchGroupId: node.data.batchGroupId,
    };
    const response = await deleteForecast({ request });
    if (response.status === "Success") {
      onClose();

      onDelete();
    }
  }

  async function editRecord() {
    navigate(`${routes.addForecastSpecification}`, {
      state: {
        engagementId: engagement.id,
        batchGroupId: node.data.batchGroupId,
        isValidated: node.data.isValidated,
      },
      gestureEnabled: false,
    });
  }

  return (
    <div className="add-forecast-table-custom-edit">
      <MotifIconButton
        onClick={editRecord}
        disabled={
          lockingData?.data.lockStatus === 1 &&
          lockingData.data?.lockedBy !== user.username
        }
      >
        <EditIcon />
      </MotifIconButton>
      <MotifIconButton
        disabled={
          lockingData.data.lockStatus === 1 &&
          lockingData.data.lockedBy !== user.username
        }
        className={`delete-forecast-icon ${
          node.data.statusFlag === "New" ? "delete-forecast-icon-inactive" : ""
        }`}
        onClick={() => {
          setModal(true);
        }}
      >
        <DeleteLogo />
      </MotifIconButton>
      <AddForecastCustomDelete
        isModalVisible={isModalVisible}
        onClose={onClose}
        onDelete={deleteRecord}
        data={node.data}
      />
    </div>
  );
});
