import { CancelBtn, ConfirmBtn, YesBtn } from "../../../util/constants";
import { Button } from "../../Button/Button";
import { Modal } from "../../Modal/Modal";
import "./ConfimationModal.scss";

function ModalBody(text) {
  return (
    <>
      <span className="view-endagement-delete-modal-body">
        {text.modalText}
      </span>
      <br></br>
      <div className="view-endagement-delete-modal-body-descr">
        {text.modalDescpText}
      </div>
    </>
  );
}

function ModalFooter({ handleCancelClick, onConfirm }) {
  return (
    <div className="view-endagement-delete-modal-footer">
      <Button variant="secondary" onClick={handleCancelClick}>
        {CancelBtn}
      </Button>
      <Button onClick={onConfirm} variant="primary">
        {ConfirmBtn}
      </Button>
    </div>
  );
}

export function ConfirmationModal({
  isModalVisible,
  onClose,
  onConfirm,
  modalHeaderText,
  modalBodyText,
  modalDescriptionText,
}) {
  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={modalHeaderText}
      body={
        <ModalBody
          modalText={modalBodyText}
          modalDescpText={modalDescriptionText}
        />
      }
      footer={<ModalFooter handleCancelClick={onClose} onConfirm={onConfirm} />}
    />
  );
}
