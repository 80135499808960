import { MotifProgressLoader } from "@ey-xd/motif-react";
import "./LoadingIndicatorEmbed.scss";

export function LoadingIndicatorEmbed({
  show,
  fullscreen = false,
  children,
  className = "",
}) {
  return (
    <>
      <MotifProgressLoader
        show={show}
        fullscreen={fullscreen}
        className={`decarb-loading-indicator-embed ${className}`}
      >
        {children}
      </MotifProgressLoader>
    </>
  );
}
