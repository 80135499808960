import React, { useEffect, useState } from "react";
import "./AddCustomModal.scss";
import { Select } from "../../../Select/Select";
import { Button } from "../../../Button/Button";
import {
  CancelBtn,
  SaveBtn,
  custom1,
  custom2,
  custom3,
  selectCustom,
} from "../../../../util/constants";
import { Modal } from "../../../Modal/Modal";

export function CustomModal({
  isModalVisible,
  onClose,
  hierarchyData,
  scopeArray,
  onConfirm,
  targetCustomData,
  onEdit,
  selectedEmissionModalData,
  selectedOrgModalData,
  selectedGeoData,
  selectedCustomData,
}) {
  const [customLevel1, setcustomLevel1] = useState([]);
  const [customLevel2, setcustomLevel2] = useState([]);
  const [customLevel3, setcustomLevel3] = useState([]);
  const [customLevel1Array, setcustomLevel1Array] = useState([]);
  const [customLevel2Array, setcustomLevel2Array] = useState([]);
  const [customLevel3Array, setcustomLevel3Array] = useState([]);
  const [customModalOption, setCustomModalOptions] = useState([{}]);
  const [disableLevel2, setDisableLevel2] = useState(true);
  const [disableLevel3, setDisableLevel3] = useState(true);
  const [disableSave, setDisableSave] = useState(true);
  const [level1SelectAll, setlevel1SelectAll] = useState(false);
  const [selectAllLevel, setSelectAllLevel] = useState("");

  async function GetAllHierarchyData() {
    if (hierarchyData) {
      let filteredData = hierarchyData.filter((element) =>
        scopeArray.includes(element.scope)
      );
      if (selectedCustomData) {
        if (selectedCustomData.length === 0) {
          setcustomLevel1([]);
          setcustomLevel1Array([]);
          setcustomLevel2([]);
          setcustomLevel2Array([]);
          setcustomLevel3([]);
          setcustomLevel3Array([]);
          setDisableLevel2(true);
          setDisableLevel3(true);
          setDisableSave(true);
        }
      }
      if (selectedEmissionModalData) {
        if (selectedEmissionModalData?.category_level3 !== undefined) {
          filteredData = filteredData.filter((element) =>
            selectedEmissionModalData?.category_level3.includes(
              element.category_level3
            )
          );
        }
      }
      if (selectedOrgModalData) {
        if (selectedOrgModalData?.entity_level4 !== undefined) {
          filteredData = filteredData.filter((element) =>
            selectedOrgModalData?.entity_level4?.includes(element.entity_level4)
          );
        }
      }
      if (selectedGeoData) {
        if (selectedGeoData?.region_level3 !== undefined) {
          filteredData = filteredData.filter((element) =>
            selectedGeoData?.region_level3?.includes(element.region_level3)
          );
        }
      }
      setCustomModalOptions(filteredData);
      let customlevel1 = [
        ...new Set(
          filteredData.map((element) => element.custom_specification1)
        ),
      ];
      customlevel1 = customlevel1.filter((element) => element !== null);
      if (customlevel1.length === 0) {
        customlevel1.push("N/A");
      }
      setcustomLevel1Array(customlevel1);
      if (
        targetCustomData?.custom_specification3 &&
        targetCustomData?.functionality !== "Save"
      ) {
        await handleEditModal(targetCustomData, filteredData, customlevel1);
      }
    }
  }
  async function handleEditModal(targetCustomData, filteredData, customlevel1) {
    setcustomLevel1Array(customlevel1);
    setcustomLevel1(targetCustomData.custom_specification1);
    setcustomLevel2(targetCustomData.custom_specification2);
    setcustomLevel3(targetCustomData.custom_specification3);
    setcustomLevel2Array(targetCustomData.custom_specification2);
    setcustomLevel3Array(targetCustomData.custom_specification3);

    setDisableSave(true);
    let selectAll = "";
    let region1Array = [
      ...new Set(filteredData.map((element) => element.custom_specification1)),
    ];
    if (region1Array.length === targetCustomData.custom_specification1.length) {
      selectAll = "customLevel1";
    } else {
      let filteredByScopeArray = filteredData.filter(
        (element) =>
          targetCustomData.custom_specification1.includes(
            element.custom_specification1
          ) && scopeArray.includes(element.scope)
      );
      let emissionLevel2 = [
        ...new Set(
          filteredByScopeArray.map((element) => element.custom_specification2)
        ),
      ];

      if (
        emissionLevel2.length === targetCustomData.custom_specification2.length
      ) {
        selectAll = "customLevel2";
      } else {
        let filteredArray = filteredByScopeArray.filter(
          (element) =>
            targetCustomData.custom_specification2.includes(
              element.custom_specification2
            ) && scopeArray.includes(element.scope)
        );

        let emissionLevel3 = [
          ...new Set(
            filteredArray.map((element) => element.custom_specification3)
          ),
        ];
        if (
          emissionLevel3.length ===
          targetCustomData.custom_specification3.length
        ) {
          selectAll = "customLevel3";
        }
      }
    }
    await handleEdit(selectAll, filteredData);
  }

  function handleLevel1Change(event) {
    setcustomLevel1(event);
    setcustomLevel2([]);
    setcustomLevel3([]);
    setDisableSave(true);
    setSelectAllLevel("");
    let filteredcustomArray = customModalOption.filter((element) =>
      event.includes(element.custom_specification1)
    );
    let customlevel2 = [
      ...new Set(
        filteredcustomArray.map((element) => element.custom_specification2)
      ),
    ];
    customlevel2 = customlevel2.filter((element) => element !== null);
    if (customlevel2.length === 0) {
      customlevel2.push("N/A");
    }
    setcustomLevel2Array(customlevel2);
    if (customlevel2.length === 1 && customlevel2[0] !== "N/A") {
      handleLevel2Change(customlevel2, event);
    }
    if (
      customLevel1Array.length === event.length &&
      customLevel1Array.length !== 1 &&
      !customLevel1Array.includes("N/A")
    ) {
      setlevel1SelectAll(true);
      setSelectAllLevel("customLevel1");
    } else {
      setSelectAllLevel("");
      event.length > 0 ? setDisableLevel2(false) : setDisableLevel2(true);
    }
  }

  function handleLevel2Change(event, level1) {
    setcustomLevel2(event);
    setDisableSave(true);
    setcustomLevel3([]);

    let level1selected = [];
    if (level1?.length > 0) {
      level1selected = level1;
    } else {
      level1selected = customLevel1;
    }
    let array = customModalOption.filter((element) =>
      level1selected.includes(element.custom_specification1)
    );
    let filteredcustomArray = array.filter((element) =>
      event.includes(element.custom_specification2)
    );
    let customlevel3 = [
      ...new Set(
        filteredcustomArray.map((element) => element.custom_specification3)
      ),
    ];
    customlevel3 = customlevel3.filter((element) => element !== null);
    if (customlevel3.length === 0) {
      customlevel3.push("N/A");
    }
    setcustomLevel3Array(customlevel3);
    if (customlevel3.length === 1 && customlevel3[0] !== "N/A") {
      handleLevel3Change(customlevel3);
    }
    if (
      event.length === customLevel2Array.length &&
      customLevel2Array.length !== 1 &&
      !event.includes("N/A")
    ) {
      handleLevel3Change(customlevel3);
      setDisableLevel3(true);
      if (selectAllLevel === "") {
        setSelectAllLevel("customLevel2");
      }
    } else {
      setSelectAllLevel("");
      event.length > 0 ? setDisableLevel3(false) : setDisableLevel3(true);
    }
  }

  function handleLevel3Change(event) {
    setcustomLevel3(event);
    if (
      event.length === customLevel3Array.length &&
      customLevel3Array.length !== 1 &&
      !event.includes("N/A")
    ) {
      setSelectAllLevel("customLevel3");
    }
    event.length > 0 ? setDisableSave(false) : setDisableSave(true);
  }
  async function handleAllpayload(selectAll, filteredArray) {
    let array = [];
    let filteredByScopeArray = [];
    if (filteredArray.length > 0) {
      filteredByScopeArray = filteredArray.filter((element) =>
        scopeArray.includes(element.scope)
      );
    } else {
      filteredByScopeArray = customModalOption.filter((element) =>
        scopeArray.includes(element.scope)
      );
    }
    if (selectAllLevel === "customLevel1") {
      scopeArray.forEach((scope) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (scope === filteredByScopeArray[i].scope) {
            let record = {
              scope: scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1: "All",
              custom_specification2: "All",
              custom_specification3: "All",
            };
            array.push(record);
          }
        }
      });
    }
    if (
      targetCustomData?.custom_specification1 &&
      selectAll === "customLevel1"
    ) {
      scopeArray.forEach((scope) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (scope === filteredByScopeArray[i].scope) {
            let record = {
              scope: scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1: "All",
              custom_specification2: "All",
              custom_specification3: "All",
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAllLevel === "customLevel2") {
      customLevel1.forEach((record) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            record === filteredByScopeArray[i].custom_specification1 &&
            scopeArray.includes(filteredByScopeArray[i].scope)
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i].custom_specification1,
              custom_specification2: "All",
              custom_specification3: "All",
            };
            array.push(record);
          }
        }
      });
    }
    if (
      targetCustomData?.custom_specification2 &&
      selectAll === "customLevel2"
    ) {
      targetCustomData?.custom_specification2.forEach((scope) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (scope === filteredByScopeArray[i].scope) {
            let record = {
              scope: scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i].custom_specification1,
              custom_specification2: "All",
              custom_specification3: "All",
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAllLevel === "customLevel3") {
      customLevel2.forEach((record) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            record === filteredByScopeArray[i].custom_specification2 &&
            scopeArray.includes(filteredByScopeArray[i].scope) &&
            targetCustomData.custom_specification1.includes(
              filteredByScopeArray[i].custom_specification1
            )
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i].custom_specification1,
              custom_specification2:
                filteredByScopeArray[i].custom_specification2,
              custom_specification3: "All",
            };
            array.push(record);
          }
        }
      });
    }
    if (
      targetCustomData?.custom_specification3 &&
      selectAll === "customLevel3"
    ) {
      targetCustomData?.custom_specification2.forEach((record) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            record === filteredByScopeArray[i].custom_specification2 &&
            scopeArray.includes(filteredByScopeArray[i].scope) &&
            targetCustomData.custom_specification1.includes(
              filteredByScopeArray[i].custom_specification1
            )
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i].custom_specification1,
              custom_specification2:
                filteredByScopeArray[i].custom_specification2,
              custom_specification3: "All",
            };
            array.push(record);
          }
        }
      });
    }
    return array;
  }

  async function processData(functionality, array) {
    let uniqueRecords = [];
    let filteredByScopeArray = customModalOption.filter((element) =>
      scopeArray.includes(element.scope)
    );
    if (functionality === "Save") {
      customLevel3.forEach((value) => {
        if (value !== "N/A") {
          for (let i = 0; i < filteredByScopeArray.length; i++) {
            if (
              value === filteredByScopeArray[i].region_level3 &&
              scopeArray.includes(filteredByScopeArray[i].scope) &&
              customLevel1.includes(
                filteredByScopeArray[i].custom_specification1
              ) &&
              customLevel2.includes(
                filteredByScopeArray[i].custom_specification2
              )
            ) {
              let record = {
                scope: filteredByScopeArray[i].scope,
                category_level1: filteredByScopeArray[i].category_level1,
                category_level2: filteredByScopeArray[i].category_level2,
                category_level3: filteredByScopeArray[i].category_level3,
                region_level1: filteredByScopeArray[i].region_level1,
                region_level2: filteredByScopeArray[i].region_level2,
                region_level3: filteredByScopeArray[i].region_level3,
                entity_level1: filteredByScopeArray[i]["entity_level1"],
                entity_level2: filteredByScopeArray[i]["entity_level2"],
                entity_level3: filteredByScopeArray[i]["entity_level3"],
                entity_level4: filteredByScopeArray[i]["entity_level4"],
                custom_specification1:
                  filteredByScopeArray[i]["custom_specification1"],
                custom_specification2:
                  filteredByScopeArray[i]["custom_specification2"],
                custom_specification3:
                  filteredByScopeArray[i]["custom_specification3"],
              };
              uniqueRecords.push(record);
            }
          }
        }
      });
    }
    if (functionality === "Edit") {
      filteredByScopeArray = array.filter((element) =>
        scopeArray.includes(element.scope)
      );
      targetCustomData.custom_specification3.forEach((value) => {
        if (value !== "N/A") {
          for (let i = 0; i < filteredByScopeArray.length; i++) {
            if (
              value === filteredByScopeArray[i].region_level3 &&
              scopeArray.includes(filteredByScopeArray[i].scope) &&
              targetCustomData.custom_specification1.includes(
                filteredByScopeArray[i].custom_specification1
              ) &&
              targetCustomData.custom_specification2.includes(
                filteredByScopeArray[i].custom_specification2
              )
            ) {
              let record = {
                scope: filteredByScopeArray[i].scope,
                category_level1: filteredByScopeArray[i].category_level1,
                category_level2: filteredByScopeArray[i].category_level2,
                category_level3: filteredByScopeArray[i].category_level3,
                region_level1: filteredByScopeArray[i].region_level1,
                region_level2: filteredByScopeArray[i].region_level2,
                region_level3: filteredByScopeArray[i].region_level3,
                entity_level1: filteredByScopeArray[i]["entity_level1"],
                entity_level2: filteredByScopeArray[i]["entity_level2"],
                entity_level3: filteredByScopeArray[i]["entity_level3"],
                entity_level4: filteredByScopeArray[i]["entity_level4"],
                custom_specification1:
                  filteredByScopeArray[i]["custom_specification1"],
                custom_specification2:
                  filteredByScopeArray[i]["custom_specification2"],
                custom_specification3:
                  filteredByScopeArray[i]["custom_specification3"],
              };
              uniqueRecords.push(record);
            }
          }
        }
      });
    }
    return uniqueRecords;
  }

  async function processLevel2forLevel1SelectAll() {
    handleLevel2Change(customLevel2Array);
    customLevel2Array.length > 1
      ? setDisableLevel2(true)
      : setDisableLevel2(false);
  }
  async function handleSave() {
    const uniqueRecords = await processData("Save", []);
    const allRecords = await handleAllpayload(selectAllLevel, []);
    onConfirm({
      records: uniqueRecords,
      custom_specification1: customLevel1,
      custom_specification2: customLevel2,
      custom_specification3: customLevel3,
      allPayload: allRecords.length > 0 ? allRecords : uniqueRecords,
      selectAll: selectAllLevel,
    });
  }
  async function handleEdit(selectAll, filteredData) {
    const uniqueRecords = await processData("Edit", filteredData);
    const allRecords = await handleAllpayload(selectAll, filteredData);
    onEdit({
      records: uniqueRecords,
      custom_specification1: targetCustomData.custom_specification1,
      custom_specification2: targetCustomData.custom_specification2,
      custom_specification3: targetCustomData.custom_specification3,
      allPayload: allRecords.length > 0 ? allRecords : uniqueRecords,
      selectAll: selectAll,
    });
  }

  useEffect(() => {
    GetAllHierarchyData();
  }, [
    scopeArray,
    hierarchyData,
    selectedEmissionModalData,
    selectedOrgModalData,
    selectedGeoData,
    selectedCustomData,
  ]);
  useEffect(() => {
    if (level1SelectAll) {
      processLevel2forLevel1SelectAll();
    }
  }, [level1SelectAll]);
  function ModalFooter({ handleCancelClick, onConfirm }) {
    return (
      <div className="forecast-modal-footer">
        <Button variant="secondary" onClick={handleCancelClick}>
          {CancelBtn}
        </Button>
        <Button onClick={onConfirm} variant="primary" disabled={disableSave}>
          {SaveBtn}
        </Button>
      </div>
    );
  }
  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={selectCustom}
      body={
        <div className="add-custom-modal-body">
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {custom1}
              <span className="required-star">*</span>
            </span>
            <Select
              value={customLevel1}
              options={customLevel1Array}
              multiple={true}
              onChange={handleLevel1Change}
              showSelectAllButton={true}
            ></Select>
          </div>
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {custom2}
              <span className="required-star">*</span>
            </span>
            <Select
              value={customLevel2}
              options={customLevel2Array}
              multiple={true}
              onChange={handleLevel2Change}
              showSelectAllButton={true}
              disabled={disableLevel2}
            ></Select>
          </div>
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {custom3}
              <span className="required-star">*</span>
            </span>
            <Select
              value={customLevel3}
              options={customLevel3Array}
              multiple={true}
              onChange={handleLevel3Change}
              showSelectAllButton={true}
              disabled={disableLevel3}
            ></Select>
          </div>
        </div>
      }
      footer={
        <ModalFooter handleCancelClick={onClose} onConfirm={handleSave} />
      }
    />
  );
}
