import React from "react";

export function FilterIcon() {
  return (
    <svg
      width="25"
      height="23"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.875 5.13281C1.875 4.78763 2.15482 4.50781 2.5 4.50781H17.5C17.8452 4.50781 18.125 4.78763 18.125 5.13281C18.125 5.47799 17.8452 5.75781 17.5 5.75781H2.5C2.15482 5.75781 1.875 5.47799 1.875 5.13281Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20833 10.1328C5.20833 9.78763 5.48816 9.50781 5.83333 9.50781L14.1667 9.50781C14.5118 9.50781 14.7917 9.78764 14.7917 10.1328C14.7917 10.478 14.5118 10.7578 14.1667 10.7578L5.83333 10.7578C5.48816 10.7578 5.20833 10.478 5.20833 10.1328Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54167 15.1328C8.54167 14.7876 8.82149 14.5078 9.16667 14.5078L10.8333 14.5078C11.1785 14.5078 11.4583 14.7876 11.4583 15.1328C11.4583 15.478 11.1785 15.7578 10.8333 15.7578L9.16667 15.7578C8.82149 15.7578 8.54167 15.478 8.54167 15.1328Z"
        fill="#A4A3B1"
      />
    </svg>
  );
}
