import React, { useState } from "react";
import {
  MotifAccordion,
  MotifAccordionContent,
  MotifAccordionTrigger,
} from "@ey-xd/motif-react";

export function CollapsableAccordion(
  {headerTitle,
  accordionContent}
) {
const [isOpen, setOpen]= useState(true)
{
  return (
    <MotifAccordion
      alignIconRight={true}
      onClose={() => {
        setOpen(false)
      }}
      onOpen={() => {
        setOpen(true)
      }}
      open={isOpen}
      useChevronIcon={true}
      variant={"functional"}
    >
      <MotifAccordionTrigger>{headerTitle}</MotifAccordionTrigger>
      <MotifAccordionContent>{accordionContent}</MotifAccordionContent>
    </MotifAccordion>
  );
}
}
