import React from "react";

export function SimulateIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.125 2.125V5.04167H6.70833V2.125H2.125ZM0.875 2C0.875 1.37868 1.37868 0.875 2 0.875H6.83333C7.45465 0.875 7.95833 1.37868 7.95833 2V5.16667C7.95833 5.78799 7.45465 6.29167 6.83333 6.29167H2C1.37868 6.29167 0.875 5.78799 0.875 5.16667V2Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2917 12.9583V15.875H15.875V12.9583H11.2917ZM10.0417 12.8333C10.0417 12.212 10.5453 11.7083 11.1667 11.7083H16C16.6213 11.7083 17.125 12.212 17.125 12.8333V16C17.125 16.6213 16.6213 17.125 16 17.125H11.1667C10.5453 17.125 10.0417 16.6213 10.0417 16V12.8333Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2917 2.125V9.20833H15.875V2.125H11.2917ZM10.0417 2C10.0417 1.37868 10.5453 0.875 11.1667 0.875H16C16.6213 0.875 17.125 1.37868 17.125 2V9.33333C17.125 9.95465 16.6213 10.4583 16 10.4583H11.1667C10.5453 10.4583 10.0417 9.95465 10.0417 9.33333V2Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.125 8.79167V15.875H6.70833V8.79167H2.125ZM0.875 8.66667C0.875 8.04535 1.37868 7.54167 2 7.54167H6.83333C7.45465 7.54167 7.95833 8.04535 7.95833 8.66667V16C7.95833 16.6213 7.45465 17.125 6.83333 17.125H2C1.37868 17.125 0.875 16.6213 0.875 16V8.66667Z"
        fill="#A4A3B1"
      />
    </svg>
  );
}
