import {
  MotifModal,
  MotifModalBody,
  MotifModalFooter,
  MotifModalHeader,
} from "@ey-xd/motif-react";
import "./Modal.scss";

export function Modal({
  show,
  onClose,
  header,
  body,
  footer,
  className,
  size = "default",
}) {
  return (
    <MotifModal
      onClose={onClose}
      show={show}
      className={`${className} decarb-modal`}
      size={size}
    >
      <MotifModalHeader>{header}</MotifModalHeader>
      <MotifModalBody>{body}</MotifModalBody>
      <MotifModalFooter>{footer}</MotifModalFooter>
    </MotifModal>
  );
}
