export const Project_URL = {
  GetDistinctTags: `Project/GetDistinctTags`,
  GetDistinctProjects: `Project/GetDistinctProjects`,
  CreateProjectDraft: `Project/CreateProjectDraft`,
  createM3Project: `Project/CreateM3Project`,
  SaveProjectTags: `Project/SaveProjectTags`,
  reduceDefaultViewData: `Project/GetM3Step2ReduceTableGraphData`,
  SaveEmissionFilters: `Project/SaveEmissionSelection`,
  GetSaveProjectRule: `Project/GetSavedProjectRule`,
  SaveM3Step2ReduceData: `Project/SaveM3Step2ReduceData`,
  getM3TableGraphData: `Project/GraphTableDataForSaveSimulate`,
  ProjectEditDetails: `Project/GetEditDetails`,
  ProjectEmissionSelection: `Project/GetProjectEmissionSelection`,
  RecreateProject: `Project/ReCreateProjectM3`,
  UpdateProjectStep: `Project/UpdateProjectStep`,
  GetM3GraphData: `Project/GetM3GraphData`,
  GetProjectsScenario: `Project/GetProjectsScenario`,
  GetNavBarProjects: `Project/GetNavBarProjects`,
  ResetProject: `Project/ResetProjectM3`,
  GetUserModuleLock: `Project/getUserLockByModule`,
  SaveUserModuleLock: `Project/SaveUserModule`,
  UpdateUserModuleLock: `Project/UpdateUserModuleLock`,
  ReleaseUserModuleLock: `Project/ReleaseUserModuleLock`,
  ManualEdit: `Project/SaveM3Step2ManualEdit`,
  ColumnFlags: `Project/GetColumnValue`,
  GetProjectdetails: `Project/GetProjectdetails`,
  GetUserLockByModule: `Project/getUserLockByModule`,
  GetBusinessCaseSummaryData: `DecarbLibrary/GetBusinessCaseSummaryData`,
  GetFinancialsAbatementData: `DecarbLibrary/GetFinancialsAbatementData`,
  GetProjectBusinessCaseSummaryData: `GMP/GetM3BusinessCaseSummaryData`,
  GetProjectFinancialsAbatementData: `DecarbLibrary/GetFinancialsAbatementData`,
  GetProjectCashFlowData: `GMP/GetM3CashFlowGraphData`,
  SaveM3StepData: `Project/SaveM3Step2Data`,
  SetProjectstatustoFinalise: `Project/SetProjectStatustoFinalize`,
  CopyFromPreviousVersion: `Project/CopyFromPreviousVersion`,
};
