import React from "react";

export function AddUserIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#000000"
    >
      <path
        d="M17 10H20M23 10H20M20 10V7M20 10V13"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M1 20V19C1 15.134 4.13401 12 8 12V12C11.866 12 15 15.134 15 19V20"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
        stroke="#000000"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
}
export function AddUserModalIcon() {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.75C6.20507 1.75 4.75 3.20507 4.75 5C4.75 6.79493 6.20507 8.25 8 8.25C9.79493 8.25 11.25 6.79493 11.25 5C11.25 3.20507 9.79493 1.75 8 1.75ZM10.8583 8.79412C12.0073 7.92715 12.75 6.55031 12.75 5C12.75 2.37665 10.6234 0.25 8 0.25C5.37665 0.25 3.25 2.37665 3.25 5C3.25 6.55031 3.99271 7.92714 5.14172 8.79411C2.27612 9.93175 0.25 12.7293 0.25 16V17C0.25 17.4142 0.585786 17.75 1 17.75C1.41421 17.75 1.75 17.4142 1.75 17V16C1.75 12.5482 4.54822 9.75 8 9.75C11.4518 9.75 14.25 12.5482 14.25 16V17C14.25 17.4142 14.5858 17.75 15 17.75C15.4142 17.75 15.75 17.4142 15.75 17V16C15.75 12.7293 13.7239 9.93175 10.8583 8.79412ZM20 3.25C20.4142 3.25 20.75 3.58579 20.75 4V6.25H23C23.4142 6.25 23.75 6.58579 23.75 7C23.75 7.41421 23.4142 7.75 23 7.75H20.75V10C20.75 10.4142 20.4142 10.75 20 10.75C19.5858 10.75 19.25 10.4142 19.25 10V7.75H17C16.5858 7.75 16.25 7.41421 16.25 7C16.25 6.58579 16.5858 6.25 17 6.25H19.25V4C19.25 3.58579 19.5858 3.25 20 3.25Z"
        fill="#A4A3B1"
      />
    </svg>
  );
}
