import "./App.scss";
import Header from "./components/Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store/store";
import { SideMenu } from "./components/SideMenu/SideMenu";
import { useEffect, useState } from "react";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { Button, LoadingIndicator } from "./components";
import { InteractionType } from "@azure/msal-browser";
import axios from "axios";
import { useGetToken } from "./hooks/useAppToken";
import { setUser } from "./store/slices/userSlice";
import { AutoLogout } from "./components/AutoLogout/AutoLogout";
import { routes } from "./routes/routes.constatns";

let myInterceptor = undefined;

const UserInterceptorProvider = () => {
  const { accounts, instance } = useMsal();
  const dispatch = useDispatch();
  const token = useGetToken();
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );

  const account = accounts[0];

  useEffect(() => {
    if (!account) return;
    dispatch(setUser(account));
    instance.setActiveAccount(account);
  }, [account, instance, dispatch]);

  useEffect(() => {
    if (!selectedRegion?.apiUrl) return;

    axios.interceptors.request.eject(myInterceptor);
    myInterceptor = axios.interceptors.request.use(async (config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.baseURL = selectedRegion.apiUrl;

      return config;
    });
  }, [selectedRegion?.apiUrl, dispatch]);

  return null;
};

function App() {
  const [isSideMenuCollapsed, setSideMenuCollapsed] = useState(true);
  const { instance, accounts } = useMsal();
  const [isConfigSet, setIsConfigSet] = useState(false);
  const token = useGetToken();
  const account = accounts[0];
  const location = useLocation();
  const popup = location.pathname.includes(routes.decarbProjectsLibrary);

  useEffect(() => {
    if (!token || !account) return;

    myInterceptor = axios.interceptors.request.use(async (config) => {
      config.headers["Authorization"] = `Bearer ${token}`;
      config.baseURL = process.env.REACT_APP_API_URL_REG_US;

      return config;
    });

    setIsConfigSet(true);
  }, [token, account]);

  const initializeSignIn = () => {
    instance.loginRedirect();
  };

  return (
    <div className="motif-theme-dark main-div">
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <AuthenticatedTemplate>
          <Provider store={store}>
            <UserInterceptorProvider />
            <AutoLogout />
            <LoadingIndicator show={!isConfigSet} fullscreen={true}>
              <div className="App">
                <Header
                  setSideMenuCollapsed={() =>
                    setSideMenuCollapsed(!isSideMenuCollapsed)
                  }
                />

                <div className="app-body">
                  {popup ? (
                    <></>
                  ) : (
                    <SideMenu isSideMenuCollapsed={isSideMenuCollapsed} />
                  )}
                  <div
                    className={`app-body-content ${
                      popup
                        ? "decarb-side-menu-hidden"
                        : isSideMenuCollapsed
                        ? "decarb-side-menu-collapsed"
                        : "decarb-side-menu-expanded"
                    }`}
                  >
                    <Outlet />
                  </div>
                </div>
              </div>
            </LoadingIndicator>
          </Provider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Provider store={store}>
            <div className="App">
              <Header />
              <Button className={"asd"} onClick={initializeSignIn}>
                Click me
              </Button>
            </div>
          </Provider>
        </UnauthenticatedTemplate>
      </MsalAuthenticationTemplate>
    </div>
  );
}

export default App;
