import React from "react";

export function RightArrowIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.97472 4.55806C9.73065 4.80214 9.73065 5.19786 9.97472 5.44194L13.9078 9.375H5C4.65482 9.375 4.375 9.65482 4.375 10C4.375 10.3452 4.65482 10.625 5 10.625H13.9078L9.97472 14.5581C9.73065 14.8021 9.73065 15.1979 9.97472 15.4419C10.2188 15.686 10.6145 15.686 10.8586 15.4419L15.8586 10.4419C16.1027 10.1979 16.1027 9.80214 15.8586 9.55806L10.8586 4.55806C10.6145 4.31398 10.2188 4.31398 9.97472 4.55806Z"
        fill="#85B9FD"
      />
    </svg>
  );
}
