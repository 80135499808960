// Header Component
const appName = "Decarbonization Workbench";

// Home component constants
const homeHeader = "Welcome to EY Decarbonization Workbench!";
const homeHeaderBody =
  "Digital enabler to unlock insights for clients to help develop effective net zero transition plans and reduce GHG emissions cost-effectively and at scale";
const homeViewDashboard = "View engagement dashboard";
const homeSolutionModules = "Solution modules";
const viewresults = "View results";
const homescreencards = [
  {
    image: "MED",
    heading: "Manage emissions data",
    description:
      "Manage data for actual (Current and past) Scope 1,2 & 3 GHG emissions.",
  },
  {
    image: "FFE",
    heading: "Forecast future emissions",
    description: "Forecast ‘business-as-usual’ emissions",
  },
  {
    image: "AERO",
    heading: "Analyse emissions reduction options",
    description:
      "Analyse the abatement potential and financial impact of emissions reduction options.",
  },
  {
    image: "SSSR",
    heading: "Simulate scenarios and set roadmaps",
    description:
      "Simulate different emissions reduction scenarios and set a decarbonization roadmap.",
  },
];

// Setup module constants
const setupCreateNewEngHeader = "Create new engagement";
const setupHeader = "Engagement setup";
const setupEngagementCode = "Engagement code";
const setupEngagementName = "Engagement name";
const setupSector = "Sector";
const setupClientName = "Client name";
const setupInternalTeam = "Internal team";
const setupExternalTeam = "External team";
const setupTeamSetup = "Team setup";
const setupTableHeaderName = "Name";
const setupTableHeaderEmailId = "Email ID";
const setupTableHeaderTeam = "Team";

// Setup module constants / Validation messages
const setupEngagementCodeRequired = "Engagement code is required";
const setupEngagementCodeLength =
  "Engagement code length must not exceed 20 characters!";
// Setup module constants / Delete modal
const setupRemoveUserModalHeader = "Remove user?";
const setupRemoveUserModalBody =
  "Are you sure you want to remove this user? He/she will no longer have access to this engagement.";

const geoSpec = "Geographical specifications";

const paginationOptions = [5, 10, 15, 30];
const emissionTableColumns = [
  {
    field: "checkbox",
    width: 100,
    headerName: "",
    suppressMovable: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    pinned: "left",
  },
  {
    field: "scope",
    width: 150,
    headerName: "Scope",
    suppressMovable: true,
    editable: false,
    pinned: "left",
  },
  {
    field: "category_level1",
    minWidth: 150,
    headerName: "Subcategory 1",
    suppressMovable: true,
    pinned: "left",
    editable: false,
  },
  {
    field: "category_level2",
    minWidth: 150,
    headerName: "Subcategory 2",
    suppressMovable: true,
    pinned: "left",
    editable: false,
  },
  {
    field: "category_level3",
    minWidth: 150,
    headerName: "Subcategory 3",
    suppressMovable: true,
    pinned: "left",
    editable: false,
  },
  {
    field: "region_level1",
    minWidth: 200,
    headerName: "Region level 1",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "region_level2",
    minWidth: 200,
    headerName: "Region level 2",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "region_level3",
    minWidth: 200,
    headerName: "Region level 3",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "entity_level1",
    minWidth: 200,
    headerName: "Entity level 1",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "entity_level2",
    minWidth: 200,
    headerName: "Entity level 2",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "entity_level3",
    minWidth: 200,
    headerName: "Entity level 3",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "entity_level4",
    minWidth: 200,
    headerName: "Entity level 4",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "custom_level1",
    minWidth: 200,
    headerName: "Custom 1",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "custom_level2",
    minWidth: 200,
    headerName: "Custom 2",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "custom_level3",
    minWidth: 200,
    headerName: "Custom 3",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "data_type",
    minWidth: 200,
    headerName: "Data type",
    suppressMovable: true,
    editable: false,
  },
  {
    field: "unit",
    filter: true,
    headerName: "Unit",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2025",
    filter: true,
    headerName: "2025",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2026",
    filter: true,
    headerName: "2026",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2027",
    filter: true,
    headerName: "2027",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2028",
    filter: true,
    headerName: "2028",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2029",
    filter: true,
    headerName: "2029",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2030",
    filter: true,
    headerName: "2030",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2031",
    filter: true,
    headerName: "2031",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2032",
    filter: true,
    headerName: "2032",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2033",
    filter: true,
    headerName: "2033",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2034",
    filter: true,
    headerName: "2034",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2035",
    filter: true,
    headerName: "2035",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
  {
    field: "2036",
    filter: true,
    headerName: "2036",
    sortable: true,
    minWidth: 150,
    flex: 1,
    suppressMovable: true,
    editable: false,
  },
];
const tablecolumnFilters = ["10 years", "All years", "Forecast start year"];
const setUpTableCOlumns = [
  {
    field: "name",
    headerComponentParams: {
      enableSorting: true,
      showFilter: false,
    },
    headerName: "Name",
    sortable: true,
    flex: 1,
  },
  {
    field: "email",
    filter: true,
    headerComponentParams: {
      enableSorting: false,
      showFilter: true,
    },
    headerName: "Email ID",
    flex: 1,
  },
  {
    field: "engagementTeamTypeID",
    filter: true,
    headerComponentParams: {
      enableSorting: false,
      showFilter: true,
    },
    headerName: "Team",
    flex: 1,
  },
];
const analyseBodyTitle = "Analyze emissions reduction options";
const analyseBodyDescription =
  "Analyze the abatement potential and financial impact of emissions reduction options";
const createNewProject = "Create new project";
const copyFromPastVersions = "Copy from past versions";
const analyseProjectsData = "Analyze emissions reduction options";
const analyseTableHeaderProjectName = "Project name";
const analyseTableHeaderProjectType = "Project type";
const analyseTableHeaderSimplePaybackPeriod = "Simple payback period (yrs)";
const analyseTableHeaderTotalAbatement = "Total abatement (tCO\u{2082}e)";
const analyseTableHeaderAverageAnnualAbatement =
  "Average annual abatement (tCO\u{2082}e/yr)";
const analyseTableHeaderTechnicalAbatementPotential =
  "Technical abatement potential (%)";

const analyseTableHeaderNetPresentValue = "Net present value ($)";
const analyseTableHeaderInternalRateOfReturn =
  "Internal rate of return (%.p.a.)";
const analyseTableHeaderDiscountedPaybackPeriod =
  "Discounted payback period (yrs)";
const analyseTableHeaderMarginalAbatementCost =
  "Marginal abatement cost ($/tCO\u{2082}e)";
const deleteProjectModalHeader = "Delete project";
const deleteProjectModalBody =
  "This action will delete the project, it cannot be undone";
const deleteProjectModalBodyDesc = "Are you sure you want to delete?";
const duplicateProjectNameError = "Project name already exist";
const newProjectHeaderDescr =
  "Fill out the information on Step 1-3 to ensure accurate results in Analysation Results tab.";
const emissionsHeaderDesc =
  "Replace your activity data by clicking on Edit (pen icon), or apply percentage editing against activity data through Bulk editing.";
const financialHeaderDesc =
  "Update you CapEx and OpEx financials by clicking on Edit (pen icon).";
const projectToLibraryHeader = "Request successfully submitted.";
const projectToLibraryBody =
  "Would you like to proceed with transferring this project to the project library? Upon confirmation, the project will move to project library within a timeframe of 10  minutes.";

// Add GMP module constants
const addGMPBtn = "Financial parameters";
const syncData = "Sync data";
const setParameters = " Set parameters";
const addGMPDataType = "Data type";
const addGMPParameterName = "Parameter name";
const addGMPForceCost = "Force as cost";
const addGMPVariableType = "Variable type";
const addGMPSourceYear = "Source year";
const addGMPSource = "Source";
const addGMPDependency = "Dependency";
const addGMPAllocation = "Allocation";
const addGMPStartYearValue = "Start year value";
const addGMPCurrencyUnit = "Currency unit";
const addGMPUnit = "Unit";
const addGMPStartYear = "Start year";
const addGMPEndyear = "End year";
const addGMPCAGR = "CAGR (% p.a.)";
const addGMPDataTypeOptions = ["CapEx", "OpEx", "Discount rate"];
const addGMPForceCostOptions = ["Yes", "No"];
const addGMPVariableTypeOptions = ["Dependent", "Independent"];
const addGMPDependencyOptions = [
  "Activity data",
  "Emissions",
  "Not applicable",
];
const addGMPDependencyOptionsNew = ["Activity data", "Emissions"];
const deleteRuleModalHeader = "Delete rule";
const deleteRuleModalBody =
  "This action will delete the rule, it cannot be undone";
const deleteRuleModalBodyDesc = "Are you sure you want to delete?";
const deleteGMPModalHeader = "Delete gmp";
const deleteGMPModalBody =
  "This action will delete the rule, it cannot be undone";
const deleteGMPModalBodyDesc = "Are you sure you want to delete?";
const paramNameValidationMsg =
  "The parameter name conflicts with the chosen fields for 'Data type, Force as cost, Variable type & Dependency.' Please modify the name or adjust the field selection to successfully save the GMP.";

// Manage emissions module constants
const manageEmissionHeaderMain = "Manage emissions data";
const manageEmisionHeaderDesc =
  "Manage data for actual (current and past) Scope 1, 2 and 3 GHG emissions";
const manageEmissionBodyTemplateTitle = "Template file";
const manageEmissionBodyUploadTitle = "Upload your input file";
const manageEmissionBodyDownloadFile = "Download template file";
const manageEmissionBodyUploadBaseYear = "Base year";
const manageEmissionBodyUploadRecentYear = "Most recent year";
const manageEmissionBodyUploadSaveFile = "Upload file";
const manageEmissionBodyUploadFileHistory = "Version history";

// Manage emissions / File version history modal constants
const fileVersionHistoryModalHeaderDate = "Date";
const fileVersionHistoryModalHeaderFileName = "File name";
const fileVersionHistoryModalHeaderUploadedBy = "Uploaded by";
const fileVersionHistoryModalLiveVersion = "Live version";
const saveButtonTooltip = "Click here to upload the input file";

// Forecast module constants
const foreEndYear = "Forecast end year";
const foreStartYear = "Most recent year";
const forcastResetModalHeader = "Delete forecast";
const forcastResetModalBody =
  "Once you confirm the deletion of the M2 data, all associated forecasts and targets will be permanently removed.";
const forcastResetModalBodyDesc = "Are you sure you want to delete?";
const forecastHeaderDesc = "Forecast ‘business-as-usual’ emissions";
const forecastHeaderMain = "Forecast future emissions";
const forecastBodyCalculateEmissionsSummaryTitle = "Business-as-usual summary";
const forecastBodyCalculateEmissionsSummaryTargetRequired = "Target required";
const forecastBodyCalculateEmissionsSummaryForecastRequired =
  "Forecast required";
const forecastBodyCalculateEmissionsSummaryCoveredEmission =
  "Covered emissions";
const forecastBodyCalculateEmissionsSummaryEmissionCoverage =
  "Emission coverage of defined targets";
const forecastBodyCalculateEmissionsSummaryChangeOfEmission =
  "Change of emissions";
const forecastBodyCalculateEmissionsSummaryEmissionReducationTarget =
  "Emissions reduction target";
const forecastBodyCalculateEmissionsSummaryEmissionReductioonGap =
  "Emission reduction gap";
const forecastBodyCalculateEmissionsSummaryRelativeToBaseYear =
  "Relative to target base year";
const forecastBodyCalculateEmissionsSummaryRelativeToTargetYear =
  "Relative to target end year";

// Forecast / Skip module constants
const forecastSkipModalBody =
  "Indicate your forecast end year, and confirm if you want to skip manually defining any forecast assumptions? This would mean the actuals from the most recent year will be taken as reference for all subsequent years until the forecast end year.";
const forecastSkipModalHeader = "Skip manual forecast assumptions?";
const forecastSkipModalEmptyFEY =
  "It is mandatory to save the forecast end year";

// Forecast / Chart module constants
const forecastBodyChartTitle = "Target and forecast for $1 emissions";
const forecastBodyChartScope1And2 = "Scope 1 and 2";
const forecastBodyChartScope3 = "Scope 3";
const forecastChartYAxisLabel = "Emissions  (tCO\u{2082}e)";
const forecastChartNoData = "No data to display";
const forecastChartNoDataDesc1 = "Please add your forecasts and targets,";
const forecastChartNoDataDesc2 = "or click on next to see results.";
const forecastChartXAxisEmissionsWithTarget = "BAU emissions (with target)";
const forecastChartXAxisEmissionsWithoutTarget =
  "BAU emissions (without target)";
const forecastChartXAxisTarget = "Target";
const forecastChartProcessingForecastAndTargetData =
  "We're currently processing the provided $1 data";
const forecastChartDataLoadingMessage = "Loading data...";
const forecastChartForecastLabel = "forecast";
const forecastChartTargetLabel = "target";
const forecastChartForecastAndTargetLabel = "forecast and target";

// Forecast / Change FEY Modal constatns
const changeFEYModalHeader = "Change forecast end year?";
const changeFEYModalBody =
  "Are you sure you want to change the forecast end year? The applied forecast from excel and targets will be reset. And the UI forecast applied previously will auto apply if no change in the hierarchy.";

// Forecast / Add Target module constants
const addTarget = "Add targets";
const addTargetTableHeaderTargetName = "Target name";
const addTargetTableHeaderTargetDesc = "Target description";
const addTargetTableHeaderGeo = "Geographical";
const addTargetTableHeaderOrg = "Organisational";
const addTargetTableHeaderCustom = "Custom";
const addTargetTableHeaderTargetType = "Target type";
const addTargetTableHeaderTemporalType = "Temporal type";
const addTargetTableHeaderDataPercentageValue = "Value (% p.a.)";
const addTargetTableHeaderDataBaseYear = "Base year";
const addTargetTableHeaderDataTargetYear = "Target year";

// Forecast / Add Target / Add Target Specification module constants
const addTargetSpecificationName = "Add target details";
const addTargetSpecificationTargetName = "Target name";
const addTargetSpecificationTargetDesc = "Target description";
const addTargetSpecificationTargetType = "Target type";
const addTargetSpecificationTemporalTypeOptions = [
  {
    name: "Annual",
    id: "Annual",
  },
  {
    name: "Target end year",
    id: "Target End Year",
  },
];
const addTargetSpecificationTargetTemporalType = "Temporal type";
const addTargetSpecificationTargetTypeOptions = [
  {
    name: "Intensity",
    id: "Intensity",
  },
  {
    name: "Absolute",
    id: "Absolute",
  },
];
const addTargetSpecificationPercentValueAnnual = "Value (% p.a.)";
const addTargetSpecificationPercentValueTargetYear = "Value (%)";
const addTargetSpecificationBaseYear = "Base year";
const addTargetSpecificationTargetYear = "Target year";
const addTargetSpecificationEmission = "Emission";
const addTargetSpecificationAddEmissionBtn = "Add emission";
const addTargetSpecificationGeo = "Geographical";
const addTargetSpecificationAddGeoBtn = "Add geographical";
const addTargetSpecificationOrg = "Organisational";
const addTargetSpecificationAddOrgBtn = "Add organisational";
const addTargetSpecificationCustom = "Custom";
const addTargetSpecificationAddCustomBtn = "Add custom";
const activityOutputText = "Set alternative output for the intensity target?";
const activityOutput = "Activity output";
// Forecast / Add Forecast / Add Forecast Specification / Validation messages
const addTargetSpecificationEmissionRequired = "Emission data is required";
const addTargetSpecificationGeoRequired = "Geographical data is required";
const addTargetSpecificationOrgRequired = "Organisational data is required";
const addTargetSpecificationTargetTypeRequired = "Target type is required";
const addTargetSpecificationTemporalTypeRequired = "Temporal type is required";
const addTargetSpecificationPercentValueAnnualRequired =
  "Value (% p.a.) is required";
const addTargetSpecificationPercentValueTargetYearRequired =
  "Value (%) is required";
const addTargetSpecificationBaseYearRequired = "Base year is required";
const addTargetSpecificationBaseYearLessThanMRY =
  "Base year should be equal or less than the Most recent year";
const addTargetSpecificationBaseYearGreaterThanFEY =
  "Base year should be greater than Most recent year - 10";
const addTargetSpecificationTargetYearRequired = "Target year is required";
const addTargetSpecificationTargetYearLessThanFEY =
  "Target year should be equal or less than the Forecast end year";
const addTargetSpecificationTargetYearGreaterThanMRY =
  "Target year should be greater than Most recent year";
const addTargetSpecificationForecastNameRequired = "Target name is required";
const duplicateIntensityTargetUnitModalHeader = "Unit consistency confirmation";
const duplicateIntensityTargetUnitDescription =
  "Please ensure that the activity data is measured in consistent units before setting the intensity target. Consider reviewing the choices made regarding this dropdown selection.";

// Forecast / Add Forecast module constants
const addForecast = "Add forecasts";
const addForecastTableHeaderForecastName = "Forecast name";
const addForecastTableHeaderForecastDesc = "Forecast description";
const addForecastTableHeaderEmission = "Emission";
const addForecastTableHeaderGeo = "Geographical";
const addForecastTableHeaderOrg = "Organisational";
const addForecastTableHeaderCustom = "Custom";
const addForecastTableHeaderDataType = "Data type";
const addForecastTableHeaderDataPercentageValue = "Value (% p.a.)";
const addForecastTableHeaderDataStartYear = "Start year";
const addForecastTableHeaderDataEndYear = "End year";

// Forecast / Add Forecast / Add Forecast Specification module constants
const addForecastSpecificationHeader = "Add forecast details";
const addForecastSpecificationForecastName = "Forecast name";
const addForecastSpecificationForecastDesc = "Forecast description";
const addForecastSpecificationDataType = "Data type";
const addForecastSpecificationDataTypeOptions = [
  "Activity data",
  "Emission factor",
];
const addForecastSpecificationPercentValue = "Value (% p.a.)";
const addForecastSpecificationStartYear = "Start year";
const addForecastSpecificationEndYear = "End year";
const emission = "Emission";
const addForecastSpecificationAddEmissionBtn = "Add emission";
const addForecastSpecificationGeo = "Geographical";
const addForecastSpecificationAddGeoBtn = "Add geographical";
const addForecastSpecificationOrg = "Organisational";
const addForecastSpecificationAddOrgBtn = "Add organisational";
const addForecastSpecificationCustom = "Custom";
const addForecastSpecificationAddCustomBtn = "Add custom";

// Forecast / Add Forecast / Add Forecast Specification / Validation messages
const addForecastSpecificationEmissionRequired = "Emission data is required";
const addForecastSpecificationGeoRequired = "Geographical data is required";
const addForecastSpecificationOrgRequired = "Organisational data is required";
const addForecastSpecificationDataTypeRequired = "Data type is required";
const addForecastSpecificationPercentValueRequired =
  "Value (% p.a.) is required";
const addForecastSpecificationStartYearRequired = "Start year is required";
const addForecastSpecificationStartYearGreaterThanMRY =
  "Start year should be greater than the most recent year";
const addForecastSpecificationStartYearLessThanFEY =
  "Start year should be less than forecast end year";
const addForecastSpecificationEndYearRequired = "End year is required";
const addForecastSpecificationEndYearGreaterThanMRY =
  "End year should be greater than the most recent year";
const addForecastSpecificationEndYearLessThanFEY =
  "End year should be equal or less than forecast end year";
const addForecastSpecificationEndYearLessThanStartYear =
  "End year should be greater than start year";
const addForecastSpecificationStartYearGreaterThanEndYear =
  "Start year should be less than end year";
const addForecastSpecificationForecastNameRequired =
  "Forecast name is required";

const projInfo = "Project information";
const projType = "Project type";
const specSelection = "Specification selection";

const projName = "Project name";
const addNewUser = "Add new user";
const name = "Name";
const email = "Email ID";
const selectEmission = "Select emission";
const selectOrg = "Select organisational";
const selectGeo = "Select geographical";
const selectCustom = "Select custom";
const scope = "Scope";
const subcat1 = "Subcategory 1";
const subcat2 = "Subcategory 2";
const subcat3 = "Subcategory 3";
const entLevel1 = "Entity level 1";
const entLevel2 = "Entity level 2";
const entLevel3 = "Entity level 3";
const entLevel4 = "Entity level 4";
const regLevel1 = "Regional level 1";
const regLevel2 = "Regional level 2";
const regLevel3 = "Regional level 3";
const custom1 = "Custom 1";
const custom2 = "Custom 2";
const custom3 = "Custom 3";
const projDesc = "Project description";
const projTag = "Project tags";
const selectProjectTag = "Select project tag";
const createNewProjectTag = "Create new project tag";
const SearchNewProjectTags = "Search existing or type new project tags";
const strtYr = "Start year";
const endYr = "End year";
const orgSpec = " Organisational specifications";
const emisSpec = "Emission specifications";
const custSpec = " Custom specifications";
const userDuplicateMsg =
  "The user you are attempting to add already exists within the engagement.";
const setEngDet = "Setup of engagement details";

const impExcel = "Import excel template";
const addForecastSpec =
  "Add forecast specifications as applicable, and click 'Apply'";
const forecastSpec = "Add forecast";
const targetSpec = "Add target specifications";
const impExcelDesc =
  "Please download the blank template file, fill out the required information, and upload the completed file below";

const specHeaderDesc =
  "These specifications are retrieved from Manage and Forecast modules. Select the specification you want to apply for this project.";

// View Engagement module constants
const engDash = "Engagement dashboard";
const dashHeaderDesc =
  "Below are your current engagements, select any engagement names to make your updates.";
const TableHeaderEngagementName = "Engagement name";
const TableHeaderEngagementCode = "Engagement code";
const TableHeaderClientName = "Client name";
const TableHeaderEngagementStatus = "Engagement status";
const CloseProjectHint = "Close project";

//View Engagement / Delete modal module constants
const deleteModalHeader = "Close engagement";
const deleteModalBody =
  "Are you sure you want to close the engagement? This action cannot be undone.";

// Shared constants
const CancelBtn = "Cancel";
const YesBtn = "Yes";
const YesChangeBtn = "Yes, change";
const YesSkipBtn = "Yes, skip";
const ConfirmBtn = "Confirm";
const PreviousBtn = "Previous";
const NextBtn = "Next";
const SaveAndNextBtn = "Save and next";
const SaveBtn = "Save";
const ApplyBtn = "Apply";
const PoweBiBtn = "View Power BI results";
const DownloadBtn = "Download";
const DownloadTemplateBtn = "Download template file";
const DownloadErrorReportBtn = "Download error report";
const CreateNewEngBtn = "Create new engagement";
const AddUserBtn = "Add user";
const AddForecastBtn = "Add forecast";
const AddTargetBtn = "Add target";
const ResetM2Data = "Delete data";
const DeleteBtn = "Delete";
const ExportBtn = "Export data";
const CreateNewScenarioBtn = "Create new scenario";
const BackBtn = "Back";
const SubmitBtn = "Submit";

// Shared constants / Validation messages
const FourDigitYear = "Please enter a valid 4 digit year";

//Shared constants / Excel import
const FileVerificationIsInProgress = "File verification is in progress";
const FileUploadIsInProgress = "File upload is in progress";
// const FileUploadIsInSuccess = "File Upload is in Success";
const FileUploadIsInSuccess = "Data load process triggered successfully";
// const DataVerificationIsInProgress = "Data Verification is in Progress";
const DataVerificationIsInProgress = "Data validation is in progress";
// const DataVerificationIsInFailed = "Data Verification is Failed";
const DataVerificationIsInFailed =
  "Data validation has failed. Please refer file to get the data validation errors.";
const DataVerificationIsInSuccess = "Data validation is successful";
const DataLoadIsInProgress = "Data load is in progress";
const DataLoadIsInFailed = "Data load is failed";
const DataLoadHasFailed =
  "Data load has failed. This may have occurred due to some technical error. Please try again and contact support team if issue persist";
const DataLoadIsInSuccess = "Data load is in success";
const GeneratingErrorReport = "Generating error report...";
const ExcelDataVerificationFailed =
  "A total of $1 records have been identified with errors and the uploading has been terminated. Please review the error report and re-upload the file.";
const FailedStatus = "Fail";
const uploadSuccessMessage1 = "The data upload was successful. A total of ";
const uploadSuccessMessage2 = " records have been uploaded.";
const uploadSuccessMessage3 =
  "No record has been uploaded. Please validate the input file and re-upload.";
const baseYear = "Base year";
const targetYear = "Target year";

const teamType = "Team";
const removeForecast = "Remove forecast specification?";
const removeTarget = "Remove target specification?";
const addForecastModalHeading = "Select how you want to add your forecast.";
const viaExcel = "Via excel import";
const viaUI = "Via UI input";
const deleteMessage = "Are you sure you want to delete this record?";
const deleteMessageLine2 =
  " Once confirmed, the record will disappear from the screen.";
const deleteMessageLine3 =
  "If you are deleting an earlier 'applied' forecast, changes will only be applied to the data after you click 'Apply'.";
const applyErrorMessageTarget =
  " System is busy in executing earlier targets, please try after sometime!";
const applyErrorMessageForecast =
  " System is busy in executing earlier forecasts, please try after sometime!";
const forecastPercentageError =
  "Please enter a valid 2 digit number (up to 2 decimals allowed).";
const targetPercentageError =
  "The value cannot exceed 100 and is limited to 2 decimal places";

//Emission page constants

const emissionHeaderTitle = "Emissions selection";
const reset = "Reset";
const filters = "Filters";
const editData = "Edit data";
const disableEditButton = "Disable edit";
const batchEditreduce = "Batch edit (Reduce)";
const batchEditCompensate = "Batch edit (Compensate)";
const batchEditReplace = "Batch edit (Replace)";
const reduceChartNoData = "No data to display";
const cumulative = "Cumulative";
const simulateValue = "Value";
const unit = "Unit";
const simulate = "Simulate";
const unitDropdown = ["Absolute", "Relative"];
const yesNo = ["Yes", "No"];
const emissionArray = ["Emissions"];
const projectTypes = [
  { id: 1, name: "Reduce" },
  { id: 2, name: "Replace" },
  { id: 3, name: "Compensate" },
];
const dropdowns = [
  {
    Name: "Emission",
    dropdownList: [
      { label: "Scope", fieldId: "scope", id: 1 },
      { label: "Subcategory 1", fieldId: "category_level1", id: 2 },
      { label: "Subcategory 2", fieldId: "category_level2", id: 3 },
      { label: "Subcategory 3", fieldId: "category_level3", id: 4 },
    ],
    tooltip:
      "Specify emissions categories to tailor the general modelling parameter assumptions from an emissions perspective",
  },
  {
    Name: "Geographical",
    dropdownList: [
      { label: "Region level 1", fieldId: "region_level1", id: 5 },
      { label: "Region level 2", fieldId: "region_level2", id: 6 },
      { label: "Region level 3", fieldId: "region_level3", id: 7 },
    ],
    tooltip:
      "Specify geographical categories to tailor the general modelling parameter assumptions from a regional perspective",
  },
  {
    Name: "Organisational",
    dropdownList: [
      { label: "Entity level 1", fieldId: "entity_level1", id: 8 },
      { label: "Entity level 2", fieldId: "entity_level2", id: 9 },
      { label: "Entity level 3", fieldId: "entity_level3", id: 10 },
      { label: "Entity level 4", fieldId: "entity_level4", id: 11 },
    ],
    tooltip:
      "Specify organisational categories to tailor the general modelling parameter assumptions from a company perspective",
  },
  {
    Name: "Custom",
    dropdownList: [
      { label: "Custom level 1", fieldId: "custom_specification1", id: 12 },
      { label: "Custom level 2", fieldId: "custom_specification2", id: 13 },
      { label: "Custom level 3", fieldId: "custom_specification3", id: 14 },
    ],
    tooltip:
      "Specify custom categories to tailor the general modelling parameter assumptions to customized areas",
  },
];
const unitTooltip =
  "Absolute change would only apply on the activity data or emission factor when the unit for activity data or Unit and Value both for emission factor is same in the table";
const emissionFilterTooltip =
  "Filters applied will update the treemap chart and data table.";
const simulateValueTooltip =
  "Value is dependent on data type and unit. Please select data type and unit first.";
const emissionHeaderTooltip =
  "Select your emissions and click on 'Apply' to enable further drill down on your geographical, organisational and custom filters.";
const emissionGraphHeading =
  "Average annual emissions over forecast period (kgCO\u{2082}e)";
const changeLog = "Change log";
const emissionsChangeLog = "Change log";
const calculate = "Calculate";
const changeLogDescription =
  "Rules with checkbox ticked are applied in the treemap chart and data table. Select or deselect a row to include or exclude a rule, and then click on “Recreate project”.";
const changeLogTableColDefs = [
  {
    field: "checkbox",
    width: 100,
    headerName: "",
    suppressMovable: true,
    checkboxSelection: function (params) {
      return params.node.isValidated === "N" ? false : true;
    },
    headerCheckboxSelection: true,
    pinned: "left",
    rowDrag: true,
    rowDragManaged: true,
  },
  {
    field: "rule_name",
    width: 150,
    headerName: "Rule name",
    headerTooltip: "Rule name",
    suppressMovable: true,
    pinned: "left",
    rowDrag: true,
    rowDragManaged: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  },
  {
    field: "changeType",
    width: 150,
    headerName: "Change type",
    headerTooltip: "Change type",
    suppressMovable: true,
    rowDrag: true,
    rowDragManaged: true,
    pinned: "left",
  },
  {
    field: "data_type",
    width: 150,
    headerName: "Data type",
    headerTooltip: "Data type",
    suppressMovable: true,
    rowDrag: true,
  },
  {
    field: "cumulative_flag",
    width: 150,
    headerName: "Cumulative",
    headerTooltip: "Cumulative",
    suppressMovable: true,
  },
  {
    field: "calculation_type",
    width: 150,
    headerName: "Unit operation",
    headerTooltip: "Unit operation",
    suppressMovable: true,
  },
  {
    field: "datatype_unit",
    width: 150,
    headerName: "Unit",
    headerTooltip: "Unit",
    suppressMovable: true,
  },
  {
    field: "abatement_value",
    width: 150,
    headerName: "Value",
    headerTooltip: "Value",
    suppressMovable: true,
  },
  {
    field: "start_year",
    width: 150,
    headerName: "Start year",
    headerTooltip: "Start year",
    suppressMovable: true,
  },
  {
    field: "end_year",
    width: 150,
    headerName: "End year",
    headerTooltip: "End year",
    suppressMovable: true,
  },
  {
    field: "scope",
    width: 150,
    headerName: "Scope",
    headerTooltip: "Scope",
    suppressMovable: true,
  },
  {
    field: "category_level1",
    width: 150,
    headerName: "Subcategory 1",
    headerTooltip: "Subcategory 1",
    suppressMovable: true,
  },
  {
    field: "category_level2",
    width: 150,
    headerName: "Subcategory 2",
    headerTooltip: "Subcategory 2",
    suppressMovable: true,
  },
  {
    field: "category_level3",
    width: 150,
    headerName: "Subcategory 3",
    headerTooltip: "Subcategory 3",
    suppressMovable: true,
  },
  {
    field: "region_level1",
    width: 150,
    headerName: "Regional 1",
    headerTooltip: "Regional 1",
    suppressMovable: true,
  },
  {
    field: "region_level2",
    width: 150,
    headerName: "Regional 2",
    headerTooltip: "Regional 2",
    suppressMovable: true,
  },
  {
    field: "region_level3",
    width: 150,
    headerName: "Regional 3",
    headerTooltip: "Regional 3",
    suppressMovable: true,
  },
  {
    field: "entity_level1",
    width: 150,
    headerName: "Entity 1",
    headerTooltip: "Entity 1",
    suppressMovable: true,
  },
  {
    field: "entity_level2",
    width: 150,
    headerName: "Entity 2",
    headerTooltip: "Entity 2",
    suppressMovable: true,
  },
  {
    field: "entity_level3",
    width: 150,
    headerName: "Entity 3",
    headerTooltip: "Entity 3",
    suppressMovable: true,
  },
  {
    field: "entity_level4",
    width: 150,
    headerName: "Entity 4",
    headerTooltip: "Entity 4",
    suppressMovable: true,
  },
  {
    field: "custom_specification1",
    width: 150,
    headerName: "Custom 1",
    headerTooltip: "Custom 1",
    suppressMovable: true,
  },
  {
    field: "custom_specification2",
    width: 150,
    headerName: "Custom 2",
    headerTooltip: "Custom 2",
    suppressMovable: true,
  },
  {
    field: "custom_specification3",
    width: 150,
    headerName: "Custom 3",
    headerTooltip: "Custom 3",
    suppressMovable: true,
  },
];
const changeLogReplaceTableColDefs = [
  {
    field: "checkbox",
    width: 100,
    headerName: "",
    suppressMovable: true,
    checkboxSelection: function (params) {
      return params.node.isValidated === "N" ? false : true;
    },
    headerCheckboxSelection: true,
    pinned: "left",
    rowDrag: true,
    rowDragManaged: true,
  },
  {
    field: "rule_name",
    width: 150,
    headerName: "Rule name",
    headerTooltip: "Rule name",
    suppressMovable: true,
    pinned: "left",
    rowDrag: true,
    rowDragManaged: true,
    resizable: true,
    wrapText: true,
    autoHeight: true,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  },
  {
    field: "changeType",
    width: 150,
    headerName: "Change type",
    headerTooltip: "Change type",
    suppressMovable: true,
    rowDrag: true,
    rowDragManaged: true,
    pinned: "left",
  },
  {
    field: "data_type",
    width: 150,
    headerName: "Data type",
    headerTooltip: "Data type",
    suppressMovable: true,
    rowDrag: true,
  },
  {
    field: "targetHierarchy",
    width: 150,
    headerName: "Hierarchy",
    headerTooltip: "Hierarchy",
    suppressMovable: true,
  },
  {
    field: "target_Name",
    width: 150,
    headerName: "Name",
    headerTooltip: "Name",
    suppressMovable: true,
  },
  {
    field: "calculation_type",
    width: 150,
    headerName: "Unit operation",
    headerTooltip: "Unit operation",
    suppressMovable: true,
  },
  {
    field: "cumulative_flag",
    width: 150,
    headerName: "Cumulative",
    headerTooltip: "Cumulative",
    suppressMovable: true,
  },
  {
    field: "datatype_unit",
    width: 150,
    headerName: "Unit",
    headerTooltip: "Unit",
    suppressMovable: true,
  },
  {
    field: "abatement_value",
    width: 150,
    headerName: "Value",
    headerTooltip: "Value",
    suppressMovable: true,
  },
  {
    field: "transferAD",
    width: 150,
    headerName: "Transfer AD",
    headerTooltip: "Transfer AD",
    suppressMovable: true,
  },
  {
    field: "activitydata_value",
    width: 150,
    headerName: "AD value",
    headerTooltip: "AD value",
    suppressMovable: true,
  },
  {
    field: "activitydata_Unit",
    width: 150,
    headerName: "AD unit",
    headerTooltip: "AD unit",
    suppressMovable: true,
  },
  {
    field: "emissiondata_value",
    width: 150,
    headerName: "EF value",
    headerTooltip: "EF value",
    suppressMovable: true,
  },
  {
    field: "start_year",
    width: 150,
    headerName: "Start year",
    headerTooltip: "Start year",
    suppressMovable: true,
  },
  {
    field: "end_year",
    width: 150,
    headerName: "End year",
    headerTooltip: "End year",
    suppressMovable: true,
  },
  {
    field: "scope",
    width: 150,
    headerName: "Scope",
    headerTooltip: "Scope",
    suppressMovable: true,
  },
  {
    field: "category_level1",
    width: 150,
    headerName: "Subcategory 1",
    headerTooltip: "Subcategory 1",
    suppressMovable: true,
  },
  {
    field: "category_level2",
    width: 150,
    headerName: "Subcategory 2",
    headerTooltip: "Subcategory 2",
    suppressMovable: true,
  },
  {
    field: "category_level3",
    width: 150,
    headerName: "Subcategory 3",
    headerTooltip: "Subcategory 3",
    suppressMovable: true,
  },
  {
    field: "region_level1",
    width: 150,
    headerName: "Regional 1",
    headerTooltip: "Regional 1",
    suppressMovable: true,
  },
  {
    field: "region_level2",
    width: 150,
    headerName: "Regional 2",
    headerTooltip: "Regional 2",
    suppressMovable: true,
  },
  {
    field: "region_level3",
    width: 150,
    headerName: "Regional 3",
    headerTooltip: "Regional 3",
    suppressMovable: true,
  },
  {
    field: "entity_level1",
    width: 150,
    headerName: "Entity 1",
    headerTooltip: "Entity 1",
    suppressMovable: true,
  },
  {
    field: "entity_level2",
    width: 150,
    headerName: "Entity 2",
    headerTooltip: "Entity 2",
    suppressMovable: true,
  },
  {
    field: "entity_level3",
    width: 150,
    headerName: "Entity 3",
    headerTooltip: "Entity 3",
    suppressMovable: true,
  },
  {
    field: "entity_level4",
    width: 150,
    headerName: "Entity 4",
    headerTooltip: "Entity 4",
    suppressMovable: true,
  },
  {
    field: "custom_specification1",
    width: 150,
    headerName: "Custom 1",
    headerTooltip: "Custom 1",
    suppressMovable: true,
  },
  {
    field: "custom_specification2",
    width: 150,
    headerName: "Custom 2",
    headerTooltip: "Custom 2",
    suppressMovable: true,
  },
  {
    field: "custom_specification3",
    width: 150,
    headerName: "Custom 3",
    headerTooltip: "Custom 3",
    suppressMovable: true,
  },
];
const recreateProject = "Recreate project";
const ruleName = "Rule name";
const endYearGreaterThanFEYear =
  "End year should not be greater than forecast end year";
const endYearLessThanStartYear = "End year should not be less than start year";

// Analyze page constants
// Analyze / Project Results module constants
const projectResultsHeader = "Project results";
const projectResultsEmissionsAbatementChartHeader = "Emissions abatement";
const projectResultsCashFlowChartHeader = "Cash flow";
const projectResultsBusinessCaseSummaryHeader = "Business case summary";
const projectResultsAbatementCashFlowCalcHeader =
  "Abatement and cash flow calculations";
const projectResultsEmissionAbatementChartFBP = "Forecast (before project)";
const projectResultsEmissionAbatementChartAE = "Abated emissions";
const projectResultsEmissionAbatementChartFAP = "Forecast (after project)";
const projectResultsEmissionAbatementChartRE = "Residual emissions";
const projectResultsEmissionAbatementChartYears = "Years";
const datainSyncMessage =
  "Data synchronization is in process. It may take a while...";

// Session timeout constants
const sessionTimeoutTitle = "Your session is about to expire";
const sessionTimeoutMessage =
  "You're being timed out due to inactivity. Please choose to stay signed in or signed out. Otherwise, you will be signed out automatically in $1 minute(s).";
const sessionTimeoutStayLoggedIn = "Stay signed in";
const sessionTimeoutLogOut = "Sign out now";

// Users consent constants

const consentHeading = "Disclaimer";
const consentBodyText =
  "EY does not intentionally collect any sensitive personal data from you via the tool. The tool’s intention is not to process such information. Accordingly, you should not enter any unnecessary personal information or any sensitive personal data (including government identifiers, such as tax file, social security or national identification numbers), client confidential information (except client entity names), audit secrets, state secrets, trade secrets, or anything that would violate professional secrecy or confidentiality rules or that would be considered abusive or irrelevant in the tool. ";
const consentPrivacyText = "privacy notice";
const consentIAgree = "I agree";
const consentNotice = " Please review our ";
const consentNoticePrivacy = " and click “I agree” to access the application. ";

// Simulate module constants
const simulateHeaderTitle = "Simulate scenarios and set roadmap";
const simulateHeaderDescription =
  "Simulate different emissions reduction scenarios and set a decarbonization roadmap";
const simulateCloseProjectScenarioHint = "Close project scenario";
const decimalsNotAllowedError = "Please enter numbers only";
const relativePercentageError =
  "Value should be in the range of -100% to 9999.99% for this combination";
const activityDataRelativeError =
  "Value should be in the range of 1 to 100 for this combination";
const CAGRPercentageError = "Value should be in the range of -100% to 9999.99%";
const only2DecimalsAllowed = "Up to 2 decimals only allowed.";
const negativeNotallowed =
  "Negative values are not allowed for this combination";
const maxlimitExceeded = "Max limit exceeded";
const onlyNumbersAllowed = "Only numbers are allowed";
const saveSimulateHeaderTitle = "Save scenario details";
const ScenarioName = "Scenario name";
const ScenarioDescription = "Scenario description";
const scenarioValidationMessage =
  "There have been modifications in previous modules, please click on “Sync data” before you proceed.";
const correctM3inM4Message =
  "Data has been modified due to M1 file reupload. Please update M3 data to proceed further.";
// Projects Library module constants
const projectsLibraryHeader = "Decarbonization library";
const scopes = [
  { id: "Scope 1", name: "Scope 1" },
  { id: "Scope 2", name: "Scope 2" },
  { id: "Scope 3.1", name: "Scope 3.1" },
  { id: "Scope 3.2", name: "Scope 3.2" },
  { id: "Scope 3.3", name: "Scope 3.3" },
  { id: "Scope 3.4", name: "Scope 3.4" },
  { id: "Scope 3.6", name: "Scope 3.6" },
  { id: "Scope 3.7", name: "Scope 3.7" },
  { id: "Scope 3.8", name: "Scope 3.8" },
  { id: "Scope 3.9", name: "Scope 3.9" },
  { id: "Scope 3.12", name: "Scope 3.12" },
];

// Projects Library / Project Library Item module constants
const projectLibraryItemNetPresent = "Net present value";
const projectLibraryItemRateOfReturn = "Internal rate of return";
const projectLibraryItemTechAbatement = "Technical abatement potential";
const projectLibraryItemMargCost = "Marginal abatement cost";

// Projects Library / Project Details module constants
const projectDetailsType = "Project type:";
const projectDetailsTags = "Project tags:";
const projectDetailsBusinessRules = "Business rules";
const projectDetailsBusinessRulesRuleName = "Rule name";
const projectDetailsBusinessRulesChangeType = "Change type";
const projectDetailsBusinessRulesDataType = "Data type";
const projectDetailsBusinessRulesHierarchy = "Hierarchy";
const projectDetailsBusinessRulesName = "Name";
const projectDetailsBusinessRulesUnitOp = "Unit operation";
const projectDetailsBusinessRulesCumulative = "Cumulative";
const projectDetailsBusinessRulesUnit = "Unit";
const projectDetailsBusinessRulesValue = "Value";
const projectDetailsBusinessRulesTransferAD = "Transfer AD";
const projectDetailsBusinessRulesADValue = "AD value";
const projectDetailsBusinessRulesADUnit = "AD unit";
const projectDetailsBusinessRulesEfValue = "EF value";
const projectDetailsBusinessRulesStartYear = "Start year";
const projectDetailsBusinessRulesEndYear = "End year";
const projectDetailsBusinessRulesScope = "Scope";
const projectDetailsBusinessRulesSubcat1 = "Subcategory 1";
const projectDetailsBusinessRulesSubcat2 = "Subcategory 2";
const projectDetailsBusinessRulesSubcat3 = "Subcategory 3";
const projectDetailsBusinessRulesReg1 = "Regional 1";
const projectDetailsBusinessRulesReg2 = "Regional 2";
const projectDetailsBusinessRulesReg3 = "Regional 3";
const projectDetailsBusinessRulesEnt1 = "Entity 1";
const projectDetailsBusinessRulesEnt2 = "Entity 2";
const projectDetailsBusinessRulesEnt3 = "Entity 3";
const projectDetailsBusinessRulesEnt4 = "Entity 4";
const projectDetailsBusinessRulesCust1 = "Custom 1";
const projectDetailsBusinessRulesCust2 = "Custom 2";
const projectDetailsBusinessRulesCust3 = "Custom 3";
const projectDetailsAbatementCashFlowCalcHeader = "Abatement and cash flow";
const projectDetailsFinancialsHeader = "Financials";
const projectDetailsItemNetPresent = "Net present value ($)";
const projectDetailsItemRateOfReturn = "Internal rate of return (% p.a)";
const projectDetailsItemTechAbatement = "Technical abatement potential (%)";
const projectDetailsItemMargCost = "Marginal abatement cost (tCO\u{2082}e)";
const projectDetailsItemDPP = "Discounted payback period (yrs)";
const projectDetailsItemSImplePayback = "Simple payback period (yrs)";
const projectDetailsCategoryHeader = "Data type";
const projectDetailsUnitHeader = "Unit";

const financialVariableType = "Variable type";
const financialRulesType = "Rules";
const financialParameterType = "Parameter name";
const financialSetVariable = "Set variables";
const targetHierarchy = "Target hierarchy";
const actDataValue = "Activity data value";
const emisFactValue = "Emission factor value";
const actDataUnit = "Activity data unit";
const emisFactorUnit = "Emission factor unit";
const transferAd = "Transfer AD";
const targetHierarchyValues = ["Emission", "Geography", "Organisational"];

const resetFiltersHeader = "Reset emission selection?";
const resetFiltersBody =
  "The dataset will undergo a reset, resulting in the removal of all configured rules, as well as capital expenditure (CapEx) and operational expenditure (OpEx) information. Please select confirm to proceed with this action, otherwise select cancel.";
const analyseValidationMessage =
  "There have been modifications in module 2, please click on “Sync data” before you proceed.";
const analyseDeleteMessage =
  "The data in module 2 has been deleted, please correct it before you proceed.";
const copyProjectsMessage =
  "The projects are being copied from the previous version, which may take some time. Please revisit the page later to view the projects.";

// Tooltips

const sectorTooltip = "Select industry sector";
const templatefileM1tooltip =
  "Use template to translate the relevant client information into right format ";
const mostRecentYeartooltip =
  "Latest year of existing greenhouse gas inventory";
const forecastendYearTooltip =
  "Target year for your decarbonization engagement";
const addforecastTooltip = "Set a specific emissions reduction forecast";
const addtargetTooltip = "Set a specific emissions reduction target";
const forecastDescriptionTooltip =
  "Outline goal and rationale of your emissions forecast assumption";
const forecastPercentageTooltip =
  "Expected yearly change rate of the emissions";
const forecastValueTooltip = "Expected yearly change rate of the emissions";
const forecastStartYearTooltip = "Starting year for your forecast assumption";
const forecastEndYeartooltip = "Concluding year for your forecast assumption";
const emissionTooltip = "Specify emissions categories included in forecast";
const targetDescriptiontooltip =
  "Outline goal and rationale of your emissions target";
const targetTypetooltip = "Kind of emissions reduction target you are setting";
const temporalTypeTooltip =
  "Define either as 'yearly' percentual reduction or reduction at 'target year' relative to the base year";
const targetPercentageTooltip =
  "Annual change for selected data expressed in %";
const targetBaseYearTooltip =
  "Reference year from which reductions are calculated";
const targetYearTooltip =
  "Year until which yearly targets are being formulated";
const targetEmissionTooltip =
  "Scopes and respective categories of emissions targeted for reduction";
const targetGeographytooltip = "Geographic focus of emissions target";
const targetOrganisationTooltip = "Organisational reach of emissions target";
const projNameTooltip = "Key identifier for a project.";
const projTypeTooltip =
  "Category of the project. It determines the available functionalities and sets the calculation order for scenario analysis.";
const ruleNameTooltip =
  "Operation designed to simulate the impact of decarbonization actions on your data.";
const reduceDataTypetooltip =
  "Category of data to modify within the project. For this particular project type, modifications can be made to either 'Activity Data' or 'Emission Factors'. ";
const replaceDataTypeTooltip =
  "Specifies the category of data to modify within the project. For this particular project type, modifications can be made to either 'Activity Data' or 'Emission Factors'. ";
const compensateDataTypeTooltip =
  "Identifies the specific data category targeted for modifications in the project.Changes are exclusively applied to the calculated 'Emissions,' without affecting the associated 'Emission factors' or 'Activity data.'";
const cumulativeTooltip =
  "The 'Cumulative' option, as suggested by its name, results in incremental changes. This implies that with each passing year, the change value is compounded by the number of years elapsed since the designated Start year, effectively multiplying the input value accordingly.";
const emissionsValuetooltip =
  "Represents the quantitative input of the company's initiatives.";
const emissionsStartyearTooltip =
  "Initial year when the project becomes operational. Consequently, it is the earliest year that experiences the project's impact.";
const emissionsEndYeartooltip =
  "Concluding year of the project's activity. It marks the final year that will be influenced by the project's interventions.";
const emissionsFilterbuttonTooltip =
  "The filter function allows you to navigate through your defined hierarchies to customize the table view. Use it to apply rules at the correct level of detail by selecting the desired category—Activity Data, Emission Factors, or Emissions—using the selection icons located to the left of each line.";
const emissionsEditButtonTooltip =
  "The 'Edit Data' feature enables manual input of data values. For Activity Data and Emissions, the total amount is distributed down to the most detailed level. For Emission Factors, the entered value replaces the existing figure in all instances.";
const emissionsToggleTooltip =
  "The toggle feature between 'Edit' and 'Extend' modes facilitates the application of different types of rules. 'Edit' rules modify existing data, allowing for adjustments to previously defined emission factors or activity data.'Extend' rules introduce new data points for activity data and correlating emission factors, creating entirely new hierarchies. For optimal results, it is recommended to complete all 'Edit' rules before applying 'Extend' rules within a project.";
const targetHierarchyTooltip =
  "The Target Hierarchy specifies the level within the existing hierarchy where a new element will be added, with the granularity determined by the toggle setting in the table. ";
const activityDataValueTooltip =
  "The Activity Data Value field is where you input the numerical amount for the new activity data, using the unit you've already chosen. This value represents the total at the specified granularity level and is automatically allocated by the tool to the corresponding finer levels of the hierarchy.";
const emissionFactorTooltip =
  "The Emission Factor Value is the numerical representation of the emission factor that corresponds to each activity data point.";
const transferADTooltip =
  "'Transfer AD' refers to the optional transfer of Activity Data from one entry to another. It involves subtracting the value from the original (reference) entry and adding it to a new (extended) entry. For accurate transfer, the units between the reference and the extended entries must match.";
const addGMPTooltip =
  "GMP, or Financial parameters, includes the financial parameters essential for automatically calculating the financial impact of your projects and scenarios. These parameters, once defined, will be associated with their respective projects during the project financials phase.";
const setHierarchyTooltip =
  "Specify you hierarchies to tailor your general modelling parameters to the respective hierarchies";
const setparametersTooltip =
  "In the Parameters section, you can define the financial considerations for your projects, tailored to the specific hierarchies established in step 1. Here, you can detail both the qualitative nature and the quantitative representation of each parameter with respective implications on the calculatons. By grouping multiple entries under a single Parameter name, you can create detailed and granular financial assumptions. An example of this would be setting variable carbon prices across different regions.";
const addGMPDatatypeTooltip =
  "Select the relevant data type for the parameter to determine whether the focus is on changes in emissions or activity data. This definition is crucial for determining what to base the parameter's financial impact on. ";
const addGMPParamterTooltip =
  "Each parameter name is a unique identifier. Multiple entries under the same parameter can specify variables with different levels of detail, and the correct numerical values are determined based on a hierarchical principle. 'Discount rate' is a special variable, which is a predefined variable that can be adapted by using this specific parameter name.  ";
const forceAsCostTooltip =
  "Description: 'Force as cost' ensures that the financial impact is consistently accounted for as a cost, even if it would normally be considered a benefit under standard calculation methods. Example: When a company implements energy efficiency measures, each kWh saved results in additional maintenance work that must be performed. The financial impact of this maintenance is directly proportional to the energy savings. Under normal circumstances, the energy savings would be considered a financial benefit. However, by applying the 'Force as cost' approach, the company ensures that these maintenance costs are treated as a cost.";
const vartypeTooltip =
  "The 'Variable type' distinguishes between dependent and independent variables. Dependent variables link the parameter to the project's impact on emissions or activity data, calculating the financial outcome as a result of that impact. Independent variables are distributed across various hierarchy levels without altering their overall financial impact.";
const sourceYearTooltip =
  "The 'Source year' is an optional field used to indicate the year from which the parameter's numerical inputs are sourced, allowing for a recency check without affecting the calculations.";
const sourceTooltip =
  "The 'Source' field, an optional entry, provides a description or link to the origin of the parameter's numerical inputs, serving as a reference for transparency.";
const dependency =
  "The 'Dependency' setting is crucial for the calculation as it dictates whether the financial implications are influenced by a project's impact on the selected data type or if they remain entirely independent.";
const allocation =
  "The 'Allocation' setting is crucial for the calculation as it dictates whether the financial implications are influenced by a project's impact on the selected data type or if they remain entirely independent.";
const startyearTooltip =
  "The numerical input represents the initial value of the parameter for the start year.";
const currencyUnitTooltip =
  "The 'Currency Unit' specifies the monetary unit used to measure the project's financial impacts.";
const addGMPUnitTooltip =
  "The 'Unit' field specifies the measurement unit for either emissions or activity data, according to the selected data type. The unit must match the target data for a financial impact to be calculated.";
const addGMPstartYearTooltip =
  "The 'Start Year' is the starting point where the financial parameters is first defined numerically.";
const addGMPEndYearTooltip =
  "The 'End Year' is the last year where the financial parameters are defined numerically.";
const CAGRTooltip =
  "The 'CAGR' (Compound Annual Growth Rate) is an annual growth factor applied cumulatively to the start year value, from the start year through to the end year.";
const CapexTooltip =
  "'CapEx' (capital expenditures) are used to classify the financial implications. ";
const addGmpTitleTooltip =
  "The 'Financial parameters' section displays the list of defined and saved parameters. Here, you can adjust the numerical values annually as needed. During project creation, each unique parameter name can be selected from a dropdown menu to associate it with the project.";
export {
  projNameTooltip,
  projTypeTooltip,
  ruleNameTooltip,
  reduceDataTypetooltip,
  replaceDataTypeTooltip,
  compensateDataTypeTooltip,
  cumulativeTooltip,
  emissionsValuetooltip,
  emissionsStartyearTooltip,
  emissionsEndYeartooltip,
  emissionsFilterbuttonTooltip,
  emissionsEditButtonTooltip,
  emissionsToggleTooltip,
  targetHierarchyTooltip,
  activityDataValueTooltip,
  emissionFactorTooltip,
  transferADTooltip,
  addGMPTooltip,
  setparametersTooltip,
  setHierarchyTooltip,
  addGMPDatatypeTooltip,
  addGMPParamterTooltip,
  forceAsCostTooltip,
  vartypeTooltip,
  sourceYearTooltip,
  sourceTooltip,
  dependency,
  allocation,
  startyearTooltip,
  currencyUnitTooltip,
  addGMPUnitTooltip,
  addGMPstartYearTooltip,
  addGMPEndYearTooltip,
  CAGRTooltip,
  CapexTooltip,
  addGmpTitleTooltip,
  addforecastTooltip,
  addtargetTooltip,
  forecastendYearTooltip,
  mostRecentYeartooltip,
  sectorTooltip,
  targetYearTooltip,
  targetBaseYearTooltip,
  targetDescriptiontooltip,
  targetEmissionTooltip,
  emissionTooltip,
  targetOrganisationTooltip,
  targetGeographytooltip,
  targetPercentageTooltip,
  temporalTypeTooltip,
  templatefileM1tooltip,
  targetTypetooltip,
  forecastDescriptionTooltip,
  forecastEndYeartooltip,
  forecastStartYearTooltip,
  forecastValueTooltip,
  forecastPercentageTooltip,
  removeTarget,
  appName,
  name,
  email,
  selectEmission,
  scope,
  subcat1,
  subcat2,
  subcat3,
  selectOrg,
  entLevel1,
  entLevel2,
  entLevel3,
  entLevel4,
  selectCustom,
  selectGeo,
  regLevel1,
  regLevel2,
  regLevel3,
  custom1,
  custom2,
  custom3,
  impExcelDesc,
  foreEndYear,
  addTarget,
  baseYear,
  targetSpec,
  targetYear,
  addForecast,
  impExcel,
  addForecastSpec,
  dashHeaderDesc,
  forecastSpec,
  engDash,
  setUpTableCOlumns,
  setEngDet,
  custSpec,
  orgSpec,
  geoSpec,
  strtYr,
  endYr,
  paginationOptions,
  projTag,
  selectProjectTag,
  createNewProjectTag,
  SearchNewProjectTags,
  projName,
  projType,
  projDesc,
  projInfo,
  userDuplicateMsg,
  analyseBodyTitle,
  analyseBodyDescription,
  createNewProject,
  analyseProjectsData,
  analyseTableHeaderProjectName,
  analyseTableHeaderProjectType,
  analyseTableHeaderSimplePaybackPeriod,
  analyseTableHeaderTotalAbatement,
  analyseTableHeaderAverageAnnualAbatement,
  analyseTableHeaderTechnicalAbatementPotential,
  analyseTableHeaderNetPresentValue,
  analyseTableHeaderInternalRateOfReturn,
  analyseTableHeaderDiscountedPaybackPeriod,
  analyseTableHeaderMarginalAbatementCost,
  deleteProjectModalHeader,
  deleteProjectModalBody,
  deleteProjectModalBodyDesc,
  deleteRuleModalHeader,
  deleteRuleModalBody,
  deleteRuleModalBodyDesc,
  deleteGMPModalHeader,
  deleteGMPModalBody,
  deleteGMPModalBodyDesc,
  paramNameValidationMsg,
  duplicateProjectNameError,
  newProjectHeaderDescr,
  emissionsHeaderDesc,
  emissionTableColumns,
  financialHeaderDesc,
  addGMPBtn,
  setParameters,
  addGMPDataType,
  addGMPParameterName,
  addGMPForceCost,
  addGMPVariableType,
  addGMPSourceYear,
  addGMPSource,
  addGMPDependency,
  addGMPAllocation,
  addGMPStartYearValue,
  addGMPCurrencyUnit,
  addGMPUnit,
  addGMPStartYear,
  addGMPEndyear,
  addGMPCAGR,
  addGMPDataTypeOptions,
  addGMPForceCostOptions,
  addGMPVariableTypeOptions,
  addGMPDependencyOptions,
  addGMPDependencyOptionsNew,
  manageEmissionHeaderMain,
  manageEmisionHeaderDesc,
  manageEmissionBodyTemplateTitle,
  manageEmissionBodyDownloadFile,
  manageEmissionBodyUploadBaseYear,
  manageEmissionBodyUploadRecentYear,
  manageEmissionBodyUploadSaveFile,
  manageEmissionBodyUploadFileHistory,
  fileVersionHistoryModalHeaderDate,
  fileVersionHistoryModalHeaderFileName,
  fileVersionHistoryModalHeaderUploadedBy,
  saveButtonTooltip,
  forecastHeaderDesc,
  forecastHeaderMain,
  forecastBodyCalculateEmissionsSummaryTitle,
  forecastBodyCalculateEmissionsSummaryTargetRequired,
  forecastBodyCalculateEmissionsSummaryForecastRequired,
  forecastBodyCalculateEmissionsSummaryCoveredEmission,
  forecastBodyCalculateEmissionsSummaryEmissionCoverage,
  forecastBodyCalculateEmissionsSummaryChangeOfEmission,
  forecastBodyCalculateEmissionsSummaryEmissionReducationTarget,
  forecastBodyCalculateEmissionsSummaryEmissionReductioonGap,
  forecastBodyCalculateEmissionsSummaryRelativeToBaseYear,
  forecastBodyCalculateEmissionsSummaryRelativeToTargetYear,
  forecastBodyChartTitle,
  forecastChartYAxisLabel,
  forecastSkipModalBody,
  forecastSkipModalHeader,
  addForecastTableHeaderCustom,
  addForecastTableHeaderDataType,
  addForecastTableHeaderDataEndYear,
  addForecastTableHeaderDataPercentageValue,
  addForecastTableHeaderDataStartYear,
  addForecastTableHeaderEmission,
  addForecastTableHeaderForecastDesc,
  addForecastTableHeaderForecastName,
  addForecastTableHeaderGeo,
  addForecastTableHeaderOrg,
  foreStartYear,
  manageEmissionBodyUploadTitle,
  homeHeader,
  homeHeaderBody,
  homescreencards,
  CreateNewEngBtn,
  specSelection,
  deleteModalBody,
  forcastResetModalHeader,
  forcastResetModalBody,
  forcastResetModalBodyDesc,
  deleteModalHeader,
  specHeaderDesc,
  emisSpec,
  CancelBtn,
  ConfirmBtn,
  PreviousBtn,
  NextBtn,
  SaveAndNextBtn,
  PoweBiBtn,
  SaveBtn,
  YesBtn,
  AddUserBtn,
  addNewUser,
  teamType,
  removeForecast,
  addForecastModalHeading,
  viaExcel,
  viaUI,
  DownloadTemplateBtn,
  DownloadErrorReportBtn,
  FourDigitYear,
  FileVerificationIsInProgress,
  FileUploadIsInProgress,
  FileUploadIsInSuccess,
  DataVerificationIsInProgress,
  DataVerificationIsInFailed,
  DataVerificationIsInSuccess,
  GeneratingErrorReport,
  DataLoadIsInFailed,
  DataLoadIsInSuccess,
  DataLoadIsInProgress,
  ExcelDataVerificationFailed,
  ApplyBtn,
  FailedStatus,
  TableHeaderClientName,
  TableHeaderEngagementCode,
  TableHeaderEngagementName,
  TableHeaderEngagementStatus,
  CloseProjectHint,
  homeSolutionModules,
  homeViewDashboard,
  setupClientName,
  setupCreateNewEngHeader,
  setupEngagementCode,
  setupEngagementName,
  setupExternalTeam,
  setupHeader,
  setupInternalTeam,
  setupSector,
  setupTableHeaderEmailId,
  setupTableHeaderName,
  setupTableHeaderTeam,
  setupTeamSetup,
  setupRemoveUserModalBody,
  setupRemoveUserModalHeader,
  setupEngagementCodeRequired,
  AddForecastBtn,
  AddTargetBtn,
  ResetM2Data,
  addForecastSpecificationAddCustomBtn,
  addForecastSpecificationAddEmissionBtn,
  addForecastSpecificationAddGeoBtn,
  addForecastSpecificationAddOrgBtn,
  addForecastSpecificationCustom,
  addForecastSpecificationDataType,
  addForecastSpecificationDataTypeOptions,
  addForecastSpecificationEndYear,
  addForecastSpecificationForecastDesc,
  addForecastSpecificationForecastName,
  addForecastSpecificationGeo,
  addForecastSpecificationOrg,
  addForecastSpecificationPercentValue,
  addForecastSpecificationStartYear,
  addForecastSpecificationHeader,
  addForecastSpecificationEmissionRequired,
  addForecastSpecificationGeoRequired,
  addForecastSpecificationOrgRequired,
  addForecastSpecificationDataTypeRequired,
  addForecastSpecificationPercentValueRequired,
  addForecastSpecificationStartYearRequired,
  addForecastSpecificationStartYearGreaterThanMRY,
  addForecastSpecificationStartYearLessThanFEY,
  addForecastSpecificationEndYearRequired,
  addForecastSpecificationEndYearGreaterThanMRY,
  addForecastSpecificationEndYearLessThanFEY,
  addForecastSpecificationForecastNameRequired,
  addTargetTableHeaderCustom,
  addTargetTableHeaderDataPercentageValue,
  addTargetTableHeaderDataBaseYear,
  emission,
  addTargetTableHeaderGeo,
  addTargetTableHeaderOrg,
  addTargetTableHeaderTargetDesc,
  addTargetTableHeaderTargetName,
  addTargetTableHeaderTargetType,
  addTargetTableHeaderTemporalType,
  addTargetTableHeaderDataTargetYear,
  addTargetSpecificationName,
  addTargetSpecificationTargetDesc,
  addTargetSpecificationTargetName,
  addTargetSpecificationTargetTemporalType,
  addTargetSpecificationTargetType,
  addTargetSpecificationPercentValueAnnual,
  addTargetSpecificationPercentValueTargetYear,
  addTargetSpecificationBaseYear,
  addTargetSpecificationTargetYear,
  addTargetSpecificationEmission,
  addTargetSpecificationAddEmissionBtn,
  addTargetSpecificationGeo,
  addTargetSpecificationAddGeoBtn,
  addTargetSpecificationOrg,
  addTargetSpecificationAddOrgBtn,
  addTargetSpecificationCustom,
  addTargetSpecificationAddCustomBtn,
  activityOutputText,
  activityOutput,
  addTargetSpecificationTargetTypeOptions,
  addTargetSpecificationTemporalTypeOptions,
  addTargetSpecificationEmissionRequired,
  addTargetSpecificationGeoRequired,
  addTargetSpecificationOrgRequired,
  addTargetSpecificationTargetTypeRequired,
  addTargetSpecificationTemporalTypeRequired,
  addTargetSpecificationPercentValueAnnualRequired,
  addTargetSpecificationPercentValueTargetYearRequired,
  addTargetSpecificationBaseYearRequired,
  addTargetSpecificationBaseYearGreaterThanFEY,
  addTargetSpecificationBaseYearLessThanMRY,
  addTargetSpecificationTargetYearRequired,
  addTargetSpecificationForecastNameRequired,
  duplicateIntensityTargetUnitModalHeader,
  duplicateIntensityTargetUnitDescription,
  addTargetSpecificationTargetYearGreaterThanMRY,
  addTargetSpecificationTargetYearLessThanFEY,
  addForecastSpecificationEndYearLessThanStartYear,
  addForecastSpecificationStartYearGreaterThanEndYear,
  DeleteBtn,
  forecastChartNoData,
  forecastChartNoDataDesc1,
  forecastChartNoDataDesc2,
  forecastChartProcessingForecastAndTargetData,
  forecastChartXAxisEmissionsWithTarget,
  forecastChartXAxisEmissionsWithoutTarget,
  forecastChartXAxisTarget,
  forecastChartDataLoadingMessage,
  forecastChartForecastLabel,
  forecastChartTargetLabel,
  forecastChartForecastAndTargetLabel,
  DataLoadHasFailed,
  deleteMessage,
  deleteMessageLine2,
  deleteMessageLine3,
  setupEngagementCodeLength,
  uploadSuccessMessage1,
  uploadSuccessMessage2,
  uploadSuccessMessage3,
  applyErrorMessageForecast,
  applyErrorMessageTarget,
  forecastPercentageError,
  targetPercentageError,
  DownloadBtn,
  fileVersionHistoryModalLiveVersion,
  YesChangeBtn,
  changeFEYModalBody,
  changeFEYModalHeader,
  YesSkipBtn,
  forecastSkipModalEmptyFEY,
  forecastBodyChartScope1And2,
  forecastBodyChartScope3,
  emissionHeaderTitle,
  reset,
  simulateValueTooltip,
  emissionHeaderTooltip,
  emissionGraphHeading,
  filters,
  editData,
  disableEditButton,
  emissionFilterTooltip,
  batchEditreduce,
  reduceChartNoData,
  cumulative,
  simulateValue,
  unit,
  simulate,
  unitTooltip,
  unitDropdown,
  yesNo,
  dropdowns,
  tablecolumnFilters,
  sessionTimeoutLogOut,
  sessionTimeoutMessage,
  sessionTimeoutStayLoggedIn,
  sessionTimeoutTitle,
  ExportBtn,
  changeLog,
  emissionsChangeLog,
  changeLogDescription,
  changeLogTableColDefs,
  changeLogReplaceTableColDefs,
  recreateProject,
  endYearGreaterThanFEYear,
  endYearLessThanStartYear,
  consentHeading,
  consentBodyText,
  consentPrivacyText,
  consentIAgree,
  consentNotice,
  consentNoticePrivacy,
  ruleName,
  viewresults,
  CreateNewScenarioBtn,
  simulateHeaderDescription,
  simulateHeaderTitle,
  saveSimulateHeaderTitle,
  ScenarioName,
  ScenarioDescription,
  scenarioValidationMessage,
  projectTypes,
  projectResultsHeader,
  projectResultsEmissionsAbatementChartHeader,
  decimalsNotAllowedError,
  relativePercentageError,
  CAGRPercentageError,
  only2DecimalsAllowed,
  negativeNotallowed,
  financialParameterType,
  financialRulesType,
  financialVariableType,
  financialSetVariable,
  simulateCloseProjectScenarioHint,
  batchEditCompensate,
  batchEditReplace,
  emissionArray,
  projectResultsAbatementCashFlowCalcHeader,
  projectResultsBusinessCaseSummaryHeader,
  projectResultsCashFlowChartHeader,
  projectResultsEmissionAbatementChartAE,
  projectResultsEmissionAbatementChartFAP,
  projectResultsEmissionAbatementChartFBP,
  projectResultsEmissionAbatementChartRE,
  projectResultsEmissionAbatementChartYears,
  targetHierarchy,
  actDataValue,
  actDataUnit,
  emisFactValue,
  emisFactorUnit,
  transferAd,
  targetHierarchyValues,
  projectsLibraryHeader,
  scopes,
  maxlimitExceeded,
  resetFiltersHeader,
  resetFiltersBody,
  projectLibraryItemMargCost,
  projectLibraryItemNetPresent,
  projectLibraryItemRateOfReturn,
  projectLibraryItemTechAbatement,
  BackBtn,
  projectDetailsType,
  projectDetailsTags,
  projectDetailsBusinessRules,
  projectDetailsBusinessRulesRuleName,
  projectDetailsBusinessRulesChangeType,
  projectDetailsBusinessRulesDataType,
  projectDetailsBusinessRulesHierarchy,
  projectDetailsBusinessRulesName,
  projectDetailsBusinessRulesUnitOp,
  projectDetailsBusinessRulesCumulative,
  projectDetailsBusinessRulesUnit,
  projectDetailsBusinessRulesValue,
  projectDetailsBusinessRulesTransferAD,
  projectDetailsBusinessRulesADValue,
  projectDetailsBusinessRulesADUnit,
  projectDetailsBusinessRulesEfValue,
  projectDetailsBusinessRulesStartYear,
  projectDetailsBusinessRulesEndYear,
  projectDetailsBusinessRulesScope,
  projectDetailsBusinessRulesSubcat1,
  projectDetailsBusinessRulesSubcat2,
  projectDetailsBusinessRulesSubcat3,
  projectDetailsBusinessRulesReg1,
  projectDetailsBusinessRulesReg2,
  projectDetailsBusinessRulesReg3,
  projectDetailsBusinessRulesEnt1,
  projectDetailsBusinessRulesEnt2,
  projectDetailsBusinessRulesEnt3,
  projectDetailsBusinessRulesEnt4,
  projectDetailsBusinessRulesCust1,
  projectDetailsBusinessRulesCust2,
  projectDetailsBusinessRulesCust3,
  projectDetailsAbatementCashFlowCalcHeader,
  projectDetailsFinancialsHeader,
  projectDetailsItemDPP,
  projectDetailsItemMargCost,
  projectDetailsItemNetPresent,
  projectDetailsItemRateOfReturn,
  projectDetailsItemSImplePayback,
  projectDetailsItemTechAbatement,
  projectDetailsCategoryHeader,
  projectDetailsUnitHeader,
  projectToLibraryHeader,
  projectToLibraryBody,
  SubmitBtn,
  onlyNumbersAllowed,
  syncData,
  analyseValidationMessage,
  datainSyncMessage,
  analyseDeleteMessage,
  copyFromPastVersions,
  copyProjectsMessage,
  activityDataRelativeError,
  correctM3inM4Message,
  calculate,
};
