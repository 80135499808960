import { MotifButton } from "@ey-xd/motif-react";
import "./Button.scss";

export function Button({
  className,
  type = "button",
  variant = "text",
  onClick,
  children,
  disabled = false,
  size = "large",
  tooltip,
}) {
  return (
    <MotifButton
      className={`decarb-button ${className}`}
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      size={size}
      data-testid="Button"
      title={tooltip}
    >
      {children}
    </MotifButton>
  );
}
