export const FORECAST_URL = {
  GetForecastRecords: `Forecast/GetAllForecastSpecs/GetAllRecordsByForeCastSpecification`,
  SaveForecastSpecification: `Forecast/SaveSpecification`,
  GetTargetRecords: `Forecast/GetAllTargetSpecs/GetAllRecordsByTargetSpecification`,
  SaveTargetSpecification: `Forecast/SaveTargetSpecifications`,
  UpdateTargetSpecification: `Forecast/UpdateTargetSpecifications`,
  UpdateForecastSpecification: `Forecast/UpdateSpecification`,
  GetTargetSpecificationByBatchGroupId: `Forecast/GetTargetSpecificationByID/GetTargetSpecificationByGroupID`,
  GetForecastSpecificationByBatchGroupId: `Forecast/GetForecastSpecificationByID/GetForeCastSpecificationByGroupID`,
  UpdateBatchIdForTargetSpecifcationAndCallSP: `Forecast/UpdateBatchIdForTargetSpecifcationAndCallSP`,
  UpdateBatchIdForForecastSpecifcationAndCallSP: `Forecast/UpdateBatchIdForForecastSpecifcationAndCallSP`,
  DeleteForecast: `Forecast/DeleteForecastSpecification`,
  DeleteTarget: `Forecast/DeleteTargetSpecification`,
  GetForecastSpecificationProcessingStatus: `Forecast/GetForecastSpecificationProcessingStatus`,
  GetTargetSpecificationProcessingStatus: `Forecast/GetTargetSpecificationProcessingStatus`,
  AllSaveForecastspecification: `Forecast/SaveForecastSpecification`,
  AllSaveTargetspecification: `Forecast/SaveTargetSpecification`,
  AllUpdateForecast: `Forecast/UpdateForecastSpecification`,
  AllUpdateTarget: `Forecast/UpdateTargetSpecification`,
  ApplyForecast: `Forecast/ApplyForecast`,
  ApplyTarget: `Forecast/ApplyTarget`,
  SetShowForecast: `Forecast/SetShowForecast`,
  SetShowTarget: `Forecast/SetShowTarget`,
  checkName: `Forecast/CheckIfNameExist`,
  UpdateForeCastEndYearData: `Forecast/UpdateForeCastEndYearData`,
  SaveManualIntensityTarget: "Forecast/SaveManualIntensityTargetSpecifications",
  UpdateManualIntensityTarget:
    "Forecast/UpdateManualIntensityTargetSpecifications",
  GetM2TargetDownloadData: "Forecast/GetM2TargetDownloadData",
  GetM2ForeCastDownloadData: "Forecast/GetM2ForeCastDownloadData",
};
