import { Modal } from "../../../Modal/Modal";
import {
  CancelBtn,
  duplicateIntensityTargetUnitDescription,
  duplicateIntensityTargetUnitModalHeader
} from "../../../../util/constants";
import { Button } from "../../../Button/Button";

function ModalHeader() {
  return (
    <div className="file-version-history-modal-header">
      <span>{duplicateIntensityTargetUnitModalHeader}</span>
    </div>
  );
}

function ModalBody() {
  return <span>{duplicateIntensityTargetUnitDescription}</span>;
}

function ModalFooter({ handleCancelClick }) {
  return (
    <div className="view-endagement-delete-modal-footer">
      <Button variant="secondary" onClick={handleCancelClick}>
        {CancelBtn}
      </Button>
    </div>
  );
}

export function TargetIntensityValidationModal({
  isModalVisible,
  onClose,
  onConfirm,
}) {
  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={<ModalHeader />}
      body={<ModalBody />}
      footer={<ModalFooter handleCancelClick={onClose}/>}
      className={"change-fey-modal"}
    />
  );
}
