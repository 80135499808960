import { FORECAST_URL } from "./endpoints/forecast";
import { get, post } from "./base.service";

async function getForecastRecords({ request }) {
  return await get(
    `${FORECAST_URL.GetForecastRecords}?EngagementID=${request.engagementId}`
  );
}

async function saveForecastSpecification({ payload }) {
  return await post(`${FORECAST_URL.SaveForecastSpecification}`, payload);
}

async function allSaveForecastSpecification({ allpayload }) {
  return await post(`${FORECAST_URL.AllSaveForecastspecification}`, allpayload);
}

async function allSaveTargetSpecification({ allpayload }) {
  return await post(`${FORECAST_URL.AllSaveTargetspecification}`, allpayload);
}

async function editForecastSpecification({ request }) {
  return await post(`${FORECAST_URL.UpdateForecastSpecification}`, request);
}

async function getTargetRecords({ request }) {
  return await get(
    `${FORECAST_URL.GetTargetRecords}?EngagementID=${request.engagementId}`
  );
}

async function saveTargetSpecification({ payload }) {
  return await post(`${FORECAST_URL.SaveTargetSpecification}`, payload);
}

async function updateTargetSpecification({ payload }) {
  return await post(`${FORECAST_URL.UpdateTargetSpecification}`, payload);
}

async function updateForecastSpecification({ payload }) {
  return await post(`${FORECAST_URL.UpdateForecastSpecification}`, payload);
}

async function getForecastTargetByGroupId({ request }) {
  return await get(
    `${FORECAST_URL.GetTargetSpecificationByBatchGroupId}?EngagementID=${request.engagementId}&BatchGroupID=${request.batchGroupId}`
  );
}

async function getForecastByGroupId({ request }) {
  return await get(
    `${FORECAST_URL.GetForecastSpecificationByBatchGroupId}?EngagementID=${request.engagementId}&BatchGroupID=${request.batchGroupId}`
  );
}

async function updateBatchIdForTargetSpecification({ payload }) {
  return await post(
    `${FORECAST_URL.UpdateBatchIdForTargetSpecifcationAndCallSP}?engagementId=${payload.engagementId}`
  );
}

async function updateBatchIdForForecastSpecification({ payload }) {
  return await post(
    `${FORECAST_URL.UpdateBatchIdForForecastSpecifcationAndCallSP}?engagementId=${payload.engagementId}`
  );
}

async function deleteForecast({ request }) {
  return await post(
    `${FORECAST_URL.DeleteForecast}?engagementId=${request.engagementId}&batchgroupId=${request.batchGroupId}`
  );
}

async function deleteTarget({ request }) {
  return await post(
    `${FORECAST_URL.DeleteTarget}?engagementId=${request.engagementId}&batchgroupId=${request.batchGroupId}`
  );
}

async function getForecastSpecificationProcessingStatus({ request }) {
  return await get(
    `${FORECAST_URL.GetForecastSpecificationProcessingStatus}?engagementId=${request.engagementId}`
  );
}

async function getTargetSpecificationProcessingStatus({ request }) {
  return await get(
    `${FORECAST_URL.GetTargetSpecificationProcessingStatus}?engagementId=${request.engagementId}`
  );
}

async function applyForecast({ request }) {
  return await post(
    `${FORECAST_URL.ApplyForecast}?engagementId=${request.engagementId}`
  );
}

async function applyTarget({ request }) {
  return await post(
    `${FORECAST_URL.ApplyTarget}?engagementId=${request.engagementId}`
  );
}

async function setShowTarget({ request }) {
  return await post(
    `${FORECAST_URL.SetShowTarget}?engagementId=${request.engagementId}&status=${request.status}`
  );
}

async function setShowForecast({ request }) {
  return await post(
    `${FORECAST_URL.SetShowForecast}?engagementId=${request.engagementId}&status=${request.status}`
  );
}

async function checkName({ request }) {
  return await post(
    `${FORECAST_URL.checkName}?engagementId=${request.engagementId}&name=${request.name}&type=${request.type}`
  );
}

async function allUpdateForecast({ allpayload }) {
  return await post(`${FORECAST_URL.AllUpdateForecast}`, allpayload);
}
async function allUpdateTarget({ allpayload }) {
  return await post(`${FORECAST_URL.AllUpdateTarget}`, allpayload);
}

async function SaveManualIntensityTargetSpecs({ payload }) {
  return await post(`${FORECAST_URL.SaveManualIntensityTarget}`, payload);
}

async function UpdateManualIntensityTargetSpecs({ payload }) {
  return await post(`${FORECAST_URL.UpdateManualIntensityTarget}`, payload);
}

async function updateForeCastEndYearData({ request }) {
  return await post(
    `${FORECAST_URL.UpdateForeCastEndYearData}?EngagementCode=${request.engagementCode}`
  );
}

async function getM2ForeCastDownloadData({ request }) {
  return await get(
    `${FORECAST_URL.GetM2ForeCastDownloadData}?StartYear=${request.startYear}&EngagementCode=${request.engagementCode}`
  );
}

async function getM2TargetDownloadData({ request }) {
  return await get(
    `${FORECAST_URL.GetM2TargetDownloadData}?StartYear=${request.startYear}&EngagementCode=${request.engagementCode}`
  );
}

export {
  getForecastRecords,
  saveForecastSpecification,
  editForecastSpecification,
  getTargetRecords,
  saveTargetSpecification,
  updateTargetSpecification,
  updateForecastSpecification,
  getForecastTargetByGroupId,
  getForecastByGroupId,
  updateBatchIdForTargetSpecification,
  updateBatchIdForForecastSpecification,
  deleteForecast,
  deleteTarget,
  getForecastSpecificationProcessingStatus,
  getTargetSpecificationProcessingStatus,
  allSaveForecastSpecification,
  allSaveTargetSpecification,
  applyForecast,
  applyTarget,
  setShowForecast,
  setShowTarget,
  checkName,
  allUpdateForecast,
  allUpdateTarget,
  updateForeCastEndYearData,
  SaveManualIntensityTargetSpecs,
  UpdateManualIntensityTargetSpecs,
  getM2ForeCastDownloadData,
  getM2TargetDownloadData,
};
