import { MotifIconButton } from "@ey-xd/motif-react";
import React, { memo } from "react";
import { DeleteLogo, DownloadIcon } from "../../icons";
import { Locker } from "../../icons/locker";
import { MotifTooltip } from "@ey-xd/motif-react";

export default memo(({ node, onViewProjectScenarioClick, isDataNotinSync }) => {
  const isScenarioLocked = node.data.isLocked === 1;
  return (
    <span
      className={` ${
        isDataNotinSync ? "scenario-table-data-validation-link" : ""
      } ${
        node.data.isValidated === "N"
          ? "scenario-table-pending-validation-link"
          : "scenario-link"
      }`}
      onClick={() =>
        node.data.activeFlag !== 0
          ? onViewProjectScenarioClick(node.data)
          : undefined
      }
    >
        {isScenarioLocked && (
        <MotifTooltip
          className="tooltip-icon"
          children={node.data.lockedby}
          placement="bottom"
          hideCloseButton="true"
          variant="alt"
          trigger={<Locker />}
        />
      )}
      {node.data.scenarioName}
    </span>
  );
});

export function customButtonRenderer({
  onExportClick,
  onDeleteProjectScenarioClick,
  node,
}) {
  return (
    <div className="analyze-table-action">
      <MotifIconButton
        onClick={() => {
          onExportClick(node.data);
        }}
      >
        <DownloadIcon />
      </MotifIconButton>
      <MotifIconButton
        aria-label="Close project scenario"
        onClick={() =>
          node.data.activeFlag !== 0
            ? onDeleteProjectScenarioClick(node.data.scenarioID)
            : undefined
        }
        className={`view-engagement-close-project ${
          node.data.activeFlag === 0 ? "view-engagement-link-inactive" : ""
        }`}
      >
        <DeleteLogo />
      </MotifIconButton>
    </div>
  );
}
