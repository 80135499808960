import React from "react";
import { MotifTooltip } from "@ey-xd/motif-react";
export function Tooltip({ trigger, variant, tooltipText }) {
  return (
    <MotifTooltip
      trigger={trigger}
      variant={variant}
      allowClick="false"
      allowHover="true"
      flip="true"
      hideCloseButton="true"
    >
      {tooltipText}
    </MotifTooltip>
  );
}
