import React, { useState, useEffect } from "react";
import {
  SaveBtn,
  addForecastSpecificationDataType,
  addForecastSpecificationDataTypeOptions,
  batchEditreduce,
  cumulative,
  endYr,
  simulate,
  strtYr,
  unit,
  unitDropdown,
  unitTooltip,
  simulateValue,
  yesNo,
  addForecastSpecificationStartYearGreaterThanMRY,
  addForecastSpecificationStartYearLessThanFEY,
  endYearGreaterThanFEYear,
  endYearLessThanStartYear,
  ruleName,
  decimalsNotAllowedError,
  relativePercentageError,
  only2DecimalsAllowed,
  negativeNotallowed,
  batchEditReplace,
  batchEditCompensate,
  emissionArray,
  name,
  targetHierarchy,
  emisFactorUnit,
  actDataUnit,
  emisFactValue,
  actDataValue,
  transferAd,
  targetHierarchyValues,
  maxlimitExceeded,
  onlyNumbersAllowed,
  ruleNameTooltip,
  reduceDataTypetooltip,
  replaceDataTypeTooltip,
  compensateDataTypeTooltip,
  cumulativeTooltip,
  emissionsValuetooltip,
  emissionsStartyearTooltip,
  emissionsEndYeartooltip,
  emissionsToggleTooltip,
  targetHierarchyTooltip,
  activityDataValueTooltip,
  emissionFactorTooltip,
  transferADTooltip,
  activityDataRelativeError,
} from "../../../util/constants";
import { Select } from "../../Select/Select";
import { EmissionIcon, SaveIcon, SimulateIcon } from "../../../icons";
import { Button } from "../../Button/Button";
import { Tooltip } from "../../CommonUIComponents/Tooltip/Tooltip";
import { Input } from "../../Input/Input";
import { useDispatch, useSelector } from "react-redux";
import {
  setSimulateDataOnTable,
  setSimulateFlag,
  setGraphSimulateFlag,
  setSimulateDataOnGraph,
  setReduceDefautViewData,
  setIsAnyRowSelected,
  setEditDataButton,
  getLockingData,
  setTogglechecked,
  resetManualEditRules,
  setSelectedRowData,
  setM3Step2Loader,
} from "../../../store/slices/projectSlice";
import {
  getColumnValueFlags,
  getM3TableGraphData,
  manualEditM3Step2,
  SaveM3Step2ReplaceData,
} from "../../../store/services/project.service";
import { ToggleSwitch } from "../../CommonUIComponents/ToggleSwitch/ToggleSwitch";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";
export function EmissionSimulate() {
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const user = useSelector((state) => state.user.user);
  const selectedRowData = useSelector((state) => state.project.selectedRowData);
  const selectedRow = useSelector((state) => state.project.isAnyRowSelected);
  const filtersPayload = useSelector(
    (state) => state.project.appliedFilterPayload
  );
  const reduceDefaultViewData = useSelector(
    (state) => state.project.defaultViewData
  );
  const isResetApplied = useSelector((state) => state.project.isResetApplied);
  const [dataType, setDataType] = useState("");
  const [cumlative, setCumulative] = useState("");
  const [unitSelected, setUnit] = useState("");
  const [value, setValue] = useState("");
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");
  const [valueErrorMessage, setValueErrorMessage] = useState("");
  const [valueError, setValueError] = useState(false);
  const [startYearErrorMessage, setStartYearErrorMessage] = useState("");
  const [endYearErrorMessage, setEndYearErrorMessage] = useState("");
  const decimalNumberRegExp = /^-?\d{1,13}(\.?\d{1,2})?$/;
  const onlyPositiveDecimalsRegExp = /^\d{1,13}(\.?\d{1,2})?$/;
  const onlyNumbersRegExp = /^-?\d{1,11}$/;
  const onlyPositiveRegExp = /^\d{1,15}$/;
  const [showstartYearError, setShowStartYearError] = useState(false);
  const [showendYearError, setShowEndYearError] = useState(false);
  const [rule, setRuleName] = useState("");
  const [isSimulateApplied, setSimulateApplied] = useState(false);
  const [isSimulateBtnDisable, setSimulateBtnDisable] = useState(true);
  const [simulateLoader, setSimulateloader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [togglechecked, settoggleChecked] = useState(false);
  const [toggledisabled, setToggleDisabled] = useState(false);
  const [extendCumulative, setExtendCumulative] = useState("");
  const selectedHeaderFilters = useSelector(
    (state) => state.project.selectedUIHeaderFilters
  );
  const selectedAllFilters = useSelector(
    (state) => state.project.selectedUIAllFilters
  );
  const isManualEdit = useSelector((state) => state.project.isManualEdit);
  const manualEditRules = useSelector((state) => state.project.manualEditRules);
  const [trgtHrchy, setTrgtHrchy] = useState("");
  const [replaceName, setReplaceName] = useState("");
  const [activityDataValue, setActivityDataValue] = useState("");
  const [emissionFactorValue, setEmissionFactorValue] = useState("");
  const [activityDataUnit, setActivityDataUnit] = useState("");
  const [emissionFactorUnit, setEmissionFactorUnit] = useState("");
  const [trnsfrAD, setTransferAD] = useState("");
  const [activityDataValueErrorMessage, setActivityDataValueErrorMessage] =
    useState("");
  const [isActivityDataValueError, setisActivityDataValueError] =
    useState(false);
  const [emissionFactorValueErrorMessage, setEmissionFactorValueErrorMessage] =
    useState("");
  const [isEmissionFactorValueError, setIsEmissionFactorValueError] =
    useState(false);
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const [extendError, setExtendError] = useState(true);
  // Simulate button disable function
  useEffect(() => {
    checkSimulateEnableValidation();
    checkManualEdit();
    checkTargetHierarchy(trgtHrchy);
  }, [selectedRowData, isSimulateApplied]);

  useEffect(() => {
    checkSimulateEnableValidation();
  }, [
    selectedRowData?.isAnyRowSelected,
    dataType,
    cumlative,
    unitSelected,
    value,
    startYear,
    endYear,
    rule,
    manualEditRules,
    extendError,
    activityDataUnit,
    emissionFactorValue,
    activityDataValue,
    trnsfrAD,
    replaceName,
    trgtHrchy,
  ]);

  useEffect(() => {
    if (project.projectType === 3) {
      setDataType("Emissions");
    }
    if (project.projectType === 2) {
      checkForExtend();
    }
  }, [project]);

  useEffect(() => {
    if (isResetApplied) {
      resetSimulatefields();
      setToggleDisabled(false);
      settoggleChecked(false);
    }
  }, [isResetApplied]);

  useEffect(() => {
    dispatch(setTogglechecked({ isExtend: togglechecked }));
    resetSimulatefields();
  }, [togglechecked]);

  //Checking if Extend has already been applied
  const checkForExtend = async () => {
    let flagsPayload = {
      columnName: "Extend",
      tableName: "Project",
      id: project.projectId,
    };
    const response = await getColumnValueFlags({ flagsPayload });
    if (response.columnValue === "1") {
      settoggleChecked(true);
      setToggleDisabled(true);
    }
  };
  // To check for Manual edit enabled or not
  const checkManualEdit = () => {
    if (
      selectedRowData?.isAnyRowSelected ||
      (!selectedRowData?.isAnyRowSelected &&
        selectedRow?.rowChecked !== selectedRow?.rowUnchecked)
    ) {
      if (
        selectedRowData?.rowData?.unit !== "" &&
        selectedRowData?.rowData?.unit !== null &&
        selectedRowData?.rowData?.unit !== undefined &&
        selectedRowData?.rowData?.data_type !== "Emissions"
      ) {
        dispatch(setEditDataButton({ data: true }));
      }
    }
  };

  //Onclick function on Simulate field
  const handleOnChange = (value, fieldName) => {
    if (fieldName === "dataType") {
      setDataType(value);
      setValue("");
    } else if (fieldName === "cumulative") {
      setCumulative(value);
      setValue("");
    } else if (fieldName === "unit") {
      setUnit(value);
      setValue("");
      setActivityDataUnit("");
      setEmissionFactorUnit("");
    } else if (fieldName === "value") {
      checkValueValidation(value);
      setValue(value);
    } else if (fieldName === "startYear") {
      checkStartYearValidation(value);
      setStartYear(value);
    } else if (fieldName === "endYear") {
      checkEndYearValidation(value);
      setEndYear(value);
    } else if (fieldName === "ruleName") {
      setRuleName(value);
    } else if (fieldName === "activityDataValue") {
      checkActivityDataValue(value);
      setActivityDataValue(value);
    } else if (fieldName === "emissionFactorValue") {
      checkEmissionFactorValue(value);
      setEmissionFactorValue(value);
    } else if (fieldName === "activityDataUnit") {
      setActivityDataUnit(value);
      if (value !== "") {
        setEmissionFactorUnit("kgCO2e/" + value);
      } else {
        setEmissionFactorUnit("");
      }
    } else if (fieldName === "name") {
      setReplaceName(value);
    } else if (fieldName === "targetHierarchy") {
      setTrgtHrchy(value);
      checkTargetHierarchy(value);
    } else if (fieldName === "extendcumulative") {
      setExtendCumulative(value);
    } else if (fieldName === "transferAD") {
      setTransferAD(value);
    }
    checkSimulateEnableValidation(value, fieldName);
  };

  const checkTargetHierarchy = (value) => {
    if (
      value === "Emission" &&
      selectedRowData?.rowData?.category_level1 === ""
    ) {
      setExtendError(true);
    } else if (
      value === "Geography" &&
      selectedRowData?.rowData?.region_level1 === ""
    ) {
      setExtendError(true);
    } else if (
      value === "Organisational" &&
      selectedRowData?.rowData?.entity_level1 === ""
    ) {
      setExtendError(true);
    } else {
      setExtendError(false);
    }
  };

  // Check simulate validation
  const checkSimulateEnableValidation = async (data, field) => {
    let check = checkSelectedRowYearValues(selectedRowData?.rowData);

    if (
      (selectedRowData?.isAnyRowSelected ||
        (!selectedRowData?.isAnyRowSelected &&
          selectedRow?.rowChecked !== selectedRow?.rowUnchecked &&
          !isSimulateApplied &&
          selectedRow?.rowUnchecked !== undefined)) &&
      selectedRowData?.rowData?.data_type &&
      dataType === selectedRowData?.rowData?.data_type &&
      dataType &&
      cumlative &&
      unitSelected &&
      value &&
      startYear &&
      endYear &&
      rule &&
      selectedHeaderFilters.scope?.length > 0 &&
      !isManualEdit &&
      !valueError &&
      !showstartYearError &&
      !showendYearError
    ) {
      if (
        dataType === "Activity data" &&
        unitSelected === "Absolute" &&
        selectedRowData?.rowData?.unit !== ""
      ) {
        setSimulateBtnDisable(false);
      } else if (
        (dataType === "Activity data" || dataType === "Emission factor") &&
        unitSelected === "Relative"
      ) {
        setSimulateBtnDisable(false);
      } else if (
        dataType === "Emission factor" &&
        unitSelected === "Absolute" &&
        selectedRowData?.rowData?.unit !== "" &&
        check
      ) {
        setSimulateBtnDisable(false);
      } else if (
        dataType === "Emissions" &&
        value !== "" &&
        !valueError &&
        startYear !== "" &&
        endYear !== "" &&
        project.projectType === 3
      ) {
        setSimulateBtnDisable(false);
      } else {
        setSimulateBtnDisable(true);
      }
    } else if (
      isManualEdit &&
      selectedRowData?.isAnyRowSelected &&
      rule !== "" &&
      (((project.projectType === 1 || project.projectType === 2) &&
        selectedRowData?.rowData?.data_type !== "Emissions") ||
        project.projectType === 3) &&
      manualEditRules.length > 0
    ) {
      setSimulateBtnDisable(false);
    } else if (
      togglechecked &&
      selectedRowData?.isAnyRowSelected &&
      replaceName !== "" &&
      unitSelected &&
      activityDataValue &&
      startYear &&
      endYear &&
      (trnsfrAD === "Yes" || trnsfrAD === "No") &&
      emissionFactorValue &&
      selectedRowData?.rowData?.unit !== "" &&
      selectedRowData?.rowData?.data_type === "Activity data" &&
      !showstartYearError &&
      !showendYearError &&
      !isEmissionFactorValueError &&
      !isActivityDataValueError &&
      !extendError
    ) {
      if (unitSelected === "Absolute" && activityDataUnit !== "") {
        setSimulateBtnDisable(false);
      } else if (unitSelected === "Relative") {
        setSimulateBtnDisable(false);
      }
    } else {
      setSimulateBtnDisable(true);
    }
  };

  const checkSelectedRowYearValues = (data) => {
    let check;
    if (data) {
      for (
        let i = engagement.mostRecentYear + 1;
        i <= engagement.foreCastEndYear;
        i++
      ) {
        if (data[i] === null || data[i] === "") {
          check = false;
          break;
        } else {
          check = true;
        }
      }
    }
    return check;
  };

  // Validation for Value field
  const checkValueValidation = (value) => {
    if (
      (dataType === "Activity data" || dataType === "Emissions") &&
      unitSelected === "Absolute"
    ) {
      if (value === "" || value === "-") {
        setValueError(false);
        setValueErrorMessage("");
      } else if (!onlyNumbersRegExp.test(value)) {
        setValueErrorMessage(decimalsNotAllowedError);
        setValueError(true);
      } else {
        setValueError(false);
        setValueErrorMessage("");
      }
    }
    if (
      (dataType === "Activity data" ||
        dataType === "Emission factor" ||
        dataType === "Emissions") &&
      unitSelected === "Relative"
    ) {
      if (value === "" || value === "-") {
        setValueError(false);
        setValueErrorMessage("");
      } else if (value < -100 || value > 9999.99) {
        setValueErrorMessage(relativePercentageError);
        setValueError(true);
      } else if (!decimalNumberRegExp.test(value)) {
        setValueError(true);
        setValueErrorMessage(only2DecimalsAllowed);
      } else {
        setValueError(false);
        setValueErrorMessage("");
      }
    }
    if (dataType === "Emission factor" && unitSelected === "Absolute") {
      setValueError(false);
      setValueErrorMessage("");
      if (value === "" || value === "-") {
        setValueError(false);
        setValueErrorMessage("");
      } else if (value < 0) {
        setValueErrorMessage(negativeNotallowed);
        setValueError(true);
      } else if (!decimalNumberRegExp.test(value)) {
        if (!decimalNumberRegExp.test(value)) {
          setValueErrorMessage(only2DecimalsAllowed);
          setValueError(true);
        } else if (!onlyNumbersRegExp.test(value)) {
          setValueErrorMessage(onlyNumbersAllowed);
          setValueError(true);
        }
      } else if (parseFloat(value) > 99999999999) {
        setValueErrorMessage(maxlimitExceeded);
        setValueError(true);
      } else {
        setValueError(false);
        setValueErrorMessage("");
      }
    }
  };

  // Check Year validation
  const checkStartYearValidation = (value) => {
    const mostRecentYear = engagement.mostRecentYear;
    if (value && parseFloat(value) <= mostRecentYear) {
      setStartYearErrorMessage(addForecastSpecificationStartYearGreaterThanMRY);
      setShowStartYearError(true);
    } else if (value && parseFloat(value) > engagement?.foreCastEndYear) {
      setStartYearErrorMessage(addForecastSpecificationStartYearLessThanFEY);
      setShowStartYearError(true);
    } else {
      setStartYearErrorMessage("");
      setShowStartYearError(false);
    }
  };

  const checkEndYearValidation = (value) => {
    const forecastEndYear = engagement.foreCastEndYear;
    if (value && parseFloat(value) > forecastEndYear) {
      setEndYearErrorMessage(endYearGreaterThanFEYear);
      setShowEndYearError(true);
    } else if (value && startYear && parseFloat(value) < startYear) {
      setEndYearErrorMessage(endYearLessThanStartYear);
      setShowEndYearError(true);
    } else {
      setEndYearErrorMessage("");
      setShowEndYearError(false);
    }
  };

  //  Activity data value validation for Replace Extend
  const checkActivityDataValue = (value) => {
    if (unitSelected === "Absolute") {
      if (value == "") {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(true);
      } else if (!onlyPositiveRegExp.test(value)) {
        if (value < 0 || value === "-") {
          setActivityDataValueErrorMessage(negativeNotallowed);
          setisActivityDataValueError(true);
        } else if (!onlyPositiveRegExp.test(value)) {
          setActivityDataValueErrorMessage(decimalsNotAllowedError);
          setisActivityDataValueError(true);
        }
      } else if (value > 99999999999) {
        setActivityDataValueErrorMessage(maxlimitExceeded);
        setisActivityDataValueError(true);
      } else {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(false);
      }
    } else {
      if (value === "") {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(false);
      } else if (value > 100) {
        setActivityDataValueErrorMessage(activityDataRelativeError);
        setisActivityDataValueError(true);
      } else if (!onlyPositiveRegExp.test(value)) {
        if (value > 100) {
          setActivityDataValueErrorMessage(activityDataRelativeError);
          setisActivityDataValueError(true);
        } else if (value < 0) {
          setActivityDataValueErrorMessage(negativeNotallowed);
          setisActivityDataValueError(true);
        } else if (!decimalNumberRegExp.test(value)) {
          setActivityDataValueErrorMessage(only2DecimalsAllowed);
          setisActivityDataValueError(true);
        } else {
          setActivityDataValueErrorMessage("");
          setisActivityDataValueError(false);
        }
      } else {
        setActivityDataValueErrorMessage("");
        setisActivityDataValueError(false);
      }
    }
  };

  // Emission factor value validations for Replace Extend scenario
  const checkEmissionFactorValue = (value) => {
    if (unitSelected === "Absolute") {
      if (value === "") {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      } else if (value < 0 || value === "-") {
        setEmissionFactorValueErrorMessage(negativeNotallowed);
        setIsEmissionFactorValueError(true);
      } else if (value > 99999999999) {
        setEmissionFactorValueErrorMessage(maxlimitExceeded);
        setIsEmissionFactorValueError(true);
      } else if (!decimalNumberRegExp.test(value)) {
        setEmissionFactorValueErrorMessage(only2DecimalsAllowed);
        setIsEmissionFactorValueError(true);
      } else {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      }
    } else {
      if (value === "") {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      } else if (value < -100 || value > 9999.99) {
        setEmissionFactorValueErrorMessage(relativePercentageError);
        setIsEmissionFactorValueError(true);
      } else {
        setEmissionFactorValueErrorMessage("");
        setIsEmissionFactorValueError(false);
      }
    }
  };

  //Getting selected Column names
  const getselectedCols = (field) => {
    let array = [];
    if (field === "Emission") {
      array.push("scope");
      selectedAllFilters.category_level1.length > 0 &&
        array.push("category_level1");
      selectedAllFilters.category_level2.length > 0 &&
        array.push("category_level2");
      selectedAllFilters.category_level3.length > 0 &&
        array.push("category_level3");
    } else if (field === "Geography") {
      selectedAllFilters.region_level1.length > 0 &&
        array.push("region_level1");
      selectedAllFilters.region_level2.length > 0 &&
        array.push("region_level2");
      selectedAllFilters.region_level3.length > 0 &&
        array.push("region_level3");
    } else if (field === "Organisational") {
      selectedAllFilters.entity_level1.length > 0 &&
        array.push("entity_level1");
      selectedAllFilters.entity_level2.length > 0 &&
        array.push("entity_level2");
      selectedAllFilters.entity_level3.length > 0 &&
        array.push("entity_level3");
      selectedAllFilters.entity_level4.length > 0 &&
        array.push("entity_level4");
    }
    return array;
  };

  //getting unselectedCols
  const getUnselectedCols = (field) => {
    let array = [];
    if (field === "Emission") {
      selectedAllFilters.category_level1.length === 0 &&
        array.push("category_level1");
      selectedAllFilters.category_level2.length === 0 &&
        array.push("category_level2");
      selectedAllFilters.category_level3.length === 0 &&
        array.push("category_level3");
    } else if (field === "Geography") {
      selectedAllFilters.region_level1.length === 0 &&
        array.push("region_level1");
      selectedAllFilters.region_level2.length === 0 &&
        array.push("region_level2");
      selectedAllFilters.region_level3.length === 0 &&
        array.push("region_level3");
    } else if (field === "Organisational") {
      selectedAllFilters.entity_level1.length === 0 &&
        array.push("entity_level1");
      selectedAllFilters.entity_level2.length === 0 &&
        array.push("entity_level2");
      selectedAllFilters.entity_level3.length === 0 &&
        array.push("entity_level3");
      selectedAllFilters.entity_level4.length === 0 &&
        array.push("entity_level4");
    }
    return array;
  };

  let simulateSavePayload = {
    engagementCode: engagement.engagementCode,
    engagementId: engagement.id,
    name: rule,
    calculation_type: unitSelected,
    cumulative_flag: cumlative === "Yes" ? "Y" : "N",
    abatement_value: parseFloat(value),
    data_type: dataType,
    start_year: parseInt(startYear),
    end_year: parseInt(endYear),
    createdby: user.username,
    modifiedby: user.username,
    datatype_unit: selectedRowData?.rowData?.unit,
    project_type:
      project.projectType === 1
        ? "Reduce"
        : project.projectType === 2
        ? "Replace"
        : "Compensate",
    project_id: project.projectId,
    dropDownMetaData: [
      {
        columnType: "Scope",
        columnValue:
          selectedRowData?.rowData?.scope === ""
            ? "All"
            : selectedRowData?.rowData?.scope,
      },
      {
        columnType: "Category_level1",
        columnValue:
          selectedRowData?.rowData?.category_level1 === ""
            ? "All"
            : selectedRowData?.rowData?.category_level1,
      },
      {
        columnType: "Category_level2",
        columnValue:
          selectedRowData?.rowData?.category_level2 === ""
            ? "All"
            : selectedRowData?.rowData?.category_level2,
      },
      {
        columnType: "Category_level3",
        columnValue:
          selectedRowData?.rowData?.category_level3 === ""
            ? "All"
            : selectedRowData?.rowData?.category_level3,
      },
      {
        columnType: "Region_level1",
        columnValue:
          selectedRowData?.rowData?.region_level1 === ""
            ? "All"
            : selectedRowData?.rowData?.region_level1,
      },
      {
        columnType: "Region_level2",
        columnValue:
          selectedRowData?.rowData?.region_level2 === ""
            ? "All"
            : selectedRowData?.rowData?.region_level2,
      },
      {
        columnType: "Region_level3",
        columnValue:
          selectedRowData?.rowData?.region_level3 === ""
            ? "All"
            : selectedRowData?.rowData?.region_level3,
      },
      {
        columnType: "Entity_level1",
        columnValue:
          selectedRowData?.rowData?.entity_level1 === ""
            ? "All"
            : selectedRowData?.rowData?.entity_level1,
      },
      {
        columnType: "Entity_level2",
        columnValue:
          selectedRowData?.rowData?.entity_level2 === ""
            ? "All"
            : selectedRowData?.rowData?.entity_level2,
      },
      {
        columnType: "Entity_level3",
        columnValue:
          selectedRowData?.rowData?.entity_level3 === ""
            ? "All"
            : selectedRowData?.rowData?.entity_level3,
      },
      {
        columnType: "Entity_level4",
        columnValue:
          selectedRowData?.rowData?.entity_level4 === ""
            ? "All"
            : selectedRowData?.rowData?.entity_level4,
      },
      {
        columnType: "Custom_specification1",
        columnValue:
          selectedRowData?.rowData?.custom_request1 === ""
            ? "All"
            : selectedRowData?.rowData?.custom_request1,
      },
      {
        columnType: "Custom_specification2",
        columnValue:
          selectedRowData?.rowData?.custom_request2 === ""
            ? "All"
            : selectedRowData?.rowData?.custom_request2,
      },
      {
        columnType: "Custom_specification3",
        columnValue:
          selectedRowData?.rowData?.custom_request3 === ""
            ? "All"
            : selectedRowData?.rowData?.custom_request3,
      },
    ],
  };

  // On applying Simulate
  const onSimulate = async () => {
    if (isSimulateApplied === true) {
      dispatch(setSimulateFlag({ isSimulate: false }));
      dispatch(setGraphSimulateFlag({ isGraphSimulate: false }));
      setSimulateApplied(false);
      dispatch(setIsAnyRowSelected({ isAnyRowSelected: false }));
      setSimulateBtnDisable(true);
      dispatch(resetManualEditRules({ array: [] }));
      dispatch(setSelectedRowData({ rowData: {} }));
    } else {
      if (isManualEdit) {
        onSave("Simulate");
      } else if (project.projectType === 2) {
        setSimulateloader(true);
        dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
        let simulateSaveReplacePayload = {
          ...simulateSavePayload,
          target_Name: "",
          view_type: "Simulate",
          ruleType: "Simulate",
          activitydata_value: activityDataValue,
          activitydata_Unit: activityDataUnit,
          emissiondata_value: emissionFactorValue,
          emissiondata_unit: emissionFactorUnit,
          changeType: "Edit",
          activitydata_value: "",
          activitydata_Unit: "",
          emissiondata_value: "",
          emissiondata_unit: "",
          targetHierarchy: "",
          transferAD: "N",
          columnNames: filtersPayload.columnNames,
          selectedcolumns: [],
          nonselectedcolumns: [],
        };
        const data = await SaveM3Step2ReplaceData({
          simulateSaveReplacePayload,
        });
        dispatch(setSimulateFlag({ isSimulate: true }));
        dispatch(setSimulateDataOnTable({ simulateTableData: data.tableData }));
        dispatch(setGraphSimulateFlag({ isGraphSimulate: true }));
        dispatch(setSimulateDataOnGraph({ simulateGraphData: data.graphData }));
        // dispatch(setIsAnyRowSelected({ isAnyRowSelected: false }));
        setSimulateApplied(true);
        setSimulateloader(false);
        dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
      } else if (dataType === selectedRowData?.rowData?.data_type) {
        setSimulateloader(true);
        dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
        if (cumlative === "No" && unitSelected === "Absolute") {
          getSimulateDataForAbsolute();
        } else if (cumlative === "No" && unitSelected === "Relative") {
          setSimulateloader(true);
          dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
          let rowData = selectedRowData.rowData;
          var simulateObj = {};
          Object.keys(rowData).forEach((item) => {
            if (
              !isNaN(parseInt(item)) &&
              item >= startYear &&
              item <= endYear
            ) {
              let calculatedValue = (
                Number(rowData[item]) + Number((rowData[item] * value) / 100)
              )?.toFixed(2);
              simulateObj[item] =
                Math.sign(Number(calculatedValue)) === -1
                  ? 0
                  : Number(calculatedValue);
            } else {
              simulateObj[item] = rowData[item];
            }
          });
          let emissionDataRow = {};
          if (selectedRowData?.rowData?.data_type === "Activity data") {
            emissionDataRow =
              reduceDefaultViewData?.tableData[selectedRowData.rowIndex + 2];
          } else if (
            selectedRowData?.rowData?.data_type === "Emission factor"
          ) {
            emissionDataRow =
              reduceDefaultViewData?.tableData[selectedRowData.rowIndex + 1];
          } else if (selectedRowData?.rowData?.data_type === "Emissions") {
            emissionDataRow = rowData;
          }
          var emissionsimulateObj = {};
          emissionDataRow &&
            Object.keys(emissionDataRow).forEach((item) => {
              if (
                !isNaN(parseInt(item)) &&
                item >= startYear &&
                item <= endYear
              ) {
                let calculatedValue = (
                  Number(emissionDataRow[item]) +
                  Number((emissionDataRow[item] * value) / 100)
                )?.toFixed(2);
                emissionsimulateObj[item] =
                  Math.sign(Number(calculatedValue)) === -1
                    ? 0
                    : Number(calculatedValue);
              } else {
                emissionsimulateObj[item] = emissionDataRow[item];
              }
            });
          // ============== Graph Emession Average Update starts here===================
          let numberOfYears = Object.keys(emissionsimulateObj).filter(
            (item) => !isNaN(parseInt(item))
          );
          var total = 0;
          let calculatedSumValueAvg = 0;
          Object.keys(emissionsimulateObj).forEach((item) => {
            if (!isNaN(parseInt(item))) {
              total += Number(emissionsimulateObj[item]);
            }
            calculatedSumValueAvg = (total / numberOfYears.length)?.toFixed(2);
          });

          // ============================
          let graphData = reduceDefaultViewData?.graphData;
          let graphDataArray = [];
          let graphKeyName = "";
          graphData.map((item) => {
            Object.keys(rowData).map((element) => {
              if (item.name.split("[")[0].trimEnd() === rowData[element]) {
                graphKeyName = item.name;
              }
            });
          });
          graphData.map((item) => {
            if (item.name === graphKeyName) {
              graphDataArray.push({
                name: item.name,
                value: Number(calculatedSumValueAvg),
              });
            } else {
              graphDataArray.push({
                name: item.name,
                value: item.value,
              });
            }
          });
          setSimulateDataOnGraphFunc(graphDataArray);
          setSimulateDataOnTableFunc(simulateObj, emissionsimulateObj);
          setSimulateApplied(true);
          // dispatch(setIsAnyRowSelected({ isAnyRowSelected: false }));
          setSimulateloader(false);
          dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
        } else if (cumlative === "Yes" && unitSelected === "Relative") {
          // setSimulateloader(true);
          let rowData = selectedRowData.rowData;
          var simulateObj = {};
          let selectedYearArr = [];
          Object.keys(rowData).forEach((item) => {
            if (
              !isNaN(parseInt(item)) &&
              item >= startYear &&
              item <= endYear
            ) {
              selectedYearArr.push(item);
              let calculatedValue = (
                Number(rowData[item]) +
                Number(
                  Number(
                    (rowData[item] *
                      (Number(value) *
                        (Number(selectedYearArr.indexOf(item)) + Number(1)))) /
                      100
                  )
                )
              )?.toFixed(2);
              simulateObj[item] =
                Math.sign(Number(calculatedValue)) === -1
                  ? 0
                  : Number(calculatedValue);
            } else {
              simulateObj[item] = rowData[item];
            }
          });
          let emissionDataRow = {};
          if (selectedRowData?.rowData?.data_type === "Activity data") {
            emissionDataRow =
              reduceDefaultViewData?.tableData[selectedRowData.rowIndex + 2];
          } else if (
            selectedRowData?.rowData?.data_type === "Emission factor"
          ) {
            emissionDataRow =
              reduceDefaultViewData?.tableData[selectedRowData.rowIndex + 1];
          } else if (selectedRowData?.rowData?.data_type === "Emissions") {
            emissionDataRow = rowData;
          }
          var emissionsimulateObj = {};
          let selectedYearArr2 = [];
          emissionDataRow &&
            Object.keys(emissionDataRow).forEach((item) => {
              if (
                !isNaN(parseInt(item)) &&
                item >= startYear &&
                item <= endYear
              ) {
                selectedYearArr2.push(item);
                let calculatedValue = (
                  Number(emissionDataRow[item]) +
                  Number(
                    Number(
                      (emissionDataRow[item] *
                        (Number(value) *
                          (Number(selectedYearArr2.indexOf(item)) +
                            Number(1)))) /
                        100
                    )
                  )
                )?.toFixed(2);
                emissionsimulateObj[item] =
                  Math.sign(Number(calculatedValue)) === -1
                    ? 0
                    : Number(calculatedValue);
              } else {
                emissionsimulateObj[item] = emissionDataRow[item];
              }
            });
          // ============== Graph Emession Average Update starts here===================
          let numberOfYears = Object.keys(emissionsimulateObj).filter(
            (item) => !isNaN(parseInt(item))
          );
          var total = 0;
          let calculatedSumValueAvg = 0;
          Object.keys(emissionsimulateObj).forEach((item) => {
            if (!isNaN(parseInt(item))) {
              total += Number(emissionsimulateObj[item]);
            }
            calculatedSumValueAvg = (total / numberOfYears.length).toFixed(2);
          });
          // ============================
          let graphData = reduceDefaultViewData?.graphData;
          let graphDataArray = [];
          let graphKeyName = "";
          graphData.map((item) => {
            Object.keys(rowData).map((element) => {
              if (item.name.split("[")[0].trimEnd() === rowData[element]) {
                graphKeyName = item.name;
              }
            });
          });
          graphData.map((item) => {
            if (item.name === graphKeyName) {
              graphDataArray.push({
                name: item.name,
                value: Number(calculatedSumValueAvg),
              });
            } else {
              graphDataArray.push({
                name: item.name,
                value: item.value,
              });
            }
          });
          setSimulateDataOnGraphFunc(graphDataArray);
          setSimulateDataOnTableFunc(simulateObj, emissionsimulateObj);
          setSimulateApplied(true);
          // dispatch(setIsAnyRowSelected({ isAnyRowSelected: false }));
          setSimulateloader(false);
          dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
        } else if (cumlative === "Yes" && unitSelected === "Absolute") {
          getSimulateDataForAbsolute();
        }
        // setSimulateloader(false);
      }
    }
  };

  //Set Simulate Data on Table
  const setSimulateDataOnTableFunc = (data, emissionData) => {
    let dataRows = reduceDefaultViewData?.tableData;
    let simulateDataRows = [];
    dataRows.map((item) => {
      simulateDataRows.push(item);
    });
    simulateDataRows[selectedRowData.rowIndex] = data;
    if (selectedRowData?.rowData?.data_type === "Activity data") {
      simulateDataRows[selectedRowData.rowIndex + 2] = emissionData;
    } else if (selectedRowData?.rowData?.data_type === "Emission factor") {
      simulateDataRows[selectedRowData.rowIndex + 1] = emissionData;
    }
    dispatch(setSimulateFlag({ isSimulate: true }));
    dispatch(setSimulateDataOnTable({ simulateTableData: simulateDataRows }));
  };

  //Set Simulate Data on Graph
  const setSimulateDataOnGraphFunc = (data) => {
    dispatch(setGraphSimulateFlag({ isGraphSimulate: true }));
    dispatch(setSimulateDataOnGraph({ simulateGraphData: data }));
  };

  // Get Simulate data for table and graph in case of Absolute Unit
  const getSimulateDataForAbsolute = async () => {
    setSimulateloader(true);
    dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
    let request = filtersPayload;
    simulateSavePayload["view_type"] = "Simulate";
    simulateSavePayload["columnNames"] = request.columnNames;
    const data = await getM3TableGraphData({ simulateSavePayload });
    dispatch(setSimulateFlag({ isSimulate: true }));
    dispatch(setSimulateDataOnTable({ simulateTableData: data.tableData }));
    dispatch(setGraphSimulateFlag({ isGraphSimulate: true }));
    dispatch(setSimulateDataOnGraph({ simulateGraphData: data.graphData }));
    // dispatch(setIsAnyRowSelected({ isAnyRowSelected: false }));
    setSimulateApplied(true);
    setSimulateloader(false);
    dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
  };

  const onSave = async (type) => {
    if (isManualEdit) {
      let unique = [...new Set(manualEditRules.map((x) => x.start_year))];
      let array = [];
      unique.forEach((year) => {
        let x = false;
        for (let i = manualEditRules.length - 1; i >= 0; i--) {
          if (manualEditRules[i]["start_year"] === year && !x) {
            array.push(manualEditRules[i]);
            x = true;
          }
        }
      });
      try {
        type === "Simulate" ? setSimulateloader(true) : setSaveLoader(true);
        dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
        const payload = {
          engagementCode: engagement.engagementCode,
          engagementId: engagement.id,
          createdby: user.username,
          modifiedby: user.username,
          project_type:
            project.projectType === 1
              ? "Reduce"
              : project.projectType === 2
              ? "Replace"
              : "Compensate",
          project_id: project.projectId,
          view_type: "M3-SaveRule",
          data_type: selectedRowData.rowData.data_type,
          datatype_unit: selectedRowData?.rowData?.unit,
          calculation_type:
            selectedRowData.rowData.data_type === "Emission factor"
              ? "Absolute"
              : "Relative",
          cumulative_flag: "N",
          name: rule,
          rule_type: type,
          manualEditYearValue: array,
          columnNames: filtersPayload.columnNames,
          dropDownMetaData: [
            {
              columnType: "Scope",
              columnValue:
                selectedRowData.rowData.scope === ""
                  ? "All"
                  : selectedRowData.rowData.scope,
            },
            {
              columnType: "Category_level1",
              columnValue:
                selectedRowData.rowData.category_level1 === ""
                  ? "All"
                  : selectedRowData.rowData.category_level1,
            },
            {
              columnType: "Category_level2",
              columnValue:
                selectedRowData.rowData.category_level2 === ""
                  ? "All"
                  : selectedRowData.rowData.category_level2,
            },
            {
              columnType: "Category_level3",
              columnValue:
                selectedRowData.rowData.category_level3 === ""
                  ? "All"
                  : selectedRowData.rowData.category_level3,
            },
            {
              columnType: "Region_level1",
              columnValue:
                selectedRowData.rowData.region_level1 === ""
                  ? "All"
                  : selectedRowData.rowData.region_level1,
            },
            {
              columnType: "Region_level2",
              columnValue:
                selectedRowData.rowData.region_level2 === ""
                  ? "All"
                  : selectedRowData.rowData.region_level2,
            },
            {
              columnType: "Region_level3",
              columnValue:
                selectedRowData.rowData.region_level3 === ""
                  ? "All"
                  : selectedRowData.rowData.region_level3,
            },
            {
              columnType: "Entity_level1",
              columnValue:
                selectedRowData.rowData.entity_level1 === ""
                  ? "All"
                  : selectedRowData.rowData.entity_level1,
            },
            {
              columnType: "Entity_level2",
              columnValue:
                selectedRowData.rowData.entity_level2 === ""
                  ? "All"
                  : selectedRowData.rowData.entity_level2,
            },
            {
              columnType: "Entity_level3",
              columnValue:
                selectedRowData.rowData.entity_level3 === ""
                  ? "All"
                  : selectedRowData.rowData.entity_level3,
            },
            {
              columnType: "Entity_level4",
              columnValue:
                selectedRowData.rowData.entity_level4 === ""
                  ? "All"
                  : selectedRowData.rowData.entity_level4,
            },
            {
              columnType: "Custom_specification1",
              columnValue:
                selectedRowData.rowData.custom_request1 === ""
                  ? "All"
                  : selectedRowData.rowData.custom_request1,
            },
            {
              columnType: "Custom_specification2",
              columnValue:
                selectedRowData.rowData.custom_request2 === ""
                  ? "All"
                  : selectedRowData.rowData.custom_request2,
            },
            {
              columnType: "Custom_specification3",
              columnValue:
                selectedRowData.rowData.custom_request3 === ""
                  ? "All"
                  : selectedRowData.rowData.custom_request3,
            },
          ],
        };
        const data = await manualEditM3Step2({ payload });
        if (type === "Simulate") {
          setSimulateApplied(true);
          dispatch(setSimulateFlag({ isSimulate: true }));
          dispatch(
            setSimulateDataOnTable({ simulateTableData: data.tableData })
          );
          dispatch(setGraphSimulateFlag({ isGraphSimulate: true }));
          dispatch(
            setSimulateDataOnGraph({ simulateGraphData: data.graphData })
          );
          setSimulateloader(false);
          setSimulateBtnDisable(true);
          // dispatch(setIsAnyRowSelected({ isAnyRowSelected: false }));
        } else {
          setSimulateApplied(false);
          dispatch(setSimulateFlag({ isSimulate: false }));
          dispatch(setGraphSimulateFlag({ isGraphSimulate: false }));
          dispatch(setReduceDefautViewData({ defaultViewData: data }));
          setSimulateBtnDisable(true);
          dispatch(setIsAnyRowSelected({ isAnyRowSelected: false }));
          dispatch(resetManualEditRules({ array: [] }));
        }
        setSaveLoader(false);
        dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
      } catch {
        setSaveLoader(false);
        dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
        setSimulateloader(false);
        setSimulateApplied(false);
        setSimulateBtnDisable(true);
      }
    } else if (togglechecked) {
      setSaveLoader(true);
      let request = filtersPayload;
      let selectedCols = await getselectedCols(trgtHrchy);
      let unSelectedCols = await getUnselectedCols(trgtHrchy);
      simulateSavePayload.activitydata_value = activityDataValue;
      simulateSavePayload.activitydata_Unit =
        unitSelected === "Relative"
          ? selectedRowData?.rowData?.unit
          : activityDataUnit;
      simulateSavePayload.emissiondata_value = emissionFactorValue;
      simulateSavePayload.emissiondata_unit =
        unitSelected === "Relative"
          ? "kgCo2/" + selectedRowData?.rowData?.unit
          : activityDataUnit;
      simulateSavePayload.transferAD = trnsfrAD === "Yes" ? "Y" : "N";
      simulateSavePayload.changeType = "Extend";
      simulateSavePayload.columnNames = request.columnNames;
      simulateSavePayload.selectedcolumns = selectedCols;
      simulateSavePayload.nonselectedcolumns = unSelectedCols;
      simulateSavePayload.cumulative_flag =
        extendCumulative === "Yes" ? "Y" : "N";
      simulateSavePayload.data_type = "";
      simulateSavePayload.targetHierarchy = trgtHrchy;
      simulateSavePayload.view_type = "M3-SaveRule";
      simulateSavePayload.ruleType = "Save";
      simulateSavePayload.target_Name = replaceName;
      simulateSavePayload.abatement_value = 0;
      let simulateSaveReplacePayload = { ...simulateSavePayload };
      const data = await SaveM3Step2ReplaceData({ simulateSaveReplacePayload });
      dispatch(setReduceDefautViewData({ defaultViewData: data }));
      setSimulateBtnDisable(true);
      settoggleChecked(true);
      setToggleDisabled(true);
      setSaveLoader(false);
    } else if (project.projectType === 2) {
      setSaveLoader(true);
      dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
      let simulateSaveReplacePayload = {
        ...simulateSavePayload,
        target_Name: "",
        view_type: "M3-SaveRule",
        activitydata_value: activityDataValue,
        activitydata_Unit: activityDataUnit,
        emissiondata_value: emissionFactorValue,
        emissiondata_unit: emissionFactorUnit,
        changeType: "Edit",
        ruleType: "Save",
        activitydata_value: "",
        activitydata_Unit: "",
        emissiondata_value: "",
        emissiondata_unit: "",
        targetHierarchy: "",
        transferAD: "N",
        columnNames: filtersPayload.columnNames,
        selectedcolumns: [],
        nonselectedcolumns: [],
      };
      const data = await SaveM3Step2ReplaceData({
        simulateSaveReplacePayload,
      });
      dispatch(setReduceDefautViewData({ defaultViewData: data }));
      dispatch(setIsAnyRowSelected({ isAnyRowSelected: false }));
      setSaveLoader(false);
      dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
      setSimulateApplied(false);
      setSimulateBtnDisable(true);
    } else {
      try {
        setSaveLoader(true);
        dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
        setSimulateloader(false);
        let request = filtersPayload;
        simulateSavePayload["view_type"] = "M3-SaveRule";
        simulateSavePayload["columnNames"] = request.columnNames;
        const data = await getM3TableGraphData({ simulateSavePayload });
        dispatch(setReduceDefautViewData({ defaultViewData: data }));
        dispatch(setIsAnyRowSelected({ isAnyRowSelected: false }));
        dispatch(setSelectedRowData({ rowData: {} }));
        setSaveLoader(false);
        dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
        setSimulateloader(false);
        setSimulateApplied(false);
        setSimulateBtnDisable(true);
      } catch (error) {
        setSaveLoader(false);
        dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
        setSimulateloader(false);
        setSimulateApplied(false);
        setSimulateBtnDisable(true);
        dispatch(setSelectedRowData({ rowData: {} }));
      }
    }
  };

  const onReplaceToggle = () => {
    settoggleChecked(!togglechecked);
  };

  const resetSimulatefields = () => {
    setRuleName("");
    setReplaceName("");
    setDataType("");
    setCumulative("");
    setUnit("");
    setValue("");
    setStartYear("");
    setEndYear("");
    setSimulateApplied(false);
    setSimulateBtnDisable(true);
    setTrgtHrchy("");
    setTransferAD("");
    setActivityDataUnit("");
    setActivityDataValue("");
    setEmissionFactorUnit("");
    setEmissionFactorValue("");
  };

  // to get a locked user flags
  useEffect(() => {
    if (project.projectId) {
      const request = {
        LockedBy: user.username,
        ModuleName: "Project",
        EngagementID: project.projectId,
      };

      dispatch(getLockingData(request)).then((action) => {
        if (action) {
          const lockedData = action.payload?.data?.lockStatus;
          const lockedBy = action.payload?.data?.lockedBy;
          setLock(lockedData);
          setLockedby(lockedBy);
        }
      });
    }
  }, [dispatch, project.projectId]);

  return (
    <div className="emissions-simulate-section">
      <div className="emissions-simulate-title">
        <span>
          {project?.projectType === 1
            ? batchEditreduce
            : project?.projectType === 2
            ? batchEditReplace
            : batchEditCompensate}
        </span>
        {project.projectType === 2 && (
          <div>
            <Tooltip
              trigger={
                <ToggleSwitch
                  offLabel={"Edit"}
                  onLabel={"Extend"}
                  onToggle={onReplaceToggle}
                  toggleChecked={togglechecked}
                  id={"Simulate"}
                  disabled={toggledisabled}
                />
              }
              variant="ghost"
              tooltipText={emissionsToggleTooltip}
            ></Tooltip>
          </div>
        )}
      </div>
      <div className="emissions-simulate-content">
        <div className="emissions-simultae-content-row">
          <div className="emissions-simulate-content-row-dropdown">
            <span>
              {ruleName}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="ghost"
                tooltipText={ruleNameTooltip}
              ></Tooltip>
            </span>
            <Input
              type="text"
              onChange={(event) => {
                handleOnChange(event?.target?.value, "ruleName");
              }}
              value={rule}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
              maxLength={50}
            ></Input>
          </div>
        </div>
        {!togglechecked && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {addForecastSpecificationDataType}{" "}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={
                    project.projectType === 1
                      ? reduceDataTypetooltip
                      : project.projectType === 2
                      ? replaceDataTypeTooltip
                      : compensateDataTypeTooltip
                  }
                ></Tooltip>
              </span>
              <Select
                options={
                  project.projectType === 3
                    ? emissionArray
                    : addForecastSpecificationDataTypeOptions
                }
                value={dataType}
                onChange={(event) => {
                  handleOnChange(event, "dataType");
                }}
                disabled={
                  isManualEdit ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId)
                }
              ></Select>
            </div>
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {cumulative}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={cumulativeTooltip}
                ></Tooltip>
              </span>
              <Select
                options={yesNo}
                value={cumlative}
                onChange={(event) => {
                  handleOnChange(event, "cumulative");
                }}
                disabled={
                  isManualEdit ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId)
                }
              ></Select>
            </div>
          </div>
        )}
        {togglechecked && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {targetHierarchy}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={targetHierarchyTooltip}
                ></Tooltip>
              </span>
              <Select
                options={targetHierarchyValues}
                onChange={(value) => {
                  handleOnChange(value, "targetHierarchy");
                }}
                value={trgtHrchy}
                disabled={isManualEdit}
              />
            </div>
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {name}
                <span className="required-star">*</span>
              </span>
              <Input
                type="text"
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "name");
                }}
                value={replaceName}
                disabled={isManualEdit}
              />
            </div>
          </div>
        )}

        {
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {unit}
                <span className="tooltip">
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={unitTooltip}
                  ></Tooltip>
                </span>
                <span className="required-star">*</span>
              </span>
              <Select
                options={unitDropdown}
                value={unitSelected}
                onChange={(event) => {
                  handleOnChange(event, "unit");
                }}
                disabled={
                  isManualEdit ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId)
                }
              ></Select>
            </div>
            {!togglechecked && (
              <div className="emissions-simulate-content-row-dropdown">
                <span>
                  {simulateValue}
                  <span className="required-star">*</span>
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={emissionsValuetooltip}
                  ></Tooltip>
                </span>
                <Input
                  errorMessage={valueErrorMessage}
                  isError={valueError}
                  disableErrorPlaceholder={true}
                  onChange={(event) => {
                    handleOnChange(event?.target?.value, "value");
                  }}
                  maxLength={value < 0 ? 12 : 11}
                  disabled={
                    unitSelected === "" ||
                    (dataType === "" && !togglechecked) ||
                    isManualEdit ||
                    (lockedData === 1 &&
                      lockedBy !== user.username &&
                      project.projectId)
                  }
                  value={value}
                  type={"decimal"}
                />
              </div>
            )}
            {togglechecked && (
              <div className="emissions-simulate-content-row-dropdown">
                <span>
                  {cumulative}
                  <span className="required-star">*</span>
                  <Tooltip
                    trigger={<EmissionIcon />}
                    variant="ghost"
                    tooltipText={cumulativeTooltip}
                  ></Tooltip>
                </span>
                <Select
                  options={yesNo}
                  value={extendCumulative}
                  onChange={(event) => {
                    handleOnChange(event, "extendcumulative");
                  }}
                  disabled={
                    isManualEdit ||
                    (lockedData === 1 &&
                      lockedBy !== user.username &&
                      project.projectId)
                  }
                ></Select>
              </div>
            )}
          </div>
        }
        {togglechecked && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {actDataValue}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={activityDataValueTooltip}
                ></Tooltip>
              </span>
              <Input
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "activityDataValue");
                }}
                value={activityDataValue}
                disabled={isManualEdit}
                errorMessage={activityDataValueErrorMessage}
                isError={isActivityDataValueError}
                type="decimal"
              />
            </div>
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {emisFactValue}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={emissionFactorTooltip}
                ></Tooltip>
              </span>
              <Input
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "emissionFactorValue");
                }}
                value={emissionFactorValue}
                disabled={isManualEdit}
                isError={isEmissionFactorValueError}
                errorMessage={emissionFactorValueErrorMessage}
                type="decimal"
              />
            </div>
          </div>
        )}
        {togglechecked && unitSelected === "Absolute" && (
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {actDataUnit}
                <span className="required-star">*</span>
              </span>
              <Input
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "activityDataUnit");
                }}
                value={activityDataUnit}
                disabled={isManualEdit}
                maxLength={30}
                type={"text"}
              />
            </div>
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {emisFactorUnit}
                <span className="required-star">*</span>
              </span>
              <Input
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "emissionFactorUnit");
                }}
                value={emissionFactorUnit}
                disabled={true}
                type={"text"}
              />
            </div>
          </div>
        )}
        {
          <div className="emissions-simultae-content-row">
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {strtYr}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={emissionsStartyearTooltip}
                ></Tooltip>
              </span>
              <Input
                errorMessage={startYearErrorMessage}
                isError={showstartYearError}
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "startYear");
                }}
                value={startYear}
                disabled={
                  isManualEdit ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId)
                }
                type="number"
              />
            </div>
            <div className="emissions-simulate-content-row-dropdown">
              <span>
                {endYr}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={emissionsEndYeartooltip}
                ></Tooltip>
              </span>
              <Input
                errorMessage={endYearErrorMessage}
                isError={showendYearError}
                disableErrorPlaceholder={true}
                onChange={(event) => {
                  handleOnChange(event?.target?.value, "endYear");
                }}
                value={endYear}
                disabled={
                  isManualEdit ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId)
                }
                type="number"
              />
            </div>
          </div>
        }
        {togglechecked && (
          <div className="emissions-simultae-content-row">
            <div className=" custom-class">
              <span>
                {transferAd}
                <span className="required-star">*</span>
                <Tooltip
                  trigger={<EmissionIcon />}
                  variant="ghost"
                  tooltipText={transferADTooltip}
                ></Tooltip>
              </span>
              <Select
                options={yesNo}
                onChange={(event) => {
                  handleOnChange(event, "transferAD");
                }}
                value={trnsfrAD}
                disabled={isManualEdit}
              />
            </div>
          </div>
        )}
      </div>
      <div className="emissions-simulate-footer">
        {!togglechecked && (
          <LoadingIndicatorEmbed show={simulateLoader}>
            <Button
              variant="primary-alt"
              onClick={onSimulate}
              disabled={
                // dataType === selectedRowData?.rowData?.data_type ? false : true
                isSimulateBtnDisable ||
                (lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId)
              }
            >
              <SimulateIcon /> {isSimulateApplied === true ? "Reset" : simulate}
            </Button>
          </LoadingIndicatorEmbed>
        )}
        <LoadingIndicatorEmbed show={saveLoader}>
          <Button
            variant="primary-alt"
            onClick={() => onSave("Save")}
            disabled={
              isSimulateBtnDisable ||
              (lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId)
            }
          >
            <SaveIcon />
            {SaveBtn}
          </Button>
        </LoadingIndicatorEmbed>
      </div>
    </div>
  );
}
