import {
  CancelBtn,
  YesBtn,
  forcastResetModalBody,
  forcastResetModalBodyDesc,
  forcastResetModalHeader,
} from "../../../../util/constants";
import { Button } from "../../../Button/Button";
import { Modal } from "../../../Modal/Modal";
import "./ForcastResetModal.scss";

function ModalBody() {
  return (
    <>
      <span className="view-endagement-delete-modal-body">
        {forcastResetModalBody}
      </span>
      <br></br>
      <div className="view-endagement-delete-modal-body-descr">
        {forcastResetModalBodyDesc}
      </div>
    </>
  );
}

function ModalFooter({ handleCancelClick, onConfirm }) {
  return (
    <div className="view-endagement-delete-modal-footer">
      <Button variant="secondary" onClick={handleCancelClick}>
        {CancelBtn}
      </Button>
      <Button onClick={onConfirm} variant="primary">
        {YesBtn}
      </Button>
    </div>
  );
}

export function ForcastResetModal({ isModalVisible, onClose, onConfirm }) {
  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={forcastResetModalHeader}
      body={<ModalBody />}
      footer={<ModalFooter handleCancelClick={onClose} onConfirm={onConfirm} />}
    />
  );
}
