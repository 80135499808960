import { Project_URL } from "./endpoints/project";
import { get, post } from "./base.service";
import axios from "axios";

async function GetProjectDistinctTags({ request }) {
  return await get(
    `${Project_URL.GetDistinctTags}?EngagementID=${request.engagementId}`
  );
}

async function getDistinctProjectsNames({ request }) {
  return await get(
    `${Project_URL.GetDistinctProjects}?EngagementID=${request.engagementId}`
  );
}

async function createProjectDraft({ request }) {
  return await post(`${Project_URL.CreateProjectDraft}`, request);
}

async function createM3Project({ dataSet }) {
  return await post(
    `${Project_URL.createM3Project}?engagementCode=${dataSet.engagementCode}&projectID=${dataSet.projectID}&createdBy=${dataSet.createdBy}`
  );
}

async function saveProjectTags({ payload }) {
  return await post(`${Project_URL.SaveProjectTags}`, payload);
}

async function getReduceDefautViewData({ request }) {
  return await post(`${Project_URL.reduceDefaultViewData}`, request);
}

async function saveEmissionFiltersReduce({ payload }) {
  return await post(`${Project_URL.SaveEmissionFilters}`, payload);
}

async function getSaveProjectRule({ request }) {
  return await get(
    `${Project_URL.GetSaveProjectRule}?ProjectID=${request.projectID}`
  );
}

async function saveM3Step2ReduceData({ payload }) {
  return await post(`${Project_URL.SaveM3Step2ReduceData}`, payload);
}

async function getM3TableGraphData({ simulateSavePayload }) {
  return await post(`${Project_URL.getM3TableGraphData}`, simulateSavePayload);
}

async function getProjectDetailsById({ request }) {
  return await get(
    `${Project_URL.ProjectEditDetails}?projectID=${request.projectID}`
  );
}

async function getProjectEmissionFilters({ request }) {
  return await get(
    `${Project_URL.ProjectEmissionSelection}?projectID=${request.projectID}`
  );
}

async function updateProjectStep({ request }) {
  return await post(
    `${Project_URL.UpdateProjectStep}?ProjectID=${request.projectId}&StepID=${request.stepId}`
  );
}

async function getM3GraphData({ request }) {
  return await get(
    `${Project_URL.GetM3GraphData}?engagementCode=${request.engagementCode}&projectId=${request.projectId}`
  );
}

async function recreateProjectM3({ request }) {
  return await post(`${Project_URL.RecreateProject}`, request);
}

async function getM4GetProjectsScenarios({ request }) {
  return await get(
    `${Project_URL.GetProjectsScenario}?EngagementID=${request.engagementId}&createdBy=${request.createdBy}`
  );
}

async function getM4NavBarProjects({ request }) {
  return await get(
    `${Project_URL.GetNavBarProjects}?EngagementID=${request.engagementId}&createdBy=${request.createdBy}&ScenarioID=${request.scenarioId}`
  );
}

async function resetProjectM3({ request }) {
  return await post(
    `${Project_URL.ResetProject}?EngagementCode=${request.engagementCode}&ProjectID=${request.projectID}`
  );
}

async function getUserModuleLock({ request }) {
  let queryParams = `LockedBy=${encodeURIComponent(
    request.LockedBy
  )}&ModuleName=${encodeURIComponent(request.ModuleName)}`;

  if (request.EngagementID) {
    queryParams += `&EngagementID=${encodeURIComponent(request.EngagementID)}`;
  }

  return await get(`${Project_URL.GetUserModuleLock}?${queryParams}`);
}

async function manualEditM3Step2({ payload }) {
  return await post(`${Project_URL.ManualEdit}`, payload);
}

async function SaveUserModuleLock(request) {
  return await post(`${Project_URL.SaveUserModuleLock}`, request);
}

async function UpdateUserModuleLock(request) {
  return await post(`${Project_URL.UpdateUserModuleLock}`, request);
}

async function ReleaseUserModuleLock(request) {
  return await post(`${Project_URL.ReleaseUserModuleLock}`, request);
}

async function getColumnValueFlags({ flagsPayload }) {
  return await post(`${Project_URL.ColumnFlags}`, flagsPayload);
}

async function GetProjectdetails({ request }) {
  return await get(
    `${Project_URL.GetProjectdetails}?projectID=${request.projectID}`
  );
}

async function GetProjectBusinessCaseSummaryData({ request }) {
  return await get(
    `${Project_URL.GetProjectBusinessCaseSummaryData}?ProjectID=${request.projectId}`
  );
}

async function GetProjectFinancialsAbatementData({ request }) {
  return await post(
    `${Project_URL.GetProjectFinancialsAbatementData}`,
    request
  );
}

async function GetProjectCashflowData({ request }) {
  return await get(
    `${Project_URL.GetProjectCashFlowData}?EngagementCode=${request.engagementCode}&ProjectID=${request.projectId}`
  );
}

async function getUserLockByModule({ request }) {
  return await axios
    .get(
      `${Project_URL.GetUserLockByModule}?LockedBy=${request.LockedBy}&EngagementID=${request.EngagementID}&ModuleName=${request.ModuleName}`
    )
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error("Error in response - " + res);
    })
    .catch((err) => {
      throw err;
    });
}

async function SaveM3Step2ReplaceData({ simulateSaveReplacePayload }) {
  return await post(
    `${Project_URL.SaveM3StepData}`,
    simulateSaveReplacePayload
  );
}

async function SetProjectStatustoFinalize({ request }) {
  return await post(
    `${Project_URL.SetProjectstatustoFinalise}?projectId=${request.projectId}`
  );
}

async function copyFromPastVersionsProject({ request }) {
  return await post(
    `${Project_URL.CopyFromPreviousVersion}?EngagementCode=${request.engagementCode}`
  );
}

export {
  GetProjectDistinctTags,
  getDistinctProjectsNames,
  createProjectDraft,
  createM3Project,
  saveProjectTags,
  getReduceDefautViewData,
  saveEmissionFiltersReduce,
  getSaveProjectRule,
  saveM3Step2ReduceData,
  getM3TableGraphData,
  getProjectDetailsById,
  getProjectEmissionFilters,
  updateProjectStep,
  getM3GraphData,
  recreateProjectM3,
  getM4GetProjectsScenarios,
  getM4NavBarProjects,
  resetProjectM3,
  getUserModuleLock,
  SaveUserModuleLock,
  ReleaseUserModuleLock,
  UpdateUserModuleLock,
  manualEditM3Step2,
  getColumnValueFlags,
  GetProjectdetails,
  getUserLockByModule,
  GetProjectBusinessCaseSummaryData,
  GetProjectFinancialsAbatementData,
  GetProjectCashflowData,
  SaveM3Step2ReplaceData,
  SetProjectStatustoFinalize,
  copyFromPastVersionsProject,
};
