import React from "react";
import { Modal } from "../../Modal/Modal";
import "../Emissions.scss";
import { Button } from "../../Button/Button";
import {
  CancelBtn,
  ConfirmBtn,
  resetFiltersBody,
  resetFiltersHeader,
} from "../../../util/constants";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";
function ModalFooter({ onApply, onReset, load }) {
  return (
    <div className="emissions-reset-filters-footer">
      <Button onClick={() => onApply()} variant="secondary">
        {CancelBtn}
      </Button>
      <LoadingIndicatorEmbed show={load}>
        <Button onClick={() => onReset()} variant="primary">
          {ConfirmBtn}
        </Button>
      </LoadingIndicatorEmbed>
    </div>
  );
}
export function ResetModal({ isModalOpen, onClose, onConfirm, loader }) {
  return (
    <Modal
      show={isModalOpen}
      onClose={onClose}
      header={resetFiltersHeader}
      body={resetFiltersBody}
      footer={
        <ModalFooter
          onApply={onClose}
          onReset={onConfirm}
          load={loader}
        ></ModalFooter>
      }
    ></Modal>
  );
}
