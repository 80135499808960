import { routes } from "./routes.constatns";
import { Navigate, Route } from "react-router-dom";
import App from "../App";
import {
  CreateNewEngagement,
  Home,
  PowerBI,
  ViewEngagement,
  CreateNewProject,
  AddGMP,
  AddForecast,
  ImportExcel,
  AddTarget,
  AddTargetSpecification,
  AddForecastSpecification,
} from "../components";
import { CreateNewScenario } from "../components/Simulate/components/createNewScenario/CreateNewScenario";
import { ProjectsLibrary } from "../components/ProjectsLibrary/ProjectsLibrary";
import { ProjectDetails } from "../components/ProjectsLibrary/components/ProjectDetails/ProjectDetails";

export const AppRoutes = (
  <Route path={routes.root} element={<App />}>
    <Route index element={<Navigate to={`/${routes.home}`} />} />
    <Route path={routes.home} element={<Home />} />
    <Route path={routes.createNewEngagement}>
      <Route index element={<CreateNewEngagement />} />
      <Route path={routes.createNewProject} element={<CreateNewProject />} />
      <Route path={routes.addGMP} element={<AddGMP />} />
      <Route path={routes.importExcel} element={<ImportExcel />} />
      <Route path={routes.addTarget}>
        <Route index element={<AddTarget />} />
        <Route
          path={routes.addTargetSpecification}
          element={<AddTargetSpecification />}
        />
      </Route>
      <Route path={routes.addforecast}>
        <Route index element={<AddForecast />} />
        <Route
          path={routes.addForecastSpecification}
          element={<AddForecastSpecification />}
        />
      </Route>
      <Route path={routes.createNewScenario} element={<CreateNewScenario />} />
    </Route>
    <Route path={routes.viewEngagement} element={<ViewEngagement />} />
    <Route path={routes.powerBI} element={<PowerBI />} />
    <Route path={routes.decarbProjectsLibrary}>
      <Route index element={<ProjectsLibrary />} />
      <Route
        path={routes.decarbProjectsLibraryDetails}
        element={<ProjectDetails />}
      />
    </Route>
  </Route>
);

export default AppRoutes;
