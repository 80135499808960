export function suppressEnter(params){
  var KEY_ENTER = "Enter";

  var event = params.event;
  var key = event.key;
  var suppress = key === KEY_ENTER;

  return suppress;
}

export function suppressNavigation(params){
  var KEY_A = "A";
  var KEY_C = "C";
  var KEY_V = "V";
  var KEY_D = "D";

  var KEY_PAGE_UP = "PageUp";
  var KEY_PAGE_DOWN = "PageDown";
  var KEY_TAB = "Tab";
  var KEY_LEFT = "ArrowLeft";
  var KEY_UP = "ArrowUp";
  var KEY_RIGHT = "ArrowRight";
  var KEY_DOWN = "ArrowDown";
  var KEY_F2 = "F2";
  var KEY_BACKSPACE = "Backspace";
  var KEY_ESCAPE = "Escape";
  var KEY_SPACE = " ";
  var KEY_DELETE = "Delete";
  var KEY_PAGE_HOME = "Home";
  var KEY_PAGE_END = "End";

  var event = params.event;
  var key = event.key;

  var keysToSuppress = [
    KEY_PAGE_UP,
    KEY_PAGE_DOWN,
    KEY_TAB,
    KEY_F2,
    KEY_ESCAPE,
  ];

  var editingKeys = [
    KEY_LEFT,
    KEY_RIGHT,
    KEY_UP,
    KEY_DOWN,
    KEY_BACKSPACE,
    KEY_DELETE,
    KEY_SPACE,
    KEY_PAGE_HOME,
    KEY_PAGE_END,
  ];

  if (event.ctrlKey || event.metaKey) {
    keysToSuppress.push(KEY_A);
    keysToSuppress.push(KEY_V);
    keysToSuppress.push(KEY_C);
    keysToSuppress.push(KEY_D);
  }

  if (!params.editing) {
    keysToSuppress = keysToSuppress.concat(editingKeys);
  }

  if (
    params.column.getId() === "country" &&
    (key === KEY_UP || key === KEY_DOWN)
  ) {
    return false;
  }

  var suppress = keysToSuppress.some(function (suppressedKey) {
    return suppressedKey === key || key.toUpperCase() === suppressedKey;
  });

  return suppress;
}