import { MotifTable } from "@ey-xd/motif-react";
import "./Table.scss";
import { useEffect, useState } from "react";

export function Table({
  rowData,
  colDefs,
  pagesize = 10,
  rowClassRules,
  selectionChanged,
  forceUpdate = crypto.randomUUID(),
  rowDragged,
  rowSelect,
  draggable,
  loading,
  pagination = true,
  onGridReady = () => {},
}) {
  const [gridApi, setGridApi] = useState();

  const gridOptions = {
    overlayNoRowsTemplate:
      "<div class='decarb-table-no-rows'>No records found</div>",
  };

  useEffect(() => {
    if (gridApi && rowData.length > 0) {
      gridApi.setGridOption("rowData", rowData);
      gridApi.redrawRows();
    }
  }, [rowData, gridApi, forceUpdate]);

  function handleGridReady(params) {
    setGridApi(params.api);
    onGridReady(params);
  }

  return (
    <MotifTable
      suppressRowTransform={true}
      suppressRowHoverHighlight={true}
      suppressCellFocus
      suppressRowClickSelection
      rowDragEntireRow={draggable}
      suppressDragLeaveHidesColumns={true}
      rowDragManaged={true}
      suppressMoveWhenRowDragging={true}
      rowData={rowData}
      columnDefs={colDefs}
      pagination={pagination}
      onGridReady={handleGridReady}
      paginationPageSize={pagesize}
      paginationPageSizeSelector={rowData.length > 5 ? [5, 10, 20] : [10]}
      onRowSelected={selectionChanged}
      onRowDragEnd={rowDragged}
      rowHeight={72}
      rowSelection={rowSelect}
      editType={"fullRow"}
      headerHeight={72}
      gridOptions={gridOptions}
      rowClassRules={rowClassRules}
      domLayout="autoHeight"
      loading={loading ? loading : false}
      overlayLoadingTemplate={`<div data-testid="table-loading" class="motif-progress-loader decarb-loading-indicator  decarb-loading-indicator-transparent decarb-table-no-rows" data-motif-progress-loader="true"><div class="motif-progress-loader-type"><div class="motif-progress-loader-content"><div class="motif-progress-loader-square"></div><div class="motif-progress-loader-square"></div><div class="motif-progress-loader-square"></div></div></div></div>`}
      firstColumnBorderRight
      tooltipShowDelay={0}
      tooltipHideDelay={3000}
    />
  );
}
