export const GMP_URL = {
  GetParametersList: `GMP/GetParametersList`,
  GetRulesList: `GMP/GetRulesList`,
  SaveDecarbGmpRuleMap: `GMP/SaveDecarbGmpRuleMap`,
  GetGMPRulesMapData: `GMP/GetGMPRulesMapData`,
  DeleteGmpRule: `GMP/DeleteGmpRule`,
  GetGMPDataURL: `GMP/GetAllGMPDetails`,
  DeleteGMPDetail: `GMP/DeleteGMPParameterDetail`,
  SaveGMPData: `GMP/SaveGMPData`,
  UpdateGMPDataManully: `GMP/UpdateGMPDataManully`,
  GetParameterMatch: `GMP/GetParameterMatch`,
  ApplyGMPRules: `GMP/ApplyGMPRules`,
  ExportToExcel: `GMP/ExportToExcel`,
};
