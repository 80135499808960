export const SIMULATE_URL = {
  SaveScenario: `M4/SaveScenario`,
  MapProjectsToScenario: `M4/MapProjectsToScenario`,
  GetM4TrajectoryGraphData: `M4/GetM4EmissionsTrajectoryGraphData`,
  GetEmissionData: `M4/GetEmissionData`,
  GetM4WaterfallGraphData: `M4/GetM4WaterfallGraphData`,
  GetM4MarginalAbatementCostGraphData: `M4/GetM4MarginalAbatementCostGraphData`,
  GetBusinessCaseSummaryData: `M4/GetBusinessCaseSummaryData`,
  GetAbatementCashflowData: `M4/GetAbatementCashflowData`,
  DeleteScenarioDetail: `M4/DeleteScenarioDetail`,
  GetScenarioDetails: `M4/GetScenarioDetails`,
  UpdateScenarioDesc: `M4/UpdateScenarioDescription`,
};
