import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllEngagementTeam,
  getAllMasterSector,
  getAllSector,
  saveSector,
} from "../services/engagement.service";

const initialState = {
  allSectors: [],
  teamList: [],
  teamListLoading: false,
  selectedSectors: undefined,
  loading: false,
  error: null,
};

export const getAllMasterSectors = createAsyncThunk(
  "Engagement/GetMasterSector/GetAllMasterSector",
  async () => {
    const allMasterSectorResponse = await getAllMasterSector();

    return allMasterSectorResponse.data.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
  }
);

export const getSelectedMasterSectors = createAsyncThunk(
  "Engagement/GetAllSector/GetAllSector",
  async ({ masterSectors, request }) => {
    let uniqueSectors = new Set();
    let userSectors = [];
    let masterSectorse = masterSectors.slice(0);

    const getAllSectorResponse = await getAllSector({
      request: request,
    });

    getAllSectorResponse.data.forEach((element) => {
      if (element.hasOwnProperty("masterSectorID")) {
        uniqueSectors.add(element["masterSectorID"]);
      }
    });

    userSectors = Array.from(uniqueSectors);

    try {
      masterSectorse.forEach((element) => {
        if (userSectors.includes(element.id)) {
          element["selected"] = true;
        }
      });
    } catch (e) {}

    return { all: masterSectorse, selected: userSectors };
  }
);

export const saveSelectedSectors = createAsyncThunk(
  "Engagement/SaveSector",
  async (request) => {
    const apiResponse = await saveSector({ request });

    return apiResponse.data;
  }
);

export const getEngagementTeam = createAsyncThunk(
  "Engagement/GetAllTeam/GetAllEngagementTeam",
  async (request) => {
    const apiResponse = await getAllEngagementTeam({ request });

    return apiResponse.data;
  }
);

const setupSlice = createSlice({
  name: "setup",
  initialState,
  reducers: {
    setSelectedSectors(state, action) {
      const { selectedOptions } = action.payload;

      state.selectedSectors = selectedOptions;
    },
    addTeamMember(state, action) {
      const { member } = action.payload;

      state.teamList = [...state.teamList, member];
    },
    deleteTeamMember(state, action) {
      const { member } = action.payload;

      state.teamList = state.teamList.filter((item) => item.id !== member.id);
    },
    dropSetupState(state) {
      state.allSectors = initialState.allSectors;
      state.selectedSectors = initialState.selectedSectors;
      state.teamList = initialState.teamList;
      state.teamListLoading = initialState.teamListLoading;
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllMasterSectors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMasterSectors.fulfilled, (state, action) => {
        state.loading = false;
        state.allSectors = action.payload;
      })
      .addCase(getAllMasterSectors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getSelectedMasterSectors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSelectedMasterSectors.fulfilled, (state, action) => {
        state.loading = false;
        state.allSectors = action.payload.all;
        state.selectedSectors = action.payload.selected;
      })
      .addCase(getSelectedMasterSectors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(saveSelectedSectors.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveSelectedSectors.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(saveSelectedSectors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getEngagementTeam.pending, (state) => {
        state.teamListLoading = true;
      })
      .addCase(getEngagementTeam.fulfilled, (state, action) => {
        state.teamListLoading = false;
        state.teamList = action.payload;
      })
      .addCase(getEngagementTeam.rejected, (state, action) => {
        state.teamListLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  dropSetupState,
  setSelectedSectorsCheckbox,
  setSelectedSectors,
  addTeamMember,
  deleteTeamMember,
} = setupSlice.actions;

export default setupSlice.reducer;
