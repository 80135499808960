import { ThemeProvider, createTheme } from "@mui/material";
import {
  BarPlot,
  ChartsAxisHighlight,
  ChartsGrid,
  ChartsLegend,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LinePlot,
  ResponsiveChartContainer,
} from "@mui/x-charts";
import "./ForecastChart.scss";
import {
  forecastChartDataLoadingMessage,
  forecastChartForecastAndTargetLabel,
  forecastChartForecastLabel,
  forecastChartProcessingForecastAndTargetData,
  forecastChartTargetLabel,
  forecastChartXAxisEmissionsWithTarget,
  forecastChartXAxisEmissionsWithoutTarget,
  forecastChartXAxisTarget,
  forecastChartYAxisLabel,
} from "../../../../util/constants";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getCalculatedEmissions,
  getEmissionsChart,
  getTargetChart,
  getCompletedForecastsTargetsCount,
  dropForecastState,
  dropEmissionsChartState,
} from "../../../../store/slices/forecastSlice";
import { LoadingIndicator } from "../../../LoadingIndicator/LoadingIndicator";
import { getEngagement } from "../../../../store/services/engagement.service";
import {
  setForecastInProgress,
  setShowForecastFlag,
  setTargetInProgress,
  setShowTargetFlag,
  getEngagementById,
} from "../../../../store/slices/engagementSlice";
import { EmptyChart } from "../../../CommonUIComponents/EmptyChart/EmptyChart";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});
let forecastsRefreshIntervalId = undefined;
let targetsRefreshIntervalId = undefined;

export function ForecastChart() {
  const emissionsNoTargetChartData = useSelector(
    (state) => state.forecast.emissionsNoTargetChartData
  );
  const emissionsChartDataLoading = useSelector(
    (state) => state.forecast.emissionsChartDataLoading
  );
  const emissionsTargetChartData = useSelector(
    (state) => state.forecast.emissionsTargetChartData
  );
  const targetsChartData = useSelector(
    (state) => state.forecast.targetsChartData
  );
  const targetsChartDataLoading = useSelector(
    (state) => state.forecast.targetsChartDataLoading
  );
  const resetM2DataLoading = useSelector(
    (state) => state.engagement.resetM2DataLoading
  );
  const isSaveFEYInProgress = useSelector(
    (state) => state.forecast.isSaveFEYInProgress
  );
  const recalculateForecastTrigger = useSelector(
    (state) => state.forecast.recalculateForecastTrigger
  );
  const recalculateTargetTrigger = useSelector(
    (state) => state.forecast.recalculateTargetTrigger
  );
  const engagement = useSelector((state) => state.engagement.engagement);
  const scope = useSelector((state) => state.forecast.scope);
  const [forecastDataLoadProgress, setForecastDataLoadProgress] =
    useState(false);
  const [targetDataLoadProgress, setTargetDataLoadProgress] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(
    forecastChartDataLoadingMessage
  );

  const [series, setSeries] = useState([]);
  const [xAxis, setXAxis] = useState([
    {
      scaleType: "band",
      id: "x-axis-id",
      disableTicks: true,
      data: [],
    },
  ]);
  const [yAxis, setYAxis] = useState([
    {
      label: forecastChartYAxisLabel,
      min: 0,
      max: 1000,
      id: "y-axis-id",
    },
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (engagement?.id && scope) {
      if (!forecastsRefreshIntervalId) {
        checkForecastDataLoadProgress();
        forecastsRefreshIntervalId = window.setInterval(
          checkForecastDataLoadProgress,
          10000
        );
      }
    }
  }, [
    dispatch,
    engagement?.id,
    scope,
    engagement.show_Forecast,
    recalculateForecastTrigger,
  ]);

  useEffect(() => {
    if (engagement?.id && scope) {
      if (!targetsRefreshIntervalId) {
        checkTargetDataLoadProgress();
        targetsRefreshIntervalId = window.setInterval(
          checkTargetDataLoadProgress,
          10000
        );
      }
    }
  }, [
    dispatch,
    engagement?.id,
    scope,
    engagement.show_Target,
    recalculateTargetTrigger,
  ]);

  useEffect(() => {
    if (engagement?.id) {
      let tempSeries = [];
      const minYearEmissionsTarget = Math.min(
        ...emissionsTargetChartData.map((item) => item.year)
      );
      const maxYearEmissionsTarget = Math.max(
        ...emissionsTargetChartData.map((item) => item.year)
      );
      const minYearEmissionsNoTarget = Math.min(
        ...emissionsNoTargetChartData.map((item) => item.year)
      );
      const maxYearEmissionsNoTarget = Math.max(
        ...emissionsNoTargetChartData.map((item) => item.year)
      );
      const minYearTarget = Math.min(
        ...targetsChartData.map((item) => item.year)
      );
      const maxYearTarget = Math.max(
        ...targetsChartData.map((item) => item.year)
      );
      let minYear = Math.min(
        minYearEmissionsTarget,
        minYearEmissionsNoTarget,
        minYearTarget
      );
      let maxYear = Math.max(
        maxYearEmissionsTarget,
        maxYearEmissionsNoTarget,
        maxYearTarget
      );
      const sequence = getSequence(minYear, maxYear);

      if (emissionsTargetChartData && emissionsTargetChartData.length !== 0) {
        let emissionsTargetData = [];

        if (minYear !== 0) {
          sequence.forEach((year) => {
            const item = emissionsTargetChartData.find(
              (item) => item.year === year
            );
            if (!item) {
              emissionsTargetData.push(null);
            } else {
              emissionsTargetData.push(item.sum);
            }
          });
        }

        const a = tempSeries.filter(
          (item) => item.label !== forecastChartXAxisEmissionsWithTarget
        );
        a.push({
          type: "bar",
          data: emissionsTargetData,
          label: forecastChartXAxisEmissionsWithTarget,
          color: "#55A6FC",
          stack: "total",
          highlightScope: { highlighted: "item", faded: "global" },
        });
        tempSeries = a;
        setYAxis([
          {
            label: forecastChartYAxisLabel,
            min: emissionsTargetChartData.length === 0 ? 0 : undefined,
            max: emissionsTargetChartData.length === 0 ? 1000 : undefined,
            id: "y-axis-id",
          },
        ]);
      }

      if (
        emissionsNoTargetChartData &&
        emissionsNoTargetChartData.length !== 0
      ) {
        let emissionsNoTargetData = [];

        if (minYear !== 0) {
          sequence.forEach((year) => {
            const item = emissionsNoTargetChartData.find(
              (item) => item.year === year
            );
            if (!item) {
              emissionsNoTargetData.push(null);
            } else {
              emissionsNoTargetData.push(item.sum);
            }
          });
        }

        const a = tempSeries.filter(
          (item) => item.label !== forecastChartXAxisEmissionsWithoutTarget
        );
        a.push({
          type: "bar",
          data: emissionsNoTargetData,
          label: forecastChartXAxisEmissionsWithoutTarget,
          color: "#0A558E",
          stack: "total",
          highlightScope: { highlighted: "item", faded: "global" },
        });
        tempSeries = a;
        setYAxis([
          {
            label: forecastChartYAxisLabel,
            min: emissionsNoTargetChartData.length === 0 ? 0 : undefined,
            max: emissionsNoTargetChartData.length === 0 ? 1000 : undefined,
            id: "y-axis-id",
          },
        ]);
      }

      if (targetsChartData && targetsChartData.length !== 0) {
        let targetData = [];

        if (minYear !== 0) {
          sequence.forEach((year) => {
            const item = targetsChartData.find((item) => item.year === year);
            if (!item) {
              targetData.push(null);
            } else {
              targetData.push(item.sum);
            }
          });

          const a = tempSeries.filter(
            (item) => item.label !== forecastChartXAxisTarget
          );

          a.push({
            type: "line",
            data: targetData,
            label: forecastChartXAxisTarget,
            color: "#DEDEE2",
            highlightScope: { highlighted: "item", faded: "global" },
            curve: "linear",
          });
          tempSeries = a;
          setYAxis([
            {
              label: forecastChartYAxisLabel,
              min: targetsChartData.length === 0 ? 0 : undefined,
              max: targetsChartData.length === 0 ? 1000 : undefined,
              id: "y-axis-id",
            },
          ]);
        }
      }

      setSeries(tempSeries);
      setXAxis([
        {
          scaleType: "band",
          id: "x-axis-id",
          disableTicks: true,
          data: minYear && maxYear ? sequence : [],
          valueFormatter: yearFormatter,
        },
      ]);
    }
  }, [
    dispatch,
    emissionsTargetChartData,
    emissionsNoTargetChartData,
    targetsChartData,
  ]);

  const yearFormatter = (date) => date.toString();

  function getSequence(min, max) {
    const sequence = [];
    for (let i = min; i <= max; i++) {
      sequence.push(i);
    }
    return sequence;
  }

  useEffect(
    () => () => {
      setSeries([]);
      setXAxis([]);
      setYAxis([]);
      window.clearInterval(targetsRefreshIntervalId);
      window.clearInterval(forecastsRefreshIntervalId);
      targetsRefreshIntervalId = undefined;
      forecastsRefreshIntervalId = undefined;
      dispatch(dropForecastState());
    },
    []
  );

  async function checkForecastDataLoadProgress() {
    const response = await getEngagement({ request: { id: engagement.id } });

    if (
      response.data.forecast_inprogress === 1 &&
      response.data.show_Forecast === 1
    ) {
      const getEmissionsChartRequest = {
        scope: scope,
        type: 1,
        engagementCode: engagement.engagementCode,
      };
      dispatch(getEmissionsChart(getEmissionsChartRequest));

      clearInterval(forecastsRefreshIntervalId);
      forecastsRefreshIntervalId = undefined;
      setForecastDataLoadProgress(false);

      dispatch(setShowForecastFlag({ show: 1 }));
      dispatch(setForecastInProgress({ inProgress: 1 }));

      const getForecastTargetcCountRequest = {
        engagementId: engagement.id,
      };

      dispatch(
        getCompletedForecastsTargetsCount(getForecastTargetcCountRequest)
      );
      const request = { id: engagement.id };
      dispatch(getEngagementById(request));

      return;
    }

    if (
      response.data.forecast_inprogress === 0 &&
      response.data.show_Forecast === 1
    ) {
      setForecastDataLoadProgress(true);

      return;
    }

    if (response.data.show_Forecast === 0 && engagement.show_Forecast === 0) {
      clearInterval(forecastsRefreshIntervalId);
      forecastsRefreshIntervalId = undefined;
      setForecastDataLoadProgress(false);

      return;
    }
  }

  async function checkTargetDataLoadProgress() {
    const response = await getEngagement({ request: { id: engagement.id } });
    if (
      response.data.target_inprogress === 1 &&
      response.data.show_Target === 1
    ) {
      if (
        response.data.show_Forecast === 1 &&
        response.data.forecast_inprogress === 1
      ) {
        clearInterval(forecastsRefreshIntervalId);
        forecastsRefreshIntervalId = undefined;
        dispatch(dropEmissionsChartState());

        if (!forecastsRefreshIntervalId) {
          checkForecastDataLoadProgress();
          forecastsRefreshIntervalId = window.setInterval(
            checkForecastDataLoadProgress,
            10000
          );
        }

        const getTargetsChartRequest = {
          scope: scope,
          type: 2,
          engagementCode: engagement.engagementCode,
        };
        dispatch(getTargetChart(getTargetsChartRequest));

        clearInterval(targetsRefreshIntervalId);
        targetsRefreshIntervalId = undefined;
        setTargetDataLoadProgress(false);

        dispatch(setShowTargetFlag({ show: 1 }));
        dispatch(setTargetInProgress({ inProgress: 1 }));

        const getForecastTargetcCountRequest = {
          engagementId: engagement.id,
        };

        dispatch(
          getCompletedForecastsTargetsCount(getForecastTargetcCountRequest)
        );

        const getCalculatedEmissionsRequest = {
          engagementId: engagement.id,
          scope: scope,
        };

        dispatch(getCalculatedEmissions(getCalculatedEmissionsRequest));
        const request = { id: engagement.id };
        dispatch(getEngagementById(request));
      }

      if (response.data.show_Forecast === 0) {
        clearInterval(targetsRefreshIntervalId);
        targetsRefreshIntervalId = undefined;
        setTargetDataLoadProgress(false);
      }
    }

    if (
      response.data.target_inprogress === 0 &&
      response.data.show_Target === 1
    ) {
      setTargetDataLoadProgress(true);
    }

    if (response.data.show_Target === 0) {
      clearInterval(targetsRefreshIntervalId);
      targetsRefreshIntervalId = undefined;
      setTargetDataLoadProgress(false);
      dispatch(dropEmissionsChartState());

      if (!forecastsRefreshIntervalId) {
        checkForecastDataLoadProgress();
        forecastsRefreshIntervalId = window.setInterval(
          checkForecastDataLoadProgress,
          10000
        );
      }
    }
  }

  useEffect(() => {
    let message = forecastChartDataLoadingMessage;
    if (forecastDataLoadProgress && !targetDataLoadProgress) {
      message = forecastChartProcessingForecastAndTargetData.replace(
        "$1",
        forecastChartForecastLabel
      );
    }

    if (targetDataLoadProgress && !forecastDataLoadProgress) {
      message = forecastChartProcessingForecastAndTargetData.replace(
        "$1",
        forecastChartTargetLabel
      );
    }

    if (forecastDataLoadProgress && targetDataLoadProgress) {
      message = forecastChartProcessingForecastAndTargetData.replace(
        "$1",
        forecastChartForecastAndTargetLabel
      );
    }

    setLoadingMessage(message);
  }, [dispatch, forecastDataLoadProgress, targetDataLoadProgress]);

  return (
    <div className="forecast-chart-section">
      <div className="forecast-chart">
        <div
          className={
            targetsChartDataLoading ||
            emissionsChartDataLoading ||
            forecastDataLoadProgress ||
            targetDataLoadProgress ||
            resetM2DataLoading ||
            isSaveFEYInProgress
              ? "show-file-upload-status"
              : "hide-file-upload-status"
          }
        >
          <span>{loadingMessage}</span>
        </div>
        <LoadingIndicator
          show={
            targetsChartDataLoading ||
            emissionsChartDataLoading ||
            forecastDataLoadProgress ||
            targetDataLoadProgress ||
            resetM2DataLoading ||
            isSaveFEYInProgress
          }
          fullscreen={false}
        >
          <ThemeProvider theme={darkTheme}>
            {series.length === 0 ? (
              <EmptyChart chartYAxisLabel={forecastChartYAxisLabel} />
            ) : (
              <ResponsiveChartContainer
                height={400}
                margin={{ top: 5, bottom: 100, left: 125, right: 10 }}
                series={series}
                xAxis={xAxis}
                yAxis={yAxis}
                sx={{
                  [`.${axisClasses.left} .${axisClasses.label}`]: {
                    transform: "translateX(-90px)",
                  },
                }}
              >
                <ChartsGrid horizontal />
                <ChartsXAxis axisId="x-axis-id" disableTicks />
                <ChartsYAxis axisId="y-axis-id" disableTicks disableLine />
                <ChartsLegend
                  direction="row"
                  position={{ vertical: "bottom", horizontal: "middle" }}
                />
                <ChartsTooltip />

                <BarPlot />
                <LinePlot />
                <ChartsAxisHighlight x="line" />
              </ResponsiveChartContainer>
            )}
          </ThemeProvider>
        </LoadingIndicator>
      </div>
    </div>
  );
}
