import { SIMULATE_URL } from "./endpoints/simulate";
import { get, post } from "./base.service";

async function saveScenario({ request }) {
  return await post(`${SIMULATE_URL.SaveScenario}`, request);
}
async function generateGraph({ request }) {
  return await post(`${SIMULATE_URL.MapProjectsToScenario}`, request);
}
async function getM4TrajectoryGraphData({ request }) {
  return await get(
    `${SIMULATE_URL.GetM4TrajectoryGraphData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
  );
}
async function getEmissionData({ request }) {
  return await get(
    `${SIMULATE_URL.GetEmissionData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
  );
}
async function getM4WaterfallGraphData({ request }) {
  return await get(
    `${SIMULATE_URL.GetM4WaterfallGraphData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
  );
}
async function getM4MarginalAbatementCostGraphData({ request }) {
  return await get(
    `${SIMULATE_URL.GetM4MarginalAbatementCostGraphData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
  );
}
async function getBusinessCaseSummaryData({ request }) {
  return await get(
    `${SIMULATE_URL.GetBusinessCaseSummaryData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
  );
}
async function getAbatementCashflowData({ request }) {
  return await get(
    `${SIMULATE_URL.GetAbatementCashflowData}?engagementCode=${request.engagementCode}&ScenarioID=${request.ScenarioID}`
  );
}
async function deleteScenarioDetail({ request }) {
  return await post(
    `${SIMULATE_URL.DeleteScenarioDetail}?engagementCode=${request.engagementCode}&ScenarioID=${request.scenarioId}`
  );
}

async function getScenarioDetails({ request }) {
  return await get(
    `${SIMULATE_URL.GetScenarioDetails}?ScenarioID=${request.scenarioId}`
  );
}
async function updateScenarioDescription({ request }) {
  return await post(
    `${SIMULATE_URL.UpdateScenarioDesc}?ScenarioID=${request.ScenarioID}&scenarioDesc=${request.scenarioDesc}`
  );
}

export {
  saveScenario,
  generateGraph,
  getM4TrajectoryGraphData,
  getEmissionData,
  getM4WaterfallGraphData,
  getM4MarginalAbatementCostGraphData,
  getBusinessCaseSummaryData,
  getAbatementCashflowData,
  deleteScenarioDetail,
  getScenarioDetails,
  updateScenarioDescription,
};
