import React, { useState, useEffect } from "react";
import "./AddGeoSpecModal.scss";
import { Select } from "../../../Select/Select";
import { Button } from "../../../Button/Button";
import {
  CancelBtn,
  SaveBtn,
  regLevel1,
  regLevel2,
  regLevel3,
  selectGeo,
} from "../../../../util/constants";
import { Modal } from "../../../Modal/Modal";

export function GeographicalModal({
  isModalVisible,
  onClose,
  onConfirm,
  onEdit,
  hierarchyData,
  scopeArray,
  forecastGeoData,
  selectedEmissionModalData,
  selectedOrgModalData,
  selectedGeoData,
}) {
  const [regionLevel1, setRegionLevel1] = useState([]);
  const [regionLevel2, setRegionLevel2] = useState([]);
  const [regionLevel3, setRegionLevel3] = useState([]);
  const [geoLevel1Array, setgeoLevel1Array] = useState([]);
  const [geoLevel2Array, setgeoLevel2Array] = useState([]);
  const [geoLevel3Array, setgeoLevel3Array] = useState([]);
  const [geoModalOptions, setGeoModalOptions] = useState([{}]);
  const [disableLevel2, setDisableLevel2] = useState(true);
  const [disableLevel3, setDisableLevel3] = useState(true);
  const [disableSave, setDisableSave] = useState(true);
  const [level1SelectAll, setlevel1SelectAll] = useState(false);
  const [selectAllLevel, setSelectAllLevel] = useState("");

  async function GetAllHierarchyData() {
    if (hierarchyData) {
      let filteredgeoArray = [];
      if (scopeArray) {
        filteredgeoArray = hierarchyData.filter((element) =>
          scopeArray.includes(element.scope)
        );
      }
      if (selectedGeoData) {
        if (selectedGeoData.length === 0) {
          setRegionLevel1([]);
          setRegionLevel2([]);
          setRegionLevel3([]);
          setgeoLevel1Array([]);
          setgeoLevel2Array([]);
          setgeoLevel3Array([]);
          setDisableLevel2(true);
          setDisableLevel3(true);
          setDisableSave(true);
        }
      }

      if (selectedEmissionModalData) {
        if (selectedEmissionModalData?.category_level3?.includes("All")) {
          if (selectedEmissionModalData?.category_level2?.includes("All")) {
            if (selectedEmissionModalData?.category_level1?.includes("All")) {
            } else {
              filteredgeoArray = filteredgeoArray.filter((element) =>
                selectedEmissionModalData?.category_level1.includes(
                  element.category_level1
                )
              );
            }
          } else {
            filteredgeoArray = filteredgeoArray.filter(
              (element) =>
                selectedEmissionModalData?.category_level1.includes(
                  element.category_level1
                ) &&
                selectedEmissionModalData?.category_level2.includes(
                  element.category_level2
                )
            );
          }
        } else {
          if (selectedEmissionModalData.category_level3 !== undefined) {
            filteredgeoArray = filteredgeoArray.filter((element) =>
              selectedEmissionModalData?.category_level3.includes(
                element.category_level3
              )
            );
          }
        }
      }
      if (selectedOrgModalData) {
        if (selectedOrgModalData?.entity_level4?.includes("All")) {
          if (selectedOrgModalData?.entity_level3?.includes("All")) {
            if (selectedOrgModalData?.entity_level2?.includes("All")) {
              if (selectedOrgModalData?.entity_level1?.includes("All")) {
              } else {
                filteredgeoArray = filteredgeoArray.filter((element) =>
                  selectedOrgModalData?.entity_level1?.includes(
                    element.entity_level1
                  )
                );
              }
            } else {
              filteredgeoArray = filteredgeoArray.filter(
                (element) =>
                  selectedOrgModalData?.entity_level1?.includes(
                    element.entity_level1
                  ) &&
                  selectedOrgModalData?.entity_level2?.includes(
                    element.entity_level2
                  )
              );
            }
          } else {
            filteredgeoArray = filteredgeoArray.filter((element) =>
              selectedOrgModalData?.entity_level3?.includes(
                element.entity_level3
              )
            );
          }
        } else {
          if (selectedOrgModalData?.entity_level4 !== undefined) {
            filteredgeoArray = filteredgeoArray.filter((element) =>
              selectedOrgModalData?.entity_level4?.includes(
                element.entity_level4
              )
            );
          }
        }
      }
      await setGeoModalOptions(filteredgeoArray);
      let geoLevel1 = [
        ...new Set(filteredgeoArray.map((element) => element.region_level1)),
      ];
      geoLevel1 = geoLevel1.filter((element) => element !== null);
      if (geoLevel1.length === 0) {
        geoLevel1.push("N/A");
      }
      await setgeoLevel1Array(geoLevel1);
      if (
        forecastGeoData?.region_level3 &&
        forecastGeoData?.functionality !== "Save"
      ) {
        await handleEditModal(forecastGeoData, filteredgeoArray);
      }
    }
  }
  async function handleEditModal(forecastGeoData, filteredgeoArray) {
    await setRegionLevel1(forecastGeoData.region_level1);
    await setgeoLevel2Array(forecastGeoData.region_level2);
    await setgeoLevel3Array(forecastGeoData.region_level3);
    await setRegionLevel2(forecastGeoData.region_level2);
    setRegionLevel3(forecastGeoData.region_level3);
    setDisableSave(true);
    let selectAll = "";
    let filteredByScopeArray = filteredgeoArray.filter((element) =>
      scopeArray.includes(element.scope)
    );
    let region1Array = [
      ...new Set(filteredByScopeArray.map((element) => element.region_level1)),
    ];
    if (region1Array.length === forecastGeoData.region_level1.length) {
      selectAll = "regionLevel1";
    } else {
      let filteredByScopeArray = hierarchyData.filter(
        (element) =>
          forecastGeoData.region_level1.includes(element.region_level1) &&
          scopeArray.includes(element.scope)
      );
      let emissionLevel2 = [
        ...new Set(
          filteredByScopeArray.map((element) => element.region_level2)
        ),
      ];

      if (emissionLevel2.length === forecastGeoData.region_level2.length) {
        selectAll = "regionLevel2";
      } else {
        let filteredArray = filteredByScopeArray.filter(
          (element) =>
            forecastGeoData.region_level2.includes(element.region_level2) &&
            scopeArray.includes(element.scope)
        );

        let emissionLevel3 = [
          ...new Set(filteredArray.map((element) => element.region_level3)),
        ];
        if (emissionLevel3.length === forecastGeoData.region_level3.length) {
          selectAll = "regionLevel3";
        }
      }
    }
    await handleEdit(selectAll, filteredgeoArray);
  }
  async function handleLevel1Change(event) {
    await setRegionLevel1(event);
    await setRegionLevel2([]);
    await setRegionLevel3([]);
    await setDisableSave(true);
    setDisableLevel3(true);
    setlevel1SelectAll(false);
    let filteredgeoArray = geoModalOptions.filter(
      (element) =>
        event.includes(element.region_level1) &&
        scopeArray.includes(element.scope)
    );
    let geoLevel2 = [
      ...new Set(filteredgeoArray.map((element) => element.region_level2)),
    ];
    geoLevel2 = geoLevel2.filter((element) => element !== null);
    if (geoLevel2.length === 0) {
      geoLevel2.push("N/A");
    }
    await setgeoLevel2Array(geoLevel2);
    if (geoLevel2.length === 1 && geoLevel2[0] !== "N/A") {
      handleLevel2Change(geoLevel2, event);
    }
    if (geoLevel1Array.length === event.length && geoLevel1Array.length !== 1) {
      setlevel1SelectAll(true);
      setSelectAllLevel("regionLevel1");
    } else {
      setSelectAllLevel("");
      event.length > 0 ? setDisableLevel2(false) : setDisableLevel2(true);
    }
  }
  async function handleLevel2Change(event, level1) {
    setRegionLevel2(event);
    setRegionLevel3([]);
    setDisableSave(true);
    let level1selected = [];
    if (level1?.length > 0) {
      level1selected = level1;
    } else {
      level1selected = regionLevel1;
    }
    let array = geoModalOptions.filter((element) =>
      level1selected.includes(element.region_level1)
    );
    let filteredgeoArray = array.filter((element) =>
      event.includes(element.region_level2)
    );
    let geoLevel3 = [
      ...new Set(filteredgeoArray.map((element) => element.region_level3)),
    ];
    geoLevel3 = geoLevel3.filter((element) => element !== null);
    if (geoLevel3.length === 0) {
      geoLevel3.push("N/A");
    }
    await setgeoLevel3Array(geoLevel3);
    if (geoLevel3.length === 1 && geoLevel3[0] !== "N/A") {
      handleLevel3Change(geoLevel3);
    }
    if (event.length === geoLevel2Array.length && geoLevel2Array.length !== 1) {
      handleLevel3Change(geoLevel3);
      setDisableLevel3(true);
      if (selectAllLevel === "") {
        setSelectAllLevel("regionLevel2");
      }
    } else {
      event.length > 0 ? setDisableLevel3(false) : setDisableLevel3(true);
    }
  }
  async function handleLevel3Change(event) {
    setRegionLevel3(event);
    if (
      event.length === geoLevel3Array.length &&
      geoLevel3Array.length !== 1 &&
      selectAllLevel === ""
    ) {
      setSelectAllLevel("regionLevel3");
    }
    event.length > 0 ? setDisableSave(false) : setDisableSave(true);
  }
  async function processLevel2forLevel1SelectAll() {
    handleLevel2Change(geoLevel2Array);
    geoLevel2Array.length > 1
      ? setDisableLevel2(true)
      : setDisableLevel2(false);
  }
  async function handleAllpayload(selectAll, filteredgeoArray) {
    let array = [];
    let filteredByScopeArray = [];
    if (filteredgeoArray.length > 0) {
      filteredByScopeArray = filteredgeoArray.filter((element) =>
        scopeArray.includes(element.scope)
      );
    } else {
      filteredByScopeArray = geoModalOptions.filter((element) =>
        scopeArray.includes(element.scope)
      );
    }

    if (selectAllLevel === "regionLevel1") {
      scopeArray.forEach((scope) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (scope === filteredByScopeArray[i].scope) {
            let record = {
              scope: scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: "All",
              region_level2: "All",
              region_level3: "All",
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (forecastGeoData?.region_level1 && selectAll === "regionLevel1") {
      scopeArray.forEach((scope) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (scope === filteredByScopeArray[i].scope) {
            let record = {
              scope: scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: "All",
              region_level2: "All",
              region_level3: "All",
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAllLevel === "regionLevel2") {
      regionLevel1.forEach((record) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            record === filteredByScopeArray[i].region_level1 &&
            scopeArray.includes(filteredByScopeArray[i].scope)
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: "All",
              region_level3: "All",
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (forecastGeoData?.region_level2 && selectAll === "regionLevel2") {
      forecastGeoData.region_level1.forEach((record) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            record === filteredByScopeArray[i].region_level1 &&
            scopeArray.includes(filteredByScopeArray[i].scope)
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: "All",
              region_level3: "All",
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAllLevel === "regionLevel3") {
      regionLevel2.forEach((record) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            record === filteredByScopeArray[i].region_level2 &&
            scopeArray.includes(filteredByScopeArray[i].scope) &&
            regionLevel1.includes(filteredByScopeArray[i].region_level1)
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: "All",
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (forecastGeoData?.region_level2 && selectAll === "regionLevel3") {
      forecastGeoData.region_level2.forEach((record) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            record === filteredByScopeArray[i].region_level2 &&
            scopeArray.includes(filteredByScopeArray[i].scope) &&
            forecastGeoData.region_level1.includes(
              filteredByScopeArray[i].region_level1
            )
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: filteredByScopeArray[i].category_level3,
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: "All",
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    return array;
  }
  async function processData(functionality, array) {
    let uniqueRecords = [];
    let filteredEmissionArray = geoModalOptions.filter((element) =>
      scopeArray.includes(element.scope)
    );
    if (functionality === "Save") {
      if (regionLevel3.length === 1 && regionLevel3[0] === "N/A") {
        if (regionLevel2.length === 1 && regionLevel2[0] === "N/A") {
          if (regionLevel1.length === 1 && regionLevel1[0] === "N/A") {
            let record = {
              scope: scopeArray[0],
              region_level1: "",
              region_level2: "",
              region_level3: "",
            };
            uniqueRecords.push(record);
          } else {
            regionLevel1.forEach((value) => {
              let index = filteredEmissionArray.findIndex(
                (element) => element.region_level1 === value
              );
              let record = {
                scope: scopeArray[0],
                region_level1: filteredEmissionArray[index]["region_level1"],
                region_level2: filteredEmissionArray[index]["region_level2"],
                region_level3: filteredEmissionArray[index]["region_level3"],
                category_level1:
                  filteredEmissionArray[index]["category_level1"],
                category_level2:
                  filteredEmissionArray[index]["category_level2"],
                category_level3:
                  filteredEmissionArray[index]["category_level3"],
                entity_level1: filteredEmissionArray[index]["entity_level1"],
                entity_level2: filteredEmissionArray[index]["entity_level2"],
                entity_level3: filteredEmissionArray[index]["entity_level3"],
                entity_level4: filteredEmissionArray[index]["entity_level4"],
              };
              uniqueRecords.push(record);
            });
          }
        } else {
          regionLevel2.forEach((value) => {
            if (value !== "N/A") {
              let index = filteredEmissionArray.findIndex(
                (element) => element.region_level2 === value
              );
              if (index !== -1) {
                let record = {
                  scope: filteredEmissionArray[index]["scope"],
                  region_level1: filteredEmissionArray[index]["region_level1"],
                  region_level2: filteredEmissionArray[index]["region_level2"],
                  region_level3: filteredEmissionArray[index]["region_level3"],
                  category_level1:
                    filteredEmissionArray[index]["category_level1"],
                  category_level2:
                    filteredEmissionArray[index]["category_level2"],
                  category_level3:
                    filteredEmissionArray[index]["category_level3"],
                  entity_level1: filteredEmissionArray[index]["entity_level1"],
                  entity_level2: filteredEmissionArray[index]["entity_level2"],
                  entity_level3: filteredEmissionArray[index]["entity_level3"],
                  entity_level4: filteredEmissionArray[index]["entity_level4"],
                };
                uniqueRecords.push(record);
              }
            }
          });
        }
      } else {
        regionLevel3.forEach((value) => {
          for (let i = 0; i < filteredEmissionArray.length; i++) {
            if (
              value === filteredEmissionArray[i].region_level3 &&
              scopeArray.includes(filteredEmissionArray[i].scope) &&
              regionLevel1.includes(filteredEmissionArray[i].region_level1) &&
              regionLevel2.includes(filteredEmissionArray[i].region_level2)
            ) {
              let record = {
                scope: filteredEmissionArray[i].scope,
                category_level1: filteredEmissionArray[i].category_level1,
                category_level2: filteredEmissionArray[i].category_level2,
                category_level3: filteredEmissionArray[i].category_level3,
                region_level1: filteredEmissionArray[i].region_level1,
                region_level2: filteredEmissionArray[i].region_level2,
                region_level3: filteredEmissionArray[i].region_level3,
                entity_level1: filteredEmissionArray[i]["entity_level1"],
                entity_level2: filteredEmissionArray[i]["entity_level2"],
                entity_level3: filteredEmissionArray[i]["entity_level3"],
                entity_level4: filteredEmissionArray[i]["entity_level4"],
                custom_specification1:
                  filteredEmissionArray[i]["custom_specification1"],
                custom_specification2:
                  filteredEmissionArray[i]["custom_specification2"],
                custom_specification3:
                  filteredEmissionArray[i]["custom_specification3"],
              };
              uniqueRecords.push(record);
            }
          }
        });
      }
    }
    if (functionality === "Edit") {
      filteredEmissionArray = array.filter((element) =>
        scopeArray.includes(element.scope)
      );
      forecastGeoData.region_level3.forEach((value) => {
        for (let i = 0; i < filteredEmissionArray.length; i++) {
          if (
            value === filteredEmissionArray[i].region_level3 &&
            scopeArray.includes(filteredEmissionArray[i].scope) &&
            forecastGeoData.region_level1.includes(
              filteredEmissionArray[i].region_level1
            ) &&
            forecastGeoData.region_level2.includes(
              filteredEmissionArray[i].region_level2
            )
          ) {
            let record = {
              scope: filteredEmissionArray[i].scope,
              category_level1: filteredEmissionArray[i].category_level1,
              category_level2: filteredEmissionArray[i].category_level2,
              category_level3: filteredEmissionArray[i].category_level3,
              region_level1: filteredEmissionArray[i].region_level1,
              region_level2: filteredEmissionArray[i].region_level2,
              region_level3: filteredEmissionArray[i].region_level3,
              entity_level1: filteredEmissionArray[i]["entity_level1"],
              entity_level2: filteredEmissionArray[i]["entity_level2"],
              entity_level3: filteredEmissionArray[i]["entity_level3"],
              entity_level4: filteredEmissionArray[i]["entity_level4"],
              custom_specification1:
                filteredEmissionArray[i]["custom_specification1"],
              custom_specification2:
                filteredEmissionArray[i]["custom_specification2"],
              custom_specification3:
                filteredEmissionArray[i]["custom_specification3"],
            };
            uniqueRecords.push(record);
          }
        }
      });
    }
    return uniqueRecords;
  }
  useEffect(() => {
    GetAllHierarchyData();
  }, [
    scopeArray,
    hierarchyData,
    forecastGeoData,
    selectedEmissionModalData,
    selectedOrgModalData,
    selectedGeoData,
  ]);
  useEffect(() => {
    if (level1SelectAll) {
      processLevel2forLevel1SelectAll();
    }
  }, [level1SelectAll]);

  async function handleSave() {
    setlevel1SelectAll(false);
    const uniqueRecords = await processData("Save", []);
    const allRecords = await handleAllpayload(selectAllLevel, []);
    onConfirm({
      records: uniqueRecords,
      region_level1: regionLevel1,
      region_level2: regionLevel2,
      region_level3: regionLevel3,
      allPayload: allRecords.length > 0 ? allRecords : uniqueRecords,
      selectAll: selectAllLevel,
      functionality: "Save",
    });
  }

  async function handleEdit(selectAll, filteredgeoArray) {
    const uniqueRecords = await processData("Edit", filteredgeoArray);
    const allRecords = await handleAllpayload(selectAll, filteredgeoArray);
    onEdit({
      records: uniqueRecords,
      region_level1: forecastGeoData.region_level1,
      region_level2: forecastGeoData.region_level2,
      region_level3: forecastGeoData.region_level3,
      allPayload: allRecords.length > 0 ? allRecords : uniqueRecords,
      selectAll: selectAll,
    });
  }
  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={selectGeo}
      body={
        <div className="add-custom-modal-body">
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {regLevel1}
              <span className="required-star">*</span>
            </span>
            <Select
              value={regionLevel1}
              options={geoLevel1Array}
              multiple={true}
              onChange={handleLevel1Change}
              showSelectAllButton={true}
            ></Select>
          </div>
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {regLevel2}
              <span className="required-star">*</span>
            </span>
            <Select
              value={regionLevel2}
              options={geoLevel2Array}
              multiple={true}
              onChange={handleLevel2Change}
              disabled={disableLevel2}
              showSelectAllButton={true}
            ></Select>
          </div>
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {regLevel3}
              <span className="required-star">*</span>
            </span>
            <Select
              value={regionLevel3}
              options={geoLevel3Array}
              multiple={true}
              onChange={handleLevel3Change}
              disabled={disableLevel3}
              showSelectAllButton={true}
            ></Select>
          </div>
        </div>
      }
      footer={
        <div className="forecast-modal-footer">
          <Button variant="secondary" onClick={() => onClose([])}>
            {CancelBtn}
          </Button>
          <Button
            variant="primary"
            className="save"
            onClick={handleSave}
            disabled={disableSave}
          >
            {SaveBtn}
          </Button>
        </div>
      }
    />
  );
}
