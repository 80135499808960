import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getStepperSteps } from "../services/engagement.service";
import {
  Setup,
  SetupHeading,
  ManageEmissions,
  ManageEmissionsHeading,
  Forecast,
  ForecastHeading,
  Analyse,
  AnalyseHeading,
} from "../../components";
import { Simulate } from "../../components/Simulate/Simulate";
import { SimulateHeading } from "../../components/Simulate/components/heading/SimulateHeading";

const initialState = {
  steps: [],
  loading: false,
  error: null,
};

const stepsComponents = [
  {
    stepIndex: 1,
    stepHeading: <SetupHeading />,
    stepComponent: <Setup />,
  },
  {
    stepIndex: 2,
    stepHeading: <ManageEmissionsHeading />,
    stepComponent: <ManageEmissions />,
  },
  {
    stepIndex: 3,
    stepHeading: <ForecastHeading />,
    stepComponent: <Forecast />,
  },
  {
    stepIndex: 4,
    stepHeading: <AnalyseHeading />,
    stepComponent: <Analyse />,
  },
  {
    stepIndex: 5,
    stepHeading: <SimulateHeading />,
    stepComponent: <Simulate />,
  },
];

export const fetchStepperSteps = createAsyncThunk(
  "Engagement/GetMasterSetupStep/GetAllMasterSetupStep",
  async () => {
    const apiResponse = await getStepperSteps();
    const response = [];

    apiResponse.data.forEach((step) => {
      if (step.activeFlag) {
        var stepComp = stepsComponents.find((s) => s.stepIndex === step.id);

        if (stepComp) {
          response.push({
            stepIndex: step.id,
            stepName: step.name,
            stepHeading: stepComp.stepHeading,
            stepComponent: stepComp.stepComponent,
          });
        } else {
          response.push({
            stepIndex: step.id,
            stepName: step.name,
            stepHeading: <></>,
            stepComponent: <></>,
          });
        }
      }
    });

    return response;
  }
);

const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    dropStepperState(state) {
      state.steps = initialState.steps;
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStepperSteps.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStepperSteps.fulfilled, (state, action) => {
        state.loading = false;
        state.steps = action.payload;
      })
      .addCase(fetchStepperSteps.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { dropStepperState } = stepperSlice.actions;

export default stepperSlice.reducer;
