import { useEffect, useState } from "react";
import "./ProjectResults.scss";
import { ProjectResultsTable } from "./components/ProjectResultsTable/ProjectResultsTable";
import {
  PoweBiBtn,
  projectResultsAbatementCashFlowCalcHeader,
  projectResultsBusinessCaseSummaryHeader,
  projectResultsCashFlowChartHeader,
  projectResultsEmissionsAbatementChartHeader,
  projectResultsHeader,
} from "../../util/constants";
import { routes } from "../../routes/routes.constatns";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../Button/Button";
import { GraphIcon } from "../../icons";
import { useNavigate } from "react-router-dom";
import { MotifCard, MotifCardBody, MotifCardHeader } from "@ey-xd/motif-react";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import { EmissionsAbatementChart } from "./components/EmissionsAbatementChart/EmissionsAbatementChart";
import { EmissionCashFlowGraph } from "./components/EmissionCashFlowGraph/EmissionCashFlowGraph";
import {
  GetProjectBusinessCaseSummaryData,
  GetProjectFinancialsAbatementData,
  GetProjectCashflowData,
} from "../../store/services/project.service";
import {
  getLockingData,
  setIsNextTabButtonDisabled,
  setProjectAbatementData,
  setProjectCashFlowData,
} from "../../store/slices/projectSlice";
import { LockerModalM3 } from "../LockerModal";
import { numberFormatter } from "../../util/dateUtil";

export function ProjectResults() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const project = useSelector((state) => state.project.project);
  const [summaryCardData, setSummaryCardData] = useState({});
  const user = useSelector((state) => state.user.user);
  const [isLockerModalOpen, setLockerModalOpen] = useState(false);
  const [lockedBy, setLockedby] = useState();
  const [lockedData, setLock] = useState();
  const lockingData = useSelector((state) => state.project?.lockingData);

  useEffect(() => {
    if (engagement?.id && project?.projectId) {
      getBusinessCaseSummaryData();
      getAbatementData();
      getCashFlowData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagement?.id, project?.id, dispatch]);
  useEffect(() => {
    dispatch(setIsNextTabButtonDisabled({ isDisabled: false }));
  }, []);

  // Get project Business case summary Data
  const getBusinessCaseSummaryData = async () => {
    const request = {
      projectId: project.projectId,
    };
    const data = await GetProjectBusinessCaseSummaryData({ request });
    setSummaryCardData(data);
  };

  // Get project abatement Data
  const getAbatementData = async () => {
    const request = {
      engagementCode: engagement.engagementCode,
      projectId: project.projectId,
      pageName: "gmp",
    };
    const data = await GetProjectFinancialsAbatementData({ request });
    dispatch(setProjectAbatementData({ abatementData: data.abatement }));
  };

  // Get project Cashflow Data
  const getCashFlowData = async () => {
    const request = {
      engagementCode: engagement.engagementCode,
      projectId: project.projectId,
    };
    const data = await GetProjectCashflowData({ request });
    dispatch(setProjectCashFlowData({ cashFlowData: data }));
  };

  // to get a locked user flags
  useEffect(() => {
    const request = {
      LockedBy: user.username,
      ModuleName: "Project",
      EngagementID: project.projectId,
    };

    dispatch(getLockingData(request)).then((action) => {
      if (action) {
        const lockedData = action.payload?.data?.lockStatus;
        const lockedBy = action.payload?.data?.lockedBy;
        setLock(lockedData);
        setLockedby(lockedBy);
        if (lockedData === 2) {
          setLockerModalOpen(true);
        }
      }
    });
  }, [dispatch, project.projectId, lockedData]);

  return (
    <div className="project-results">
      <div className="project-results-header">
        <span className="project-results-header-main">
          {projectResultsHeader}
        </span>
        <Button
          onClick={() => {
            navigate(`/${routes.powerBI}`, {
              state: {
                reportId: process.env.REACT_APP_POWER_BI_REPORT_ID_M3,
              },
              gestureEnabled: false,
            });
          }}
          variant="secondary"
        >
          <GraphIcon />
          {PoweBiBtn}
        </Button>
      </div>
      <div className="project-results-body">
        <div className="project-results-body-emissions-chart-header">
          <span>{projectResultsEmissionsAbatementChartHeader}</span>
        </div>
        <div className="project-results-body-emissions-chart">
          <EmissionsAbatementChart />
        </div>
        <div className="project-results-body-emissions-chart-header">
          <span>{projectResultsCashFlowChartHeader}</span>
        </div>
        <div className="project-results-body-emissions-chart">
          {/* <EmptyChart chartYAxisLabel={"2024 $"} /> */}
          <EmissionCashFlowGraph />
        </div>
        <div className="project-results-body-emissions-chart-header">
          <span>{projectResultsBusinessCaseSummaryHeader}</span>
        </div>
        <div className="project-results-body-business-summary">
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">{"Simple payback period (yrs)"}</span>
            </MotifCardHeader>
            <LoadingIndicator show={false} fullscreen={false} transparent>
              <MotifCardBody>
                <p className="summary-percent">
                  {summaryCardData.simplePayBackPeriod === null
                    ? "-"
                    : summaryCardData.simplePayBackPeriod}
                </p>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>

          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {"Discounted payback period (yrs)"}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={false} fullscreen={false} transparent>
              <MotifCardBody>
                <p className="summary-percent">
                  {summaryCardData.discountedPayBackPeriod === null
                    ? "-"
                    : summaryCardData.discountedPayBackPeriod}
                </p>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">{"Net present value ($)"}</span>
            </MotifCardHeader>
            <LoadingIndicator show={false} fullscreen={false} transparent>
              <MotifCardBody>
                <p className="summary-percent">
                  {summaryCardData.netPresentValue === null ||
                  summaryCardData.netPresentValue === undefined
                    ? ""
                    : summaryCardData.netPresentValue > 1000 ||
                      summaryCardData.netPresentValue < -100
                    ? numberFormatter.format(
                        summaryCardData.netPresentValue.toFixed(0)
                      )
                    : numberFormatter.format(summaryCardData.netPresentValue)}
                </p>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {"Technical abatement potential (%)"}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={false} fullscreen={false} transparent>
              <MotifCardBody>
                <p className="summary-percent">
                  {summaryCardData.technicalAbatement}
                </p>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {"Marginal abatement cost ($/tCO\u{2082}e)"}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={false} fullscreen={false} transparent>
              <MotifCardBody>
                <p className="summary-percent">
                  {summaryCardData.marginalAbatementCost === null ||
                  summaryCardData.marginalAbatementCost === undefined
                    ? ""
                    : summaryCardData.marginalAbatementCost > 1000 ||
                      summaryCardData.marginalAbatementCost < -100
                    ? numberFormatter.format(
                        summaryCardData.marginalAbatementCost.toFixed(0)
                      )
                    : numberFormatter.format(
                        summaryCardData.marginalAbatementCost
                      )}
                </p>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
          <MotifCard orientation="horizontal">
            <MotifCardHeader variant="alt">
              <span className="motif-h6">
                {"Internal rate of return (% p.a.)"}
              </span>
            </MotifCardHeader>
            <LoadingIndicator show={false} fullscreen={false} transparent>
              <MotifCardBody>
                <p className="summary-percent">
                  {summaryCardData.internalRateofReturn}
                </p>
              </MotifCardBody>
            </LoadingIndicator>
          </MotifCard>
        </div>
        <div className="project-results-body-emissions-chart-header">
          <span>{projectResultsAbatementCashFlowCalcHeader}</span>
        </div>
        <ProjectResultsTable />
      </div>
      {lockedData === 2 && lockedBy === user.username && project.projectId && (
        <LockerModalM3
          isOpen={isLockerModalOpen}
          onClose={() => setLockerModalOpen(false)}
        />
      )}
    </div>
  );
}
