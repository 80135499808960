import {
  MotifFileUploader,
  MotifFileUploaderItem,
  MotifIconButton,
} from "@ey-xd/motif-react";
import { DeleteLogo } from "../../icons";
import "./FileUploader.scss";

export function FileUploader({
  filesToUpload = [],
  onDrop,
  accept,
  disabled = false,
  multiple = true,
  percentUploaded = 0,
  onDelete,
  uploaded,
  isDeleteButtonDisabled = false,
}) {
  return (
    <div className="decarb-file-uploader">
      <MotifFileUploader
        id="my-id"
        label="Click to locate or drag and drop XLSX (max. 28 MB)"
        onDrop={onDrop}
        accept={accept}
        disabled={disabled}
        multiple={multiple}
        data-testid="dropzone"
      />
      {filesToUpload.map((file) => {
        return (
          <MotifFileUploaderItem
            fileName={file.name}
            fileSize={file.size}
            customButton={
              <div className="decarb-file-uploader-item-buttons">
                <MotifIconButton
                  onClick={onDelete}
                  disabled={isDeleteButtonDisabled}
                  data-testid="delete-file-button"
                >
                  <DeleteLogo />
                </MotifIconButton>
              </div>
            }
            error={file.error && true}
            showProgress
            key={crypto.randomUUID()}
            percentUploaded={percentUploaded}
            uploaded={uploaded}
            icons={{ close: <DeleteLogo /> }}
            className={
              uploaded ? "decarb-file-uploader-item-hide-percentage" : ""
            }
          />
        );
      })}
    </div>
  );
}
