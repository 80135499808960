import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./AddForecastModal.scss";
import { AddForecastIcon } from "../../../../icons";
import {
  addForecastModalHeading,
  forecastSpec,
  viaExcel,
  viaUI,
} from "../../../../util/constants";
import { routes } from "../../../../routes/routes.constatns";
import { Button } from "../../../Button/Button";
import { Modal } from "../../../Modal/Modal";

export function AddForecastModal({ isForecastModalVisible, onClose }) {
  const navigate = useNavigate();
  const engagement = useSelector((state) => state.engagement.engagement);

  const navigateToAddForecastSpec = async () => {
    navigate(`${routes.addforecast}`, {
      state: {
        engagementId: engagement.id,
        forecastEndYear: engagement.forecastEndYear,
      },
      gestureEnabled: false,
    });
  };

  const navigateToExcel = async () => {
    navigate(`${routes.importExcel}`, {
      state: {
        engagementId: engagement.id,
        forecastEndYear: engagement.forecastEndYear,
      },
      gestureEnabled: false,
    });
  };

  return (
    <Modal
      show={isForecastModalVisible}
      onClose={onClose}
      header={
        <div className="file-version-history-modal-header">
          <div className="motif-fab">
            <AddForecastIcon />
          </div>
          <span className="heading">{forecastSpec}</span>
        </div>
      }
      body={
        <span className="add-forecast-modal-body">
          {addForecastModalHeading}
        </span>
      }
      footer={
        <div className="view-endagement-delete-modal-footer">
          <Button
            variant="primary"
            onClick={() => {
              navigateToExcel();
            }}
          >
            {viaExcel}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              navigateToAddForecastSpec();
            }}
          >
            {viaUI}
          </Button>
        </div>
      }
    />
  );
}
