import { GMP_URL } from "./endpoints/gmp";
import { get, post } from "./base.service";

async function getParametersList({ request }) {
  return await get(
    `${GMP_URL.GetParametersList}?engagementId=${request.engagementId}&variable_type=${request.variable_type}`
  );
}

async function getruleList({ request }) {
  return await get(`${GMP_URL.GetRulesList}?projectID=${request.projectId}`);
}

async function saveDecarbGmpRuleMap({ request }) {
  return await post(`${GMP_URL.SaveDecarbGmpRuleMap}`, request);
}

async function getGMPRulesMapData({ request }) {
  return await get(
    `${GMP_URL.GetGMPRulesMapData}?projectID=${request.projectId}`
  );
}

async function deleteGmpRule({ request }) {
  return await post(`${GMP_URL.DeleteGmpRule}?ruleId=${request.ruleId}`);
}

async function getGMPData({ request }) {
  return await get(
    `${GMP_URL.GetGMPDataURL}?EngagementCode=${request.engagement}&ViewType=${request.viewType}`
  );
}

async function deleteGMPDetail({ request }) {
  return await post(
    `${GMP_URL.DeleteGMPDetail}?ParameterID=${request.ParameterID}&engagementCode=${request.engagementCode}&modifiedBy=${request.modifiedBy}`
  );
}

async function saveGMPData({ payload }) {
  return await post(`${GMP_URL.SaveGMPData}`, payload);
}

async function updateGMPDataManully({ payload }) {
  return await post(`${GMP_URL.UpdateGMPDataManully}`, payload);
}

async function getParameterMatch({ payload }) {
  return await post(`${GMP_URL.GetParameterMatch}`, payload);
}

async function applyGMPRules({ request }) {
  return await post(
    `${GMP_URL.ApplyGMPRules}?engCode=${request.engCode}&projectId=${request.projectId}`
  );
}

async function exportToExcel({ request }) {
  return await post(
    `${GMP_URL.ExportToExcel}?StartYr=${request.startYear}&EngagementCode=${request.engagementCode}&Projectname=${request.projectName}&Module=${request.module}&Scenarioname=${request.scenarioName}`
  );
}

export {
  getParametersList,
  getruleList,
  saveDecarbGmpRuleMap,
  getGMPRulesMapData,
  deleteGmpRule,
  getGMPData,
  deleteGMPDetail,
  saveGMPData,
  updateGMPDataManully,
  getParameterMatch,
  applyGMPRules,
  exportToExcel,
};
