import { MotifChip } from "@ey-xd/motif-react";
import {
  DownloadBtn,
  fileVersionHistoryModalLiveVersion,
} from "../../util/constants";
import { Button } from "../Button/Button";
import { DownloadIcon } from "../../icons";
import { downloadFileFromBlob } from "../../store/services/azureBlob.service";
import { saveAs } from "file-saver";

const ModuleName = "Module1";

export function customCellRenderer({ node }) {
  async function onDownloadClickHandler(node) {
    try {
      const blobFile = await downloadFileFromBlob({
        moduleName: `DecarbActivityData/${ModuleName}`,
        fileName: node.data.fileName,
      });

      saveAs(blobFile, node.data.fileName);
    } catch (error) {
      console.error("Error in downloading file", error);
    }
  }

  return node.data.liveVersion ? (
    <MotifChip label="Live" className="file-version-history-modal-live-chip">
      {fileVersionHistoryModalLiveVersion}
    </MotifChip>
  ) : (
    <Button
      onClick={(e) => {
        onDownloadClickHandler(node);
      }}
      variant="text"
      className={"donwload-archived-file-button"}
    >
      <DownloadIcon />
      <span>{DownloadBtn}</span>
    </Button>
  );
}
