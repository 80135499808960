import axios from "axios";

export async function get(url) {
  return await axios
    .get(`${url}`)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error("Error in response - " + res);
    })
    .catch((err) => {
      throw err;
    });
}

export async function post(url, data = null) {
  return await axios
    .post(`${url}`, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      }
      throw new Error("Error in response - " + res);
    })
    .catch((err) => {
      throw err;
    });
}

export async function getListCrossRegion(url) {
  const axiosResponse = await Promise.allSettled([
    axios.get(`${process.env.REACT_APP_API_URL_REG_US}${url}`),
  ]);

  let response = [];

  const validData = axiosResponse.map((res) => {
    if (res.status === "fulfilled") {
      return res.value.data.data;
    }

    if (res.status === "rejected") {
      console.error("Error in response", res);
    }
  });

  if (validData && validData.length) {
    response = validData.flat(1).filter((x) => x);
  }

  return response;
}
