import React from "react";

export function StepperActiveStage() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_29567_1210)">
        <rect width="32" height="32" rx="16" fill="#ffe600" />
        <circle cx="16" cy="16" r="5" fill="#2E2E38" />
      </g>
      <defs>
        <clipPath id="clip0_29567_1210">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
