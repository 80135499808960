export const ENGAGEMENT_URL = {
  SaveUploadedFileDetail: `Engagement/SaveUploadedFileDetail`,
  UpdateEngagement: `Engagement/UpdateEngagement`,
  GetAllUploadedFiles: `Engagement/GetAll/GetAllUploadedFiles`,
  GetEngagement: `Engagement/GetEngagementById`,
  GetAllEngagements: `Engagement/GetAll/GetAllEngagements`,
  CreateEngagement: `Engagement/CreateEngagement`,
  GetAllProjects: `Engagement/GetAllProjects`,
  UpdateProject: `Engagement/UpdateProject`,
  GetAllMasterSector: `Engagement/GetMasterSector/GetAllMasterSector`,
  SaveSector: `Engagement/SaveSector`,
  GetAllsectorL: `Engagement/GetAllSector/GetAllSector`,
  GetAllEngagementTeam: `Engagement/GetAllTeam/GetAllEngagementTeam`,
  SaveEngagementTeam: `Engagement/SaveEngagementTeamMember`,
  GetStepperSteps: `Engagement/GetMasterSetupStep/GetAllMasterSetupStep`,
  GetAllRecordsBySpecification: `Engagement/GetAllSpecs/GetAllRecordsBySpecification`,
  GetSaSToken: `Engagement/UploadFileToBlob`,
  GetUploadedFileProgress: `Engagement/GetUploadedFileProgress/GetUploadedFileProgress`,
  GetHierarchicalLevelData: `Engagement/GetHierarchicalLevelData`,
  GetTargetValueByScope: `Engagement/GetTargetValueByScope`,
  GetEmisionValueByScope: `Engagement/GetEmisionValueByScope`,
  DeleteEngagementTeamMember: `Engagement/DeleteEngagementTeamMember`,
  CheckIfEngagementCodeExist: `Engagement/CheckIfEngagementCodeExist`,
  GetHeadersbyModuleForValidation: `Engagement/GetHeadersbyModuleForValidation`,
  UpdateForecastEndYear: `Engagement/UpdateForecastEndYear`,
  UpdateMostRecentYear: `Engagement/UpdateMostRecentYear`,
  UpdateSkipForecast: `Engagement/UpdateSkipForecast`,
  CalculateEmissions: `Engagement/CalculateEmissions/CalculateEmissions`,
  GetDataValidationModule1Data: `Engagement/GetDataValidationModule1Data`,
  GetDataValidationModule2Data: `Engagement/GetDataValidationModule2Data`,
  resetM2Data: `Engagement/DeleteM2Data`,
  GetCopletedCountByEngagement: `Engagement/GetCopletedCountByEngagement`,
  GetM1FileUploadVersionHistory: `Engagement/GetM1FileUploadVersionHistory`,
  GetM2FileUploadVersionHistory: `Engagement/GetM2FileUploadVersionHistory`,
  GetConsentFlag: `Engagement/GetPIAConsentFlag`,
  UpdateConsentFlag: `Engagement/SavePIAConsent`,
  GetGMPDataURL: `Engagement/GetGMPDetails`,
  GetRegions: `Engagement/GetRegions`,
  SyncModulebyData: `Engagement/SyncDataByModule`,
  ReleaseAlllocks: `Engagement/ReleaseAlllocks`,
  GetAllFlags: `Engagement/GetAllFlagsValue`,
};
