import { ENGAGEMENT_URL } from "./endpoints/engagement";
import { get, post, getListCrossRegion } from "./base.service";

async function saveManageEmissions({ request }) {
  return await post(ENGAGEMENT_URL.SaveUploadedFileDetail, request);
}

async function updateEngagement({ request, url = "" }) {
  return await post(`${url}${ENGAGEMENT_URL.UpdateEngagement}`, request);
}

async function getUploadedFiles({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetAllUploadedFiles}?EngagementID=${request.id}`
  );
}

async function getEngagement({ request }) {
  return await get(`${ENGAGEMENT_URL.GetEngagement}?id=${request.id}`);
}

async function GetAllEngagements({ request }) {
  return await getListCrossRegion(
    `${ENGAGEMENT_URL.GetAllEngagements}?CreatedBy=${request.email}`
  );
}

async function createEngagement({ request }) {
  return await post(ENGAGEMENT_URL.CreateEngagement, request);
}

async function getAllProjects({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetAllProjects}?EngagementID=${request.id}`
  );
}

async function updateProject({ request }) {
  return await post(`${ENGAGEMENT_URL.UpdateProject}`, request.data);
}

async function getAllMasterSector() {
  return await get(ENGAGEMENT_URL.GetAllMasterSector);
}

async function saveSector({ request }) {
  return await post(ENGAGEMENT_URL.SaveSector, request);
}

async function getAllSector({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetAllsectorL}?EngagementId=${request.id}`
  );
}

async function getAllEngagementTeam({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetAllEngagementTeam}?EngagementId=${request.id}`
  );
}

async function saveTeamMember({ request }) {
  return await post(`${ENGAGEMENT_URL.SaveEngagementTeam}`, request);
}

async function getStepperSteps() {
  return await get(
    process.env.REACT_APP_API_URL_REG_US + ENGAGEMENT_URL.GetStepperSteps
  );
}

async function getAllSpecRecords({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetAllRecordsBySpecification}?Specification=${request.specification}`
  );
}

async function getUploadedFileProgress({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetUploadedFileProgress}?EngagementID=${request.engagementId}`
  );
}

async function getHierarchyLevelData({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetHierarchicalLevelData}?EnagagementName=${request.engagement}&ClientName=${request.clientName}`
  );
}

async function getTargetValueByScope({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetTargetValueByScope}?Scope=${request.scope}&Type=${request.type}&EngagementCode=${request.engagementCode}`
  );
}

async function GetEmisionValueByScope({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetEmisionValueByScope}?Scope=${request.scope}&Type=${request.type}&EngagementCode=${request.engagementCode}`
  );
}

async function deleteEngagementTeamMember({ request }) {
  return await post(
    `${ENGAGEMENT_URL.DeleteEngagementTeamMember}?engagementId=${request.engagementId}&engagementTeamId=${request.engagementTeamMemberId}`
  );
}
async function checkIfEngagementCodeExist({ request }) {
  return await post(
    `${ENGAGEMENT_URL.CheckIfEngagementCodeExist}?engagementCode=${request.engCode}`
  );
}

async function getHeadersbyModuleForValidation({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetHeadersbyModuleForValidation}?moduleName=${request.moduleName}`
  );
}

async function updateForecastEndYear({ request }) {
  return await post(
    `${ENGAGEMENT_URL.UpdateForecastEndYear}?engagementId=${request.engagementId}&forecastEndYear=${request.forecastEndYear}`
  );
}

async function updateSkipForecast({ request }) {
  return await post(
    `${ENGAGEMENT_URL.UpdateSkipForecast}?engagementId=${request.engagementId}&skipvalue=${request.skipvalue}`
  );
}

async function updateMostRecentEndYear({ request }) {
  return await post(
    `${ENGAGEMENT_URL.UpdateMostRecentYear}?engagementId=${request.engagementId}&MostRecentYear=${request.mostRecentYear}`
  );
}

async function calculateEmissions({ request }) {
  return await get(
    `${ENGAGEMENT_URL.CalculateEmissions}?engagementId=${request.engagementId}&Scope=${request.scope}`
  );
}

async function getDataValidationModule1Data({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetDataValidationModule1Data}?&engagementCode=${request.engagementCode}`
  );
}

async function getDataValidationModule2Data({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetDataValidationModule2Data}?&engagementCode=${request.engagementCode}`
  );
}

async function resetM2Data({ request }) {
  return await post(
    `${ENGAGEMENT_URL.resetM2Data}?EngagementID=${request.engagementId}`
  );
}

async function getCopletedCountByEngagement({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetCopletedCountByEngagement}?Id=${request.engagementId}`
  );
}

async function getM1FileUploadVersionHistory({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetM1FileUploadVersionHistory}?engagementID=${request.engagementId}`
  );
}

async function getM2FileUploadVersionHistory({ request }) {
  return await get(
    `${ENGAGEMENT_URL.GetM2FileUploadVersionHistory}?engagementID=${request.engagementId}`
  );
}

async function getConsentData({ request }) {
  return await get(`${ENGAGEMENT_URL.GetConsentFlag}?EmailId=${request.email}`);
}

async function updateConsentData({ request }) {
  return await post(
    `${ENGAGEMENT_URL.UpdateConsentFlag}?userEmailId=${request}`
  );
}

async function getRegions() {
  return await get(
    `${process.env.REACT_APP_API_URL_REG_US}${ENGAGEMENT_URL.GetRegions}`
  );
}

async function syncModulebyData({ request }) {
  return await post(
    `${ENGAGEMENT_URL.SyncModulebyData}?EngagementCode=${request.engCode}&ModuleName=${request.moduleName}`
  );
}

async function releaseAlllocks({ request }) {
  return await post(
    `${ENGAGEMENT_URL.ReleaseAlllocks}?userEmailId=${request.email}`
  );
}

async function getAllFlags({ request }) {
  return await post(
    `${ENGAGEMENT_URL.GetAllFlags}?EngagementID=${request.engagementId}&tableName=${request.tableName}`
  );
}

export {
  saveManageEmissions,
  updateEngagement,
  getUploadedFiles,
  getEngagement,
  createEngagement,
  getAllProjects,
  updateProject,
  getAllMasterSector,
  saveSector,
  getAllSector,
  getAllEngagementTeam,
  saveTeamMember,
  GetAllEngagements,
  getStepperSteps,
  getAllSpecRecords,
  getUploadedFileProgress,
  getHierarchyLevelData,
  // getGMPData,
  getTargetValueByScope,
  GetEmisionValueByScope,
  deleteEngagementTeamMember,
  checkIfEngagementCodeExist,
  getHeadersbyModuleForValidation,
  updateForecastEndYear,
  updateSkipForecast,
  updateMostRecentEndYear,
  calculateEmissions,
  getDataValidationModule1Data,
  getDataValidationModule2Data,
  resetM2Data,
  getCopletedCountByEngagement,
  getM1FileUploadVersionHistory,
  getM2FileUploadVersionHistory,
  getConsentData,
  updateConsentData,
  getRegions,
  syncModulebyData,
  releaseAlllocks,
  getAllFlags,
};
