import React from "react";
import { MotifFormField, MotifToggleSwitch } from "@ey-xd/motif-react";

export function ToggleSwitch({
  offLabel,
  onToggle,
  onLabel,
  toggleChecked,
  value,
  id,
  disabled,
}) {
  return (
    <MotifFormField>
      <MotifToggleSwitch
        offLabel={offLabel}
        onChange={onToggle}
        onLabel={onLabel}
        checked={toggleChecked}
        disabled={disabled}
        value={value}
        id={id}
      ></MotifToggleSwitch>
    </MotifFormField>
  );
}
