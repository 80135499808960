import React from "react";
import "./SetupDeleteModal.scss";
import { Button } from "../Button/Button";
import { WarningIcon } from "../../icons";
import {
  setupRemoveUserModalHeader,
  setupRemoveUserModalBody,
  CancelBtn,
} from "../../util/constants";
import { Modal } from "../Modal/Modal";

export function UserDeleteModal({ isModalVisible, onClose, onConfirm }) {
  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      body={<span className="body-content">{setupRemoveUserModalBody}</span>}
      header={
        <div className="file-version-history-modal-header">
          <div className="motif-fab">
            <WarningIcon />
          </div>
          <span>{setupRemoveUserModalHeader}</span>
        </div>
      }
      footer={
        <div className="view-endagement-delete-modal-footer">
          <Button variant="secondary" onClick={onClose}>
            {CancelBtn}
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            {"Yes, remove"}
          </Button>
        </div>
      }
    />
  );
}
