import React, { memo } from "react";
import { MotifChipGroup, MotifChip } from "@ey-xd/motif-react";

export default memo(({ node, spec }) => {
  let data = [];
  if (spec === "emission") {
    data = [...node.data.emissionSpec];
  }
  if (spec === "geo") {
    data = [...node.data.geoSpec];
    for (let i = 0; i < data.length; i++) {
      if (data[i] === "All-All-All") {
        data.splice(i, 1);
      }
    }
  }
  if (spec === "org") {
    data = [...node.data.orgSpec];
    for (let i = 0; i < data.length; i++) {
      if (data[i] === "All-All-All") {
        data.splice(i, 1);
      }
    }
  }
  if (spec === "custom") {
    data = [...node.data.customSpec];
    for (let i = 0; i < data.length; i++) {
      if (data[i] === "All-All-All") {
        data.splice(i, 1);
      }
    }
  }
  if (data.length === 0) {
    return <span>-</span>;
  }
  if (data.length === 1 && data[0] === "-") {
    return <span>-</span>;
  }
  if (data.length === 1 && data[0] !== "-") {
    return <MotifChip title={data[0]}>{data[0]}</MotifChip>;
  } else {
    return (
      <MotifChipGroup>
        <MotifChip title={data[0]}>{data[0]}</MotifChip>
        <MotifChip title={data.slice(1).join(", ")}>
          {"+" + (data.length - 1)}
        </MotifChip>
      </MotifChipGroup>
    );
  }
});
