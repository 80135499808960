import React from "react";

export function BiglogLogo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="24" fill="#2E2E38" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 17.25C24.4142 17.25 24.75 17.5858 24.75 18L24.75 23.25H30C30.4142 23.25 30.75 23.5858 30.75 24C30.75 24.4142 30.4142 24.75 30 24.75H24C23.5858 24.75 23.25 24.4142 23.25 24L23.25 18C23.25 17.5858 23.5858 17.25 24 17.25Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 14.75C18.8914 14.75 14.75 18.8914 14.75 24C14.75 29.1086 18.8914 33.25 24 33.25C29.1086 33.25 33.25 29.1086 33.25 24C33.25 18.8914 29.1086 14.75 24 14.75ZM13.25 24C13.25 18.0629 18.0629 13.25 24 13.25C29.9371 13.25 34.75 18.0629 34.75 24C34.75 29.9371 29.9371 34.75 24 34.75C18.0629 34.75 13.25 29.9371 13.25 24Z"
        fill="#A4A3B1"
      />
    </svg>
  );
}
