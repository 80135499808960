import "./AnalyseHeading.scss";
import { useSelector } from "react-redux";

export function AnalyseHeading() {
  const engagement = useSelector((state) => state.engagement.engagement);

  return (
    <div className="analyse-heading">
      <span className="analyse-heading-main">{engagement.engagementName}</span>
      <span className="analyse-heading-desc">{engagement.clientName}</span>
    </div>
  );
}
