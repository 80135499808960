import { Modal } from "../../../Modal/Modal";
import "./ChangeForecastEndYearModal.scss";
import {
  CancelBtn,
  changeFEYModalBody,
  changeFEYModalHeader,
  YesChangeBtn,
} from "../../../../util/constants";
import { Button } from "../../../Button/Button";
import { EditIcon } from "../../../../icons";

function ModalHeader() {
  return (
    <div className="file-version-history-modal-header">
      <div className="motif-fab">
        <EditIcon />
      </div>
      <span>{changeFEYModalHeader}</span>
    </div>
  );
}

function ModalBody() {
  return <span>{changeFEYModalBody}</span>;
}

function ModalFooter({ handleCancelClick, onConfirm }) {
  return (
    <div className="view-endagement-delete-modal-footer">
      <Button variant="secondary" onClick={handleCancelClick}>
        {CancelBtn}
      </Button>
      <Button onClick={onConfirm} variant="primary">
        {YesChangeBtn}
      </Button>
    </div>
  );
}

export function ChangeForecastEndYearModal({
  isModalVisible,
  onClose,
  onConfirm,
}) {
  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={<ModalHeader />}
      body={<ModalBody />}
      footer={<ModalFooter handleCancelClick={onClose} onConfirm={onConfirm} />}
      className={"change-fey-modal"}
    />
  );
}
