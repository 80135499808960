import { useSelector } from "react-redux";
import "./ManageEmissionsHeading.scss";

export function ManageEmissionsHeading() {
  const engagement = useSelector((state) => state.engagement.engagement);

  return (
    <div className="manage-emissions-heading">
      <span className="manage-emissions-heading-main">
        {engagement.engagementName}
      </span>
      <span className="manage-emissions-heading-desc">
        {engagement.clientName}
      </span>
    </div>
  );
}
