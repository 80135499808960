import React, { useState, useEffect } from "react";
import "./AddEmissionModal.scss";
import {
  CancelBtn,
  SaveBtn,
  scope,
  selectEmission,
  subcat1,
  subcat2,
  subcat3,
} from "../../../../util/constants";
import { Select } from "../../../Select/Select";
import { Button } from "../../../Button/Button";
import { Modal } from "../../../Modal/Modal";
import { LoadingIndicator } from "../../../LoadingIndicator/LoadingIndicator";

export function EmissionModal({
  isModalVisible,
  onClose,
  hierarchyData,
  emissionData,
  onConfirm,
  onEdit,
}) {
  const [scopevalue, setScope] = useState([]);
  const [subCategory1, setSubCategory1] = useState([]);
  const [subCategory2, setSubCategory2] = useState([]);
  const [subCategory3, setSubCategory3] = useState([]);
  const [options, setScopeOptions] = useState();
  const [emissionLevel1Array, setEmissionlevel1Array] = useState([]);
  const [emissionLevel2Array, setEmissionlevel2Array] = useState([]);
  const [emissionLevel3Array, setEmissionlevel3Array] = useState([]);
  const [disablesave1, setDisableSave1] = useState(true);
  const [disableLevel2, setDisableLevel2] = useState(true);
  const [disableLevel3, setDisableLevel3] = useState(true);
  const [disableSave, setDisableSave] = useState(true);
  const [level1SelectAll, setlevel1SelectAll] = useState(false);
  const [level2SelectAll, setlevel2SelectAll] = useState(false);
  const [selectAllLevel, setSelectAllLevel] = useState("");

  // Function to load the hierarchies for new and Edit forecasts for emission dropdowns
  async function GetAllHierarchyData() {
    if (hierarchyData) {
      let scopeArray = [
        ...new Set(hierarchyData.map((element) => element.scope)),
      ];
      scopeArray.sort();
      setScopeOptions(scopeArray);
      if (emissionData?.scopeArray) {
        await handleScopeChange(emissionData.scopeArray);
      }
      if (emissionData?.category_level3) {
        await handleEditModal(emissionData);
      }
    }
  }

  // Function to load the Emission dropdowns for Edit forecast
  async function handleEditModal(emissionData) {
    setSubCategory1(emissionData.category_level1);
    setEmissionlevel2Array(emissionData.category_level2);
    setEmissionlevel3Array(emissionData.category_level3);
    setSubCategory2(emissionData.category_level2);
    setSubCategory3(emissionData.category_level3);
    setDisableSave(true);
    let selectAll = "";
    let filteredByScopeArray = hierarchyData.filter((element) =>
      emissionData?.scopeArray.includes(element.scope)
    );
    let emissionLevel1 = [
      ...new Set(
        filteredByScopeArray.map((element) => element.category_level1)
      ),
    ];

    if (emissionLevel1.length === emissionData.category_level1.length) {
      selectAll = "subCategory1";
    } else {
      let filteredByScopeArray = hierarchyData.filter(
        (element) =>
          emissionData.category_level1.includes(element.category_level1) &&
          scopevalue.includes(element.scope)
      );
      let emissionLevel2 = [
        ...new Set(
          filteredByScopeArray.map((element) => element.category_level2)
        ),
      ];

      if (emissionLevel2.length === emissionData.category_level2.length) {
        selectAll = "subCategory2";
      } else {
        let filteredArray = filteredByScopeArray.filter(
          (element) =>
            emissionData.category_level2.includes(element.category_level2) &&
            scopevalue.includes(element.scope)
        );

        let emissionLevel3 = [
          ...new Set(filteredArray.map((element) => element.category_level3)),
        ];
        if (emissionLevel3.length === emissionData.category_level3.length) {
          selectAll = "subCategory3";
        }
      }
    }
    await handleEdit(selectAll);
  }
  async function handleScopeChange(event) {
    setScope(event);
    setDisableSave(true);
    setSubCategory1([]);
    setSubCategory2([]);
    setSubCategory3([]);
    setDisableLevel2(true);
    setDisableLevel3(true);
    setlevel1SelectAll(false);
    let filteredByScopeArray = hierarchyData.filter((element) =>
      event.includes(element.scope)
    );
    let emissionLevel1 = [
      ...new Set(
        filteredByScopeArray.map((element) => element.category_level1)
      ),
    ];
    emissionLevel1 = emissionLevel1.filter((element) => element !== null);
    if (emissionLevel1.length === 0) {
      emissionLevel1.push("N/A");
    }
    await setEmissionlevel1Array(emissionLevel1);
    setDisableSave1(false);
  }
  async function handleLevel1Change(event) {
    await setSubCategory1(event);
    setSubCategory2([]);
    setSubCategory3([]);
    setDisableSave(true);
    setDisableLevel3(true);
    setlevel1SelectAll(false);
    let filteredByScopeArray = hierarchyData.filter(
      (element) =>
        event.includes(element.category_level1) &&
        scopevalue.includes(element.scope)
    );
    let emissionLevel2 = [
      ...new Set(
        filteredByScopeArray.map((element) => element.category_level2)
      ),
    ];
    emissionLevel2 = emissionLevel2.filter((element) => element !== null);
    if (emissionLevel2.length === 0) {
      emissionLevel2.push("N/A");
    }
    await setEmissionlevel2Array(emissionLevel2);
    if (emissionLevel2.length === 1 && emissionLevel2[0] !== "N/A") {
      handleLevel2Change(emissionLevel2, event);
    }
    if (
      emissionLevel1Array.length === event.length &&
      emissionLevel1Array.length !== 1
    ) {
      setlevel1SelectAll(true);
      setSelectAllLevel("subCategory1");
    } else {
      setSelectAllLevel("");
      event.length > 0 ? setDisableLevel2(false) : setDisableLevel2(true);
    }
  }
  async function handleLevel2Change(event, level1) {
    setSubCategory3([]);
    setSubCategory2(event);
    setDisableSave(true);
    setlevel2SelectAll(false);
    let level1selected = [];
    if (level1?.length > 0) {
      level1selected = level1;
    } else {
      level1selected = subCategory1;
    }
    let array = hierarchyData.filter(
      (element) =>
        level1selected.includes(element.category_level1) &&
        scopevalue.includes(element.scope)
    );
    let filteredByScopeArray = array.filter(
      (element) =>
        event.includes(element.category_level2) &&
        scopevalue.includes(element.scope)
    );

    let emissionLevel3 = [
      ...new Set(
        filteredByScopeArray.map((element) => element.category_level3)
      ),
    ];
    emissionLevel3 = emissionLevel3.filter((element) => element !== null);

    if (emissionLevel3.length === 0) {
      emissionLevel3.push("N/A");
    }
    await setEmissionlevel3Array(emissionLevel3);
    if (emissionLevel3.length === 1 && emissionLevel3[0] !== "N/A") {
      handleLevel3Change(emissionLevel3);
    }
    if (
      event.length === emissionLevel2Array.length &&
      emissionLevel2Array.length !== 1
    ) {
      handleLevel3Change(emissionLevel3);
      setDisableLevel3(true);
      if (selectAllLevel === "") {
        setSelectAllLevel("subCategory2");
      }
    } else {
      setSelectAllLevel("");
      event.length > 0 ? setDisableLevel3(false) : setDisableLevel3(true);
    }
  }
  async function handleLevel3Change(event) {
    await setSubCategory3(event);
    if (
      event.length === emissionLevel3Array.length &&
      emissionLevel3Array.length !== 1 &&
      selectAllLevel === ""
    ) {
      setSelectAllLevel("subCategory3");
    }
    event.length > 0 ? setDisableSave(false) : setDisableSave(true);
  }
  async function processLevel2forLevel1SelectAll() {
    await handleLevel2Change(emissionLevel2Array);
    emissionLevel2Array.length > 1
      ? setDisableLevel2(true)
      : setDisableLevel2(false);
  }
  async function processLevel3forLevel2SelectAll() {
    handleLevel3Change(emissionLevel3Array);
    setDisableLevel3(true);
  }

  useEffect(() => {
    GetAllHierarchyData();
  }, [hierarchyData, emissionData]);

  useEffect(() => {
    if (level1SelectAll) {
      processLevel2forLevel1SelectAll();
    }
  }, [level1SelectAll]);
  useEffect(() => {
    if (!level1SelectAll) {
      if (level2SelectAll) {
        processLevel3forLevel2SelectAll();
      }
    }
  }, [level2SelectAll]);

  async function handleAllpayload(selectAll) {
    let array = [];
    let filteredByScopeArray = [];
    if (emissionData.scopeArray) {
      filteredByScopeArray = hierarchyData.filter((element) =>
        emissionData.scopeArray.includes(element.scope)
      );
    } else {
      filteredByScopeArray = hierarchyData.filter((element) =>
        scopevalue.includes(element.scope)
      );
    }

    if (selectAllLevel === "subCategory1") {
      scopevalue.forEach((scope) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (scope === filteredByScopeArray[i].scope) {
            let record = {
              scope: scope,
              category_level1: "All",
              category_level2: "All",
              category_level3: "All",
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAll === "subCategory1" && emissionData?.scopeArray) {
      emissionData.scopeArray.forEach((scope) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (scope === filteredByScopeArray[i].scope) {
            let record = {
              scope: scope,
              category_level1: "All",
              category_level2: "All",
              category_level3: "All",
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAllLevel === "subCategory2") {
      subCategory1.forEach((record) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            record === filteredByScopeArray[i].category_level1 &&
            scopevalue.includes(filteredByScopeArray[i].scope)
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: "All",
              category_level3: "All",
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAll === "subCategory2" && emissionData?.scopeArray) {
      emissionData.category_level1.forEach((record) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            record === filteredByScopeArray[i].category_level1 &&
            emissionData.scopeArray.includes(filteredByScopeArray[i].scope)
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: "All",
              category_level3: "All",
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAllLevel === "subCategory3") {
      subCategory2.forEach((record) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            record === filteredByScopeArray[i].category_level2 &&
            scopevalue.includes(filteredByScopeArray[i].scope) &&
            subCategory1.includes(filteredByScopeArray[i].category_level1)
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: "All",
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    if (selectAll === "subCategory3" && emissionData?.scopeArray) {
      emissionData.category_level2.forEach((record) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            record === filteredByScopeArray[i].category_level2 &&
            emissionData.scopeArray.includes(filteredByScopeArray[i].scope) &&
            emissionData.category_level1.includes(
              filteredByScopeArray[i].category_level1
            )
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: "All",
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            array.push(record);
          }
        }
      });
    }
    return array;
  }
  async function processData(functionality) {
    let uniqueEmiRecords = [];
    let filteredByScopeArray = hierarchyData.filter((element) =>
      scopevalue.includes(element.scope)
    );
    if (functionality === "Save") {
      if (subCategory3.length === 1 && subCategory3[0] === "N/A") {
        if (subCategory2.length === 1 && subCategory2[0] === "N/A") {
          if (subCategory1.length === 1 && subCategory1[0] === "N/A") {
            let record = {
              scope: scopevalue[0],
              category_level1: "",
              category_level2: "",
              category_level3: "",
            };
            uniqueEmiRecords.push(record);
          } else {
            subCategory1.forEach((value) => {
              let index = filteredByScopeArray.findIndex(
                (element) =>
                  element.category_level1 === value &&
                  scopevalue.includes(element.scope)
              );
              let record = {
                scope: scopevalue[0],
                category_level1: filteredByScopeArray[index]["category_level1"],
                category_level2: filteredByScopeArray[index]["category_level2"],
                category_level3: filteredByScopeArray[index]["category_level3"],
                entity_level1: filteredByScopeArray[index]["entity_level1"],
                entity_level2: filteredByScopeArray[index]["entity_level2"],
                entity_level3: filteredByScopeArray[index]["entity_level3"],
                entity_level4: filteredByScopeArray[index]["entity_level4"],
                region_level1: filteredByScopeArray[index]["region_level1"],
                region_level2: filteredByScopeArray[index]["region_level2"],
                region_level3: filteredByScopeArray[index]["region_level3"],
              };
              uniqueEmiRecords.push(record);
            });
          }
        } else {
          subCategory2.forEach((value) => {
            if (value !== "N/A") {
              let index = filteredByScopeArray.findIndex(
                (element) =>
                  element.category_level2 === value &&
                  scopevalue.includes(element.scope)
              );
              let record = {
                scope: filteredByScopeArray[index]["scope"],
                category_level1: filteredByScopeArray[index]["category_level1"],
                category_level2: filteredByScopeArray[index]["category_level2"],
                category_level3: filteredByScopeArray[index]["category_level3"],
                entity_level1: filteredByScopeArray[index]["entity_level1"],
                entity_level2: filteredByScopeArray[index]["entity_level2"],
                entity_level3: filteredByScopeArray[index]["entity_level3"],
                entity_level4: filteredByScopeArray[index]["entity_level4"],
                region_level1: filteredByScopeArray[index]["region_level1"],
                region_level2: filteredByScopeArray[index]["region_level2"],
                region_level3: filteredByScopeArray[index]["region_level3"],
              };
              uniqueEmiRecords.push(record);
            }
          });
        }
      } else {
        subCategory3.forEach((value) => {
          for (let i = 0; i < filteredByScopeArray.length; i++) {
            if (
              value === filteredByScopeArray[i].category_level3 &&
              scopevalue.includes(filteredByScopeArray[i].scope) &&
              subCategory1.includes(filteredByScopeArray[i].category_level1) &&
              subCategory2.includes(filteredByScopeArray[i].category_level2)
            ) {
              let record = {
                scope: filteredByScopeArray[i].scope,
                category_level1: filteredByScopeArray[i].category_level1,
                category_level2: filteredByScopeArray[i].category_level2,
                category_level3: value,
                region_level1: filteredByScopeArray[i].region_level1,
                region_level2: filteredByScopeArray[i].region_level2,
                region_level3: filteredByScopeArray[i].region_level3,
                entity_level1: filteredByScopeArray[i]["entity_level1"],
                entity_level2: filteredByScopeArray[i]["entity_level2"],
                entity_level3: filteredByScopeArray[i]["entity_level3"],
                entity_level4: filteredByScopeArray[i]["entity_level4"],
                custom_specification1:
                  filteredByScopeArray[i]["custom_specification1"],
                custom_specification2:
                  filteredByScopeArray[i]["custom_specification2"],
                custom_specification3:
                  filteredByScopeArray[i]["custom_specification3"],
              };
              uniqueEmiRecords.push(record);
            }
          }
        });
      }
    }
    if (functionality === "Edit") {
      filteredByScopeArray = hierarchyData.filter((element) =>
        emissionData.scopeArray.includes(element.scope)
      );
      emissionData.category_level3.forEach((value) => {
        for (let i = 0; i < filteredByScopeArray.length; i++) {
          if (
            value === filteredByScopeArray[i].category_level3 &&
            emissionData.scopeArray.includes(filteredByScopeArray[i].scope) &&
            emissionData.category_level1.includes(
              filteredByScopeArray[i].category_level1
            ) &&
            emissionData.category_level2.includes(
              filteredByScopeArray[i].category_level2
            )
          ) {
            let record = {
              scope: filteredByScopeArray[i].scope,
              category_level1: filteredByScopeArray[i].category_level1,
              category_level2: filteredByScopeArray[i].category_level2,
              category_level3: value,
              region_level1: filteredByScopeArray[i].region_level1,
              region_level2: filteredByScopeArray[i].region_level2,
              region_level3: filteredByScopeArray[i].region_level3,
              entity_level1: filteredByScopeArray[i]["entity_level1"],
              entity_level2: filteredByScopeArray[i]["entity_level2"],
              entity_level3: filteredByScopeArray[i]["entity_level3"],
              entity_level4: filteredByScopeArray[i]["entity_level4"],
              custom_specification1:
                filteredByScopeArray[i]["custom_specification1"],
              custom_specification2:
                filteredByScopeArray[i]["custom_specification2"],
              custom_specification3:
                filteredByScopeArray[i]["custom_specification3"],
            };
            uniqueEmiRecords.push(record);
          }
        }
      });
    }
    return uniqueEmiRecords;
  }
  async function handleSave() {
    const uniqueRecords = await processData("Save");
    const allRecords = await handleAllpayload(selectAllLevel);
    onConfirm({
      records: uniqueRecords,
      scope: scopevalue,
      category_level1: subCategory1,
      category_level2: subCategory2,
      category_level3: subCategory3,
      allPayload: allRecords.length > 0 ? allRecords : uniqueRecords,
      selectAll: selectAllLevel,
      functionality: "Save",
    });
  }
  async function handleEdit(selectAll) {
    const uniqueRecords = await processData("Edit");
    const allRecords = await handleAllpayload(selectAll);
    onEdit({
      records: uniqueRecords,
      scope: emissionData.scopeArray,
      category_level1: emissionData.category_level1,
      category_level2: emissionData.category_level2,
      category_level3: emissionData.category_level3,
      allPayload: allRecords.length > 0 ? allRecords : uniqueRecords,
      selectAll: selectAll,
    });
  }

  return (
    <Modal
      show={isModalVisible}
      onClose={onClose}
      header={selectEmission}
      body={
        <div className="add-custom-modal-body">
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {scope}
              <span className="required-star">*</span>
            </span>
            <Select
              value={scopevalue}
              options={options}
              visibleOptions={3}
              multiple={true}
              onChange={handleScopeChange}
              showSelectAllButton={true}
            ></Select>
          </div>
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {subcat1}
              <span className="required-star">*</span>
            </span>

            <Select
              value={subCategory1}
              options={emissionLevel1Array}
              multiple={true}
              visibleOptions={3}
              onChange={handleLevel1Change}
              showSelectAllButton={true}
              disabled={disablesave1}
            ></Select>
          </div>
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {subcat2}
              <span className="required-star">*</span>
            </span>

            <Select
              value={subCategory2}
              options={emissionLevel2Array}
              onChange={handleLevel2Change}
              multiple={true}
              visibleOptions={3}
              disabled={disableLevel2}
              showSelectAllButton={true}
            ></Select>
          </div>
          <div className="add-custom-modal-body-row">
            <span className="add-custom-modal-body-row-title">
              {subcat3}
              <span className="required-star">*</span>
            </span>
            {!disableLevel3 && emissionLevel3Array.length === 0 ? (
              <LoadingIndicator
                show={true}
                fullscreen={false}
              ></LoadingIndicator>
            ) : (
              <Select
                value={subCategory3}
                options={emissionLevel3Array}
                onChange={handleLevel3Change}
                multiple={true}
                visibleOptions={3}
                disabled={disableLevel3}
                showSelectAllButton={true}
              ></Select>
            )}
          </div>
        </div>
      }
      footer={
        <div className="forecast-modal-footer">
          <Button variant="secondary" onClick={() => onClose([])}>
            {CancelBtn}
          </Button>
          <Button
            variant="primary"
            className="save"
            onClick={handleSave}
            disabled={disableSave}
          >
            {SaveBtn}
          </Button>
        </div>
      }
    />
  );
}
