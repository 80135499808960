import React from "react";
import "./SideMenu.scss";
import {
  MotifVerticalNavigationContent,
  MotifVerticalNavigationMenu,
  MotifVerticalNavigationMenuItem,
  MotifVerticalNavigation,
} from "@ey-xd/motif-react";
import { BookIcon, FolderIcon, HomeIcon } from "../../icons";
import { routes } from "../../routes/routes.constatns";
import { releaseAlllocks } from "../../store/services/engagement.service";
import { useSelector, useDispatch } from "react-redux";
import { setDefaultLoader } from "../../store/slices/engagementSlice";

export function SideMenu({ isSideMenuCollapsed }) {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  /* istanbul ignore next */
  async function handleSideMenuClick(route) {
    dispatch(setDefaultLoader({ loader: true }));
    await releaseAlllocks({ request: { email: user.username } });
    dispatch(setDefaultLoader({ loader: false }));
    window.location.href = `${process.env.REACT_APP_URL_REG_ALL}${route}`;
  }

  return (
    <MotifVerticalNavigation
      collapse={isSideMenuCollapsed}
      className="decarb-side-menu"
    >
      <MotifVerticalNavigationContent>
        <MotifVerticalNavigationMenu>
          <MotifVerticalNavigationMenuItem
            icon={<HomeIcon />}
            label="Home"
            onClick={() => handleSideMenuClick(routes.home)}
          >
            Home
          </MotifVerticalNavigationMenuItem>
          <MotifVerticalNavigationMenuItem
            icon={<FolderIcon />}
            label="Engagement Dashboard"
            onClick={() => handleSideMenuClick(`${routes.viewEngagement}`)}
          >
            Engagement Dashboard
          </MotifVerticalNavigationMenuItem>
          <MotifVerticalNavigationMenuItem
            icon={<BookIcon />}
            label="Decarbonization projects library"
            onClick={() =>
              window.open(
                window.location.origin + `/${routes.decarbProjectsLibrary}`,
                "mywindow",
                "location=0,menubar=0,status=0,scrollbars=0,width=900,height=400"
              )
            }
          >
            Decarbonization projects library
          </MotifVerticalNavigationMenuItem>
        </MotifVerticalNavigationMenu>
      </MotifVerticalNavigationContent>
    </MotifVerticalNavigation>
  );
}
