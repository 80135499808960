import React from "react";

export function FeatureIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <rect width="48" height="48" rx="24" fill="#2E2E38" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 18.25C24.4142 18.25 24.75 18.5858 24.75 19V25C24.75 25.4142 24.4142 25.75 24 25.75C23.5858 25.75 23.25 25.4142 23.25 25V19C23.25 18.5858 23.5858 18.25 24 18.25Z"
        fill="#A4A3B1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.5117 28.4414C24.8196 28.7185 24.8446 29.1927 24.5675 29.5006L24.5575 29.5117C24.2804 29.8196 23.8062 29.8446 23.4983 29.5675C23.1904 29.2904 23.1654 28.8162 23.4425 28.5083L23.4525 28.4972C23.7296 28.1893 24.2038 28.1643 24.5117 28.4414Z"
        fill="#A4A3B1"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 14.75C18.8914 14.75 14.75 18.8914 14.75 24C14.75 29.1086 18.8914 33.25 24 33.25C29.1086 33.25 33.25 29.1086 33.25 24C33.25 18.8914 29.1086 14.75 24 14.75ZM13.25 24C13.25 18.0629 18.0629 13.25 24 13.25C29.9371 13.25 34.75 18.0629 34.75 24C34.75 29.9371 29.9371 34.75 24 34.75C18.0629 34.75 13.25 29.9371 13.25 24Z"
        fill="#A4A3B1"
      />
    </svg>
  );
}
