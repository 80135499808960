import React from "react";

export function Locker() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 12 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.82612 2.21879C3.442 1.36341 4.46297 0.708984 6 0.708984C7.53703 0.708984 8.558 1.36341 9.17388 2.21879C9.7668 3.0423 9.95833 4.01175 9.95833 4.66732V7.37565H10.5C11.1213 7.37565 11.625 7.87933 11.625 8.50065V14.1673C11.625 14.7886 11.1213 15.2923 10.5 15.2923H1.5C0.87868 15.2923 0.375 14.7886 0.375 14.1673V8.50065C0.375 7.87933 0.87868 7.37565 1.5 7.37565H2.04167V4.66732C2.04167 4.01175 2.2332 3.0423 2.82612 2.21879ZM3.29167 7.37565H8.70833V4.66732C8.70833 4.21178 8.56653 3.51455 8.15946 2.94917C7.77533 2.41567 7.12963 1.95898 6 1.95898C4.87037 1.95898 4.22467 2.41567 3.84054 2.94917C3.43347 3.51455 3.29167 4.21178 3.29167 4.66732V7.37565ZM1.625 8.62565V14.0423H10.375V8.62565H1.625Z"
        fill="#85b9fd"
      />
    </svg>
  );
}
