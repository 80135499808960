import React, { memo } from "react";
import { DeleteLogo } from "../../icons";
import { MotifIconButton } from "@ey-xd/motif-react";
import { useSelector } from "react-redux";
export default memo(({ onDeleteUserClick, node }) => {
  const user = useSelector((state) => state.user.user);
  const lockingData = useSelector((state) => state.project?.lockingData);
  const engagement = useSelector((state) => state.engagement?.engagement);
  return (
    <MotifIconButton
      disabled={
        lockingData?.data?.lockStatus === 1 &&
        lockingData?.data?.lockedBy !== user.username &&
        engagement.id
      }
      onClick={() => onDeleteUserClick(node.data)}
    >
      <DeleteLogo />
    </MotifIconButton>
  );
});
