import React, { useState } from "react";
import "./SetupModal.scss";
import {
  MotifDropdown,
  MotifDropdownItem,
  MotifErrorMessage,
  MotifInput,
} from "@ey-xd/motif-react";
import { AddUserModalIcon } from "../../icons/addUser";
import {
  CancelBtn,
  SaveBtn,
  addNewUser,
  email,
  name,
  teamType,
  userDuplicateMsg,
} from "../../util/constants";
import { Input } from "../Input/Input";
import { Select } from "../Select/Select";
import { Button } from "../Button/Button";
import { userSearch } from "../../store/services/user.service";
import { useSelector } from "react-redux";
import { Modal } from "../Modal/Modal";
import { LoadingIndicatorEmbed } from "../LoadingIndicator/LoadingIndicatorEmbed";

export function AddUserModal({ isModalVisible, onClose, onConfirm, userData }) {
  const [empName, setEmpName] = useState("");
  const [emailID, setEmail] = useState("");
  const [teamName, setTeam] = useState("");
  const [isUserDuplicate, userDuplicate] = useState(false);
  const [isDropdownOpen, setDropdown] = useState(false);
  const [usersList, setUserList] = useState([]);
  const [usersListLoading, setUserListLoading] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const currentuser = useSelector((state) => state.user.user);

  function handleSave() {
    onConfirm({
      name: empName,
      email: emailID,
      engagementTeamTypeID: teamName === "Internal team" ? 0 : 1,
    });
    setEmail("");
    setEmpName("");
    setTeam("");
    setUserList([]);
    userDuplicate(false);
  }

  async function handleEmailChange(event) {
    userDuplicate(false);
    setEmail(event.target.value);
    setUserList([]);

    if (event.target.value) {
      setUserListLoading(true);
      const request = {
        string: event.target.value,
      };
      const data = await userSearch({ request });
      setUserList(data);
      setDropdown(true);
      setEmpName("");
      setUserListLoading(false);
    } else {
      setEmpName("");
      setTeam("");
    }
  }

  async function handleDropdownItemclick(user) {
    var list = [];
    userData &&
      userData.map((item) => {
        if (item.activeFlag) {
          list.push(item.email);
        }
      });
    if (list.includes(user.emailId)) {
      userDuplicate(true);
      setEmail("");
      setEmpName("");
      setDropdown(false);
    } else {
      userDuplicate(false);
      setEmpName(user.displayName);
      setEmail(user.emailId);
      if (user.emailId === currentuser.username) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
      setDropdown(false);
    }
  }

  function onModalClose() {
    setEmail("");
    setEmpName("");
    setTeam("");
    setUserList([]);
    userDuplicate(false);
    onClose();
  }

  return (
    <Modal
      body={
        <div className="add-user-body">
          <div className="add-user-body-row">
            <span className="add-user-body-row-label">{name}</span>
            <Input
              placeholder={"Input name"}
              value={empName}
              disabled={true}
              type={"text"}
            />
          </div>
          <div className="add-user-body-row">
            <span className="add-user-body-row-label">
              {email}
              <span className="required-star">*</span>
            </span>
            <MotifDropdown
              handleClickOutside={() => setDropdown(false)}
              open={isDropdownOpen}
              trigger={
                <MotifInput
                  placeholder={"Email ID"}
                  value={emailID}
                  onChange={handleEmailChange}
                  className="decarb-input"
                />
              }
            >
              <LoadingIndicatorEmbed show={usersListLoading}>
                {usersList.map((user) => {
                  return (
                    <MotifDropdownItem
                      onClick={() => handleDropdownItemclick(user)}
                      key={user.emailId}
                    >
                      {user.emailId}
                    </MotifDropdownItem>
                  );
                })}
              </LoadingIndicatorEmbed>
            </MotifDropdown>
          </div>
          {isUserDuplicate && (
            <div className="decarb-user-error">
              <MotifErrorMessage className="decarb-input-error-message">
                {userDuplicateMsg}
              </MotifErrorMessage>
            </div>
          )}
          <div className="add-user-body-row">
            <span className="add-user-body-row-label">
              {teamType}
              <span className="required-star">*</span>
            </span>
            <Select
              options={["Internal team", "External team"]}
              value={teamName}
              onChange={(event) => setTeam(event)}
            ></Select>
          </div>
        </div>
      }
      footer={
        <div className="view-endagement-delete-modal-footer">
          <Button variant="secondary" onClick={onModalClose}>
            {CancelBtn}
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={
              teamName === "" || emailID === "" || empName === "" || disableSave
            }
          >
            {SaveBtn}
          </Button>
        </div>
      }
      header={
        <div className="file-version-history-modal-header">
          <div className="motif-fab">
            <AddUserModalIcon />
          </div>
          <span>{addNewUser}</span>
        </div>
      }
      show={isModalVisible}
      onClose={onModalClose}
    />
  );
}
