import { MotifProgressLoader } from "@ey-xd/motif-react";
import "./LoadingIndicator.scss";

export function LoadingIndicator({
  show,
  fullscreen = true,
  children,
  transparent = false,
  className = "",
}) {
  return (
    <>
      {show ? (
        <MotifProgressLoader
          show={true}
          fullscreen={fullscreen}
          className={`decarb-loading-indicator ${className} ${
            transparent ? "decarb-loading-indicator-transparent" : ""
          }`}
        ></MotifProgressLoader>
      ) : (
        children
      )}
    </>
  );
}
