import React, { useEffect, useState } from "react";
import {
  ApplyBtn,
  emissionHeaderTitle,
  emissionHeaderTooltip,
  reset,
  scope,
  subcat1,
  subcat2,
  subcat3,
} from "../../../util/constants";
import "../Emissions.scss";
import { EmissionIcon } from "../../../icons";
import { Select } from "../../Select/Select";
import { Button } from "../../Button/Button";
import { Tooltip } from "../../CommonUIComponents/Tooltip/Tooltip";
import {
  getReduceDefautViewData,
  resetProjectM3,
  saveEmissionFiltersReduce,
  getColumnValueFlags,
  getSaveProjectRule,
} from "../../../store/services/project.service";
import { useSelector, useDispatch } from "react-redux";
import {
  setFilters,
  setUISelectedHeaderFilters,
  setReduceDefautViewData,
  setselectedUIAllFilters,
  setAppliedFiltersPayload,
  setSimulateFlag,
  setGraphSimulateFlag,
  setEmissionFiltersSaved,
  setIsReset,
  getLockingData,
  setManualEditRules,
  setM3Step2Loader,
  setM3EmissionFilters,
} from "../../../store/slices/projectSlice";
import { ResetModal } from "./EmissionResetModal";
import { LockerModalM3 } from "../../LockerModal";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";

export function EmissionHeader({}) {
  const hierarchialData = useSelector((state) => state.project.hierarchialData);
  const project = useSelector((state) => state.project.project);
  const engagement = useSelector((state) => state.engagement.engagement);
  const user = useSelector((state) => state.user.user);
  const isEdit = useSelector((state) => state.project.isEditProject);
  const preSelectedHeaderFilters = useSelector(
    (state) => state.project.selectedUIHeaderFilters
  );
  const isProjectValidated = useSelector(
    (state) => state.project.isProjectValidated
  );
  const [scopeOptions, setScopeOptions] = useState(
    isEdit ? preSelectedHeaderFilters.scope : []
  );
  const [selectedScope, setSelectedScope] = useState(
    isEdit && isProjectValidated ? preSelectedHeaderFilters.scope : []
  );
  const [emissionLevel1Array, setEmissionLevel1Array] = useState(
    isEdit ? preSelectedHeaderFilters.category_level1 : []
  );
  const [emissionLevel2Array, setEmissionLevel2Array] = useState(
    isEdit ? preSelectedHeaderFilters.category_level2 : []
  );
  const [emissionLevel3Array, setEmissionLevel3Array] = useState(
    isEdit ? preSelectedHeaderFilters.category_level3 : []
  );
  const [selectedSubCategory1, selectSubcategory1] = useState(
    isEdit && isProjectValidated
      ? !preSelectedHeaderFilters.category_level1?.includes("All")
        ? preSelectedHeaderFilters.category_level1
        : []
      : []
  );
  const [selectedSubCategory2, selectSubcategory2] = useState(
    isEdit && isProjectValidated
      ? !preSelectedHeaderFilters.category_level2?.includes("All")
        ? preSelectedHeaderFilters.category_level2
        : []
      : []
  );
  const [selectedSubCategory3, selectSubcategory3] = useState(
    isEdit && isProjectValidated
      ? !preSelectedHeaderFilters.category_level3?.includes("All")
        ? preSelectedHeaderFilters.category_level3
        : []
      : []
  );
  // const [isFilterApplied, setIsFilterApplied] = useState(
  //   isEdit ? (preSelectedHeaderFilters.scope?.length > 0 ? true : false) : false
  // );
  const isFilterApplied = useSelector(
    (state) => state.project.emissionFiltersApplied
  );
  const savedFilters = useSelector(
    (state) => state.project.emissionfiltersSaved
  );
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [showResetLoader, setShowResetLoader] = useState(false);
  const [resetCompleted, setresetCompleted] = useState(false);
  const [saveCompleted, setSaveCompleted] = useState(false);
  const [isResetModalOpen, setResetModal] = useState(false);
  let refreshIntervalId = undefined;
  let refreshIntervalCount = 0;
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const [isLockerModalOpen, setLockerModalOpen] = useState(false);

  // Setting the dropdown values on page load
  useEffect(() => {
    if (hierarchialData.length > 0) {
      setDropdowns();
    }
  }, [hierarchialData]);

  // screen refresh once reset falg is completed
  useEffect(() => {
    if (resetCompleted) {
      screenRefreshafterReset();
    }
  }, [resetCompleted]);

  // screen refresh once save flagcheck is completed
  useEffect(() => {
    if (saveCompleted) {
      screenRefreshAfterSave();
    }
  }, [saveCompleted]);

  // Refresh the screen after the saveFilters andflag check is complete
  const screenRefreshAfterSave = async () => {
    const emessionFilterCounter = savedFilters?.[0]?.counter;
    const modifiedBy = savedFilters?.[0]?.modifiedby;
    const selectedCategory = ["scope"];
    selectedSubCategory1.length > 0 && selectedCategory.push("category_level1");
    selectedSubCategory2.length > 0 && selectedCategory.push("category_level2");
    selectedSubCategory3.length > 0 && selectedCategory.push("category_level3");
    const request = {
      engagementCode: engagement.engagementCode,
      projectID: project.projectId,
      columnNames: selectedCategory,
      numberOfYears: engagement.foreCastEndYear - engagement.mostRecentYear,
      viewType: "M3-ApplyScope",
      modifiedBy: modifiedBy,
      counter: emessionFilterCounter,
    };
    dispatch(setAppliedFiltersPayload({ filtersPayload: request }));
    const data = await getReduceDefautViewData({ request });
    dispatch(setReduceDefautViewData({ defaultViewData: data }));
    setShowLoader(false);
    dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
  };

  // Refreshingthe screen after reset flag check is commplete
  const screenRefreshafterReset = async () => {
    const request = {
      engagementCode: engagement.engagementCode,
      projectID: project.projectId,
      columnNames: ["scope"],
      numberOfYears: engagement.foreCastEndYear - engagement.mostRecentYear,
      viewType: "Default",
      modifiedBy: user.username,
      counter: 1,
    };
    const response = await getReduceDefautViewData({ request });
    dispatch(setReduceDefautViewData({ defaultViewData: response }));
    dispatch(setSimulateFlag({ isSimulate: false }));
    dispatch(setGraphSimulateFlag({ isGraphSimulate: false }));
    // setIsFilterApplied(false);
    dispatch(setM3EmissionFilters({ emissionFiltersApplied: false }));
    refreshIntervalCount = 0;
    setDropdowns();
    setTimeout(() => {
      setShowResetLoader(false);
    }, 1000);
  };

  // Setting the dropdown options on page load
  const setDropdowns = () => {
    let scopeArray = [
      ...new Set(hierarchialData.map((element) => element.scope)),
    ];
    scopeArray.sort();
    setScopeOptions(scopeArray);
  };

  // Call Previously selected header filters API if itis edit project
  useEffect(() => {
    if (
      isEdit &&
      preSelectedHeaderFilters.scope?.length > 0 &&
      isProjectValidated
    ) {
      callHeaderFilterAPI();
    } else {
      reduceDefautViewData();
    }
  }, [preSelectedHeaderFilters]);

  const callHeaderFilterAPI = async () => {
    // setIsFilterApplied(
    //   isEdit && isProjectValidated
    //     ? preSelectedHeaderFilters.scope?.length > 0
    //       ? true
    //       : false
    //     : false
    // );
    dispatch(
      setM3EmissionFilters({
        emissionFiltersApplied:
          isEdit && isProjectValidated
            ? preSelectedHeaderFilters.scope?.length > 0
              ? true
              : false
            : false,
      })
    );
    if (isProjectValidated) {
      setScopeOptions(isEdit ? preSelectedHeaderFilters.scope : []);
      setSelectedScope(isEdit ? preSelectedHeaderFilters.scope : []);
      setEmissionLevel1Array(
        isEdit ? preSelectedHeaderFilters.category_level1 : []
      );
      selectSubcategory1(
        isEdit
          ? !preSelectedHeaderFilters.category_level1?.includes("All")
            ? preSelectedHeaderFilters.category_level1
            : []
          : []
      );
      selectSubcategory2(
        isEdit
          ? !preSelectedHeaderFilters.category_level2?.includes("All")
            ? preSelectedHeaderFilters.category_level2
            : []
          : []
      );
      selectSubcategory3(
        isEdit
          ? !preSelectedHeaderFilters.category_level3?.includes("All")
            ? preSelectedHeaderFilters.category_level3
            : []
          : []
      );
      setEmissionLevel2Array(
        isEdit
          ? !preSelectedHeaderFilters.category_level2?.includes("All")
            ? preSelectedHeaderFilters.category_level2
            : []
          : []
      );
      setEmissionLevel3Array(
        isEdit
          ? !preSelectedHeaderFilters.category_level3?.includes("All")
            ? preSelectedHeaderFilters.category_level3
            : []
          : []
      );
    }

    const filters = {
      scope:
        preSelectedHeaderFilters.scope?.length > 0
          ? preSelectedHeaderFilters.scope
          : ["All"],
      category_level1:
        preSelectedHeaderFilters.category_level1?.length > 0
          ? preSelectedHeaderFilters.category_level1
          : ["All"],
      category_level2:
        preSelectedHeaderFilters.category_level2?.length > 0
          ? preSelectedHeaderFilters.category_level2
          : ["All"],
      category_level3:
        preSelectedHeaderFilters.category_level3?.length > 0
          ? preSelectedHeaderFilters.category_level3
          : ["All"],
    };
    const payload = await constructPayload(filters, "Edit");
    //  const apiResponse  = dispatch(saveEmissionFilters(request));
    const apiResponse = await saveEmissionFiltersReduce({ payload });
    dispatch(setEmissionFiltersSaved({ selectedEmissionFilters: apiResponse }));
    if (apiResponse[0].applyfiltercount === 0) {
      reduceDefautViewData("M3-ApplyScope");
    } else {
      reduceDefautViewData("M3-ApplyFilter");
    }
  };

  // Calling table data API to load  table and graph
  const reduceDefautViewData = async (view) => {
    setShowLoader(true);
    dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
    if (isEdit && isProjectValidated) {
      const rule = await getRuleData();
      let request = "";
      if (rule?.length > 0) {
        if (preSelectedHeaderFilters?.scope?.length > 0) {
          request = {
            engagementCode: engagement.engagementCode,
            projectID: project.projectId,
            columnNames:
              preSelectedHeaderFilters.highestLevelFilter === "category_level3"
                ? [
                    "scope",
                    "category_level1",
                    "category_level2",
                    "category_level3",
                  ]
                : preSelectedHeaderFilters.highestLevelFilter ===
                  "category_level2"
                ? ["scope", "category_level1", "category_level2"]
                : preSelectedHeaderFilters.highestLevelFilter ===
                  "category_level1"
                ? ["scope", "category_level1"]
                : ["scope"],
            numberOfYears:
              engagement.foreCastEndYear - engagement.mostRecentYear,
            // viewType: "M3-ApplyScope1",
            viewType: view,
            modifiedBy: user.username,
            counter: 1,
          };
        }
      } else {
        if (preSelectedHeaderFilters?.scope?.length > 0) {
          request = {
            engagementCode: engagement.engagementCode,
            projectID: project.projectId,
            columnNames:
              preSelectedHeaderFilters.highestLevelFilter === "category_level3"
                ? [
                    "scope",
                    "category_level1",
                    "category_level2",
                    "category_level3",
                  ]
                : preSelectedHeaderFilters.highestLevelFilter ===
                  "category_level2"
                ? ["scope", "category_level1", "category_level2"]
                : preSelectedHeaderFilters.highestLevelFilter ===
                  "category_level1"
                ? ["scope", "category_level1"]
                : ["scope"],
            numberOfYears:
              engagement.foreCastEndYear - engagement.mostRecentYear,
            // viewType: "M3-ApplyScope1",
            viewType: view,
            modifiedBy: user.username,
            counter: 1,
          };
        } else {
          request = {
            engagementCode: engagement.engagementCode,
            projectID: project.projectId,
            columnNames: ["scope"],
            numberOfYears:
              engagement.foreCastEndYear - engagement.mostRecentYear,
            // viewType: "M3-ApplyScope1",
            viewType: "Default",
            modifiedBy: user.username,
            counter: 1,
          };
        }
      }
      dispatch(setAppliedFiltersPayload({ filtersPayload: request }));
      if (request !== "") {
        const data = await getReduceDefautViewData({ request });
        dispatch(setReduceDefautViewData({ defaultViewData: data }));
      }
    } else {
      let request = {
        engagementCode: engagement.engagementCode,
        projectID: project.projectId,
        columnNames: ["scope"],
        numberOfYears: engagement.foreCastEndYear - engagement.mostRecentYear,
        // viewType: "M3-ApplyScope1",
        viewType: "Default",
        modifiedBy: user.username,
        counter: 1,
      };
      dispatch(setAppliedFiltersPayload({ filtersPayload: request }));
      const data = await getReduceDefautViewData({ request });
      dispatch(setReduceDefautViewData({ defaultViewData: data }));
    }
    setShowLoader(false);
    dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
  };

  // Calling getrules to API to check ifany rules are added or not.
  const getRuleData = async () => {
    let request = {
      projectID: project.projectId,
    };
    const data = await getSaveProjectRule({ request });
    return data;
  };
  // Set level1 Dropdown options
  const setLevel1dropdown = (event) => {
    let filteredByScopeArray = hierarchialData.filter((element) =>
      event.includes(element.scope)
    );
    let emissionLevel1 = [
      ...new Set(
        filteredByScopeArray.map((element) => element.category_level1)
      ),
    ];
    emissionLevel1 = emissionLevel1.filter((element) => element !== null);
    if (emissionLevel1.length === 0) {
      emissionLevel1.push("N/A");
    }
    setEmissionLevel1Array(emissionLevel1);
  };

  // set level2 dropdown options
  const setLevel2dropdown = (event) => {
    let filteredByScopeArray = hierarchialData.filter(
      (element) =>
        selectedScope.includes(element.scope) &&
        event.includes(element.category_level1)
    );
    let emissionLevel2 = [
      ...new Set(
        filteredByScopeArray.map((element) => element.category_level2)
      ),
    ];
    emissionLevel2 = emissionLevel2.filter((element) => element !== null);
    if (emissionLevel2.length === 0) {
      emissionLevel2.push("N/A");
    }
    setEmissionLevel2Array(emissionLevel2);
  };

  // Set level3 dropdown options
  const setLevel3dropdown = (event) => {
    let filteredByScopeArray = hierarchialData.filter(
      (element) =>
        selectedScope.includes(element.scope) &&
        selectedSubCategory1.includes(element.category_level1) &&
        event.includes(element.category_level2)
    );
    let emissionLevel = [
      ...new Set(
        filteredByScopeArray.map((element) => element.category_level3)
      ),
    ];
    emissionLevel = emissionLevel.filter((element) => element !== null);
    if (emissionLevel.length === 0) {
      emissionLevel.push("N/A");
    }
    setEmissionLevel3Array(emissionLevel);
  };

  //Handling the dropdown change and calling respective leveldropdown function
  async function handleDropdownChange(event, dropdown) {
    switch (dropdown) {
      case "Scope":
        setSelectedScope(event);
        selectSubcategory1([]);
        selectSubcategory2([]);
        selectSubcategory3([]);
        setLevel1dropdown(event);
        // setIsFilterApplied(false);
        dispatch(setM3EmissionFilters({ emissionFiltersApplied: false }));
        break;
      case "Level1":
        selectSubcategory1(event);
        selectSubcategory2([]);
        selectSubcategory3([]);
        setLevel2dropdown(event);
        break;
      case "Level2":
        selectSubcategory2(event);
        selectSubcategory3([]);
        setLevel3dropdown(event);
        break;
      case "Level3":
        selectSubcategory3(event);
        break;
    }
  }

  // Initiate the Reset filters process and reset dropdown fields
  const resetFilters = async () => {
    setShowResetLoader(true);
    setresetCompleted(false);
    dispatch(setFilters({ overallFilters: {} }));
    dispatch(setUISelectedHeaderFilters({ selectedUIHeaderFilters: {} }));
    dispatch(setselectedUIAllFilters({ selectedUIAllFilters: {} }));
    dispatch(setAppliedFiltersPayload({ filtersPayload: {} }));
    dispatch(setIsReset({ reset: true }));
    setSelectedScope([]);
    selectSubcategory1([]);
    selectSubcategory2([]);
    selectSubcategory3([]);
    setResetModal(false);
    let request = {
      engagementCode: engagement.engagementCode,
      projectID: project.projectId,
    };
    const data = await resetProjectM3({ request });
    try {
      refreshIntervalId = window.setInterval(checkFlags, 2000);
    } catch (error) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setShowResetLoader(false);
    }
  };

  // Check the Reset Filters flag
  const checkFlags = async () => {
    refreshIntervalCount++;
    if (refreshIntervalCount > 30) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setShowLoader(false);
      dispatch(setM3Step2Loader({ step2GraphTableLoader: false }));
      refreshIntervalCount = 0;
    }
    const flagsPayload = {
      columnName: "Reset",
      tableName: "Project",
      id: project.projectId,
    };
    const response = await getColumnValueFlags({ flagsPayload });
    if (response.columnValue === "3") {
      if (refreshIntervalId) {
        window.clearInterval(refreshIntervalId);
        refreshIntervalId = undefined;
      }
      setresetCompleted(true);
    }
  };

  // On Apply Filter for scope
  const applyFilters = async () => {
    setSaveCompleted(false);
    // setIsFilterApplied(true);
    dispatch(setM3EmissionFilters({ emissionFiltersApplied: true }));
    setShowLoader(true);
    dispatch(setM3Step2Loader({ step2GraphTableLoader: true }));
    const filters = {
      scope: selectedScope,
      category_level1:
        selectedSubCategory1.length > 0 ? selectedSubCategory1 : ["All"],
      category_level2:
        selectedSubCategory2.length > 0 ? selectedSubCategory2 : ["All"],
      category_level3:
        selectedSubCategory3.length > 0 ? selectedSubCategory3 : ["All"],
    };
    dispatch(setFilters({ overallFilters: filters }));
    dispatch(
      setUISelectedHeaderFilters({
        selectedUIHeaderFilters: {
          scope: selectedScope,
          category_level1: selectedSubCategory1,
          category_level2: selectedSubCategory2,
          category_level3: selectedSubCategory3,
          highestLevelFilter:
            selectedSubCategory3.length > 0
              ? "category_level3"
              : selectedSubCategory2.length > 0
              ? "category_level2"
              : selectedSubCategory1.length > 0
              ? "category_level1"
              : "scope",
        },
      })
    );
    dispatch(
      setselectedUIAllFilters({
        selectedUIAllFilters: {
          scope: selectedScope,
          category_level1: selectedSubCategory1,
          category_level2: selectedSubCategory2,
          category_level3: selectedSubCategory3,
          region_level1: [],
          region_level2: [],
          region_level3: [],
          entity_level1: [],
          entity_level2: [],
          entity_level3: [],
          entity_level4: [],
          custom_specification1: [],
          custom_specification2: [],
          custom_specification3: [],
          highestLevelFilter:
            selectedSubCategory3.length > 0
              ? "category_level3"
              : selectedSubCategory2.length > 0
              ? "category_level2"
              : selectedSubCategory1.length > 0
              ? "category_level1"
              : "scope",
        },
      })
    );
    const payload = await constructPayload(filters, "Save");
    const apiResponse = await saveEmissionFiltersReduce({ payload });
    dispatch(setEmissionFiltersSaved({ selectedEmissionFilters: apiResponse }));
    dispatch(setIsReset({ reset: false }));
    try {
      refreshIntervalId = window.setInterval(checkSaveFlags(apiResponse), 2000);
    } catch (error) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
    }
  };

  // Check the Save filters flag
  const checkSaveFlags = async () => {
    refreshIntervalCount++;
    if (refreshIntervalCount > 30) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
    }
    const flagsPayload = {
      columnName: "Apply",
      tableName: "Project",
      id: project.projectId,
    };
    const response = await getColumnValueFlags({ flagsPayload });
    if (response.columnValue === "3") {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setSaveCompleted(true);
    }
  };

  //Payload construction  for Applying filters.
  const constructPayload = async (filters, type) => {
    let dropDownMetaData = [];
    const selectedScope = filters.scope.map((x) => {
      return { columnType: "Scope", columnValue: x };
    });
    const selectedCatLevel1 = filters.category_level1.map((x) => {
      return { columnType: "Category_level1", columnValue: x };
    });
    const selectedCatLevel2 = filters.category_level2.map((x) => {
      return { columnType: "Category_level2", columnValue: x };
    });
    const selectedCatLevel3 = filters.category_level3.map((x) => {
      return { columnType: "Category_level3", columnValue: x };
    });

    dropDownMetaData = [
      ...selectedScope,
      ...selectedCatLevel1,
      ...selectedCatLevel2,
      ...selectedCatLevel3,
    ];
    let name = "";
    if (type === "Edit") {
      name = "bindfilter";
    } else {
      name = project.projectName;
    }
    const payload = {
      engagementId: engagement.id,
      engagementCode: engagement.engagementCode,
      name: name,
      moduleName: "M3-ApplyScope",
      CreatedBy: user.username,
      modifiedby: user.username,
      dropDownMetaData: dropDownMetaData,
      projectId: project.projectId,
    };
    return payload;
  };

  // to get a locked user flags
  useEffect(() => {
    if (project.projectId) {
      const request = {
        LockedBy: user.username,
        ModuleName: "Project",
        EngagementID: project.projectId,
      };

      dispatch(getLockingData(request)).then((action) => {
        if (action) {
          const lockedData = action.payload?.data?.lockStatus;
          const lockedBy = action.payload?.data?.lockedBy;
          setLock(lockedData);
          setLockedby(lockedBy);
          if (lockedData === 2) {
            setLockerModalOpen(true);
          }
        }
      });
    }
  }, [dispatch, project.projectId, lockedData]);

  return (
    <div className="emission-header-main">
      <div className="emission-header-title">
        <span>{emissionHeaderTitle}</span>
        <Tooltip
          trigger={<EmissionIcon />}
          variant="ghost"
          tooltipText={emissionHeaderTooltip}
        ></Tooltip>
      </div>
      <div className="emission-header-content">
        <div className="emission-header-content-body">
          <div className="emission-header-content-body-dropdown">
            <span>
              {scope} <span className="required-star">*</span>
            </span>
            <Select
              options={scopeOptions}
              value={selectedScope}
              showSelectAllButton={true}
              onChange={(event) => {
                handleDropdownChange(event, "Scope");
              }}
              multiple={true}
              visibleOptions={3}
              disabled={
                isProjectValidated &&
                (isFilterApplied ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId))
              }
            ></Select>
          </div>
          <div className="emission-header-content-body-dropdown">
            <span>{subcat1}</span>
            <Select
              options={emissionLevel1Array}
              value={selectedSubCategory1}
              onChange={(event) => {
                handleDropdownChange(event, "Level1");
              }}
              multiple={true}
              visibleOptions={3}
              showSelectAllButton={true}
              disabled={
                isProjectValidated &&
                (selectedScope?.length === 0 ||
                  isFilterApplied ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId))
              }
            ></Select>
          </div>
          <div className="emission-header-content-body-dropdown">
            <span>{subcat2}</span>
            <Select
              options={emissionLevel2Array}
              value={selectedSubCategory2}
              onChange={(event) => {
                handleDropdownChange(event, "Level2");
              }}
              multiple={true}
              visibleOptions={3}
              showSelectAllButton={true}
              disabled={
                isProjectValidated &&
                (selectedSubCategory1?.length === 0 ||
                  isFilterApplied ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId))
              }
            ></Select>
          </div>
          <div className="emission-header-content-body-dropdown">
            <span>{subcat3}</span>
            <Select
              options={emissionLevel3Array}
              value={selectedSubCategory3}
              onChange={(event) => {
                handleDropdownChange(event, "Level3");
              }}
              multiple={true}
              visibleOptions={3}
              showSelectAllButton={true}
              disabled={
                isProjectValidated &&
                (selectedSubCategory2?.length === 0 ||
                  isFilterApplied ||
                  (lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId))
              }
            ></Select>
          </div>
          <LoadingIndicatorEmbed show={showResetLoader}>
            <Button
              variant="secondary"
              disabled={
                !isFilterApplied ||
                (lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId)
              }
              onClick={() => {
                setResetModal(true);
              }}
            >
              {reset}
            </Button>
          </LoadingIndicatorEmbed>
          <LoadingIndicatorEmbed show={showLoader}>
            <Button
              variant="primary"
              disabled={
                selectedScope?.length === 0 ||
                isFilterApplied ||
                (lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId)
              }
              onClick={() => {
                applyFilters();
              }}
            >
              {ApplyBtn}
            </Button>
          </LoadingIndicatorEmbed>
        </div>
      </div>
      <ResetModal
        isModalOpen={isResetModalOpen}
        onClose={() => setResetModal(false)}
        onConfirm={() => resetFilters()}
        loader={showResetLoader}
        disabled={
          lockedData === 1 && lockedBy !== user.username && project.projectId
        }
      />
      {lockedData === 2 && lockedBy === user.username && project.projectId && (
        <LockerModalM3
          isOpen={isLockerModalOpen}
          onClose={() => setLockerModalOpen(false)}
        />
      )}
    </div>
  );
}
