import { MotifTabControl, MotifTabNavigation } from "@ey-xd/motif-react";
import { setCurrentTab } from "../../../../store/slices/projectSlice";
import { useDispatch } from "react-redux";
import "./TabNavigation.scss";

export function TabNavigation({ tabSteps, currentTab, tabIndex }) {
  const dispatch = useDispatch();

  return (
    <MotifTabNavigation
      variant="alt"
      className="decarb-tab-navigation"
      id="decarb-new-project-tab-navigation"
    >
      {tabSteps.map((step, index) => {
        return (
          <MotifTabControl
            key={index}
            onClick={(event) => {
              dispatch(setCurrentTab({ currentTab: index }));
            }}
            disabled={index > tabIndex}
            tabKey={index}
            className={index === currentTab ? "motif-active" : "inactive-tab"}
          >
            {step.stepName}
          </MotifTabControl>
        );
      })}
    </MotifTabNavigation>
  );
}
