import axios from "axios";
import { MS_GRAPH_URL } from "./endpoints/graph";
import { USER_URL } from "./endpoints/user";
import { get, post } from "./base.service";

async function getUserPhoto() {
  return await axios(MS_GRAPH_URL.GetUserPhoto, {
    responseType: "arraybuffer",
  }).catch((err) => {
    throw err;
  });
}

async function userSearch({ request }) {
  return await post(
    `${USER_URL.UserSearch}?searchString=${request.string}&count=10`
  );
}

async function CheckIfUserIsAdmin({ request }) {
  return await get(`${USER_URL.CheckIfUserIsAdmin}?emailId=${request.emailId}`);
}

export { getUserPhoto, userSearch, CheckIfUserIsAdmin };
