import { MotifTextLink } from "@ey-xd/motif-react";
import { DownloadIcon } from "../../icons";
import "./DownloadLink.scss";
import { useEffect, useState } from "react";
import { getLockingData } from "../../store/slices/projectSlice";
import { useDispatch, useSelector } from "react-redux";

export function DownloadLink({ template, text }) {
  const [lockedData, setLock] = useState();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const engagement = useSelector((state) => state.engagement.engagement);
  useEffect(() => {
    const request = {
      LockedBy: user.username,
      ModuleName: "Engagement",
      EngagementID: engagement.id,
    };

    dispatch(getLockingData(request))
      .then((action) => {
        if (action) {
          const lockedData = action.payload.data.lockStatus;
          setLock(lockedData);
        } else {
          console.error("Failed to fetch locking data:", action.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching locking data:", error);
      });
  }, [dispatch]);

  return (
    <MotifTextLink
      download
      href={template}
      className="motif-button motif-button-primary-alt decarb-button decarb-download-file-link"
    >
      <DownloadIcon />
      {text}
    </MotifTextLink>
  );
}
