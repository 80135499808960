import {
  MotifProgressIndicatorStep,
  MotifProgressIndicator,
} from "@ey-xd/motif-react";
import { StepperCompletedStage, StepperActiveStage } from "../../icons";
import "./Stepper.scss";

export function Stepper({
  steps,
  stepIndex,
  currentStep,
  size = "md",
  className,
}) {
  const getStatus = (itemIdx) => {
    if (itemIdx === currentStep) {
      return "active";
    } else if (itemIdx < stepIndex) {
      return "completed";
    } else if (itemIdx > stepIndex) {
      return "default";
    }
  };

  return (
    <MotifProgressIndicator
      variant="row"
      className={`decarb-stepper ${className} ${
        size === "small" ? "decarb-stepper-small" : ""
      }`}
      size={size === "small" ? "sm" : "md"}
    >
      {steps.map((item, index) => {
        const stepStatus = getStatus(index);
        const currectIcon =
          stepStatus === "completed"
            ? { complete: <StepperCompletedStage /> }
            : { incomplete: <StepperActiveStage /> };

        return (
          <MotifProgressIndicatorStep
            heading={item.stepName}
            status={stepStatus}
            key={index}
            disabled={stepStatus !== "completed" && index !== currentStep}
            icons={currectIcon}
          />
        );
      })}
    </MotifProgressIndicator>
  );
}
