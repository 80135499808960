import React from "react";

export function CopyIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87565 7.9987C6.87565 7.37738 7.37933 6.8737 8.00065 6.8737H16.1673C16.7886 6.8737 17.2923 7.37738 17.2923 7.9987V16.1654C17.2923 16.7867 16.7886 17.2904 16.1673 17.2904H8.00065C7.37933 17.2904 6.87565 16.7867 6.87565 16.1654V7.9987ZM8.12565 8.1237V16.0404H16.0423V8.1237H8.12565Z"
        fill="#A4A3B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.70898 3.83203C2.70898 3.21071 3.21266 2.70703 3.83398 2.70703H12.0007C12.622 2.70703 13.1257 3.21071 13.1257 3.83203V7.4987C13.1257 7.84388 12.8458 8.1237 12.5007 8.1237C12.1555 8.1237 11.8757 7.84388 11.8757 7.4987V3.95703H3.95898V11.8737H7.50065C7.84583 11.8737 8.12565 12.1535 8.12565 12.4987C8.12565 12.8439 7.84583 13.1237 7.50065 13.1237H3.83398C3.21266 13.1237 2.70898 12.62 2.70898 11.9987V3.83203Z"
        fill="#A4A3B1"
      />
    </svg>
  );
}
