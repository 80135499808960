import { styled } from "@mui/material";
import { BarChart, useDrawingArea, useXScale, useYScale } from "@mui/x-charts";
import { useState } from "react";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import {
  forecastChartNoData,
  forecastChartNoDataDesc1,
  forecastChartNoDataDesc2,
} from "../../../util/constants";
import "./EmptyChart.scss";

const NoDataTextMain = styled("text")(({ theme }) => ({
  stroke: "none",
  fill: "#DEDEE2",
  shapeRendering: "crispEdges",
  textAnchor: "middle",
  dominantBaseline: "middle",
}));

const NoDataTextDesc = styled("text")(({ theme }) => ({
  stroke: "none",
  fill: "#A4A3B1",
  shapeRendering: "crispEdges",
  textAnchor: "middle",
  dominantBaseline: "middle",
}));

const NoDataSvg = styled("svg")(({ theme }) => ({
  stroke: "none",
  width: "40",
  height: "41",
  viewBox: "0 0 40 41",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
}));

function NoDataOverlay() {
  const xScale = useXScale();
  const yScale = useYScale();
  const { left, width, height } = useDrawingArea();

  const [bottom, top] = yScale.range();

  return (
    <g>
      <NoDataSvg x={left + width / 2 - 18} y={top + height / 2 - 72}>
        <rect y="0.386719" width="40" height="40" rx="20" fill="#2E2E38" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.6667 16.4284C17.0118 16.4284 17.2917 16.7082 17.2917 17.0534V23.7201C17.2917 24.0652 17.0118 24.3451 16.6667 24.3451C16.3215 24.3451 16.0417 24.0652 16.0417 23.7201V17.0534C16.0417 16.7082 16.3215 16.4284 16.6667 16.4284Z"
          fill="#A4A3B1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 18.9284C20.3452 18.9284 20.625 19.2082 20.625 19.5534V23.7201C20.625 24.0652 20.3452 24.3451 20 24.3451C19.6548 24.3451 19.375 24.0652 19.375 23.7201V19.5534C19.375 19.2082 19.6548 18.9284 20 18.9284Z"
          fill="#A4A3B1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3333 20.5951C23.6785 20.5951 23.9583 20.8749 23.9583 21.2201V23.7201C23.9583 24.0652 23.6785 24.3451 23.3333 24.3451C22.9882 24.3451 22.7083 24.0652 22.7083 23.7201V21.2201C22.7083 20.8749 22.9882 20.5951 23.3333 20.5951Z"
          fill="#A4A3B1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.125 13.5117V27.2617H26.875V13.5117H13.125ZM11.875 13.3867C11.875 12.7654 12.3787 12.2617 13 12.2617H27C27.6213 12.2617 28.125 12.7654 28.125 13.3867V27.3867C28.125 28.008 27.6213 28.5117 27 28.5117H13C12.3787 28.5117 11.875 28.008 11.875 27.3867V13.3867Z"
          fill="#A4A3B1"
        />
      </NoDataSvg>
      <NoDataTextMain x={left + width / 2} y={top + height / 2}>
        {forecastChartNoData}
      </NoDataTextMain>
      <NoDataTextDesc x={left + width / 2} y={top + height / 2 + 32}>
        {forecastChartNoDataDesc1}
      </NoDataTextDesc>
      <NoDataTextDesc x={left + width / 2} y={top + height / 2 + 48}>
        {forecastChartNoDataDesc2}
      </NoDataTextDesc>
    </g>
  );
}

export function EmptyChart({ chartYAxisLabel }) {
  const [xAxis] = useState([
    {
      scaleType: "band",
      id: "x-axis-id",
      disableTicks: true,
      data: [0],
    },
  ]);
  const [yAxis] = useState([
    {
      label: chartYAxisLabel,
      min: 0,
      max: 1000,
      id: "y-axis-id",
    },
  ]);

  return (
    <div className="empty-chart">
      <BarChart
        slots={{ noDataOverlay: NoDataOverlay }}
        series={[]}
        margin={{ top: 5, bottom: 100, left: 120, right: 10 }}
        height={400}
        xAxis={xAxis}
        yAxis={yAxis}
        leftAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        bottomAxis={{
          disableLine: false,
        }}
        sx={{
          [`.${axisClasses.left} .${axisClasses.label}`]: {
            transform: "translateX(-80px)",
          },
        }}
      />
    </div>
  );
}
