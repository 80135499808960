import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import "./Header.scss";
import {
  MotifHeader,
  MotifHeaderLogo,
  MotifAvatar,
  MotifVerticalNavigationHeaderButton,
  MotifTooltip,
  MotifDropdownItem,
  MotifDropdown,
} from "@ey-xd/motif-react";
import { HelpIcon } from "../../icons/Help";
import { useDispatch, useSelector } from "react-redux";
import { dropProjectState } from "../../store/slices/projectSlice";
import {
  dropEngagementState,
  dropSelectedRegion,
  getEngagementById,
  getRegionList,
  setRegion,
} from "../../store/slices/engagementSlice";
import {
  dropStepperState,
  fetchStepperSteps,
} from "../../store/slices/stepperSlice";
import { dropSetupState } from "../../store/slices/setupSlice";
import { appName } from "../../util/constants";
import { dropForecastState } from "../../store/slices/forecastSlice";
import { getPhoto } from "../../store/slices/userSlice";
import { dropSimulateState } from "../../store/slices/simulateSlice";
import { dropDecarbLibraryState } from "../../store/slices/decarbLibrarySlice";
import { Locker } from "../../icons/locker";
import { routes } from "../../routes/routes.constatns";
import { useMsal } from "@azure/msal-react";
import { releaseAlllocks } from "../../store/services/engagement.service";

function Avatar({ user, avatar }) {
  const [isDropdownOpen, setDropdown] = useState(false);
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const triggerId = "dropdown-trigger-1";

  const handleClickDropdownItem = async () => {
    await releaseAlllocks({ request: { email: user.username } });
    dispatch(dropEngagementState());
    dispatch(dropStepperState());
    dispatch(dropProjectState());
    dispatch(dropSetupState());
    dispatch(dropForecastState());
    dispatch(dropSimulateState());
    dispatch(dropDecarbLibraryState());
    dispatch(dropSelectedRegion());

    instance.logoutRedirect({
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: `${process.env.REACT_APP_URL_REG_ALL}${routes.home}`,
    });
  };

  return (
    <MotifDropdown
      ariaLabelledby={triggerId}
      handleOnClose={() => setDropdown(false)}
      onClick={() => setDropdown(!isDropdownOpen)}
      open={isDropdownOpen}
      placement="left"
      trigger={
        <MotifAvatar
          description={user.name}
          size="medium"
          userName={user.name}
          src={avatar}
          interactive={true}
          id={triggerId}
        />
      }
    >
      <MotifDropdownItem
        indexItem={1}
        aria-label="Log out"
        onClick={handleClickDropdownItem}
        type="container"
      >
        <div
          style={{
            display: "flex",
            gap: "12px",
          }}
        >
          <MotifAvatar
            description={user.name}
            size="large"
            userName={user.name}
            src={avatar}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span className="motif-body1-default-bold">{user.name}</span>
            <span className="motif-body1-default-light">{user.username}</span>
          </div>
        </div>
      </MotifDropdownItem>
      <span className="motif-dropdown-horizontal-divider" />
      <MotifDropdownItem
        indexItem={2}
        aria-label="Log out"
        onClick={handleClickDropdownItem}
      >
        Log out
      </MotifDropdownItem>
    </MotifDropdown>
  );
}

function Header({ setSideMenuCollapsed = () => {} }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = location.state;
  const popup = location.pathname.includes(routes.decarbProjectsLibrary);
  const user = useSelector((state) => state.user.user);
  const avatar = useSelector((state) => state.user.photo);
  const lockingData = useSelector((state) => state.project?.lockingData);
  const engagement = useSelector((state) => state.engagement?.engagement);
  const regionList = useSelector((state) => state.engagement.regionList);
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const steps = useSelector((state) => state.stepper.steps);
  const [remainingTime, setRemainingTime] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const regionId = searchParams.get("regionId");
  const engagementId = searchParams.get("engagementId");
  async function onLogoClickHandler() {
    await releaseAlllocks({ request: { email: user.username } });
    window.location.href = `${process.env.REACT_APP_URL_REG_ALL}${routes.home}`;
  }

  useEffect(() => {
    if (user && user?.username) {
      if (!avatar) dispatch(getPhoto());
    }
  }, [dispatch, user, user?.username, avatar]);

  useEffect(() => {
    dispatch(getRegionList());

    if (!steps || steps.length === 0) dispatch(fetchStepperSteps());
  }, [dispatch]);

  useEffect(() => {
    var engId = state?.engagementId ?? engagementId ?? engagement?.id;
    if (engId) {
      dispatch(getEngagementById({ id: engId }));
    }
  }, [state?.engagementId, engagement?.id, engagementId, dispatch]);

  useEffect(() => {
    const regId = state?.regionId ?? regionId ?? selectedRegion?.id;

    if (regionList.length && regId) {
      dispatch(
        setRegion({
          regId: parseInt(regId),
        })
      );
    }
  }, [
    regionList.length,
    state?.regionId,
    regionId,
    selectedRegion?.id,
    dispatch,
  ]);

  useEffect(() => {
    if (!lockingData?.data?.lockedTime) {
      setRemainingTime("");
      return;
    }

    const updateRemainingTime = () => {
      const currentTime = new Date(); // User's local time
      const lockedTimeUTC = new Date(lockingData?.data?.lockedTime); // Assuming lockedTime is in UTC
      const lockedTimeLocal = new Date(
        lockedTimeUTC.getTime() - currentTime.getTimezoneOffset() * 60000
      );
      const endTime = new Date(lockedTimeLocal.getTime() + 15 * 60000);

      const timeDiff = endTime - currentTime;
      if (timeDiff >= 0) {
        const minutes = Math.floor(timeDiff / 60000);
        const seconds = Math.floor((timeDiff % 60000) / 1000);
        setRemainingTime(`${minutes}m ${seconds}s`);
      } else {
        setRemainingTime("0m 0s");
      }
    };

    // Immediately update remaining time
    updateRemainingTime();

    // Set interval to update remaining time every second
    const interval = setInterval(updateRemainingTime, 1000);
    return () => clearInterval(interval);
  }, [lockingData]);

  return (
    <MotifHeader
      className="decarb-header"
      appHeaderName={appName}
      logo={
        <MotifHeaderLogo>
          <a
            aria-label="EY Logo - Home"
            onClick={() => {
              return !popup ? onLogoClickHandler() : null;
            }}
            className="decarb-header-logo"
          >
            <img
              aria-hidden="true"
              alt="LOGO"
              src={require("../../assets/Images/EY_Logo_Beam_RGB_White_Yellow.png")}
            />
          </a>
        </MotifHeaderLogo>
      }
      iconsContainer={
        <div className="decarb-header-right-components">
          <div className="tooling-body">
            {lockingData?.data?.lockStatus === 1 && engagement && (
              <MotifTooltip
                className="tooltip-icon"
                children={`${lockingData?.data?.lockedBy}\nRemaining time: ${remainingTime}`}
                placement="auto"
                hideCloseButton="true"
                variant="alt"
                trigger={<Locker />}
              />
            )}
          </div>
          <HelpIcon className="help-icon" />
          <Avatar user={user} avatar={avatar} />
        </div>
      }
      leftComponents={
        popup ? (
          <></>
        ) : (
          <div className="decarb-header-left-components">
            <MotifVerticalNavigationHeaderButton
              onClick={setSideMenuCollapsed}
            />
          </div>
        )
      }
    />
  );
}

export default Header;
