import { useEffect, useState } from "react";
import "./ProjectsFilter.scss";
import { MotifCheckbox, MotifTree, MotifTreeNode } from "@ey-xd/motif-react";
import { useDispatch, useSelector } from "react-redux";
import { getNavBarProjects } from "../../../../../../store/slices/simulateSlice";
import { LoadingIndicator } from "../../../../../LoadingIndicator/LoadingIndicator";
import { Button } from "../../../../../Button/Button";
import { RepeatIcon } from "../../../../../../icons/repeat";
import { RefreshIcon } from "../../../../../../icons";
import { SearchInput } from "../../../../../SearchInput/SearchInput";
import { generateGraph } from "../../../../../../store/services/simulate.service";
import { setGenerateGraphResponse } from "../../../../../../store/slices/simulateSlice";
import { LoadingIndicatorEmbed } from "../../../../../LoadingIndicator/LoadingIndicatorEmbed";

export const BuildTree = ({ item, checked, onChecked, tooltipTheme }) => {
  let children = null;
  if (item.children && item.children.length) {
    children = item.children.map((x) => (
      <BuildTree
        item={x}
        key={x.key}
        checked={checked}
        onChecked={onChecked}
        tooltipTheme={tooltipTheme}
      />
    ));
  }

  const checkBox = (
    <MotifCheckbox
      id={item.key}
      value={item.key}
      name={item.key}
      indeterminate={checked[item.key].indeterminate}
      checked={checked[item.key].checked}
      disabled={checked[item.key].disabled || false}
      onChange={() => onChecked(item.key)}
    >
      <span tabIndex="0" className="decarb-tree-label">
        {item.title}
        {item?.count ? <p>{item.count}</p> : ""}
      </span>
    </MotifCheckbox>
  );

  return (
    <MotifTreeNode value={checkBox} disabled={checked[item.key].disabled}>
      {children}
    </MotifTreeNode>
  );
};

const flat = (array, pkey = null) => {
  let result = [];
  array.forEach((a) => {
    result.push({ ...a, pkey });
    if (Array.isArray(a.children)) {
      result = result.concat(flat(a.children, a.key));
    }
  });

  return result;
};

const convertArrayToObject = (array, key) => {
  const initialValue = {};

  return array.reduce(
    (obj, item) => ({
      ...obj,
      [item[key]]: { ...item, indeterminate: false },
    }),
    initialValue
  );
};

export const checkTowardsParent = (treeChecked, parentKey) => {
  let treeParentChecked = treeChecked;

  const childrenNode = treeChecked[parentKey].children;

  const someChildrenIndeterminate = childrenNode.some(
    ({ key }) => treeChecked[key].indeterminate
  );

  if (someChildrenIndeterminate) {
    treeParentChecked = {
      ...treeChecked,
      [parentKey]: {
        ...treeChecked[parentKey],
        checked: false,
        indeterminate: true,
      },
    };

    return treeParentChecked;
  }

  const shouldParentNodeChecked = childrenNode.every(
    ({ key }) => treeChecked[key].checked
  );
  const someChildrenNodesChecked = childrenNode.some(
    ({ key }) => treeChecked[key].checked || treeChecked[key].indeterminate
  );

  const indeterminate = shouldParentNodeChecked
    ? !shouldParentNodeChecked
    : someChildrenNodesChecked;

  treeParentChecked = {
    ...treeChecked,
    [parentKey]: {
      ...treeChecked[parentKey],
      checked: shouldParentNodeChecked,
      indeterminate,
    },
  };

  return treeParentChecked;
};

export const checkTowardsChildren = (
  treeChecked,
  keyChildrenNode,
  parentKey
) => {
  let treeChildrenChecked = treeChecked;

  for (let i = 0; i < keyChildrenNode.length; i++) {
    const childDisabled = treeChildrenChecked[keyChildrenNode[i]].disabled;

    if (childDisabled) {
      treeChildrenChecked = {
        ...treeChildrenChecked,
        [keyChildrenNode[i]]: {
          ...treeChildrenChecked[keyChildrenNode[i]],
          checked: false,
          indeterminate: false,
        },
      };

      // eslint-disable-next-line no-continue
      continue;
    }

    const subChildren = flat(treeChildrenChecked[keyChildrenNode[i]].children);

    const subChildrenDisabled = subChildren.some(({ disabled }) => disabled);

    const checked =
      treeChildrenChecked[parentKey].checked ||
      treeChildrenChecked[parentKey].indeterminate;
    const indeterminate =
      treeChildrenChecked[parentKey].indeterminate && subChildrenDisabled;

    treeChildrenChecked = {
      ...treeChildrenChecked,
      [keyChildrenNode[i]]: {
        ...treeChildrenChecked[keyChildrenNode[i]],
        checked,
        indeterminate,
      },
    };
  }

  return treeChildrenChecked;
};

export function ProjectFilter() {
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const user = useSelector((state) => state.user.user);
  const scenario = useSelector((state) => state.simulate.scenario);
  const navBarProjectsData = useSelector(
    (state) => state.simulate.navBarProjectsData
  );
  const navBarProjectsDataLoading = useSelector(
    (state) => state.simulate.navBarProjectsDataLoading
  );
  const [projectFilter, setProjectFilter] = useState("");
  const [checked, setChecked] = useState(undefined);
  const [applyProjectFilter, setApplyProjectFilter] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState(undefined);
  const [checkedProjects, setCheckedProjects] = useState({});
  const lockingData = useSelector((state) => state.project?.lockingData);
  const [isDataSaveInProgress, setIsDataSaveInProgress] = useState(false);
  

  useEffect(() => {
    if (engagement?.id) {
      dispatch(
        getNavBarProjects({
          engagementId: engagement.id,
          createdBy: user.username,
          scenarioId: scenario.scenarioID,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagement?.id]);

  /* istanbul ignore next */
  useEffect(() => {
    if (navBarProjectsData.length) {
      let ch = convertArrayToObject(flat(navBarProjectsData), "key");

      const optionsChecked = Object.keys(ch).reduce((acc, item) => {
        if (ch[item].checked && isNaN(item) && item !== "ALL") {
          return {
            ...acc,
            [item]: ch[item].title,
          };
        }

        return acc;
      }, {});

      const projects = Object.keys(optionsChecked).map((key) => {
        return {
          id: key,
          title: optionsChecked[key],
        };
      });

      projects.forEach((item) => {
        let newPosition = ch[item.id].pkey;

        while (newPosition) {
          ch = checkTowardsParent(ch, newPosition);
          newPosition = ch[newPosition].pkey;
        }
      });

      setChecked(ch);
      if (projects.length > 0) {
        onGenerateClick(projects);
      }
      setCheckedProjects(projects);

      setFilteredProjects(navBarProjectsData);
    }

    if (!navBarProjectsData.length && !navBarProjectsDataLoading) {
      setFilteredProjects(navBarProjectsData);
    }
  }, [navBarProjectsData]);

  function onProjectFilterChange(event) {
    const value = event.target.value;
    setProjectFilter(value);

    if (!value) {
      setApplyProjectFilter(false);
      onResetFilterClick();
    }
  }

  const onChecked = (position) => {
    let updatedChecked = checked;

    const childrenAtPosition = flat(updatedChecked[position].children);
    updatedChecked = {
      ...checked,
      [position]: {
        ...checked[position],
        checked: !checked[position].checked,
        indeterminate: false,
      },
    };

    const childrenKeys = childrenAtPosition.map(({ key }) => key);
    updatedChecked = checkTowardsChildren(
      updatedChecked,
      childrenKeys,
      position
    );

    let newPosition = updatedChecked[position].pkey;

    while (newPosition) {
      updatedChecked = checkTowardsParent(updatedChecked, newPosition);
      newPosition = updatedChecked[newPosition].pkey;
    }

    setChecked(updatedChecked);

    const optionsChecked = Object.keys(updatedChecked).reduce((acc, item) => {
      if (updatedChecked[item].checked && isNaN(item) && item !== "ALL") {
        return {
          ...acc,
          [item]: updatedChecked[item].title,
        };
      }

      return acc;
    }, {});

    const projects = Object.keys(optionsChecked).map((key) => {
      return {
        id: key,
        title: optionsChecked[key],
      };
    });
    setCheckedProjects(projects);
  };

  /* istanbul ignore next */
  function onResetFilterClick() {
    setProjectFilter("");
    navBarProjectsData.forEach((item) => {
      item.children.forEach((child) => {
        child.children.forEach((subChild) => {
          subChild.checked = false;
        });
      });
    });
    setFilteredProjects(navBarProjectsData);
    setChecked(convertArrayToObject(flat(navBarProjectsData), "key"));
    setCheckedProjects({});
  }

  /* istanbul ignore next */
  useEffect(() => {
    if (navBarProjectsData.length > 0) {
      let filteredProj = navBarProjectsData;

      if (projectFilter && applyProjectFilter) {
        filteredProj = navBarProjectsData[0].children
          .map((item) => {
            const ttt = item.children.filter((x) =>
              x.title.toLowerCase().includes(projectFilter.toLowerCase())
            );

            if (ttt.length) {
              return {
                ...item,
                children: ttt,
              };
            }
            return null;
          })
          .filter((x) => x);

        let allProjects = JSON.parse(JSON.stringify(navBarProjectsData[0]));
        allProjects.children = filteredProj;

        setFilteredProjects([allProjects]);
        setChecked(convertArrayToObject(flat([allProjects]), "key"));
      } else if (!projectFilter && !applyProjectFilter) {
        setFilteredProjects(navBarProjectsData);
        setChecked(convertArrayToObject(flat(navBarProjectsData), "key"));
      }

      setApplyProjectFilter(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyProjectFilter, navBarProjectsData.length]);

  /* istanbul ignore next */
  async function onGenerateClick(proj) {
    setIsDataSaveInProgress(true);

    let projectArray = [];
    let projData = proj ? proj : checkedProjects;
    projData?.map((item) => {
      projectArray.push({
        projectId: item.id,
        projectName: item.title,
      });
    });
    const request = {
      scenarioID: scenario?.scenarioID,
      scenario_Description: scenario?.scenarioDescrption,
      engagementID: engagement.id,
      engagementCode: engagement.engagementCode,
      projects: projectArray,
      createdDate: new Date(),
      createdBy: user.username,
    };
    const response = await generateGraph({ request });
    // dispatch(setScenarioDetails({ scenarioID: response[0]?.scenarioID }));
    dispatch(setGenerateGraphResponse({ generateGraphResponse: response }));
    setIsDataSaveInProgress(false);
  }

  return (
    <div className="project-filter">
      <div>
        <div className="project-filter-header">
          <div className="project-filter-header-h">
            <span className="project-filter-header-main">Select projects</span>
            <span className="project-filter-header-desc">
              Search or filter by projects below.
            </span>
          </div>
          <div className="project-filter-header-buttons">
            <Button
              variant="primary-alt"
              onClick={onResetFilterClick}
              disabled={
                (!projectFilter && !Object.keys(checkedProjects).length) ||
                (lockingData?.data?.lockStatus === 1 &&
                  lockingData?.data?.lockedBy !== user.username)
              }
            >
              <RepeatIcon />
            </Button>
            <Button
              variant="primary-alt"
              onClick={() => onGenerateClick()}
              disabled={
                !Object.keys(checkedProjects).length ||
                scenario.scenarioValidate === "N" ||
                (lockingData?.data?.lockStatus === 1 &&
                  lockingData?.data?.lockedBy !== user.username)
              }
            >
              <RefreshIcon />
            </Button>
          </div>
        </div>
        <div className="project-filter-search">
          <SearchInput
            id="sumulate-project-search"
            placeholder="Search project name"
            value={projectFilter}
            onEnter={() => setApplyProjectFilter(true)}
            onChange={onProjectFilterChange}
            onSearchButtonClick={() => setApplyProjectFilter(true)}
            hideClearButton
          />
        </div>
      </div>
      <div className="project-filter-tree">
        <LoadingIndicator
          show={filteredProjects === undefined && !checked}
          fullscreen={false}
          transparent
        >
          <MotifTree expandAll>
            {filteredProjects?.map((item) => (
              <BuildTree
                key={item.key}
                item={item}
                checked={checked}
                onChecked={onChecked}
                tooltipTheme="default"
              />
            ))}
          </MotifTree>
        </LoadingIndicator>
        {filteredProjects?.length === 0 ? (
          <div className="project-filter-no-projects">
            No projects available
          </div>
        ) : null}
      </div>
      <div className="project-filter-footer">
        <Button
          variant="primary-alt"
          onClick={onResetFilterClick}
          disabled={
            (!projectFilter && !Object.keys(checkedProjects).length) ||
            (lockingData?.data?.lockStatus === 1 &&
              lockingData?.data?.lockedBy !== user.username)
          }
        >
          <RepeatIcon />
          Reset
        </Button>
        <LoadingIndicatorEmbed show={isDataSaveInProgress}>
          <Button
            variant="primary-alt"
            onClick={() => onGenerateClick()}
            disabled={
              !Object.keys(checkedProjects).length ||
              scenario.scenarioValidate === "N" ||
              (lockingData?.data?.lockStatus === 1 &&
                lockingData?.data?.lockedBy !== user.username)
            }
          >
            <RefreshIcon />
            Generate
          </Button>
        </LoadingIndicatorEmbed>
      </div>
    </div>
  );
}
