import { useSelector } from "react-redux";
import "./SimulateHeading.scss";

export function SimulateHeading() {
  const engagement = useSelector((state) => state.engagement.engagement);

  return (
    <div className="simulate-heading">
      <span className="simulate-heading-main">{engagement.engagementName}</span>
      <span className="simulate-heading-desc">{engagement.clientName}</span>
    </div>
  );
}
