import { useEffect, useState } from "react";
import "./CreateNewEngagement.scss";
import { Button, Stepper } from "..";
import { useSelector, useDispatch } from "react-redux";
import {
  nextStep,
  nextStepIndex,
  previousStep,
  setIsSaveAndNextDisabled,
  updateEngagement,
  setStep,
  setDefaultLoader,
} from "../../store/slices/engagementSlice";
import { NextBtn, PreviousBtn, SaveAndNextBtn } from "../../util/constants";
import { saveSelectedSectors } from "../../store/slices/setupSlice";
import { setIsSkipModalOpen } from "../../store/slices/forecastSlice";
import {
  getUserModuleLock,
  SaveUserModuleLock,
} from "../../store/services/project.service";

export function CreateNewEngagement() {
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.stepper.steps);
  const engagement = useSelector((state) => state.engagement.engagement);
  const stepIndex = useSelector((state) => state.engagement.stepIndex);
  const currentStep = useSelector((state) => state.engagement.currentStep);
  const selectedSectors = useSelector((state) => state.setup.selectedSectors);
  const forecastsCount = useSelector((state) => state.forecast.forecastsCount);
  const targetsCount = useSelector((state) => state.forecast.targetsCount);
  const user = useSelector((state) => state.user.user);
  const isSaveAndNextDisabled = useSelector(
    (state) => state.engagement.isSaveAndNextDisabled
  );
  const isSkipModalOpen = useSelector(
    (state) => state.forecast.isSkipModalOpen
  );
  useEffect(() => {
    if (engagement?.id) {
      const request = {
        LockedBy: user.username,
        ModuleName: "Engagement",
        EngagementID: engagement.id,
      };
      getUserModuleLock({ request }).then((data) => {
        const lockedData = data.data.lockStatus;
        if (lockedData === 0) {
          const request = {
            LockedBy: user.username,
            ModuleName: "Engagement",
            lockGuid: engagement.id,
            Id: 0,
          };
          SaveUserModuleLock(request);
        }
      });
    } else {
      dispatch(setStep({ step: 0 }));
    }
  }, [engagement?.id, dispatch]);

  /* istanbul ignore next */
  async function onSaveAndNext() {
    try {
      let newEngagement = engagement;

      if (currentStep === 0) {
        selectedSectors.forEach(async (element) => {
          const request = {
            engagementId: engagement.id,
            masterSectorID: element,
          };
          dispatch(saveSelectedSectors(request));
        });

        const request = {
          ...newEngagement,
          updatedBy: user.username,
          updatedDate: new Date().toString(),
        };

        dispatch(updateEngagement(request));
      }

      if (currentStep === 2) {
        if (
          !targetsCount &&
          !forecastsCount &&
          isSkipModalOpen === false &&
          engagement.skip_ForeCast === 0 &&
          engagement.show_Target === 0 &&
          engagement.show_Forecast === 0
        ) {
          dispatch(setIsSkipModalOpen({ isOpen: true }));
          return;
        }
      }

      if (stepIndex === currentStep) {
        const request = {
          ...newEngagement,
          engagementSetupStepID: stepIndex + 1,
          activeFlag: 1,
          updatedBy: user.username,
        };

        dispatch(updateEngagement(request));

        dispatch(nextStepIndex());
      }

      dispatch(setIsSaveAndNextDisabled({ isDisabled: true }));
      dispatch(nextStep());
    } catch (err) {
      console.error(err);
    }
  }

  /* istanbul ignore next */
  function onPrevious() {
    dispatch(previousStep());
  }

  return (
    // <LoadingIndicatorEmbed show={defaultLoader} fullscreen={false}>
    // {!defaultLoader && (
    <div className="new-engagement">
      <div className="new-engagement-header">
        <div className="new-engagement-header-title">
          {steps[currentStep]?.stepHeading}
        </div>
        <div className="new-engagement-header-stepper">
          <Stepper
            steps={steps}
            currentStep={currentStep}
            stepIndex={stepIndex}
          />
        </div>
      </div>
      <div className={"new-engagement-content"}>
        {steps[currentStep]?.stepComponent}
      </div>

      <div className="new-engagement-footer">
        <div className="new-engagement-footer-nav-buttons">
          {currentStep !== 0 ? (
            <Button onClick={onPrevious} variant="secondary">
              {PreviousBtn}
            </Button>
          ) : (
            <></>
          )}

          {currentStep !== 4 ? (
            <Button
              onClick={onSaveAndNext}
              variant="primary"
              disabled={currentStep === steps.length || isSaveAndNextDisabled}
            >
              {currentStep === 0 ? SaveAndNextBtn : NextBtn}
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
    //   )}
    //
  );
}
