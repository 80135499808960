import "./TextArea.scss";
import {
  MotifTextArea,
  MotifFormField,
  MotifErrorMessage,
} from "@ey-xd/motif-react";

export function TextArea({
  label,
  onChange,
  value,
  maxLength,
  id = crypto.randomUUID(),
  className,
  required = false,
  disabled = false,
  placeholder = "",
  isError,
  errorMessage,
  disableErrorPlaceholder = true,
}) {

  function onInputChange(event) {
    if (event.target?.value) {
      if (maxLength && event.target?.value.length <= maxLength) {
        onChange(event);
      }
    }

    onChange(event);
  }
  return (
    <MotifFormField className="decarb-text-area">
      <MotifTextArea
        id={id}
        label={label}
        onChange={onInputChange}
        value={value}
        maxLength={maxLength}
        className={`${className}`}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
      />
      {isError ? (
        <MotifErrorMessage className="decarb-input-error-message">
          {errorMessage}
        </MotifErrorMessage>
      ) : !disableErrorPlaceholder ? (
        <div className="decarb-input-hide-error">No Error</div>
      ) : (
        <></>
      )}
    </MotifFormField>
  );
}
