import { Input, Select, TextArea } from "..";
import "./ProjectInformation.scss";
import { useEffect, useState } from "react";
import {
  projInfo,
  projName,
  projType,
  projDesc,
  projTag,
  selectProjectTag,
  createNewProjectTag,
  SearchNewProjectTags,
  duplicateProjectNameError,
  projectTypes,
  projNameTooltip,
  projTypeTooltip,
} from "../../util/constants";
import {
  getDistinctProjectsNames,
  GetProjectDistinctTags,
} from "../../store/services/project.service";
import {
  setProjectName,
  setProjectType,
  setProjectDescrption,
  setProjectTags,
  setIsNextTabButtonDisabled,
  getLockingData,
} from "../../store/slices/projectSlice";
import MultiSelectCreatableOption from "../CommonUIComponents/MultiSelectCreatableOption";
import { useDispatch, useSelector } from "react-redux";
import { LockerModalM3 } from "../LockerModal";
import { Tooltip } from "../CommonUIComponents/Tooltip/Tooltip";
import { EmissionIcon } from "../../icons";

export function ProjectInformation({ onTabStepCompleted }) {
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const [projectNameErrorMessage, setProjectNameErrorMessage] = useState("");
  const [avilableProjectTags, setAvilableTags] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  const [isLockerModalOpen, setLockerModalOpen] = useState(false);

  useEffect(() => {
    async function GetProjectDistinctTagsFn() {
      const request = { engagementId: engagement.id };
      const data = await GetProjectDistinctTags({ request });
      setAvilableTags(data);
    }
    if (engagement?.id) {
      GetProjectDistinctTagsFn();
    }
  }, [engagement?.id, dispatch]);

  useEffect(() => {
    onTabStepCompleted();
    if (
      project.projectName &&
      project.projectType &&
      !projectNameErrorMessage
    ) {
      dispatch(setIsNextTabButtonDisabled({ isDisabled: false }));
    } else {
      dispatch(setIsNextTabButtonDisabled({ isDisabled: true }));
    }
  });

  const handleOnChange = async (event, fieldName) => {
    if (fieldName === "Project Name") {
      setProjectNameErrorMessage("");
      dispatch(setProjectName({ name: event.target.value }));
    } else if (fieldName === "Project Type") {
      dispatch(setProjectType({ type: parseInt(event) }));
    } else if (fieldName === "Project Desc") {
      dispatch(setProjectDescrption({ descrp: event.target.value }));
    } else if (fieldName === "Project Tags") {
      dispatch(setProjectTags({ tags: event }));
    }
  };

  const handlecheckIfProjectNameExist = async (event) => {
    if (!project.projectName) return;
    const data = await getDistinctProjectsNames({
      request: { engagementId: engagement.id },
    });
    const dataArray = data.map((v) => v.toLowerCase());
    const duplicateProjectName = dataArray.includes(
      project.projectName.toLowerCase()
    )
      ? true
      : false;
    if (duplicateProjectName) {
      setProjectNameErrorMessage(duplicateProjectNameError);
      dispatch(setIsNextTabButtonDisabled({ isDisabled: true }));
    } else {
      setProjectNameErrorMessage("");
    }
  };
  // to get a locked user flags
  useEffect(() => {
    if (project.projectId) {
      const request = {
        LockedBy: user.username,
        ModuleName: "Project",
        EngagementID: project.projectId,
      };

      dispatch(getLockingData(request)).then((action) => {
        if (action) {
          const lockedData = action.payload?.data?.lockStatus;
          const lockedBy = action.payload?.data?.lockedBy;
          setLock(lockedData);
          setLockedby(lockedBy);
          if (lockedData === 2) {
            setLockerModalOpen(true);
          }
        }
      });
    }
  }, [dispatch]);

  return (
    <div className="project-information">
      <div className="project-information-header">
        <span className="project-information-header-main">{projInfo}</span>
      </div>
      <div className="project-information-body">
        <div className="project-information-body-project">
          <div className="project-information-body-project-details">
            <span className="project-information-body-project-details-name">
              {projName}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={projNameTooltip}
              ></Tooltip>
            </span>
            <Input
              id="base-year-scope-1-2"
              label="Input"
              data-testid="M3Step1Name"
              type={"text"}
              onChange={(e) => handleOnChange(e, "Project Name")}
              onBlur={handlecheckIfProjectNameExist}
              errorMessage={projectNameErrorMessage}
              isError={projectNameErrorMessage}
              value={project.projectName}
              disabled={
                project.projectId !== "" ||
                (lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId)
              }
            />
            <span className="project-information-body-project-details-type">
              {projType}
              <span className="required-star">*</span>
              <Tooltip
                trigger={<EmissionIcon />}
                variant="default"
                tooltipText={projTypeTooltip}
              ></Tooltip>
            </span>
            <Select
              id="base-year-scope-3"
              label="Input"
              onChange={(e) => {
                handleOnChange(e, "Project Type");
              }}
              value={project.projectType}
              options={projectTypes}
              disabled={
                project.projectId !== "" ||
                (lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId)
              }
            />
          </div>
          <div className="project-information-body-project-desc">
            <span className="project-information-body-project-desc-name">
              {projDesc}
            </span>
            <TextArea
              id="base-year-scope-1-2"
              onChange={(e) => {
                handleOnChange(e, "Project Desc");
              }}
              value={project.projectDescrption}
              placeholder="Enter project description here"
              className={"project-information-body-project-desc-textarea"}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
            />
          </div>
          <div className="project-information-body-project-tags">
            <span className="project-information-body-project-tags-name">
              {projTag}
            </span>
            <div className="multiselectDiv">
            <MultiSelectCreatableOption
              value={project.projectTags}
              onChange={(e) => {
                handleOnChange(e, "Project Tags");
              }}
              id="base-year-scope-1-2"
              avilableOptions={avilableProjectTags}
              label={selectProjectTag}
              overrideStrings={{
                create: createNewProjectTag,
                search: SearchNewProjectTags,
              }}
              disabled={
                lockedData === 1 &&
                lockedBy !== user.username &&
                project.projectId
              }
            />
            </div>
          </div>
        </div>
      </div>
      {lockedData === 2 && lockedBy === user.username && project.projectId && (
        <LockerModalM3
          isOpen={isLockerModalOpen}
          onClose={() => setLockerModalOpen(false)}
        />
      )}
    </div>
  );
}
