import React, { useEffect, useState } from "react";
import { Modal } from "../Modal/Modal";
import "./UsersConsent.scss";
import { Button } from "../Button/Button";
import {
  consentBodyText,
  consentHeading,
  consentIAgree,
  consentNotice,
  consentNoticePrivacy,
  consentPrivacyText,
} from "../../util/constants";
import { updateConsentData } from "../../store/services/engagement.service";
import { useDispatch, useSelector } from "react-redux";
import privacyNotice from "../../assets/templates/privacyNotice.pdf";
import { setUserConsent } from "../../store/slices/engagementSlice";

export function UsersConsent() {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const user = useSelector((state) => state.user.user);
  const userConsent = useSelector((state) => state.engagement.userConsent);

  // to fetch user data
  useEffect(() => {
    if (userConsent !== undefined && !userConsent) {
      setModalOpen(true);
    }
  }, [userConsent]);

  //on agree user consent form
  const onAgreeNotice = async () => {
    try {
      const userEmail = user.username;
      await updateConsentData({ request: userEmail });
      dispatch(setUserConsent({ consent: true }));
      setModalOpen(false);
    } catch (error) {}
  };

  return (
    <div>
      {isModalOpen && (
        <Modal
          className="modal-head"
          show={isModalOpen}
          size="lg"
          header={<div>{consentHeading}</div>}
          body={
            <div className="content-body">
              {consentBodyText}
              <div className="content-privacy">
                {consentNotice}
                <a className="content-notice" href={privacyNotice} download>
                  {consentPrivacyText}
                </a>
                {consentNoticePrivacy}
              </div>
            </div>
          }
          footer={
            <div>
              <Button
                onClick={onAgreeNotice}
                className="disclaimer-button"
                variant="primary"
              >
                {consentIAgree}
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
}
