import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { MotifTheme } from "@ey-xd/motif-react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";
import { AppRoutes } from "./routes/app-routes";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./configs/authConfig";

const router = createBrowserRouter(createRoutesFromElements(AppRoutes));

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <MsalProvider instance={msalInstance}>
      <MotifTheme variant="dark" />
      <RouterProvider router={router} />
    </MsalProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
