import React, { useState, useEffect, useCallback } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import "powerbi-report-authoring";
import "./PowerBI.css";
import { getPowerBIEmbedToken } from "../../store/services/powerBI.service";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { routes } from "../../routes/routes.constatns";
import { useSelector } from "react-redux";

export function PowerBI() {
  const user = useSelector((state) => state.user.user);
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const { state } = useLocation();

  const [reportConfig, setReportConfig] = useState({
    type: "report",
    embedUrl: undefined,
    id: state?.reportId,
    tokenType: models.TokenType.Aad,
    permissions: models.Permissions.All,
    viewMode: models.ViewMode.View,
    accessToken: undefined,
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
    },
  });

  // const getReport = useCallback(async () => {
  //   const response = await getPowerBIEmbedToken({
  //     request: {
  //       username: user.username,
  //       datasetId: datasetId,
  //     },
  //   });

  //   const reportConfig = {
  //     Id: reportId,
  //     EmbedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&autoAuth=true&filter=vw_EngagementMaster/EngagementCode eq '${engagementCode}'`,
  //     Type: "PowerBIReport",
  //     EmbedToken: {
  //       Token: response.embedToken,
  //       TokenId: response.embedTokenId,
  //       Expiration: response.expiration,
  //     },
  //     MinutesToExpiration: 1,
  //     DefaultPage: null,
  //     MobileDefaultPage: null,
  //   };

  //   setReportConfig({
  //     ...reportConfig,
  //     embedUrl: reportConfig.EmbedUrl,
  //     accessToken: reportConfig.EmbedToken.Token,
  //   });
  // }, [accessLevel, reportId]);

  // useEffect(() => {
  //   getReport();
  // }, [getReport]);

  function getEmbedUrl() {
    let projectQuery = project?.projectId
      ? `vw_decarb_project/ProjectID eq '${project?.projectId}'`
      : `vw_EngagementMaster/EngagementCode eq '${engagement.engagementCode}'`;

    if (state?.scenarioId) {
      projectQuery = `vw_Scenario/ScenarioId eq '${state?.scenarioId}'`;
    }

    return `https://app.powerbi.com/reportEmbed?reportId=${state?.reportId}&autoAuth=true&filter=${projectQuery}`;
  }

  return (
    <>
      <iframe
        title="Decarb-GHG Emissions Insights-M1"
        width="1140"
        height="541.25"
        src={getEmbedUrl()}
        frameBorder="0"
        allowFullScreen={true}
        className="report-container"
      ></iframe>
      {/*     <PowerBIEmbed
        embedConfig={reportConfig}
        cssClassName={"report-container"}
      />*/}
    </>
  );
}
