import { DECARB_LIBRARY_URL } from "./endpoints/decarbLibrary";
import { get, getListCrossRegion, post } from "./base.service";

async function GetAllProjectlibraryDetails() {
  // return await get(`${DECARB_LIBRARY_URL.GetAllProjectlibraryDetails}`);
  return await getListCrossRegion(
    `${DECARB_LIBRARY_URL.GetAllProjectlibraryDetails}`
  );
}

async function GetBusinessCaseSummaryData({ request }) {
  return await get(
    `${DECARB_LIBRARY_URL.GetBusinessCaseSummaryData}?ProjectID=${request.projectId}`
  );
}

async function GetProjectBusinessRulesData({ request }) {
  return await get(
    `${DECARB_LIBRARY_URL.GetProjectBusinessRulesData}?ProjectID=${request.projectId}`
  );
}

async function GetFinancialsAbatementData({ request }) {
  return await post(
    `${DECARB_LIBRARY_URL.GetFinancialsAbatementData}`,
    request
  );
}

async function AddProjecttoProjectLibrary({ request }) {
  return await post(
    `${DECARB_LIBRARY_URL.AddProjecttoProjectLibrary}?ProjectID=${request.ProjectID}&EngagementCode=${request.EngagementCode}`,
    request
  );
}

async function GetGMPTableData({ request }) {
  return await post(
    `${DECARB_LIBRARY_URL.GetGMPTableData}?EnagagementCode=${request.engagementCode}&ProjectID=${request.projectID}`,
    null
  );
}

export {
  GetAllProjectlibraryDetails,
  GetBusinessCaseSummaryData,
  GetProjectBusinessRulesData,
  GetFinancialsAbatementData,
  AddProjecttoProjectLibrary,
  GetGMPTableData,
};
