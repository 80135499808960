import "./Input.scss";
import { MotifInput, MotifErrorMessage } from "@ey-xd/motif-react";

export function Input({
  label,
  onChange,
  value,
  id = crypto.randomUUID(),
  className,
  type,
  required = false,
  disabled = false,
  onBlur,
  onKeyDown,
  maxLength = 100,
  isError,
  errorMessage,
  disableErrorPlaceholder = true,
  hideClearButton = false,
  placeholder,
  onPaste,
  allowNegative = false,
  onMouseOut,
  ...other
}) {
  const numberRegExp = /^[0-9\b]+$/;
  const decimalNumberRegExp = /^\d{1,2}(\.\d{1,2})?$/;

  function onInputChange(event) {
    if (event.target?.value) {
      if (maxLength && event.target?.value.length <= maxLength) {
        onChange(event);
      }
    }

    onChange(event);
  }

  const onInputKeyDown = (event) => {
    if (type === "number") {
      if (
        event.key === "e" ||
        event.key === "+" ||
        event.key === "E" ||
        event.key === "."
      ) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      if (!allowNegative && event.key === "-") {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }

    if (type === "decimal") {
      if (event.key === "e" || event.key === "+" || event.key === "E") {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }

    onKeyDown && onKeyDown(event);
  };

  const onInputPaste = (event) => {
    if (type === "number") {
      const pastedData = event.clipboardData.getData("text");
      if (!numberRegExp.test(pastedData)) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }

    if (type === "decimal") {
      const pastedData = event.clipboardData.getData("text");
      if (!decimalNumberRegExp.test(pastedData)) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
    }

    onPaste && onPaste(event);
  };

  return (
    <div style={{ flex: 1 }}>
      <MotifInput
        {...other}
        id={id}
        label={label}
        onChange={onInputChange}
        value={value}
        className={`decarb-input ${className}`}
        type={type}
        required={required}
        disabled={disabled}
        onBlur={onBlur}
        onKeyDown={onInputKeyDown}
        maxLength={maxLength}
        hideClearButton={hideClearButton || disabled}
        placeholder={placeholder}
        onPaste={onInputPaste}
        autoComplete={"off"}
        onMouseOut={onMouseOut}
      />
      {isError ? (
        <MotifErrorMessage className="decarb-input-error-message">
          {errorMessage}
        </MotifErrorMessage>
      ) : !disableErrorPlaceholder ? (
        <div className="decarb-input-hide-error">No Error</div>
      ) : (
        <></>
      )}
    </div>
  );
}
