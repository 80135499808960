import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getForecastRecords,
  getTargetRecords,
} from "../services/forecast.service";
import {
  GetEmisionValueByScope,
  calculateEmissions,
  getCopletedCountByEngagement,
  getM2FileUploadVersionHistory,
  getTargetValueByScope,
} from "../services/engagement.service";

const initialState = {
  forecasts: [],
  forecastsDataLoading: false,
  targets: [],
  targetsDataLoading: false,
  isSkipModalOpen: false,
  emissionsNoTargetChartData: [],
  emissionsTargetChartData: [],
  emissionsChartDataLoading: false,
  targetsChartData: [],
  targetsChartDataLoading: false,
  calculatedEmissions: {},
  calculatedEmissionsLoading: false,
  forecastsCount: 0,
  targetsCount: 0,
  scope: undefined,
  error: null,
  fileUploadHistory: [],
  fileUploadHistoryLoading: false,
  isSaveFEYInProgress: false,
  recalculateForecastTrigger: 0,
  recalculateTargetTrigger: 0,
};

export const getTargets = createAsyncThunk(
  "Forecast/GetAllTargetSpecs/GetAllRecordsByTargetSpecification",
  async (request) => {
    const apiResponse = await getTargetRecords({ request });

    return apiResponse.data;
  }
);

export const getForecasts = createAsyncThunk(
  "Engagement/GetEmisionValueByScope1",
  async (request) => {
    const apiResponse = await getForecastRecords({ request });

    return apiResponse.data;
  }
);

export const getEmissionsChart = createAsyncThunk(
  "Engagement/GetEmisionValueByScope2",
  async (request) => {
    const emissionsNoTarget = await GetEmisionValueByScope({ request });
    request.type = 2;
    const emissionsTarget = await GetEmisionValueByScope({ request });

    return {
      emissionsNoTarget: emissionsNoTarget,
      emissionsTarget: emissionsTarget,
    };
  }
);

export const getTargetChart = createAsyncThunk(
  "Engagement/GetTargetValueByScope",
  async (request) => {
    const apiResponse = await getTargetValueByScope({ request });

    return apiResponse;
  }
);

export const getCalculatedEmissions = createAsyncThunk(
  "Engagement/CalculateEmissions/CalculateEmissions",
  async (request) => {
    const apiResponse = await calculateEmissions({ request });

    return apiResponse;
  }
);

export const getCompletedForecastsTargetsCount = createAsyncThunk(
  "Engagement/GetCopletedCountByEngagement",
  async (request) => {
    const apiResponse = await getCopletedCountByEngagement({ request });

    return apiResponse[0];
  }
);

export const getFileUploadHistoryM2 = createAsyncThunk(
  "Engagement/GetM2FileUploadVersionHistory",
  async (request) => {
    const apiResponse = await getM2FileUploadVersionHistory({ request });

    const sortedData = apiResponse.sort(function (a, b) {
      return new Date(b.uploadDate) - new Date(a.uploadDate);
    });

    sortedData[0].liveVersion = true;

    return sortedData;
  }
);

const forecastSlice = createSlice({
  name: "forecast",
  initialState,
  reducers: {
    setScope(state, action) {
      const { scope } = action.payload;

      state.scope = scope;
    },
    setIsSkipModalOpen(state, action) {
      const { isOpen } = action.payload;

      state.isSkipModalOpen = isOpen;
    },
    setIsSaveFEYInProgress(state, action) {
      const { isSaveInProgress } = action.payload;

      state.isSaveFEYInProgress = isSaveInProgress;
    },
    triggerForecastRecalculate(state) {
      state.recalculateForecastTrigger = state.recalculateForecastTrigger + 1;
    },
    triggerTargetRecalculate(state) {
      state.recalculateTargetTrigger = state.recalculateTargetTrigger + 1;
    },
    dropEmissionsChartState(state) {
      state.emissionsNoTargetChartData =
        initialState.emissionsNoTargetChartData;
      state.emissionsTargetChartData = initialState.emissionsTargetChartData;
      state.targetsChartData = initialState.targetsChartData;
    },
    dropForecastChartState(state) {
      state.forecasts = initialState.forecasts;
      state.forecastsDataLoading = initialState.forecastsDataLoading;
      state.targets = initialState.targets;
      state.targetsDataLoading = initialState.targetsDataLoading;
      state.isSkipModalOpen = initialState.isSkipModalOpen;
      state.emissionsNoTargetChartData =
        initialState.emissionsNoTargetChartData;
      state.emissionsChartDataLoading = initialState.emissionsChartDataLoading;
      state.emissionsTargetChartData = initialState.emissionsTargetChartData;
      state.targetsChartData = initialState.targetsChartData;
      state.targetsChartDataLoading = initialState.targetsChartDataLoading;
      state.calculatedEmissions = initialState.calculatedEmissions;
      state.calculatedEmissionsLoading =
        initialState.calculatedEmissionsLoading;
      state.forecastsCount = initialState.forecastsCount;
      state.targetsCount = initialState.targetsCount;
    },
    dropForecastState(state) {
      state.forecasts = initialState.forecasts;
      state.forecastsDataLoading = initialState.forecastsDataLoading;
      state.targets = initialState.targets;
      state.targetsDataLoading = initialState.targetsDataLoading;
      state.isSkipModalOpen = initialState.isSkipModalOpen;
      state.emissionsNoTargetChartData =
        initialState.emissionsNoTargetChartData;
      state.emissionsChartDataLoading = initialState.emissionsChartDataLoading;
      state.emissionsTargetChartData = initialState.emissionsTargetChartData;
      state.targetsChartData = initialState.targetsChartData;
      state.targetsChartDataLoading = initialState.targetsChartDataLoading;
      state.calculatedEmissions = initialState.calculatedEmissions;
      state.calculatedEmissionsLoading =
        initialState.calculatedEmissionsLoading;
      state.forecastsCount = initialState.forecastsCount;
      state.targetsCount = initialState.targetsCount;
      state.resetM2DataLoading = initialState.resetM2DataLoading;
      state.scope = initialState.scope;
      state.error = initialState.error;
      state.fileUploadHistory = initialState.fileUploadHistory;
      state.fileUploadHistoryLoading = initialState.fileUploadHistoryLoading;
      state.isSaveFEYInProgress = initialState.isSaveFEYInProgress;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTargets.pending, (state) => {
        state.targetsDataLoading = true;
      })
      .addCase(getTargets.fulfilled, (state, action) => {
        state.targetsDataLoading = false;
        state.targets = action.payload;
      })
      .addCase(getTargets.rejected, (state, action) => {
        state.targetsDataLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getForecasts.pending, (state) => {
        state.forecastsDataLoading = true;
      })
      .addCase(getForecasts.fulfilled, (state, action) => {
        state.forecastsDataLoading = false;
        state.forecasts = action.payload;
      })
      .addCase(getForecasts.rejected, (state, action) => {
        state.forecastsDataLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getEmissionsChart.pending, (state) => {
        state.emissionsChartDataLoading = true;
      })
      .addCase(getEmissionsChart.fulfilled, (state, action) => {
        state.emissionsChartDataLoading = false;
        state.emissionsNoTargetChartData = action.payload.emissionsNoTarget;
        state.emissionsTargetChartData = action.payload.emissionsTarget;
      })
      .addCase(getEmissionsChart.rejected, (state, action) => {
        state.emissionsChartDataLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getTargetChart.pending, (state) => {
        state.targetsChartDataLoading = true;
      })
      .addCase(getTargetChart.fulfilled, (state, action) => {
        state.targetsChartDataLoading = false;
        state.targetsChartData = action.payload;
      })
      .addCase(getTargetChart.rejected, (state, action) => {
        state.targetsChartDataLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getCalculatedEmissions.pending, (state) => {
        state.calculatedEmissionsLoading = true;
      })
      .addCase(getCalculatedEmissions.fulfilled, (state, action) => {
        state.calculatedEmissionsLoading = false;
        state.calculatedEmissions = action.payload;
      })
      .addCase(getCalculatedEmissions.rejected, (state, action) => {
        state.calculatedEmissionsLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getCompletedForecastsTargetsCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompletedForecastsTargetsCount.fulfilled, (state, action) => {
        state.loading = false;
        state.forecastsCount = action.payload.foreCastCount;
        state.targetsCount = action.payload.targetCastCount;
      })
      .addCase(getCompletedForecastsTargetsCount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getFileUploadHistoryM2.pending, (state) => {
        state.fileUploadHistoryLoading = true;
      })
      .addCase(getFileUploadHistoryM2.fulfilled, (state, action) => {
        state.fileUploadHistoryLoading = false;
        state.fileUploadHistory = action.payload;
      })
      .addCase(getFileUploadHistoryM2.rejected, (state, action) => {
        state.fileUploadHistoryLoading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setScope,
  dropForecastState,
  setIsSkipModalOpen,
  dropForecastChartState,
  dropEmissionsChartState,
  setIsSaveFEYInProgress,
  triggerForecastRecalculate,
  triggerTargetRecalculate,
} = forecastSlice.actions;

export default forecastSlice.reducer;
