import React, { useState, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import HighchartsTreemap from "highcharts/modules/treemap";
import NoDataToDisplay from "highcharts/modules/no-data-to-display";
import Highcharts from "highcharts";
import { LoadingIndicator } from "../../../LoadingIndicator/LoadingIndicator";
import { useSelector } from "react-redux";
import "./EmissionCashFlowGraph.scss";

export function EmissionCashFlowGraph({ loader }) {
  HighchartsTreemap(Highcharts);
  NoDataToDisplay(Highcharts);
  const project = useSelector((state) => state.project);
  const engagement = useSelector((state) => state.engagement.engagement);
  const [chartOptions, setChartOptions] = useState({});
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (Object.keys(project?.projectCashFlowData).length > 0) {
      updateChartDataSet(project.projectCashFlowData);
    }
  }, [project?.projectCashFlowData]);

  useEffect(() => {
    if (loader) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [loader]);

  // Update chart with data set
  const updateChartDataSet = (data) => {
    const graphData = {
      Benefit: [...data?.Benefit],
      Cost: [...data?.Cost],
      Year: [...data?.Year],
      Cashflow: [...data?.Cashflow],
    };
    setChartOptions({
      chart: {
        zooming: {
          type: "xy",
        },
      },
      title: {
        text: "",
        align: "left",
      },
      xAxis: [
        {
          categories: graphData?.Year,
          crosshair: true,
          title: {
            text: "Years",
            style: {
              color: Highcharts.getOptions().colors[1],
            },
          },
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
      ],
      yAxis: [
        {
          // Secondary yAxis
          title: {
            text: "",
            style: {
              color: Highcharts.getOptions().colors[2],
            },
          },
          labels: {
            format: "{value}$",
            style: {
              color: Highcharts.getOptions().colors[2],
            },
          },
          opposite: true,
        },
        {
          // Primary yAxis
          labels: {
            format: "{value}$",
            style: {
              color: "#afaeba",
            },
          },
          title: {
            text: engagement?.mostRecentYear + 1 + "($)",
            style: {
              color: "#afaeba",
            },
          },
        },
      ],
      tooltip: {
        shared: true,
      },
      legend: {
        align: "center",
        verticalAlign: "bottom",
        backgroundColor: "#fff",
      },
      series: [
        {
          name: "Discounted benefits",
          type: "column",
          yAxis: 1,
          data: graphData?.Benefit,
          tooltip: {
            valueSuffix: "$",
          },
        },
        {
          name: "Discounted cost",
          type: "column",
          yAxis: 1,
          data: graphData?.Cost,
          tooltip: {
            valueSuffix: "$",
          },
        },
        {
          name: "Discounted cumulative cashflow",
          type: "spline",
          data: graphData?.Cashflow,
          tooltip: {
            valueSuffix: "$",
          },
          dashStyle: "dash",
          yAxis: 1,
          color: "#fff",
        },
      ],
      noData: {
        style: {
          fontWeight: "100",
          fontSize: "15px",
          color: "#DEDEE2",
        },
        position: {
          align: "center",
          verticalAlign: "middle",
        },
        text: "No data available",
      },
    });
  };
  return (
    <div className="emissions-graph-section">
      <div className="emissions-graph-title"></div>
      <div className="emissions-graph-content">
        <LoadingIndicator show={showLoader} fullscreen={false} transparent>
          <div className="emissions-cashflow-graph-content">
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
        </LoadingIndicator>
      </div>
    </div>
  );
}
