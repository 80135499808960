import React, { memo } from "react";
import { MotifChipGroup, MotifChip } from "@ey-xd/motif-react";

export default memo(({ node, spec }) => {
  let data = [];
  if (spec === "entity") {
    const emissionSpec = [
      ...new Set([
        node.data.entity_level1,
        node.data.entity_level2,
        node.data.entity_level3,
        node.data.entity_level4,
      ]),
    ];
    data = emissionSpec.join("-");
  }
  if (spec === "region") {
    const regionSpec = [
      ...new Set([
        node.data.region_level1,
        node.data.region_level2,
        node.data.region_level3,
      ]),
    ];
    data = regionSpec.join("-");
  }
  if (spec === "category") {
    const categorySpec = [
      ...new Set([
        node.data.category_level1,
        node.data.category_level2,
        node.data.category_level3,
      ]),
    ];
    data = categorySpec.join("-");
  }
  if (spec === "custom") {
    const customSpec = [
      ...new Set([
        node.data.custom_specification1,
        node.data.custom_specification2,
        node.data.custom_specification3,
      ]),
    ];
    data = customSpec.join("-");
  }
  if (spec === "scope") {
    const scopeSpec = node.data.scope.split(",");
    data = scopeSpec.join("-");
  }
  if (data.length === 0) {
    return <span>-</span>;
  }
  if (data.length === 1 && data[0] === "-") {
    return <span>-</span>;
  }
  if (data.length === 1 && data[0] !== "-") {
    return <MotifChip title={data[0]}>{data[0]}</MotifChip>;
  } else {
    return (
      <MotifChipGroup>
        <MotifChip title={data}>{data}</MotifChip>
      </MotifChipGroup>
    );
  }
});
