import React, { useEffect, useState } from "react";
import { Modal } from "../Modal/Modal";
import { Button } from "../Button/Button";
import {
  ReleaseUserModuleLock,
  UpdateUserModuleLock,
} from "../../store/services/project.service";
import { useDispatch, useSelector } from "react-redux";
import { FeatureIcon } from "../../icons/featureIcon";
import { getLockingData } from "../../store/slices/projectSlice";

export function LockerModal({ isOpen, onClose }) {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const engagement = useSelector((state) => state.engagement?.engagement);
  const user = useSelector((state) => state.user?.user);
  const lockingData = useSelector((state) => state.project?.lockingData);
  const [isReleasingLock, setIsReleasingLock] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose();
  };
  // to get a locked user flags
  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  // useEffect hook for countdown timer
  useEffect(() => {
    let interval;
    if (isModalOpen) {
      interval = setInterval(() => {
        setIsModalOpen(false); // Close the modal after 1 minute
        onLockNotApply(); // Release the lock
      }, 60000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isModalOpen]);

  // to update the lock if user wants to continue lock
  async function onLockApply() {
    const request = {
      LockedBy: user.username,
      ModuleName: "Engagement",
      engagementID: engagement.id,
      id: lockingData.data.id,
    };
    const response = await UpdateUserModuleLock(request);
    setIsModalOpen(false);
    if (response) {
      setIsModalOpen(false);
      dispatch(
        getLockingData({
          LockedBy: user.username,
          ModuleName: "Engagement",
          EngagementID: engagement.id,
        })
      );
    }
  }

  // to update the lock if user wants to discontinue lock
  function onLockNotApply() {
    if (isReleasingLock) return;
    setIsReleasingLock(true);
    const request = {
      LockedBy: user.username,
      ModuleName: "Engagement",
      engagementID: engagement.id,
      id: lockingData.data.id,
    };
    ReleaseUserModuleLock(request).then((data) => {
      setIsModalOpen(false);
      setIsReleasingLock(false);
      dispatch(
        getLockingData({
          LockedBy: user.username,
          ModuleName: "Engagement",
          EngagementID: engagement.id,
        })
      );
    });
  }

  return (
    <div>
      <Modal
        show={isModalOpen}
        onClose={handleCloseModal}
        header={
          <div className="file-version-history-modal-header">
            <div className="motif-fab">
              <FeatureIcon />
            </div>
            <span>Reapply lock ? </span>
          </div>
        }
        body={
          <span>
            The lock on your engagement for M1 and M2 has expired. Would you
            like to reapply the lock?
          </span>
        }
        footer={
          <div className="view-endagement-delete-modal-footer">
            <Button variant="secondary" onClick={onLockNotApply} className={""}>
              No
            </Button>
            <Button variant="primary" onClick={onLockApply} className={""}>
              Yes reapply
            </Button>
          </div>
        }
        size="md"
      />
    </div>
  );
}
