import { useEffect } from "react";
import "./Financials.scss";
import { FinancialsTable } from "./FinancialsTable";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes/routes.constatns";
import { setIsNextTabButtonDisabled } from "../../store/slices/projectSlice";
import { useDispatch, useSelector } from "react-redux";
import { FinancialsForm } from "./FinancialsForm";
import { Button } from "../Button/Button";
import { ControlSlider } from "../../icons/controlSlider";
import { getGMPRulesMapData } from "../../store/services/gmp.service";
import { setrulesMapData } from "../../store/slices/gmpSlice";

export function Financials({ onTabStepCompleted }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const engagement = useSelector((state) => state.engagement.engagement);
  const project = useSelector((state) => state.project.project);
  const lockingData = useSelector((state) => state.project?.lockingData);
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    dispatch(setIsNextTabButtonDisabled({ isDisabled: false }));
    onTabStepCompleted();
  }, []);

  useEffect(() => {
    if (engagement?.id && project?.projectId) {
      // getParameterList();
      getRulesMapData();
    }
  }, [engagement?.id, dispatch, project?.projectId]);

  // Navigate to Add GMP page
  function navigateToAddGMP() {
    // dispatch(dropProjectState());
    navigate(`/create-new-engagement/` + `${routes.addGMP}`, {
      state: {
        engagementId: engagement.id,
      },
      gestureEnabled: false,
    });
  }

  //Fetching GMP rules maped data
  const getRulesMapData = async () => {
    const request = {
      projectId: project?.projectId,
    };
    const data = await getGMPRulesMapData({ request });
    dispatch(setrulesMapData({ rulesMapData: data }));
  };

  return (
    <div className="financials">
      <div className="financials-header">
        <span className="financials-header-main">
          <Button
            variant="text"
            className="gmp-button-link"
            onClick={navigateToAddGMP}
          >
            <ControlSlider /> &nbsp;Financial parameters
          </Button>
        </span>
      </div>
      <div class="motif-row">
        <div class="motif-col-xs-4 motif-col-md-2">
          <FinancialsForm />
        </div>
        <div class="motif-col-xs-4 motif-col-md-6">
          <FinancialsTable />
        </div>
      </div>
    </div>
  );
}
